import { all, fork, select, takeLatest, put, call, takeEvery, take, cancel } from "redux-saga/effects";
import { actions, getBoothsAction, syncBoothDataAction } from "../reducers/booth";
import { db, rsfDB } from "../firebase";

// call은 동기, fork는 비동기 요청
function* getBooths() {
  try {
    const snapshot = yield call(rsfDB.getCollection, db.collection("booth").orderBy("title"));
    let booths = [];
    snapshot.forEach((booth) => {
      booths.push({ id: booth.id, ...booth.data(), order: booth.data().order ?? 10 });
    });
    yield put({
      type: actions.GET_BOOTHS_SUCCESS,
      data: booths,
    });
  } catch (err) {
    yield put({
      type: actions.GET_BOOTHS_FAILURE,
      error: err.message,
    });
  }
}
function* syncBoothData(action) {
  try {
    let images = [];
    let documents = [];
    let videos = [];
    const videoSnapshot = yield call(rsfDB.getCollection, `booth/${action.id}/videos`);
    const imageSnapshot = yield call(rsfDB.getCollection, `booth/${action.id}/images`);
    const documentSnapshot = yield call(rsfDB.getCollection, `booth/${action.id}/documents`);
    videoSnapshot.forEach((doc) => {
      videos = [...videos, { ...doc.data(), id: doc.id }];
    });
    imageSnapshot.forEach((doc) => {
      images = [...images, { ...doc.data(), id: doc.id }];
    });
    documentSnapshot.forEach((doc) => {
      documents = [...documents, { ...doc.data(), id: doc.id }];
    });
    yield put({
      type: actions.SYNC_BOOTH_DATA_SUCCESS,
      id: action.id,
      data: { ...action.data, videos, images, documents },
    });
  } catch (err) {
    yield put({
      type: actions.GET_BOOTHS_FAILURE,
      error: err.message,
    });
  }
}
function* getBoothData(action) {
  try {
    // sync 로 가져오기.
    if (action.sync) {
      const task = yield fork(rsfDB.syncDocument, `booth/${action.id}`, {
        successActionCreator: (data) => syncBoothDataAction(action.id, data.data()),
      });
      yield put({
        type: actions.GET_BOOTH_DATA_SUCCESS,
        id: action.id,
        sync: action.sync,
      });
      yield take(actions.SYNC_BOOTH_DONE);
      yield cancel(task);
    } else {
      //기본 1회성 가져오기. 기본값
      const snapshot = yield call(rsfDB.getDocument, `booth/${action.id}`);
      const boothData = snapshot.data();
      let images = [];
      let documents = [];
      let videos = [];

      const videoSnapshot = yield call(rsfDB.getCollection, `booth/${action.id}/videos`);
      const imageSnapshot = yield call(rsfDB.getCollection, `booth/${action.id}/images`);
      const documentSnapshot = yield call(rsfDB.getCollection, `booth/${action.id}/documents`);
      videoSnapshot.forEach((doc) => {
        videos = [...videos, { ...doc.data(), id: doc.id }];
      });
      imageSnapshot.forEach((doc) => {
        images = [...images, { ...doc.data(), id: doc.id }];
      });
      documentSnapshot.forEach((doc) => {
        documents = [...documents, { ...doc.data(), id: doc.id }];
      });
      yield put({
        type: actions.GET_BOOTH_DATA_SUCCESS,
        id: action.id,
        data: { ...boothData, videos, images, documents },
        sync: action.sync,
      });
    }
  } catch (err) {
    yield put({
      type: actions.GET_BOOTH_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* updateBoothData(action) {
  const { id, data } = action;
  try {
    yield call(rsfDB.updateDocument, `booth/${id}`, `${data.target}`, data.value);
    const snapshot = yield call(rsfDB.getDocument, `booth/${action.id}`);
    const boothData = snapshot.data();
    yield put({
      type: actions.UPDATE_BOOTH_DATA_SUCCESS,
      data: boothData,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_BOOTH_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* addBoothContent(action) {
  // video, image, doc, livePrograms을 위한 add
  const { id, target, data } = action;
  try {
    yield call(rsfDB.addDocument, `booth/${id}/${target}`, data);
    const snapshot = yield call(rsfDB.getCollection, `booth/${id}/${target}`);
    let boothData = [];
    snapshot.forEach((doc) => {
      boothData = [...boothData, { ...doc.data(), id: doc.id }];
    });
    yield put({
      type: actions.ADD_BOOTH_CONTENT_SUCCESS,
      target,
      data: boothData,
    });
  } catch (err) {
    yield put({
      type: actions.ADD_BOOTH_CONTENT_FAILURE,
      error: err.message,
    });
  }
}
function* deleteBoothContent(action) {
  // video, image, doc, livePrograms을 위한 delete
  const { id, target, targetId } = action;
  try {
    yield call(rsfDB.deleteDocument, `booth/${id}/${target}/${targetId}`);
    const snapshot = yield call(rsfDB.getCollection, `booth/${id}/${target}`);
    let boothData = [];
    snapshot.forEach((doc) => {
      boothData = [...boothData, { ...doc.data(), id: doc.id }];
    });
    yield put({
      type: actions.DELETE_BOOTH_CONTENT_SUCCESS,
      target,
      data: boothData,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_BOOTH_CONTENT_FAILURE,
      error: err.message,
    });
  }
}
function* changeOrderBoothContent(action) {
  // video, image, doc을 위한 delete
  const { id, target, targetId, order } = action;
  try {
    yield call(rsfDB.updateDocument, `booth/${id}/${target}/${targetId}`, "order", order);
    const snapshot = yield call(rsfDB.getCollection, `booth/${id}/${target}`);
    let boothData = [];
    snapshot.forEach((doc) => {
      boothData = [...boothData, { ...doc.data(), id: doc.id }];
    });
    yield put({
      type: actions.CHANGE_ORDER_BOOTH_CONTENT_SUCCESS,
      target,
      data: boothData,
    });
  } catch (err) {
    yield put({
      type: actions.CHANGE_ORDER_BOOTH_CONTENT_FAILURE,
      error: err.message,
    });
  }
}

function* getLiveProgram(action) {
  const { id } = action;
  const task = yield fork(rsfDB.syncCollection, `booth/${id}/livePrograms`, {
    successActionCreator: (data) => {
      let livePrograms = [];
      data.forEach((program) => {
        livePrograms.push({ id: program.id, ...program.data() });
      });
      return {
        type: actions.GET_LIVE_PROGRAM_SUCCESS,
        data: livePrograms,
      };
    },
    failureActionCreator: (err) => ({
      type: actions.GET_LIVE_PROGRAM_FAILURE,
      error: err.message,
    }),
  });
  yield take(actions.SYNC_LIVE_PROGRAM_DONE);
  yield cancel(task);
}

function* getAllLivePrograms() {
  try {
    yield put(getBoothsAction());
    yield take(actions.GET_BOOTHS_SUCCESS);
    const { boothData } = yield select((state) => state.booth);
    let livePrograms = [];
    const snapshot = yield all(boothData.map((booth) => call(rsfDB.getCollection, `booth/${booth.id}/livePrograms`)));
    snapshot.forEach((program, i) => {
      let liveProgram = [];
      program.forEach((p) => {
        liveProgram = [...liveProgram, { id: p.id, ...p.data() }];
      });
      livePrograms.push({
        id: boothData[i].id,
        title: boothData[i].title,
        description: boothData[i].description,
        logo: boothData[i].logo,
        isActive: boothData[i].isActive,
        livePrograms: liveProgram,
      });
    });
    yield put({
      type: actions.GET_ALL_LIVE_PROGRAMS_SUCCESS,
      data: livePrograms,
    });
  } catch (err) {
    yield put({
      type: actions.GET_ALL_LIVE_PROGRAMS_FAILURE,
      error: err.message,
    });
  }
}
function* watchGetBooths() {
  yield takeLatest(actions.GET_BOOTHS_REQUEST, getBooths);
}
function* watchGetBoothData() {
  yield takeLatest(actions.GET_BOOTH_DATA_REQUEST, getBoothData);
}
function* watchUpdateBoothData() {
  yield takeLatest(actions.UPDATE_BOOTH_DATA_REQUEST, updateBoothData);
}
function* watchAddBoothContent() {
  yield takeLatest(actions.ADD_BOOTH_CONTENT_REQUEST, addBoothContent);
}
function* watchDeleteBoothContent() {
  yield takeLatest(actions.DELETE_BOOTH_CONTENT_REQUEST, deleteBoothContent);
}
function* watchSyncBoothData() {
  yield takeEvery(actions.SYNC_BOOTH_DATA_REQUEST, syncBoothData);
}
function* watchChangeOrderBoothContent() {
  yield takeLatest(actions.CHANGE_ORDER_BOOTH_CONTENT_REQUEST, changeOrderBoothContent);
}
function* watchGetLiveProgram() {
  yield takeEvery(actions.GET_LIVE_PROGRAM_REQUEST, getLiveProgram);
}
function* watchGetAllLivePrograms() {
  yield takeEvery(actions.GET_ALL_LIVE_PROGRAMS_REQUEST, getAllLivePrograms);
}
export default function* boothSaga() {
  yield all([
    fork(watchGetBooths),
    fork(watchGetBoothData),
    fork(watchUpdateBoothData),
    fork(watchAddBoothContent),
    fork(watchDeleteBoothContent),
    fork(watchChangeOrderBoothContent),
    fork(watchSyncBoothData),
    fork(watchGetLiveProgram),
    fork(watchGetAllLivePrograms),
  ]);
}
