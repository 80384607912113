import { all, fork, takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import { actions } from '../reducers/video';
import { db, rsfDB } from '../firebase';
import { addChannelAction } from '../reducers/comment';

// call은 동기, fork는 비동기 요청
function* getVideos() {
  try {
    const snapshot = yield call(rsfDB.getCollection, db.collection('video').orderBy('title'));
    let videos = [];
    snapshot.forEach(video => {
      videos.push({ id: video.id, ...video.data() });
    });
    yield put({
      type: actions.GET_VIDEOS_SUCCESS,
      data: videos,
    });
  } catch (err) {
    yield put({
      type: actions.GET_VIDEOS_FAILURE,
      error: err.message,
    });
  }
}
function* getVideoData(action) {
  try {
    const snapshot = yield call(rsfDB.getDocument, `video/${action.id}`);
    const videoData = snapshot.data();
    yield put({
      type: actions.GET_VIDEO_DATA_SUCCESS,
      id: action.id,
      data: videoData,
    });
  } catch (err) {
    yield put({
      type: actions.GET_VIDEO_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* updateVideohData(action) {
  const { id, data } = action;
  try {
    yield call(rsfDB.updateDocument, `video/${id}`, `${data.target}`, data.value);
    const snapshot = yield call(rsfDB.getDocument, `video/${action.id}`);
    const videoData = snapshot.data();
    yield put({
      type: actions.UPDATE_VIDEO_DATA_SUCCESS,
      data: videoData,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_VIDEO_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* addVideoData(action) {
  const { data } = action;
  try {
    const createdAt = new Date();
    const ref = yield call(rsfDB.addDocument, `video`, { ...data, createdAt });
    yield put(
      addChannelAction(ref.id, {
        category: 'video',
        title: data.title,
        description: data.questions?.join('/'),
        createdAt,
      }),
    );
    const snapshot = yield call(rsfDB.getCollection, `video`);
    const videos = [];
    snapshot.forEach(video => {
      videos.push({ id: video.id, ...video.data() });
    });
    yield put({
      type: actions.ADD_VIDEO_DATA_SUCCESS,
      data: videos,
    });
  } catch (err) {
    yield put({
      type: actions.ADD_VIDEO_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* deleteVideoData(action) {
  const { id } = action;
  try {
    yield call(rsfDB.deleteDocument, `video/${id}`);
    const snapshot = yield call(rsfDB.getCollection, `video`);
    let videoData = [];
    snapshot.forEach(video => {
      videoData.push({ id: video.id, ...video.data() });
    });
    yield put({
      type: actions.DELETE_VIDEO_DATA_SUCCESS,
      data: videoData,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_VIDEO_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* watchGetVideos() {
  yield takeLatest(actions.GET_VIDEOS_REQUEST, getVideos);
}
function* watchGetVideoData() {
  yield takeLatest(actions.GET_VIDEO_DATA_REQUEST, getVideoData);
}
function* watchUpdateVideoData() {
  yield takeLatest(actions.UPDATE_VIDEO_DATA_REQUEST, updateVideohData);
}
function* watchAddVideoData() {
  yield takeLatest(actions.ADD_VIDEO_DATA_REQUEST, addVideoData);
}
function* watchDeleteVideoData() {
  yield takeLatest(actions.DELETE_VIDEO_DATA_REQUEST, deleteVideoData);
}
export default function* videoSaga() {
  yield all([
    fork(watchGetVideos),
    fork(watchGetVideoData),
    fork(watchUpdateVideoData),
    fork(watchAddVideoData),
    fork(watchDeleteVideoData),
  ]);
}
