import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Avatar from '../Avatar';
import { image } from 'faker';

const MyComment = styled.div`
  align-self: flex-end;
  background-color: #2c333e;
  padding: 8px 16px;
  max-width: calc(100% - 150px);
  border-radius: 6px;
  color: white;
  margin-bottom: 6px;
  word-break: break-all;
`;
const OtherComment = styled.div`
  .text {
    margin-top: ${(props) => (props.linked ? '-6px' : '6px')};
    display: inline-block;
    margin-left: 38px;
    background-color: white;
    padding: 8px 16px;
    max-width: calc(100% - 150px);
    border-radius: 6px;
    color: black;
    word-break: break-all;
  }
`;
const Profile = styled.div`
  position: relative;
  margin-top: ${(props) => (props.first ? ' 0 ' : '-8px')};
  width: 150px;
  height: 20px;
  > p {
    position: absolute;
    top: -10px;
    left: 38px;
    font-size: 0.9em;
    font-weight: 600;
    margin-bottom: 0;
    height: 20px;
    overflow: hidden;
  }
`;
// const Avatar = styled.img`
//   display: block;
//   width: 32px;
//   height: 32px;
//   border-radius: 50%;
//   border: 1px solid black;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;
const MyImageContainer = styled.div`
  max-width: calc(100% - 150px);
  padding: 6px;
  padding-bottom: 1px;
  background-color: #2c333e;
  border-radius: 6px;
  align-self: flex-end;
  margin-bottom: 6px;
  img {
    width: 100%;
  }
`;
const ImageContainer = styled.div`
  max-width: calc(100% - 150px);
  padding: 6px;
  padding-bottom: 1px;
  background-color: white;
  border-radius: 6px;
  align-self: flex-start;
  margin-bottom: 14px;
  margin-top: ${(props) => (props.linked ? '-6px' : '6px')};
  margin-left: 38px;
  img {
    width: 100%;
  }
`;

function Comment({ data, linked, first, onClick }) {
  const { userData } = useSelector((state) => state.user);
  if (data.isDeleted) return <></>;
  if (userData.uid === data.uid) {
    if (data.type === 'text')
      return (
        <MyComment className='my-comment' onClick={onClick}>
          {data.value}
        </MyComment>
      );
    else if (data.type === 'image')
      return (
        <>
          {/* {isImageLoaded && ( */}
          <MyImageContainer onClick={onClick}>
            <img src={data.value?.url} alt='채팅 이미지' />
          </MyImageContainer>
          {/* )} */}
        </>
      );
  } else {
    if (data.type === 'text')
      return (
        <>
          <OtherComment className='my-comment' linked={linked}>
            {!linked && (
              <Profile first={first}>
                <Avatar id={data.avatar} />
                <p>{data.userName}</p>
              </Profile>
            )}
            <p className='text' onClick={onClick}>
              {data.value}
            </p>
          </OtherComment>
        </>
      );
    else if (data.type === 'image')
      return (
        <OtherComment className='my-comment' linked={linked}>
          {!linked && (
            <Profile first={first}>
              <Avatar id={data.avatar} />
              <p>{data.userName}</p>
            </Profile>
          )}
          <ImageContainer onClick={onClick}>
            <img src={data.value?.url} alt='채팅 이미지' />
          </ImageContainer>
        </OtherComment>
      );
  }
}

export default Comment;
