import { all, fork, put, call, takeEvery, take, cancel } from 'redux-saga/effects';
import { actions } from '../reducers/ranking';
import { rsfDB, firestore } from '../firebase';
import firebase from 'firebase';

// call은 동기, fork는 비동기 요청
function* getMyClassPoint({ id }) {
  const task = yield fork(rsfDB.syncDocument, `class/${id}`, {
    successActionCreator: data => ({
      type: actions.GET_MY_CLASS_DATA_SUCCESS,
      data: { id: data.id, ...data.data() },
    }),
    failureActionCreator: err => ({
      type: actions.GET_MY_CLASS_DATA_FAILURE,
      err: err.message,
    }),
  });
  yield take(actions.SYNC_MY_CLASS_DATA_DONE);
  yield cancel(task);
}
function* getUserRanking({ count }) {
  const task = yield fork(rsfDB.syncCollection, firebase.firestore().collection(`user`).where('status', '==', 'student').orderBy('point', 'desc').limit(count), {
    successActionCreator: data => {
      const users = [];
      data.forEach(doc => {
        users.push({
          id: doc.data().id,
          userName: doc.data().userName,
          point: doc.data().point,
          avatar: doc.data().avatar,
        });
      });
      return {
        type: actions.GET_USER_RANKING_SUCCESS,
        data: users,
      };
    },
    failureActionCreator: err => ({
      type: actions.GET_USER_RANKING_FAILURE,
      err: err.message,
    }),
  });
  yield take(actions.SYNC_RANKING_DONE);
  yield cancel(task);
}
function* getClassRanking({ count }) {
  const task = yield fork(rsfDB.syncCollection, firebase.firestore().collection(`class`).orderBy('point', 'desc').limit(count), {
    successActionCreator: data => {
      const classes = [];
      data.forEach(doc => {
        classes.push({
          id: doc.data().id,
          ...doc.data(),
        });
      });
      return {
        type: actions.GET_CLASS_RANKING_SUCCESS,
        data: classes,
      };
    },
    failureActionCreator: err => ({
      type: actions.GET_CLASS_RANKING_FAILURE,
      err: err.message,
    }),
  });
  yield take(actions.SYNC_RANKING_DONE);
  yield cancel(task);
}

function* watchGetMyClassPoint() {
  yield takeEvery(actions.GET_MY_CLASS_DATA_REQUEST, getMyClassPoint);
}
function* watchGetUserRanking() {
  yield takeEvery(actions.GET_USER_RANKING_REQUEST, getUserRanking);
}
function* watchGetClassRanking() {
  yield takeEvery(actions.GET_CLASS_RANKING_REQUEST, getClassRanking);
}

export default function* rankingSaga() {
  yield all([fork(watchGetMyClassPoint), fork(watchGetUserRanking), fork(watchGetClassRanking)]);
}
