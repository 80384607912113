import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Link_ = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
const A = styled.a`
  text-decoration: none;
  color: inherit;
  word-break: keep-all;
`;
function LinkComponent({ children, to, href }) {
  if (to) return <Link_ to={to}>{children}</Link_>;
  else if (href)
    return (
      <A href={href} target='_blank'>
        {children}
      </A>
    );
  else return <A>{children}</A>;
}

export default LinkComponent;
