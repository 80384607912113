import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import ModalVideo from "react-modal-video";
import ModalPortal from "../../components/ModalPortal";
import YoutubeThumb from "../../components/YoutubeThumb";
import getYoutubeId from "../../utils/getYoutubeId";
const Container = styled(Grid)``;
const VideoGrid = styled(Grid)``;
const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;
const Thumbnail = styled.img`
  width: 100%;
  border-radius: 6px;
`;
const PlayButton = styled.img`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-25px, -25px);
  width: 50px;
  height: 50px;
`;
const Title = styled.p`
  margin-bottom: 1px;
`;
const VIDEOS = [
  { title: "11월 7일 개막식", url: "https://youtu.be/t_q0rkZ3X7A" },
  { title: "11월 8일 학생 동아리 공연", url: "https://youtu.be/Da1HJ3XxXn4" },
];
export default function LiveVideos() {
  const [isOpen, setOpen] = useState(false);
  const [youTubeId, setYoutubeId] = useState();
  const openYoutubeModal = (targetId) => {
    setYoutubeId(targetId);
    setOpen(true);
  };

  return (
    <>
      <Container container spacing={2}>
        {VIDEOS?.map((item) => (
          <VideoGrid item xs={6} sm={6} md={6} lg={6} justify="center" container key={item.id}>
            <YoutubeThumb onClick={() => openYoutubeModal(getYoutubeId(item.url))} url={item.url} title={item.title} />
          </VideoGrid>
        ))}
      </Container>
      <ModalPortal>
        <ModalVideo channel="youtube" autoplay={1} isOpen={isOpen} videoId={youTubeId} onClose={() => setOpen(false)} />
      </ModalPortal>
    </>
  );
}
