// const mainBanner = "https://dummyimage.com/1200x350/ffd859/fff";
// const boothBanner = "https://dummyimage.com/1200x200/36B48F/fff";
// const videoBanner = "https://dummyimage.com/1200x200/FA947D/fff";
// const programBanner = "https://dummyimage.com/1200x200/EAEAEA/fff";
// const liveProgramBanner = "https://dummyimage.com/1200x200/3AB795/fff";
// const introductionBanner = "https://dummyimage.com/1200x200/FAD25A/fff";
// const guideBanner = "https://dummyimage.com/1200x200/DB5C2F/fff";
// const mobileGuideBanner = "https://dummyimage.com/500x250/DB5C2F/fff";
// const mobileMainBanner = "https://dummyimage.com/500x450/ffd859/fff";
// const mobileBoothBanner = "https://dummyimage.com/500x250/36B48F/fff";
// const mobileVideoBanner = "https://dummyimage.com/500x250/FA947D/fff";
// const mobileProgramBanner = "https://dummyimage.com/500x250/EAEAEA/fff";
// const mobileLiveProgramBanner = "https://dummyimage.com/500x250/3AB795/fff";
// const mobileIntroductionBanner = "https://dummyimage.com/500x250/FAD25A/fff";
import mainBanner from "../assets/central/banners/mainBanner.png";
import boothBanner from "../assets/central/banners/boothBanner.png";
import videoBanner from "../assets/central/banners/videoBanner.png";
import programBanner from "../assets/central/banners/programBanner.png";
import liveProgramBanner from "../assets/central/banners/liveProgramBanner.png";
import introductionBanner from "../assets/central/banners/introductionBanner.png";
import metaverseBanner from "../assets/central/banners/metaverseBanner.png";
import guideBanner from "../assets/central/banners/guideBanner.png";
import mobileGuideBanner from "../assets/central/banners/mobile/guideBanner.png";
import mobileMainBanner from "../assets/central/banners/mobile/mainBanner.png";
import mobileBoothBanner from "../assets/central/banners/mobile/boothBanner.png";
import mobileVideoBanner from "../assets/central/banners/mobile/videoBanner.png";
import mobileProgramBanner from "../assets/central/banners/mobile/programBanner.png";
import mobileLiveProgramBanner from "../assets/central/banners/mobile/liveProgramBanner.png";
import mobileIntroductionBanner from "../assets/central/banners/mobile/introductionBanner.png";
import mobileMetaverseBanner from "../assets/central/banners/mobile/metaverseBanner.png";
import LogoImage from "../assets/central/logo/logo.png";

import img01 from "../assets/images/profileImage/01.png";
import img02 from "../assets/images/profileImage/02.png";
import img03 from "../assets/images/profileImage/03.png";
import img04 from "../assets/images/profileImage/04.png";
import img05 from "../assets/images/profileImage/05.png";
import img06 from "../assets/images/profileImage/06.png";
import img07 from "../assets/images/profileImage/07.png";
import img08 from "../assets/images/profileImage/08.png";
import img09 from "../assets/images/profileImage/09.png";
import img10 from "../assets/images/profileImage/10.png";
import img11 from "../assets/images/profileImage/11.png";
import img12 from "../assets/images/profileImage/12.png";
import img13 from "../assets/images/profileImage/13.png";
import img14 from "../assets/images/profileImage/14.png";
import img15 from "../assets/images/profileImage/15.png";
import img16 from "../assets/images/profileImage/16.png";
import img17 from "../assets/images/profileImage/17.png";
import img18 from "../assets/images/profileImage/18.png";
import img19 from "../assets/images/profileImage/19.png";
import img20 from "../assets/images/profileImage/20.png";
import img21 from "../assets/images/profileImage/21.png";

import img27 from "../assets/images/profileImage/27.png";
import img28 from "../assets/images/profileImage/28.png";

export const profileImage = [
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
];
export const profileImageTotal = [
  img01,
  img02,
  img03,
  img04,
  img05,
  img06,
  img07,
  img08,
  img09,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  ,
  ,
  ,
  ,
  ,
  ,
  img27,
  img28,
];
export const BANNERS = {
  mainBanner,
  boothBanner,
  videoBanner,
  programBanner,
  liveProgramBanner,
  introductionBanner,
  guideBanner,
  metaverseBanner,
};
export const LOGO = LogoImage;
export const MOBILE_BANNERS = {
  guideBanner: mobileGuideBanner,
  mainBanner: mobileMainBanner,
  boothBanner: mobileBoothBanner,
  videoBanner: mobileVideoBanner,
  programBanner: mobileProgramBanner,
  liveProgramBanner: mobileLiveProgramBanner,
  introductionBanner: mobileIntroductionBanner,
  metaverseBanner: mobileMetaverseBanner,
};
