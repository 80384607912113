import React from 'react';
import styled from 'styled-components';
import { BeatLoader } from 'react-spinners';
import theme from '../styles/theme';
const Container = styled.div`
  width: 100%;
  min-height: ${(props) => `${props.minHeight}px`};
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Loading({ size = 30 }) {
  return (
    <Container minHeight={size * 17}>
      <BeatLoader color={theme.primaryColor} size={size} />
    </Container>
  );
}

export default Loading;
