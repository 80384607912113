import { Suspense, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { ThemeProvider as MThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";

import Theme from "./styles/theme";
import GlobalStyles from "./styles/GlobalStyles";
import Routes from "./components/Routes";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import ScrollToTop from "./utils/ScrollToTop";
import Loading from "./components/Loading";
import { getGlobalDataAction, syncGlobalDataDoneAction } from "./reducers/global";
import "./styles/App.scss";
const BodyContainer = styled.div`
  background-color: ${(props) => props.theme.bgColor};
`;
const HeaderWrapper = styled.header`
  background-color: ${(props) => props.theme.bgColor};
  position: fixed;
  top: 0;
  left: 0;
  height: 80px;
  width: 100vw;
  z-index: 999;
  /* @media screen and (max-width: 500px) {
    background-color: transparent;
  } */
`;

const Wrapper = styled.section`
  max-width: ${(props) => props.theme.maxWidth};
  width: 95vw;
  min-height: calc(100vh - 420px);
  margin: 80px auto 0 auto;
  padding: 5px 0 0 0;
`;
const FooterWrapper = styled.footer`
  background-color: ${(props) => props.theme.footerBgColor};
`;
const App = () => {
  const MuiTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#E94625",
      },
      secondary: {
        main: "#e0e0e0",
      },
    },
  });
  const dispatch = useDispatch();
  const { globalData, getGlobalDataStatus } = useSelector((state) => state.global);
  useEffect(() => {
    var agent = navigator.userAgent.toLowerCase();

    if ((navigator.appName == "Netscape" && navigator.userAgent.search("Trident") != -1) || agent.indexOf("msie") != -1) {
      alert("인터넷 익스플로러는 호환되지 않는 브라우저 입니다. 크롬 혹은 엣지 브라우저를 사용해주시길 바랍니다.");
    }
  }, []);
  useEffect(() => {
    dispatch(getGlobalDataAction());
    return () => {
      dispatch(syncGlobalDataDoneAction());
    };
  }, []);
  return (
    <Suspense fallback={<Loading />}>
      <ThemeProvider theme={Theme}>
        <MThemeProvider theme={MuiTheme}>
          <GlobalStyles />
          <CssBaseline />
          <Router>
            <ScrollToTop>
              <BodyContainer>
                <HeaderWrapper>
                  <Header />
                </HeaderWrapper>
                <Wrapper>
                  <Routes />
                </Wrapper>
                <FooterWrapper>
                  <Footer />
                </FooterWrapper>
              </BodyContainer>
            </ScrollToTop>
          </Router>
        </MThemeProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
