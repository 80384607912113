import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { getBoothsAction } from '../../reducers/booth';
import BoothCard from '../../components/BoothCard';
import pickRandomNumbers from '../../utils/pickRandomNumbers';

const Booths = () => {
  const [randomBoothData, setRandomBoothData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { boothData } = useSelector((state) => state.booth);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBoothsAction());
  }, []);

  //랜덤 데이터 불러오기
  useEffect(() => {
    setIsLoading(true);
    // 활성화 된 부스 데이터만 분류하기
    const activeBoothData = [...boothData].filter((booth) => booth.isActive);
    if (activeBoothData) {
      const length = activeBoothData.length;
      if (length < 12) {
        // 전체 데이터가 12개 이하일 경우는 그냥 하고
        setRandomBoothData(activeBoothData);
      } else {
        // 12개의 랜덤 숫자를 어레이에 저장한 후, 랜덤 숫자 픽
        let randomIndexArray = pickRandomNumbers(length, 12);
        setRandomBoothData(randomIndexArray.map((i) => activeBoothData[i]));
      }
    }
    setIsLoading(false);
  }, [boothData]);
  return (
    <Box>
      <Grid container spacing={2}>
        {/* <BoothListOptions /> */}
        {!isLoading &&
          randomBoothData.map((booth) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={4}
              lg={3}
              key={booth.id}
              justify='center'
              container
            >
              <BoothCard booth={booth} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Booths;
