import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import firebase from "firebase/app";
import Comment from "./Comment";
import CommentInput from "./CommentInput";
import { deleteCommentAction, getCommentsAction, reportUserAction, syncCommentDone, updateCommentAction } from "../../reducers/comment";
import Loading from "../Loading";
import CommentModal from "./CommentModal";
import { db } from "../../firebase";
import { updateUserDataAction } from "../../reducers/user";
import { updateUserPointAction } from "../../reducers/admin";

const Container = styled.div`
  background-color: #eeeeee;
  height: ${(props) => props.height + "px"};
  border-radius: ${(props) => props.theme.borderRadius};
  padding: 16px;
`;

const CommentContainer = styled.div`
  overflow-y: scroll;
  height: ${(props) => props.height + "px"};
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.commentScrollbar}
`;
const CommentBottom = styled.div`
  /* background-color: red;
  height: 5px;
  padding-bottom: 1px; */
`;
function Comments({ channelId, height = 360, startedAt = false }) {
  const { comments, getCommentsStatus, enteredAt } = useSelector((state) => state.comment);
  const { userData, isLoggedIn } = useSelector((state) => state.user);
  const {
    globalData: { points },
  } = useSelector((state) => state.global);
  const commentRef = useRef(null);
  const bottomLineRef = useRef(null);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
  // modal 관련
  const [open, setOpen] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const handleClickOpen = (userId, comment) => {
    if (isLoggedIn) {
      setOpen(true);
      setSelectedComment(comment);
      setSelectedUser(userId);
    }
  };
  const clearData = () => {
    setSelectedUser(null);
    setSelectedComment(null);
  };
  const handleClose = async (value) => {
    setOpen(false);
    if (value === "report") {
      const isConfirmed = confirm("정말 신고하시겠습니까?");
      if (isConfirmed) {
        dispatch(updateCommentAction(channelId, selectedComment.id, "reported", firebase.firestore.FieldValue.increment(1)));
        dispatch(updateUserDataAction("reported", firebase.firestore.FieldValue.increment(1), selectedComment.uid));
        dispatch(reportUserAction(selectedComment.uid, channelId, selectedComment));
      }
    } else if (value === "block") {
      const isConfirmed = confirm("정말 유저를 정지하시겠습니까? 정지된 유저는 다시 댓글을 달 수 없으며, 정지를 해제할 수 없습니다.");
      if (isConfirmed) {
        db.collection("user").doc(selectedUser).update({
          isBlocked: true,
        });
        dispatch(updateUserPointAction(selectedUser, points.BLOCKED_USER));
      }
    } else if (value === "delete") {
      const isConfirmed = confirm("정말 댓글을 삭제하시겠습니까?");
      if (isConfirmed) {
        dispatch(deleteCommentAction(channelId, selectedComment.id));
        if (selectedUser !== userData.uid) dispatch(updateUserPointAction(selectedUser, points.REMOVE_COMMENT));
      }
    }
    clearData();
  };

  // 체널 로딩
  useEffect(() => {
    console.log(startedAt);
    dispatch(getCommentsAction(channelId, startedAt ? startedAt : enteredAt));
    return () => {
      dispatch(syncCommentDone());
    };
  }, []);
  // scroll 을 bottom 으로 이동
  useEffect(() => {
    if (commentRef.current) {
      commentRef.current.scrollTop = commentRef.current.scrollHeight;
      commentRef.current.scrollLeft = 0;
      setCount((prev) => prev + 1);
    }
  }, [comments]);
  return (
    <>
      {getCommentsStatus.loading ? (
        <Loading />
      ) : (
        <Container height={height}>
          <CommentContainer ref={commentRef} height={height - 94}>
            {comments &&
              comments.map((comment, i) => {
                let linked = false;
                if (i > 0) linked = comments[i - 1].uid === comment.uid;
                return <Comment data={comment} linked={linked} first={i === 0} onClick={() => handleClickOpen(comment.uid, comment)} />;
              })}
            <CommentBottom ref={bottomLineRef} />
          </CommentContainer>
          <CommentInput channelId={channelId} />
          <CommentModal open={open} onClose={handleClose} mine={selectedUser === userData.uid} />
        </Container>
      )}
    </>
  );
}

export default Comments;
