import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from "./reducerUtils";

export const initialState = {
  globalData: {
    liveVideoUrl: "",
    liveVideoUrl2: "",
    announce: {
      text: "",
      url: "",
    },
    points: {
      REMOVE_COMMENT: -10,
      BLOCKED_USER: -100,
      WRITE_MAIN_COMMENT: 3,
      POST_MAIN_IMAGE: 10,
      WRITE_BOOTH_LIVE_COMMENT: 3,
      POST_BOOTH_LIVE_IMAGE: 10,
      WRITE_VIDEO_COMMENT: 3,
      VISIT_BOOTH: 1,
      WATCH_VIDEO: 1,
      STAMP: 100,
    },
  },
  getGlobalDataStatus: createStatus(),
  updateGlobalDataStatus: createStatus(),
};

// 액션
export const actions = {
  GET_GLOBAL_DATA_REQUEST: "GET_GLOBAL_DATA_REQUEST",
  GET_GLOBAL_DATA_SUCCESS: "GET_GLOBAL_DATA_SUCCESS",
  GET_GLOBAL_DATA_FAILURE: "GET_GLOBAL_DATA_FAILURE",
  UPDATE_GLOBAL_DATA_REQUEST: "UPDATE_GLOBAL_DATA_REQUEST",
  UPDATE_GLOBAL_DATA_SUCCESS: "UPDATE_GLOBAL_DATA_SUCCESS",
  UPDATE_GLOBAL_DATA_FAILURE: "UPDATE_GLOBAL_DATA_FAILURE",
  UPDATE_GLOBAL_DATA_STATUS_RESET: "UPDATE_GLOBAL_DATA_STATUS_RESET",
  SYNC_GLOBAL_DATA_DONE: "SYNC_GLOBAL_DATA_DONE",
  SYNC_GET_POINTS_DONE: "SYNC_GET_POINTS_DONE",
};

// 액션 크리에이터
export const getGlobalDataAction = () => ({
  type: actions.GET_GLOBAL_DATA_REQUEST,
});
export const updateGlobalDataAction = (target, value) => ({
  type: actions.UPDATE_GLOBAL_DATA_REQUEST,
  target,
  value,
});
export const syncGlobalDataDoneAction = () => ({
  type: actions.SYNC_GLOBAL_DATA_DONE,
});
export const resetStatusAction = (type) => ({ type });
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_GLOBAL_DATA_REQUEST:
        draft.getGlobalDataStatus = createRequestStatus();
        break;
      case actions.GET_GLOBAL_DATA_SUCCESS:
        draft.getGlobalDataStatus = createSuccessStatus();
        draft.globalData = action.data;
        break;
      case actions.GET_GLOBAL_DATA_FAILURE:
        draft.getGlobalDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_GLOBAL_DATA_REQUEST:
        draft.updateGlobalDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_GLOBAL_DATA_SUCCESS:
        draft.updateGlobalDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_GLOBAL_DATA_FAILURE:
        draft.updateGlobalDataStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_GLOBAL_DATA_STATUS_RESET:
        draft.updateGlobalDataStatus = resetStatus();
        break;
      default:
        break;
    }
  });

export default reducer;
