import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from "./reducerUtils";

export const initialState = {
  isLoggedIn: false,
  userData: {
    uid: null,
    name: null,
    email: null,
    status: null,
    phone: "",
    photoURL: null,
  },
  syncUserStatus: createStatus(),
  logInStatus: createStatus(),
  logOutStatus: createStatus(),
  signUpStatus: createStatus(),
  checkUserSessionStatus: createStatus(),
  updatePointStatus: createStatus(),
  updateUserDataStatus: createStatus(),
  adminLogInStatus: createStatus(),
  adminSignUpStatus: createStatus(),
  sendPassowrdResetStatus: createStatus(),
};

// 액션을 객체로 정리
export const actions = {
  LOG_IN_REQUEST: "LOG_IN_REQUEST",
  LOG_IN_SUCCESS: "LOG_IN_SUCCESS",
  LOG_IN_FAILURE: "LOG_IN_FAILURE",
  LOG_OUT_REQUEST: "LOG_OUT_REQUEST",
  LOG_OUT_SUCCESS: "LOG_OUT_SUCCESS",
  LOG_OUT_FAILURE: "LOG_OUT_FAILURE",
  SIGN_UP_REQUEST: "SIGN_UP_REQUEST",
  SIGN_UP_SUCCESS: "SIGN_UP_SUCCESS",
  SIGN_UP_FAILURE: "SIGN_UP_FAILURE",
  SEND_PASSWORD_RESET_REQUEST: "SEND_PASSWORD_RESET_REQUEST",
  SEND_PASSWORD_RESET_SUCCESS: "SEND_PASSWORD_RESET_SUCCESS",
  SEND_PASSWORD_RESET_FAILURE: "SEND_PASSWORD_RESET_FAILURE",
  CHECK_USER_SESSION_REQUEST: "CHECK_USER_SESSION_REQUEST",
  CHECK_USER_SESSION_SUCCESS: "CHECK_USER_SESSION_SUCCESS",
  CHECK_USER_SESSION_FAILURE: "CHECK_USER_SESSION_FAILURE",
  UPDATE_POINT_REQUEST: "UPDATE_POINT_REQUEST",
  UPDATE_POINT_SUCCESS: "UPDATE_POINT_SUCCESS",
  UPDATE_POINT_FAILURE: "UPDATE_POINT_FAILURE",
  UPDATE_USER_DATA_REQUEST: "UPDATE_USER_DATA_REQUEST",
  UPDATE_USER_DATA_SUCCESS: "UPDATE_USER_DATA_SUCCESS",
  UPDATE_USER_DATA_FAILURE: "UPDATE_USER_DATA_FAILURE",
  ADMIN_LOG_IN_REQUEST: "ADMIN_LOG_IN_REQUEST",
  ADMIN_LOG_IN_SUCCESS: "ADMIN_LOG_IN_SUCCESS",
  ADMIN_LOG_IN_FAILURE: "ADMIN_LOG_IN_FAILURE",
  ADMIN_SIGN_UP_REQUEST: "ADMIN_SIGN_UP_REQUEST",
  ADMIN_SIGN_UP_SUCCESS: "ADMIN_SIGN_UP_SUCCESS",
  ADMIN_SIGN_UP_FAILURE: "ADMIN_SIGN_UP_FAILURE",
  // reset
  SEND_PASSWORD_RESET_STATUS_RESET: "SEND_PASSWORD_RESET_STATUS_RESET",
  // 로그인 / 세션 체크 이후 유저 데이터 동기화
  SYNC_USER_REQUEST: "SYNC_USER_REQUEST",
  SYNC_USER_SUCCESS: "SYNC_USER_SUCCESS",
  SYNC_USER_FAILURE: "SYNC_USER_FAILURE",
  SYNC_USER_DONE: "SYNC_USER_DONE",
};

// 로그인, 로그아웃 및 회원가입용 Action Creator
export const loginRequestAction = (email, password) => ({
  type: actions.LOG_IN_REQUEST,
  email,
  password,
});
export const logoutRequestAction = () => ({ type: actions.LOG_OUT_REQUEST });
export const signUpAction = (email, password, data) => ({
  type: actions.SIGN_UP_REQUEST,
  email,
  password,
  data,
});
// 클라이언트상에서 세션 확인하는 Action Creator
export const checkUserSessionAction = () => ({
  type: actions.CHECK_USER_SESSION_REQUEST,
});
export const sendPasswordResetAction = (email) => ({
  type: actions.SEND_PASSWORD_RESET_REQUEST,
  email,
});
export const statusResetAction = (type) => ({
  type,
});
// 학생의 점수와 연동된 반의 점수를 업데이트하는 Action Creator
export const updatePointAction = (point, uid = null) => ({
  type: actions.UPDATE_POINT_REQUEST,
  point,
  uid,
});
// UserData를 업데이트하는 Action Creator
export const updateUserDataAction = (target, value, id) => ({
  type: actions.UPDATE_USER_DATA_REQUEST,
  target,
  value,
  id,
});
// 관리자 로그인 및 회원가입 Action Creator
export const adminLogInAction = (userId, password) => ({
  type: actions.ADMIN_LOG_IN_REQUEST,
  userId,
  password,
});

export const adminSignUpAction = (userId, password, data) => ({
  type: actions.ADMIN_SIGN_UP_REQUEST,
  userId,
  password,
  data,
});
export const syncUserAction = (id) => ({
  type: actions.SYNC_USER_REQUEST,
  id,
});
export const syncUserDoneAction = () => ({
  type: actions.SYNC_USER_DONE,
});

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.LOG_IN_REQUEST:
        draft.logInStatus = createRequestStatus();
        break;
      case actions.LOG_IN_SUCCESS:
        draft.logInStatus = createSuccessStatus();
        break;
      case actions.LOG_IN_FAILURE:
        draft.isLoggedIn = false;
        draft.logInStatus = createFailureStatus(action.error);
        break;

      case actions.SYNC_USER_REQUEST:
        draft.syncUserStatus = createRequestStatus();
        break;
      case actions.SYNC_USER_SUCCESS:
        draft.isLoggedIn = true;
        draft.syncUserStatus = createSuccessStatus();
        draft.userData = action.data;
        break;
      case actions.SYNC_USER_FAILURE:
        draft.isLoggedIn = false;
        draft.syncUserStatus = createFailureStatus(action.error);
        break;
      case actions.LOG_OUT_REQUEST:
        draft.logOutStatus = createRequestStatus();
        break;
      case actions.LOG_OUT_SUCCESS:
        draft.isLoggedIn = false;
        draft.logOutStatus = createSuccessStatus();
        draft.userData = {
          uid: null,
          name: null,
          email: null,
          status: null,
          phone: "",
          photoURL: null,
        };
        break;
      case actions.LOG_OUT_FAILURE:
        draft.logOutStatus = createFailureStatus(action.error);
        break;
      case actions.SIGN_UP_REQUEST:
        draft.isLoggedIn = false;
        draft.signUpStatus = createRequestStatus();
        break;
      case actions.SIGN_UP_SUCCESS:
        draft.signUpStatus = createSuccessStatus();
        break;
      case actions.SIGN_UP_FAILURE:
        draft.signUpStatus = createFailureStatus(action.error);
        break;
      case actions.SEND_PASSWORD_RESET_REQUEST:
        draft.sendPassowrdResetStatus = createRequestStatus();
        break;
      case actions.SEND_PASSWORD_RESET_SUCCESS:
        draft.sendPassowrdResetStatus = createSuccessStatus();
        break;
      case actions.SEND_PASSWORD_RESET_FAILURE:
        draft.sendPassowrdResetStatus = createFailureStatus(action.error);
        break;
      case actions.SEND_PASSWORD_RESET_STATUS_RESET:
        draft.sendPassowrdResetStatus = resetStatus();
        break;
      case actions.CHECK_USER_SESSION_REQUEST:
        draft.isLoggedIn = false;
        draft.checkUserSessionStatus = createRequestStatus();
        break;
      case actions.CHECK_USER_SESSION_SUCCESS:
        draft.checkUserSessionStatus = createSuccessStatus();
        // user과 session이 일치하면
        if (!action.session) {
          draft.isLoggedIn = false;
          draft.userData = {
            uid: null,
            name: null,
            email: null,
            status: null,
            phone: "",
            photoURL: null,
          };
        }
        break;
      case actions.CHECK_USER_SESSION_FAILURE:
        draft.isLoggedIn = false;
        draft.checkUserSessionStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_POINT_REQUEST:
        draft.updatePointStatus = createRequestStatus();
        break;
      case actions.UPDATE_POINT_SUCCESS:
        draft.updatePointStatus = createSuccessStatus();
        break;
      case actions.UPDATE_POINT_FAILURE:
        draft.updatePointStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_USER_DATA_REQUEST:
        draft.updateUserDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_USER_DATA_SUCCESS:
        draft.updateUserDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_USER_DATA_FAILURE:
        draft.updateUserDataStatus = createFailureStatus(action.error);
        break;
      //Admin 기능
      case actions.ADMIN_LOG_IN_REQUEST:
        draft.adminLogInStatus = createRequestStatus();
        break;
      case actions.ADMIN_LOG_IN_SUCCESS:
        draft.adminLogInStatus = createSuccessStatus();
        break;
      case actions.ADMIN_LOG_IN_FAILURE:
        draft.adminLogInStatus = createFailureStatus(action.error);
        break;
      case actions.ADMIN_SIGN_UP_REQUEST:
        draft.adminSignUpStatus = createRequestStatus();
        break;
      case actions.ADMIN_SIGN_UP_SUCCESS:
        draft.adminSignUpStatus = createSuccessStatus();
        break;
      case actions.ADMIN_SIGN_UP_FAILURE:
        draft.adminSignUpStatus = createFailureStatus(action.error);
        break;

      case actions.default:
        break;
    }
  });

export default reducer;
