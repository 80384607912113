//hooks
import React, { useCallback, useEffect, useState } from "react";
import usePathname from "../../hooks/usePathname";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useInput from "../../hooks/useInput";

//libraries
import firebase from "firebase/app";
import styled from "styled-components";

// Components
import { CropOriginal, Send } from "@material-ui/icons/";
import ImagePostModal from "./ImagePostModal";

//constants
import { boothEvaluate } from "../../constants/pointConstants";
// reducers
import { postCommentAction } from "../../reducers/comment";
import { updatePointAction, updateUserDataAction } from "../../reducers/user";
import { updateBoothDataAction } from "../../reducers/booth";
import { MAIN_TIME_LIMIT, BOOTH_TIME_LIMIT } from "../../constants/commentsConstants";
const Container = styled.div`
  background-color: white;
  height: 46px;
  display: flex;
  align-items: center;
  padding: 0 6px;
  border-radius: 6px;
  button {
    background: none;
    border: none;
    width: 32px;
    &:focus {
      outline: none;
    }
  }
  form {
    height: 32px;
    width: calc(100% - 32px);
    display: flex;
    input {
      height: 32px;
      width: calc(100% - 56px);
      margin: 0 12px;
      border: none;
      &:focus {
        outline: none;
      }
    }
    button {
      align-content: center;
    }
  }
`;
function CommentInput({ channelId }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathname = usePathname();
  const text = useInput("");
  const { userData, isLoggedIn } = useSelector((state) => state.user);
  const { globalData } = useSelector((state) => state.global);
  // 모달
  const [isModal, setIsModal] = useState(false);
  const openModal = () => {
    setIsModal(true);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    if (isLoggedIn) {
      if (pathname === "home" && globalData.isCommentBlocked?.home) {
        alert("댓글을 작성하실 수 없습니다.");
        return;
      }
      if (pathname === "booth" && globalData.isCommentBlocked?.booth) {
        alert("학교 부스에 댓글을 작성하실 수 없습니다.");
        return;
      }
      if (userData.isBlocked) {
        alert("블록된 사용자입니다. 댓글을 작성하실 수 없습니다.");
        return;
      }
      if (text.value?.length > 0 && !userData.isBlocked) {
        if (pathname === "home") {
          dispatch(
            postCommentAction(
              channelId,
              {
                uid: userData.uid,
                type: "text",
                value: text.value,
                userName: userData.userName,
                avatar: userData.avatar,
              },
              MAIN_TIME_LIMIT
            )
          );
        } else if (pathname === "booth") {
          dispatch(
            postCommentAction(
              channelId,
              {
                uid: userData.uid,
                type: "text",
                value: text.value,
                userName: userData.userName,
                avatar: userData.avatar,
              },
              BOOTH_TIME_LIMIT
            )
          );
        }
        if (userData.status === "student") {
          if (text.value.length > 15) {
            dispatch(
              updateBoothDataAction(channelId, {
                target: "point",
                value: firebase.firestore.FieldValue.increment(boothEvaluate.textComment),
              })
            );
            if (pathname === "home") {
              //고등학생 처리. 2021 직업온라인 박람회 랭킹 점수에 고등학교는 포함되지 않음.
              if (!userData.schoolName.includes("고등학교")) {
                dispatch(updatePointAction(globalData.points.WRITE_MAIN_COMMENT));
              }
            } else if (pathname === "booth") {
              //고등학생 처리. 2021 직업온라인 박람회 랭킹 점수에 고등학교는 포함되지 않음.
              if (!userData.schoolName.includes("고등학교")) {
                dispatch(updatePointAction(globalData.points.WRITE_BOOTH_LIVE_COMMENT));
              }
            }
          }
        }
        text.setValue("");
      }
    } else {
      history.push("/login");
    }
  };
  return (
    <>
      <Container>
        <button onClick={openModal}>
          <CropOriginal />
        </button>
        <form onSubmit={onSubmitHandler}>
          <input
            type="text"
            placeholder="대화 내용을 이곳에 입력하세요"
            value={text.value}
            onChange={text.onChange}
            maxLength={140}
          ></input>
          <button type="submit">
            <Send fontSize="small" />
          </button>
        </form>
      </Container>
      {isModal && (
        <ImagePostModal isModal={isLoggedIn ? isModal : () => history.push("/login")} setIsModal={setIsModal} channelId={channelId} />
      )}
    </>
  );
}

export default CommentInput;
