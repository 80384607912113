import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    //font import 
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap');
    *{
        box-sizing:border-box;
    }
    a{
        text-decoration: none;
    }
    .customoverlay {
    position: relative;
    bottom: 85px;
    border-radius: 6px;
    border: 1px solid #ccc;
    border-bottom: 2px solid #ddd;
    float: left
}

    .customoverlay:nth-of-type(n) {
        border: 0;
        box-shadow: 0 1px 2px #888
    }
    .mapLogo{
        width: 30px;
        height: 30px;
        vertical-align: middle;
        margin-right: 5px;
    }
    .mainBgColor {
        background: #424B54;
    }
    .customoverlay div {
    display: block;
    text-decoration: none;
    color: #000;
    text-align: center;
    border-radius: 6px;
    font-size: 14px;
    overflow: hidden;
    background-image: url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/arrow_white.png);
    background-repeat: no-repeat;
    background-position: right 14px center
}

.customoverlay .title {
    display: block;
    text-align: center;
    background: #fff;
    margin-right: 35px;
    padding: 4px 7px 3px 6px;
    font-size: 13px
}

.customoverlay:after {
    content: "";
    position: absolute;
    margin-left: -12px;
    left: 50%;
    bottom: -12px;
    width: 22px;
    height: 12px;
    background: url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png)
}

`;
