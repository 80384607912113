import React from "react";
import styled, { keyframes } from "styled-components";
import { Hidden } from "@material-ui/core";
import LinkComponent from "../../components/Link";
// import mobileBgTitle from '../../assets/central/banners/mainBannerAssets/banner_m_bg+title.png';
// import BG from '../../assets/central/banners/mainBannerAssets/banner_bg+title.png';
// import element1 from '../../assets/central/banners/mainBannerAssets/character_car.png';
// import element2 from '../../assets/central/banners/mainBannerAssets/character_cook.png';
// import element3 from '../../assets/central/banners/mainBannerAssets/character_power.png';
// import element4 from '../../assets/central/banners/mainBannerAssets/character_ship.png';
// // import title from '../../assets/central/banners/mainBannerAssets/banner_title_only.png';
// import glitter from '../../assets/central/banners/mainBannerAssets/glitter.gif';
// const elementAnimition1 = keyframes`
//   0% {
//     transform: translate(5px, 0px);
// }
// 50% {
//     transform: translate(0px, 0px);
// }
// 100% {
//       transform: translate(5px, 0px);
//   }
// `;
// const elementAnimition1_hover = keyframes`
//   0% {
//     transform: translate(0px, 0px);
// }
// 10% {
//     transform: translate(30px, 0px);
// }
// 50% {
//     transform: translate(-1500px, 0px);
// }
// 100% {
//       transform: translate(-1500px, 0px);
//   }
// `;
// const elementAnimition2 = keyframes`
//   0% {
//     transform: translate(0px, 5px);
// }
// 50% {
//     transform: translate(0px, 0px);
// }
// 100% {
//       transform: translate(0px, 5px);
//   }
// `;
// const elementAnimition2_hover = keyframes`
//   0% {
//     transform: translate(0px, 5px) scale(1);
// }
// 20% {
//     transform: translate(0px, 0px) scale(1.5);
// }
// 50%{
//   transform: translate(0px, 0px) scale(1);
// }
// }
// 70% {
//     transform: translate(0px, 0px) scale(3);
// }
// 100% {
//       transform: translate(0px, 5px) scale(1);
//   }
// `;
// const elementAnimition3 = keyframes`
//  0% {
//     transform: translate(0px, -3px);
// }
// 20% {
//     transform: translate(0px, 3px);
// }
// 100% {
//       transform: translate(0px, -3px);
//   }
// `;
// const elementAnimition3_hover = keyframes`
//  0% {
//     transform: translate(0px, -3px);
// }
// 20% {
//     transform: translate(0px, 3px);
// }
// 25% {
//     transform: translate(5px, 3px);
// }
// 30% {
//     transform: translate(-5px, 3px);
// }
// 35% {
//     transform: translate(0px, 3px);
// }
// 100% {
//       transform: translate(0px, -3px);
//   }
// `;
// const elementAnimition4 = keyframes`
//  0% {
//     transform: translate(-3px, -3px);
// }
// 50% {
//     transform: translate(0px, 0px);
// }
// 100% {
//       transform: translate(3px, 3px);
//   }
// `;
// const elementAnimition4_hover = keyframes`
//  0% {
//     transform: translate(-3px, -3px);
// }
//  20% {
//     transform: translate(0px, 0px);
// }
// 40% {
//     transform: translate(3px, 3px);
// }
// 60% {
//     transform: translate(6px, 0px);
// }
// 80% {
//     transform: translate(9px, -3px);
// }
// 100% {
//       transform: translate(12px, 0px);
//   }
// `;
const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.marginBottom ? props.theme.smallMargin : props.theme.bigBoxInMargin)};
  overflow: hidden;
  position: relative;
`;
const Image = styled.img`
  border-radius: ${(props) => props.theme.borderRadius};
  width: 100%;
`;
// const Title = styled.img`
//   position: absolute;
//   width: 50%;
//   top: 15%;
//   left: 5%;
//   /* animation: ${elementAnimition1} 1s 1s infinite linear alternate; */
// `;
// const Glitter = styled.img`
//   position: absolute;
//   width: 5%;
//   top: ${props => `${props.top}%`};
//   left: ${props => `${props.left}%`};
//   transform: ${props => `rotate(${props.deg}deg)`};
//   /* animation: ${elementAnimition1} 1s 1s infinite linear alternate; */
// `;
// const El1 = styled.img`
//   position: absolute;
//   width: 22%;
//   top: 60%;
//   left: 75%;
//   animation: ${elementAnimition1} 1s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition1_hover} 2s 0.1s ease-in alternate;
//   }
// `;
// const El2 = styled.img`
//   position: absolute;
//   width: 17%;
//   top: 18%;
//   left: 78%;
//   animation: ${elementAnimition2} 1s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition2_hover} 20s 0.1s ease-in alternate;
//     z-index: 10;
//   }
// `;
// const El3 = styled.img`
//   position: absolute;
//   width: 17%;
//   top: 13%;
//   left: 60%;
//   animation: ${elementAnimition3} 0.5s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition3_hover} 0.5s 0.1s infinite linear alternate;
//   }
// `;
// const El4 = styled.img`
//   position: absolute;
//   width: 17%;
//   top: 45%;
//   left: 56%;
//   animation: ${elementAnimition4} 0.7s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition4_hover} 1s 0.1s infinite linear alternate;
//   }
// `;
// const MobileEl1 = styled.img`
//   position: absolute;
//   width: 35%;
//   top: 30%;
//   left: 55%;
//   animation: ${elementAnimition1} 1s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition1_hover} 2s 0.1s ease-in alternate;
//   }
// `;
// const MobileEl2 = styled.img`
//   position: absolute;
//   width: 28%;
//   top: 8%;
//   left: 50%;
//   animation: ${elementAnimition2} 1s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition2_hover} 20s 0.1s ease-in alternate;
//     z-index: 10;
//   }
// `;
// const MobileEl3 = styled.img`
//   position: absolute;
//   width: 28%;
//   top: 10%;
//   left: 18%;
//   animation: ${elementAnimition3} 0.5s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition3_hover} 0.5s 0.1s infinite linear alternate;
//   }
// `;
// const MobileEl4 = styled.img`
//   position: absolute;
//   width: 28%;
//   top: 30%;
//   left: 20%;
//   animation: ${elementAnimition4} 0.7s 0.1s infinite linear alternate;
//   &:hover {
//     animation: ${elementAnimition4_hover} 1s 0.1s infinite linear alternate;
//   }
// `;

function MainBanner({ mdSrc, xsSrc, to, alt, marginBottom = null }) {
  return (
    <Container marginBottom={marginBottom}>
      <Hidden xsDown>
        <LinkComponent to={to ? to : null}>
          <Image src={mdSrc} alt={alt} />
          {/* <Title src={title} alt={alt} /> */}
          {/* <El3 src={element3} alt="element3" />
          <El4 src={element4} alt="element4" />
          <El1 src={element1} alt="element1" />
          <El2 src={element2} alt="element2" />
          <Glitter src={glitter} alt={alt} deg={260} top={30} left={55} />
          <Glitter src={glitter} alt={alt} deg={30} top={50} left={94} /> */}
        </LinkComponent>
      </Hidden>
      <Hidden smUp>
        <LinkComponent to={to ? to : null}>
          <Image src={xsSrc} alt={alt} />
          {/* <MobileEl3 src={element3} alt="element3" />
          <MobileEl4 src={element4} alt="element4" />
          <MobileEl1 src={element1} alt="element1" />
          <MobileEl2 src={element2} alt="element2" />
          <Glitter src={glitter} alt={alt} deg={260} top={30} left={15} />
          <Glitter src={glitter} alt={alt} deg={30} top={40} left={87} /> */}
        </LinkComponent>
      </Hidden>
    </Container>
  );
}

export default MainBanner;
