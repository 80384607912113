export default function pickRandomNumbers(max, count) {
  let randomIndexArray = [];
  for (let i = 0; i < count; i++) {
    let randomNum = Math.floor(Math.random() * max);
    if (randomIndexArray.indexOf(randomNum) === -1) {
      randomIndexArray.push(randomNum);
    } else {
      i--;
    }
  }
  return randomIndexArray;
}
