import { all, fork, put, call, takeEvery, take, cancel, select, takeLatest } from "redux-saga/effects";
import { actions, getChannelListAction, getUserAction } from "../reducers/admin";
import { GLOBAL_DATA_KEY } from "../constants/globalConstants";
import { ADMIN_COMMENTS_KEY, ADMIN_COMMENTS_UPDATED_AT_KEY } from "../constants/storageConstants";
import { rsfDB, firestore, db } from "../firebase";

// call은 동기, fork는 비동기 요청
function* getUser({ id }) {
  try {
    const { userIds } = yield select((state) => state.admin);
    const isUserExists = userIds.has(id);
    if (!isUserExists) {
      yield put({ type: actions.GET_USER_ID, id });
      const snapshot = yield call(rsfDB.getDocument, `user/${id}`);
      yield put({
        type: actions.GET_USER_SUCCESS,
        id,
        data: snapshot.data(),
      });
    } else {
      yield put({
        type: actions.GET_USER_SUCCESS,
        data: null,
      });
    }
  } catch (err) {
    yield put({
      type: actions.GET_USER_FAILURE,
      error: err.message,
    });
  }
}
function* getAllUsers() {
  try {
    const { userData } = yield select((state) => state.user);
    // 슈퍼관리자만 사용 가능.
    if (userData.status !== "superAdmin") return;

    const snapshot = yield call(rsfDB.getCollection, `user`);
    const allUsers = [];
    snapshot.forEach((user) => {
      if (user.data()?.status === "student") {
        allUsers.push(user.data());
      }
    });
    yield put({
      type: actions.GET_ALL_USERS_SUCCESS,
      data: allUsers,
    });
  } catch (err) {
    yield put({
      type: actions.GET_ALL_USERS_FAILURE,
      error: err.message,
    });
  }
}

function* getChannelList() {
  try {
    const snapshot = yield call(rsfDB.getCollection, "channel");
    const channelList = [];
    snapshot.forEach((channel) => channelList.push([channel.id, channel.data()]));
    yield put({ type: actions.GET_CHANNEL_LIST_SUCCESS, data: channelList });
  } catch (err) {
    yield put({
      type: actions.GET_CHANNEL_LIST_FAILURE,
      error: err.message,
    });
  }
}

function* getAllComments() {
  try {
    yield put(getChannelListAction());
    yield take(actions.GET_CHANNEL_LIST_SUCCESS);
    const { channelList } = yield select((state) => state.admin);

    for (let i = 0; i < channelList.length; i++) {
      let snapshot;
      snapshot = yield call(rsfDB.getCollection, db.collection(`channel/${channelList[i][0]}/comments`).orderBy("createdAt", "desc"));
      const comments = [];
      snapshot.forEach((comment) => {
        comments.push({
          ...comment.data(),
          id: comment.id,
          channelId: channelList[i][0],
          channelInfo: channelList[i][1],
        });
      });
      yield put({
        type: actions.GET_ALL_COMMENTS_SUCCESS,
        data: comments,
        firstCall: i === 0,
        lastCall: channelList.length === i + 1,
      });
    }
  } catch (err) {
    yield put({
      type: actions.GET_ALL_COMMENTS_FAILURE,
      error: err.message,
    });
  }
}
// function* getAllComments() {
//   try {
//     yield put(getChannelListAction());
//     yield take(actions.GET_CHANNEL_LIST_SUCCESS);
//     const { channelList } = yield select(state => state.admin);

//     for (let i = 0; i < channelList.length; i++) {
//       let snapshot;
//       snapshot = yield call(rsfDB.getCollection, db.collection(`channel/${channelList[i][0]}/comments`).orderBy('createdAt', 'desc'));
//       snapshot.forEach(comment => {
//         db.collection(`channel/${channelList[i][0]}/comments`).doc(comment.id).update({
//           channelId: channelList[i][0],
//         });
//       });
//       yield put({
//         type: actions.GET_ALL_COMMENTS_SUCCESS,
//         // data: comments,
//         firstCall: i === 0,
//         lastCall: channelList.length === i + 1,
//       });
//     }
//   } catch (err) {
//     yield put({
//       type: actions.GET_ALL_COMMENTS_FAILURE,
//       error: err.message,
//     });
//   }
// }

function* getReportedUsers() {
  const task = yield call(rsfDB.syncCollection, firestore().collection(`reportedUser`).orderBy("reported", "desc"), {
    successActionCreator: (data) => {
      const users = [];
      data.forEach((doc) => {
        users.push(doc.data());
      });
      return {
        type: actions.GET_REPORTED_USERS_SUCCESS,
        data: users,
      };
    },
    failureActionCreator: (err) => ({
      type: actions.GET_REPORTED_USERS_FAILURE,
      error: err.message,
    }),
  });
  yield take(actions.SYNC_REPORTED_USER_DONE);
  yield cancel(task);
}

function* updateUserPoint({ classId, id, point }) {
  try {
    // let { users } = yield select(state => state.admin);
    // let userData = users.find(user => user.uid === id);
    // if (userData === undefined) {
    //   yield put(getUserAction(id));
    //   yield take(actions.GET_USER_SUCCESS);
    //   users = yield select(state => state.admin.users);
    //   userData = users.find(user => user.uid === id);
    // }
    // if (userData.status === 'student') {
    yield call(rsfDB.updateDocument, `user/${id}`, "point", firestore.FieldValue.increment(point));
    if (point < 0) {
      yield call(rsfDB.updateDocument, `user/${id}`, "subtractedPoint", firestore.FieldValue.increment(point * -1));
    }
    // if (userData.isRanker) {
    //   yield call(rsfDB.updateDocument, `topRanker/${userData.uid}`, 'point', firestore.FieldValue.increment(point));
    // }
    // if (userData.isClassRanker) {
    //   yield call(rsfDB.updateDocument, `topRankClass/${userData.classId}`, 'point', firestore.FieldValue.increment(point));
    // }
    yield call(rsfDB.updateDocument, `class/${classId}`, "point", firestore.FieldValue.increment(point));
    // }

    yield put({
      type: actions.UPDATE_USER_POINT_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_USER_POINT_FAILURE,
      error: err.message,
    });
  }
}
function* updatePoints({ data }) {
  try {
    let { userData } = yield select((state) => state.user);
    if (userData.status === "superAdmin") {
      yield call(rsfDB.updateDocument, `global/${GLOBAL_DATA_KEY}`, "points", data);
      yield put({
        type: actions.UPDATE_POINTS_SUCCESS,
      });
    } else {
      yield put({
        type: actions.UPDATE_POINTS_FAILURE,
        error: "허용되지 않은 접근입니다.",
      });
    }
  } catch (err) {
    yield put({
      type: actions.UPDATE_POINTS_FAILURE,
      error: err.message,
    });
  }
}
function* updateReportedUserData({ id, target, value }) {
  try {
    let { userData } = yield select((state) => state.user);
    if (userData.status === "superAdmin" || userData.status === "admin") {
      yield call(rsfDB.updateDocument, `reportedUser/${id}`, target, value);
      yield put({
        type: actions.UPDATE_REPORTED_USER_DATA_SUCCESS,
      });
    } else {
      yield put({
        type: actions.UPDATE_REPORTED_USER_DATA_FAILURE,
        error: "허용되지 않은 접근입니다.",
      });
    }
  } catch (err) {
    yield put({
      type: actions.UPDATE_REPORTED_USER_DATA_FAILURE,
      error: err.message,
    });
  }
}

function* resetAllUsers() {}
function* getAllCommentsForManage({ count }) {
  let { userData } = yield select((state) => state.user);

  if (userData.status === "superAdmin") {
    const snap1 = yield db.collection("booth").get();
    const snap2 = yield db.collection("video").get();
    const cat = { main: { title: "메인", type: "메인" } };
    snap1.forEach((doc) => {
      cat[doc.id] = { title: doc.data().title, type: "부스" };
    });
    snap2.forEach((doc) => {
      cat[doc.id] = { title: doc.data().title, type: "비디오" };
    });

    const task = yield call(rsfDB.syncCollection, db.collectionGroup(`comments`).orderBy("createdAt", "desc").limit(count), {
      successActionCreator: (snapshot) => {
        const comments = [];
        const currentTime = new Date();
        snapshot.forEach((comment) => {
          comments.push({
            ...comment.data(),
            catData: cat[comment.data().channelId],
            id: comment.id,
            loadedAt: currentTime,
          });
        });
        return {
          type: actions.GET_ALL_COMMENTS_FOR_MANAGE_SUCCESS,
          data: comments,
        };
      },
      failureActionCreator: (err) => ({
        type: actions.GET_ALL_COMMENTS_FOR_MANAGE_FAILURE,
        error: err.message,
      }),
    });
    yield take(actions.SYNC_ALL_COMMENTS_FOR_MANAGE_DONE);
    yield cancel(task);
  }
}
function* watchGetUser() {
  yield takeEvery(actions.GET_USER_REQUEST, getUser);
}
function* watchGetReportedUsers() {
  yield takeEvery(actions.GET_REPORTED_USERS_REQUEST, getReportedUsers);
}

function* watchUpdateUserPoint() {
  yield takeEvery(actions.UPDATE_USER_POINT_REQUEST, updateUserPoint);
}
function* watchUpdatePoints() {
  yield takeLatest(actions.UPDATE_POINTS_REQUEST, updatePoints);
}
function* watchUpdateReportedUserData() {
  yield takeEvery(actions.UPDATE_REPORTED_USER_DATA_REQUEST, updateReportedUserData);
}
function* watchGetAllComments() {
  yield takeEvery(actions.GET_ALL_COMMENTS_REQUEST, getAllComments);
}
function* watchGetChannelList() {
  yield takeEvery(actions.GET_CHANNEL_LIST_REQUEST, getChannelList);
}
function* watchGetAllUsers() {
  yield takeLatest(actions.GET_ALL_USERS_REQUEST, getAllUsers);
}
function* watchResetAllUsers() {
  yield takeLatest(actions.RESET_ALL_USERS_REQUEST, resetAllUsers);
}
function* watchGetAllCommentsForManage() {
  yield takeLatest(actions.GET_ALL_COMMENTS_FOR_MANAGE_REQUEST, getAllCommentsForManage);
}
export default function* adminSaga() {
  yield all([
    fork(watchGetUser),
    fork(watchUpdateUserPoint),
    fork(watchUpdatePoints),
    fork(watchGetReportedUsers),
    fork(watchUpdateReportedUserData),
    fork(watchGetAllComments),
    fork(watchGetChannelList),
    fork(watchGetAllUsers),
    fork(watchResetAllUsers),
    fork(watchGetAllCommentsForManage),
  ]);
}
