import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus, resetStatus } from "./reducerUtils";

export const initialState = {
  boothData: [],
  currentBoothData: {},
  currentLiveProgram: [],
  liveProgramData: [],
  currentCategory: "전체보기",
  getBoothsStatus: createStatus(),
  getBoothDataStatus: createStatus(),
  updateBoothDataStatus: createStatus(),
  addBoothContentStatus: createStatus(),
  deleteBoothContentStatus: createStatus(),
  changeOrderBoothContentStatus: createStatus(),
  syncBoothDataStatus: createStatus(),
  getLiveProgramStatus: createStatus(),
  getAllLiveProgramsStatus: createStatus(),
};

export const actions = {
  GET_BOOTHS_REQUEST: "GET_BOOTHS_REQUEST",
  GET_BOOTHS_SUCCESS: "GET_BOOTHS_SUCCESS",
  GET_BOOTHS_FAILURE: "GET_BOOTHS_FAILURE",
  GET_BOOTH_DATA_REQUEST: "GET_BOOTH_DATA_REQUEST",
  GET_BOOTH_DATA_SUCCESS: "GET_BOOTH_DATA_SUCCESS",
  GET_BOOTH_DATA_FAILURE: "GET_BOOTH_DATA_FAILURE",
  UPDATE_BOOTH_DATA_REQUEST: "UPDATE_BOOTH_DATA_REQUEST",
  UPDATE_BOOTH_DATA_SUCCESS: "UPDATE_BOOTH_DATA_SUCCESS",
  UPDATE_BOOTH_DATA_FAILURE: "UPDATE_BOOTH_DATA_FAILURE",
  UPDATE_BOOTH_DATA_STATUS_RESET: "UPDATE_BOOTH_DATA_STATUS_RESET",
  ADD_BOOTH_CONTENT_REQUEST: "ADD_BOOTH_CONTENT_REQUEST",
  ADD_BOOTH_CONTENT_SUCCESS: "ADD_BOOTH_CONTENT_SUCCESS",
  ADD_BOOTH_CONTENT_FAILURE: "ADD_BOOTH_CONTENT_FAILURE",
  DELETE_BOOTH_CONTENT_REQUEST: "DELETE_BOOTH_CONTENT_REQUEST",
  DELETE_BOOTH_CONTENT_SUCCESS: "DELETE_BOOTH_CONTENT_SUCCESS",
  DELETE_BOOTH_CONTENT_FAILURE: "DELETE_BOOTH_CONTENT_FAILURE",
  CHANGE_ORDER_BOOTH_CONTENT_REQUEST: "CHANGE_ORDER_BOOTH_CONTENT_REQUEST",
  CHANGE_ORDER_BOOTH_CONTENT_SUCCESS: "CHANGE_ORDER_BOOTH_CONTENT_SUCCESS",
  CHANGE_ORDER_BOOTH_CONTENT_FAILURE: "CHANGE_ORDER_BOOTH_CONTENT_FAILURE",
  SYNC_BOOTH_DATA_REQUEST: "SYNC_BOOTH_DATA_REQUEST",
  SYNC_BOOTH_DATA_SUCCESS: "SYNC_BOOTH_DATA_SUCCESS",
  SYNC_BOOTH_DATA_FAILURE: "SYNC_BOOTH_DATA_FAILURE",
  GET_LIVE_PROGRAM_REQUEST: "GET_LIVE_PROGRAM_REQUEST",
  GET_LIVE_PROGRAM_SUCCESS: "GET_LIVE_PROGRAM_SUCCESS",
  GET_LIVE_PROGRAM_FAILURE: "GET_LIVE_PROGRAM_FAILURE",
  GET_ALL_LIVE_PROGRAMS_REQUEST: "GET_ALL_LIVE_PROGRAMS_REQUEST",
  GET_ALL_LIVE_PROGRAMS_SUCCESS: "GET_ALL_LIVE_PROGRAMS_SUCCESS",
  GET_ALL_LIVE_PROGRAMS_FAILURE: "GET_ALL_LIVE_PROGRAMS_FAILURE",
  SET_BOOTH_CATEGORY: "SET_BOOTH_CATEGORY",
  SYNC_BOOTH_DONE: "SYNC_BOOTH_DONE",
  SYNC_LIVE_PROGRAM_DONE: "SYNC_LIVE_PROGRAM_DONE",
};

export const getBoothsAction = () => ({
  type: actions.GET_BOOTHS_REQUEST,
});
export const getBoothDataAction = (id, sync = false) => ({
  type: actions.GET_BOOTH_DATA_REQUEST,
  id,
  sync,
});
export const updateBoothDataAction = (id, data) => ({
  type: actions.UPDATE_BOOTH_DATA_REQUEST,
  id,
  data,
});
export const statusResetAction = (type) => ({ type });
export const addBoothContentAction = (id, target, data) => ({
  type: actions.ADD_BOOTH_CONTENT_REQUEST,
  id,
  target,
  data,
});
export const deleteBoothContentAction = (id, target, targetId) => ({
  type: actions.DELETE_BOOTH_CONTENT_REQUEST,
  id,
  target,
  targetId,
});
export const changeOrderBoothContentAction = (id, target, targetId, order) => ({
  type: actions.CHANGE_ORDER_BOOTH_CONTENT_REQUEST,
  id,
  target,
  targetId,
  order,
});
export const syncBoothDataAction = (id, data) => ({
  type: actions.SYNC_BOOTH_DATA_REQUEST,
  id,
  data,
});

export const setCategoryAction = (value) => ({
  type: actions.SET_BOOTH_CATEGORY,
  value,
});
export const getLiveProgramAction = (id) => ({
  type: actions.GET_LIVE_PROGRAM_REQUEST,
  id,
});
export const getAllLiveProgramsAction = () => ({
  type: actions.GET_ALL_LIVE_PROGRAMS_REQUEST,
});
export const syncBoothDone = () => ({ type: actions.SYNC_BOOTH_DONE });
export const syncLiveProgramDone = () => ({
  type: actions.SYNC_LIVE_PROGRAM_DONE,
});
const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_BOOTHS_REQUEST:
        draft.getBoothsStatus = createRequestStatus();
        break;
      case actions.GET_BOOTHS_SUCCESS:
        draft.getBoothsStatus = createSuccessStatus();
        draft.boothData = action.data;
        break;
      case actions.GET_BOOTHS_FAILURE:
        draft.getBoothsStatus = createFailureStatus();
        break;
      case actions.GET_BOOTH_DATA_REQUEST:
        draft.getBoothDataStatus = createRequestStatus();
        break;
      case actions.GET_BOOTH_DATA_SUCCESS:
        draft.getBoothDataStatus = createSuccessStatus();
        if (!action.sync) draft.currentBoothData = { ...action.data, id: action.id };
        break;
      case actions.GET_BOOTH_DATA_FAILURE:
        draft.getBoothDataStatus = createFailureStatus();
        break;
      case actions.UPDATE_BOOTH_DATA_REQUEST:
        draft.updateBoothDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_BOOTH_DATA_SUCCESS:
        draft.updateBoothDataStatus = createSuccessStatus();
        draft.currentBoothData = { ...draft.currentBoothData, ...action.data };
        break;
      case actions.UPDATE_BOOTH_DATA_FAILURE:
        draft.updateBoothDataStatus = createFailureStatus();
        break;
      case actions.UPDATE_BOOTH_DATA_STATUS_RESET:
        draft.updateBoothDataStatus = resetStatus();
        break;
      case actions.ADD_BOOTH_CONTENT_REQUEST:
        draft.addBoothContentStatus = createRequestStatus();
        break;
      case actions.ADD_BOOTH_CONTENT_SUCCESS:
        draft.addBoothContentStatus = createSuccessStatus();
        draft.currentBoothData[action.target] = action.data;
        break;
      case actions.ADD_BOOTH_CONTENT_FAILURE:
        draft.addBoothContentStatus = createFailureStatus();
        break;
      case actions.DELETE_BOOTH_CONTENT_REQUEST:
        draft.deleteBoothContentStatus = createRequestStatus();
        break;
      case actions.DELETE_BOOTH_CONTENT_SUCCESS:
        draft.deleteBoothContentStatus = createSuccessStatus();
        draft.currentBoothData[action.target] = action.data;
        break;
      case actions.DELETE_BOOTH_CONTENT_FAILURE:
        draft.deleteBoothContentStatus = createFailureStatus();
        break;
      case actions.CHANGE_ORDER_BOOTH_CONTENT_REQUEST:
        draft.changeOrderBoothContentStatus = createRequestStatus();
        break;
      case actions.CHANGE_ORDER_BOOTH_CONTENT_SUCCESS:
        draft.changeOrderBoothContentStatus = createSuccessStatus();
        draft.currentBoothData[action.target] = action.data;
        break;
      case actions.CHANGE_ORDER_BOOTH_CONTENT_FAILURE:
        draft.changeOrderBoothContentStatus = createFailureStatus();
        break;
      case actions.DELETE_BOOTH_CONTENT_FAILURE:
        draft.deleteBoothContentStatus = createFailureStatus();
        break;
      case actions.SYNC_BOOTH_DATA_REQUEST:
        draft.syncBoothDataStatus = createRequestStatus();
        break;
      case actions.SYNC_BOOTH_DATA_SUCCESS:
        draft.syncBoothDataStatus = createSuccessStatus();
        draft.currentBoothData = { ...action.data, id: action.id };
        break;
      case actions.SYNC_BOOTH_DATA_FAILURE:
        draft.syncBoothDataStatus = createFailureStatus();
        break;
      case actions.GET_LIVE_PROGRAM_REQUEST:
        draft.getLiveProgramStatus.done = false;
        draft.getLiveProgramStatus = createRequestStatus();
        draft.getLiveProgramStatus.error = null;
        break;
      case actions.GET_LIVE_PROGRAM_SUCCESS:
        draft.getLiveProgramStatus = createSuccessStatus();
        draft.currentLiveProgram = action.data;
        break;
      case actions.GET_LIVE_PROGRAM_FAILURE:
        draft.getLiveProgramStatus = createFailureStatus();
        break;
      case actions.GET_ALL_LIVE_PROGRAMS_REQUEST:
        draft.getAllLiveProgramsStatus = createRequestStatus();
        break;
      case actions.GET_ALL_LIVE_PROGRAMS_SUCCESS:
        draft.getAllLiveProgramsStatus = createSuccessStatus();
        draft.liveProgramData = action.data;
        break;
      case actions.GET_ALL_LIVE_PROGRAMS_FAILURE:
        draft.getAllLiveProgramsStatus = createFailureStatus();
        break;
      case actions.SYNC_LIVE_PROGRAM_DONE:
        draft.currentLiveProgram = [];
        break;
      case actions.SET_BOOTH_CATEGORY:
        draft.currentCategory = action.value;
        break;
      case actions.SYNC_BOOTH_DONE:
        draft.currentBoothData = {};
        break;
      default:
        break;
    }
  });

export default reducer;
