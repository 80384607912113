import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  width: ${props => props.width};
  height: ${props => props.width};
  border-radius: ${props => props.width};
  background-color: ${props => props.theme.navColor};
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const RoundIcon = ({ children, width = '60px', color = 'white', onClick, ...props }) => {
  return (
    <Container width={width} color={color} onClick={onClick} {...props}>
      {children}
    </Container>
  );
};

RoundIcon.propTypes = {
  width: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.any,
};

export default RoundIcon;
