import React, { useLayoutEffect, useRef, useState } from "react";
import { Button, Dialog, Typography, DialogActions, DialogContent } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ArrowRightAltSharp } from "@material-ui/icons";
export default function EventResultModal(props) {
  const { isModal, setIsEventResultModal, src, rankingInfo, id, liveApply } = props;
  const history = useHistory();
  const randomMargin = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const closeOneDay = () => {
    window.localStorage.setItem(`eventResult${id}`, new Date().getDate());
    setIsEventResultModal(false);
  };
  useLayoutEffect(() => {
    if (window.document.body.offsetWidth <= 800) randomMargin.current = 0;
    else randomMargin.current = rankingInfo || liveApply ? Math.random() * 300 : 0;
    setIsLoading(false);
  }, []);

  if (isLoading) return <></>;
  return (
    <>
      <Dialog open={isModal} onClose={() => setIsEventResultModal(false)} style={{ marginRight: randomMargin.current }}>
        <DialogContent style={{ padding: 0, display: "flex" }}>
          {src.map((sr) => (
            <img src={sr} style={{ width: "100%", height: "100%" }} alt="이벤트 배너" />
          ))}
        </DialogContent>
        {/* <Typography style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
          다양한 상품을 확인해보세요
          <ArrowRightAltSharp />
        </Typography> */}
        <DialogActions style={{ padding: "5px" }}>
          {rankingInfo && (
            <Button onClick={() => history.push("/ranking")} color="primary" variant="outlined">
              랭킹 정보 더보기
            </Button>
          )}
          {liveApply && (
            <Button onClick={() => history.push("/liveProgramPlan")} color="primary" variant="outlined">
              상담 신청 바로가기
            </Button>
          )}
          <Button onClick={() => closeOneDay()} color="primary" variant="outlined">
            하루동안 보지않기
          </Button>
          <Button onClick={() => setIsEventResultModal(false)} color="primary" variant="outlined">
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
