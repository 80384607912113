import { SITE_NAME } from "./globalConstants";
export const ROUTES = {
  home: {
    headTitle: `${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `${SITE_NAME}`,
      },
    ],
  },
  booths: {
    headTitle: `참가부스 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `참가부스 : ${SITE_NAME}`,
      },
    ],
  },
  videos: {
    headTitle: `영상자료관 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `영상자료관 : ${SITE_NAME}`,
      },
    ],
  },
  liveProgramPlan: {
    headTitle: `프로그램(Live) : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `프로그램(Live) : ${SITE_NAME}`,
      },
    ],
  },
  introduction: {
    headTitle: `박람회 소개 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `박람회 소개 : ${SITE_NAME}`,
      },
    ],
  },
  guide: {
    headTitle: `박람회 안내 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `박람회 안내 : ${SITE_NAME}`,
      },
    ],
  },
  mypage: {
    headTitle: `마이페이지 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `마이페이지 : ${SITE_NAME}`,
      },
    ],
  },
  siteTerm: {
    headTitle: `개인정보처리방침 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `개인정보처리방침 : ${SITE_NAME}`,
      },
    ],
  },
  serviceTerm: {
    headTitle: `이용약관 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `이용약관 : ${SITE_NAME}`,
      },
    ],
  },
  admin: {
    headTitle: `관리자 모드 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `관리자 모드 : ${SITE_NAME}`,
      },
    ],
  },
  adminLogin: {
    headTitle: `관리자 로그인 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `관리자 로그인 : ${SITE_NAME}`,
      },
    ],
  },
  adminRegister: {
    headTitle: `관리자 회원가입 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `관리자 회원가입 : ${SITE_NAME}`,
      },
    ],
  },
  superAdmin: {
    headTitle: `슈퍼 관리자 모드 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `슈퍼 관리자 모드 : ${SITE_NAME}`,
      },
    ],
  },
  superAdminLogin: {
    headTitle: `슈퍼 관리자 로그인 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `슈퍼 관리자 로그인 : ${SITE_NAME}`,
      },
    ],
  },
  // login: {
  //   headTitle: `로그인 : ${SITE_NAME}`,
  //   meta: [
  //     {
  //       property: 'og:title',
  //       content: `로그인 : ${SITE_NAME}`,
  //     },
  //   ],
  // },
  // register: {
  //   headTitle: `회원가입 : ${SITE_NAME}`,
  //   meta: [
  //     {
  //       property: 'og:title',
  //       content: `회원가입 : ${SITE_NAME}`,
  //     },
  //   ],
  // },
  livePrograms: {
    headTitle: `라이브 프로그램 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `라이브 프로그램 : ${SITE_NAME}`,
      },
    ],
  },
  ranking: {
    headTitle: `실시간 랭킹 : ${SITE_NAME}`,
    meta: [
      {
        property: "og:title",
        content: `실시간 랭킹 : ${SITE_NAME}`,
      },
    ],
  },
};
