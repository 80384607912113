import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus } from './reducerUtils';
export const initialState = {
  channelId: null,
  enteredAt: null,
  createdAt: null,
  recentCommentPostedAt: null,
  comments: [],
  getCommentsStatus: createStatus(),
  postCommentStatus: createStatus(),
  deleteCommentStatus: createStatus(),
  getAllCommentsStatus: createStatus(),
  updateCommentStatus: createStatus(),
  reportUserStatus: createStatus(),
  addChannelStatus: createStatus(),
};

export const actions = {
  ENTER_CHANNEL_REQUEST: 'ENTER_CHANNEL_REQUEST',
  ENTER_CHANNEL_SUCCESS: 'ENTER_CHANNEL_SUCCESS',
  ADD_CHANNEL_REQUEST: 'ADD_CHANNEL_REQUEST',
  ADD_CHANNEL_FAILURE: 'ADD_CHANNEL_FAILURE',
  ADD_CHANNEL_SUCCESS: 'ADD_CHANNEL_SUCCESS',
  GET_COMMENTS_REQUEST: 'GET_COMMENTS_REQUEST',
  GET_COMMENTS_FAILURE: 'GET_COMMENTS_FAILURE',
  GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
  GET_NUMBER_OF_RECENT_COMMENTS_REQUEST: 'GET_NUMBER_OF_RECENT_COMMENTS_REQUEST',
  GET_NUMBER_OF_RECENT_COMMENTS_FAILURE: 'GET_NUMBER_OF_RECENT_COMMENTS_FAILURE',
  GET_NUMBER_OF_RECENT_COMMENTS_SUCCESS: 'GET_NUMBER_OF_RECENT_COMMENTS_SUCCESS',
  POST_COMMENT_REQUEST: 'POST_COMMENT_REQUEST',
  POST_COMMENT_FAILURE: 'POST_COMMENT_FAILURE',
  POST_COMMENT_SUCCESS: 'POST_COMMENT_SUCCESS',
  DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
  DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  GET_ALL_COMMENTS_REQUEST: 'GET_ALL_COMMENTS_REQUEST',
  GET_ALL_COMMENTS_FAILURE: 'GET_ALL_COMMENTS_FAILURE',
  GET_ALL_COMMENTS_SUCCESS: 'GET_ALL_COMMENTS_SUCCESS',
  UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
  UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',
  UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
  REPORT_USER_REQUEST: 'REPORT_USER_REQUEST',
  REPORT_USER_SUCCESS: 'REPORT_USER_SUCCESS',
  REPORT_USER_FAILURE: 'REPORT_USER_FAILURE',
  SYNC_COMMENT_DONE: 'SYNC_COMMENT_DONE',
};

export const enterChannelAction = channelId => ({
  type: actions.ENTER_CHANNEL_REQUEST,
  channelId,
});
export const addChannelAction = (channelId, data) => ({
  type: actions.ADD_CHANNEL_REQUEST,
  channelId,
  data,
});
export const getCommentsAction = (channelId, enteredAt = null) => ({
  type: actions.GET_COMMENTS_REQUEST,
  channelId,
  enteredAt,
});
export const getNumberOfRecentCommentsAction = (channelId, amount) => ({
  type: actions.GET_NUMBER_OF_RECENT_COMMENTS_REQUEST,
  channelId,
  amount,
});
export const postCommentAction = (channelId, data, timeLimit) => ({
  type: actions.POST_COMMENT_REQUEST,
  channelId,
  data,
  timeLimit,
});
export const deleteCommentAction = (channelId, commentId) => ({
  type: actions.DELETE_COMMENT_REQUEST,
  channelId,
  commentId,
});
export const getAllCommentsAction = () => ({
  type: actions.GET_ALL_COMMENTS_REQUEST,
});
export const updateCommentAction = (channelId, commentId, target, value) => ({
  type: actions.UPDATE_COMMENT_REQUEST,
  channelId,
  commentId,
  target,
  value,
});
export const reportUserAction = (id, channelId, comment) => ({
  type: actions.REPORT_USER_REQUEST,
  id,
  channelId,
  comment,
});
export const syncCommentDone = () => ({ type: actions.SYNC_COMMENT_DONE });

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.ENTER_CHANNEL_REQUEST:
        break;
      case actions.ENTER_CHANNEL_SUCCESS:
        draft.channelId = action.channelId;
        draft.enteredAt = action.enteredAt;
        break;
      case actions.GET_COMMENTS_REQUEST:
        draft.getCommentsStatus = createRequestStatus();
        break;
      case actions.GET_COMMENTS_SUCCESS:
        draft.getCommentsStatus = createSuccessStatus();
        draft.comments = action.data;
        break;
      case actions.GET_COMMENTS_FAILURE:
        draft.getCommentsStatus = createFailureStatus(action.error);
        break;
      case actions.GET_ALL_COMMENTS_REQUEST:
        draft.getAllCommentsStatus = createRequestStatus();
        break;
      case actions.GET_ALL_COMMENTS_SUCCESS:
        draft.getAllCommentsStatus = createSuccessStatus();
        draft.comments = action.data;
        break;
      case actions.GET_ALL_COMMENTS_FAILURE:
        draft.getAllCommentsStatus = createFailureStatus(action.error);
        break;
      case actions.GET_NUMBER_OF_RECENT_COMMENTS_REQUEST:
        draft.getCommentsStatus = createRequestStatus();
        break;
      case actions.GET_NUMBER_OF_RECENT_COMMENTS_SUCCESS:
        draft.getCommentsStatus = createSuccessStatus();
        draft.comments = action.data;
        break;
      case actions.GET_NUMBER_OF_RECENT_COMMENTS_FAILURE:
        draft.getCommentsStatus = createFailureStatus(action.error);
        break;
      case actions.POST_COMMENT_REQUEST:
        draft.postCommentStatus = createRequestStatus();
        const recentCommentPostedAt = sessionStorage.getItem('reccentCommentPostedAt');
        if (recentCommentPostedAt) {
          draft.recentCommentPostedAt = new Date(recentCommentPostedAt);
        }

        break;
      case actions.POST_COMMENT_SUCCESS:
        draft.postCommentStatus = createSuccessStatus();
        draft.recentCommentPostedAt = action.now;
        sessionStorage.setItem('reccentCommentPostedAt', action.now.toString());
        break;
      case actions.POST_COMMENT_FAILURE:
        draft.postCommentStatus = createFailureStatus(action.error);
        break;
      case actions.DELETE_COMMENT_REQUEST:
        draft.deleteCommentStatus = createRequestStatus();
        break;
      case actions.DELETE_COMMENT_SUCCESS:
        draft.deleteCommentStatus = createSuccessStatus();
        break;
      case actions.DELETE_COMMENT_FAILURE:
        draft.deleteCommentStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_COMMENT_REQUEST:
        draft.updateCommentStatus = createRequestStatus();
        break;
      case actions.UPDATE_COMMENT_SUCCESS:
        draft.updateCommentStatus = createSuccessStatus();
        break;
      case actions.UPDATE_COMMENT_FAILURE:
        draft.updateCommentStatus = createFailureStatus(action.error);
        break;
      case actions.REPORT_USER_REQUEST:
        draft.reportUserStatus = createRequestStatus();
        break;
      case actions.REPORT_USER_SUCCESS:
        draft.reportUserStatus = createSuccessStatus();
        break;
      case actions.REPORT_USER_FAILURE:
        draft.reportUserStatus = createFailureStatus(action.error);
      case actions.ADD_CHANNEL_REQUEST:
        draft.addChannelStatus = createRequestStatus();
        break;
      case actions.ADD_CHANNEL_SUCCESS:
        draft.addChannelStatus = createSuccessStatus();
        break;
      case actions.ADD_CHANNEL_FAILURE:
        draft.addChannelStatus = createFailureStatus(action.error);
        break;
      case actions.SYNC_COMMENT_DONE:
        draft.getCommentsStatus = createStatus();
        draft.comments = [];
        break;
      default:
        break;
    }
  });

export default reducer;
