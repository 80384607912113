import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import pickRandomNumbers from '../../utils/pickRandomNumbers';
import { getVideosAction } from '../../reducers/video';
import YoutubeCard from '../../components/YoutubeCard';
const Container = styled(Grid)``;
const VideoGrid = styled(Grid)``;
const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
`;
const Thumbnail = styled.img`
  width: 100%;
  border-radius: 6px;
`;
const PlayButton = styled.img`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-25px, -25px);
  width: 50px;
  height: 50px;
`;
const Title = styled.p`
  margin-bottom: 1px;
`;
export default function Videos() {
  const dispatch = useDispatch();
  const { videoData } = useSelector(state => state.video);
  const [randomVideos, setRandomVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    dispatch(getVideosAction());
  }, []);
  //랜덤 데이터 불러오기
  useEffect(() => {
    setIsLoading(true);
    if (videoData) {
      const length = videoData.length;
      if (length < 12) {
        // 전체 데이터가 12개 이하일 경우는 그냥 하고
        setRandomVideos(videoData);
      } else {
        // 12개의 랜덤 숫자를 어레이에 저장한 후, 랜덤 숫자 픽
        let randomIndexArray = pickRandomNumbers(length, 12);
        setRandomVideos(randomIndexArray.map(i => videoData[i]));
      }
    }
    setIsLoading(false);
  }, [videoData]);

  return (
    <>
      <Container container spacing={2}>
        {!isLoading &&
          randomVideos?.map(item => {
            if (item.category === 'VR관')
              return (
                <VideoGrid item xs={6} sm={6} md={4} lg={3} justify="center" container key={item.id}>
                  <a href={item.url} target="_blank">
                    <img src={item.thumbnail} alt="vr 썸네일 이미지" style={{ width: '100%', borderRadius: '10px' }} />
                  </a>
                  <p>{item.title}</p>
                </VideoGrid>
              );
            else
              return (
                <VideoGrid item xs={6} sm={6} md={4} lg={3} justify="center" container key={item.id}>
                  <YoutubeCard video={item} />
                </VideoGrid>
              );
          })}
      </Container>
    </>
  );
}
