import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
const Container = styled.div`
  width: 100%;
  height: 2.8em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.greyColor};
  background-color: ${(props) => props.theme.footerBgColor};
  border-radius: 10px;
  margin: 10px auto;
  font-weight: 600;
  ${(props) => props.theme.transition};
  border: 1px solid ${(props) => props.theme.greyColor};
  &:hover {
    color: white;
    border: 1px solid ${(props) => props.theme.footerBgColor};
    background-color: ${(props) => props.theme.greyColor};
  }
`;
function RoundButton({ children, to, href }) {
  const history = useHistory();
  const onClickHandler = useCallback(() => {
    if (to) history.push(to);
    if (href) window.open(href, '_blank');
  }, []);
  return <Container onClick={onClickHandler}>{children}</Container>;
}

export default RoundButton;
