import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, CardMedia, Typography, CardContent, CardActions, Button } from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import liveTagImage from "../assets/images/icon/liveTag.png";
import zep01 from "../assets/zep/zep01.png";
import zep02 from "../assets/zep/zep02.png";
import zep03 from "../assets/zep/zep03.png";
import zep04 from "../assets/zep/zep04.png";
import zep05 from "../assets/zep/zep05.png";

const useStyles = makeStyles(({ breakpoints }) => ({
  card: {
    width: "100%",
    position: "relative",
    [breakpoints.up("sm")]: {
      height: "340px",
    },
    [breakpoints.down("xs")]: {
      maxHeight: 265,
    },
  },
  cardMedia: {
    height: "200px",
    [breakpoints.down("xs")]: {
      height: "120px",
    },
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      opacity: 0.8,
    },
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  title: {
    overflow: "hidden",
    wordWrap: "break-word",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    maxHeight: 30,
    [breakpoints.only("sm")]: {
      fontSize: "20px",
      maxHeight: 28,
    },
    [breakpoints.only("xs")]: {
      fontSize: "16px",
      maxHeight: 40,
    },
  },
  description: {
    height: "40px",
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    marginBottom: "70px",
    [breakpoints.only("xs")]: {
      height: "60px",
      marginBottom: "50px",
      WebkitLineClamp: 3,
    },
  },
  button: {
    width: "50%",
    color: "#bababa",
  },
  actions: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    [breakpoints.only("xs")]: {
      backgroundColor: "white",
      padding: 5,
    },
  },
  span: {
    [breakpoints.only("xs")]: {
      display: "none",
    },
  },
}));

const LiveTag = styled.img`
  width: 54px;
  position: absolute;
  display: block;
  top: 8px;
  right: 10px;
  z-index: 100;
`;
// 둥실둥실하는 애니메이션 효과
const float = keyframes`
  0% {
    transform: translateY(0px);
  }
  25%{
    opacity: 0.75;
  }
  50% {
    transform: translateY(-10px);
    
  }
  75%{
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
  }
`;

const Zep = styled.img`
  height: 100%;
`;

const ZepWrapper = styled.div`
  height: 88px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  transition: all 0.5s ease;
  gap: 5px;
  ${(props) =>
    props.hoverIn
      ? `
   
    bottom: 132px;
    opacity: 1;
  `
      : `
    bottom: -122px;
    opacity: 0;
  `}
  &:hover {
    // 둥실둥실 하는 애니메이션 효과
    animation: ${float} 1s ease-in-out infinite;
  }
`;
const Label = styled.div`
  background-color: #6758ff;
  color: white;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: 12px;
`;
function BoothCard({ booth }) {
  const { userData, isLoggedIn } = useSelector((state) => state.user);
  const { globalData } = useSelector((state) => state.global);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [hoverIn, setHoverIn] = React.useState(false);
  const zeps = [zep01, zep02, zep03, zep04, zep05];
  const goBoothDetailPage = (boothId, isProgram = false) => {
    if (isProgram) {
      history.push({
        pathname: `/booth/${boothId}`,
        search: "?program=true",
      });
    } else {
      history.push(`/booth/${boothId}`);
    }
  };

  return (
    <Card className={classes.card} onMouseEnter={() => setHoverIn(true)} onMouseLeave={() => setHoverIn(false)}>
      <CardMedia
        // component='img'
        alt={booth.title}
        image={booth.logo?.url === "" || !booth.logo?.url ? "https://dummyimage.com/600x600/94B3FF/ffffff&text=NoImage" : booth.logo?.url}
        onClick={() => goBoothDetailPage(booth.id)}
        title={booth.title}
        className={classes.cardMedia}
      />
      {(booth.isLiveZoom || booth.isLiveYoutube) && <LiveTag src={liveTagImage} />}
      <CardContent>
        <Typography className={classes.title} gutterBottom variant="h5" component="h2">
          {booth.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p" className={classes.description}>
          {booth.description}
        </Typography>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button className={classes.button} size="medium" color="primary" onClick={() => goBoothDetailPage(booth.id)}>
          소개
        </Button>
        {booth.id !== "kDHHuyBb" && booth.id !== "UZ2G5Szm" && (
          <>
            {booth.metaverse && (
              <Button className={classes.button} size="medium" color="primary" onClick={() => goBoothDetailPage(booth.id, true)}>
                <span className={classes.span}>메타버스</span>
              </Button>
            )}
          </>
        )}
        {booth.metaverse && (
          <ZepWrapper hoverIn={hoverIn} onClick={() => window.open(booth.metaverse, "_blank")}>
            <Zep src={zeps[Math.floor(Math.random() * 5)]} />
            <Label>메타버스 실행하기</Label>
          </ZepWrapper>
        )}
      </CardActions>
    </Card>
  );
}

export default BoothCard;
