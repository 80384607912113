import React from 'react';
// import { Link } from 'react-router-dom';
import Link from '../components/Link';
import styled from 'styled-components';
import plusSvg from '../assets/svg/plusIcon.svg';
const Container = styled.h1`
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;
const Text = styled.h1`
  /* color: ${props => props.theme.mainColor}; */
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  & h2 {
    color: grey;
    margin-left: 10px;
    font-size: 1.1rem;
    font-weight: 400;
  }
  @media screen and (max-width: 350px) {
    margin: 5px;
    font-size: 1.2rem;
    & h2 {
      font-size: 1.1rem;
    }
  }
`;
const Button = styled.div`
  background-color: ${props => props.theme.primaryColor};
  min-width: 102px;
  min-height: 36px;
  padding: 5px;
  font-weight: 400;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    font-size: 1rem;
    margin-top: 2px;
  }
  &:hover {
    opacity: 0.8;
    transition: 0.3s linear;
  }
  @media screen and (max-width: 500px) {
    min-width: 77px;
    max-width: 120px;
    min-height: 35px;
    border-radius: 6px;
    & > div {
      font-size: 0.95rem;
      margin-top: 2px;
    }
  }
  @media screen and (max-width: 350px) {
    margin: 5px;
    max-width: 90px;
    & > div {
      font-size: 0.7rem;
    }
  }
`;
const Plus = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 4px;
  @media screen and (max-width: 500px) {
    width: 18px;
    height: 18px;
  }
  @media screen and (max-width: 350px) {
    width: 18px;
    height: 18px;
  }
`;

function H1({ children, subHeading, button, ...props }) {
  return (
    <Container {...props}>
      <Text>
        {children}
        {subHeading && <h2>{subHeading}</h2>}
      </Text>

      {button && (
        <Link {...button}>
          <Button>
            <Plus src={plusSvg} />
            <div>{button.text}</div>
          </Button>
        </Link>
      )}
    </Container>
  );
}

export default H1;
