const BOX_BORDER = "1px solid #e6e6e6";
const BORDER_RADIUS = "10px";
const MAIN_COLOR = "#F5A742";
const FOOTER_COLOR = "#F5F5F5";

export default {
  maxWidth: "1184px",
  borderRadius: "4px",
  borderRadiusLarge: "10px",
  boxBorder: "1px solid #e6e6e6",

  //colors
  footerBgColor: `${FOOTER_COLOR}`,
  lightGreyColor: "#EAEAEA",
  greyColor: "#8E8E8E",
  navColor: `${MAIN_COLOR}`,
  bgColor: "#fff",
  mainColor: `${MAIN_COLOR}`,
  linkColor: "#F5A742",
  primaryColor: "#E94625",
  blackColor: "#363838",
  black2Color: "#252C37",

  //margins
  smallMargin: "12px",
  bigBoxInMargin: "26px",
  bigBoxOutMargin: "65px",

  // animaintions
  transition: `transition: all 250ms ease-out;
  `,

  //etc
  boxShadow: `-webkit-box-shadow: 5px 5px 10px 0px rgba(132,132,132,0.3); 
  box-shadow: 5px 5px 10px 0px rgba(132,132,132,0.3);`,

  whiteBox: ` border-radius: ${BORDER_RADIUS};
                background-color: white;
                `,

  blueColor: "#3897F0",
  darkBlueColor: "#003569",
  redColor: "#ed4956",
  logoFont: `"Segoe UI", Tahoma, Geneva, Verdana, sans-serif`,
  scrollbar: `
  scrollbar-width: thin;
  scrollbar-color: blue orange;
  // explorer 최적화 해야함
  /* html {scrollbar-3dLight-Color: #efefef; scrollbar-arrow-color: #dfdfdf; scrollbar-base-color: #efefef; scrollbar-Face-Color: #dfdfdf; scrollbar-Track-Color: #efefef; scrollbar-DarkShadow-Color: #efefef; scrollbar-Highlight-Color: #efefef; scrollbar-Shadow-Color: #efefef} */
  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background: ${MAIN_COLOR};
    width: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 20px;
    border: 5px solid ${MAIN_COLOR};
  }
  `,
  commentScrollbar: `
  scrollbar-width: thin;
  scrollbar-color: blue orange;
  // explorer 최적화 해야함
  /* html {scrollbar-3dLight-Color: #efefef; scrollbar-arrow-color: #dfdfdf; scrollbar-base-color: #efefef; scrollbar-Face-Color: #dfdfdf; scrollbar-Track-Color: #efefef; scrollbar-DarkShadow-Color: #efefef; scrollbar-Highlight-Color: #efefef; scrollbar-Shadow-Color: #efefef} */
  /* Works on Chrome, Edge, and Safari */
  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar-track {
    background:  #eeeeee;
    width: 5px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #b3b3b3;
    border-radius: 20px;
    border: 5px solid #eeeeee;
  }
  `,
};
