//hooks
import usePathname from '../../hooks/usePathname';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//components
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { BeatLoader } from 'react-spinners';
import ImageUploadModule from './ImageUploadModule';
import imageCompression from 'browser-image-compression';

//libraries
import firebase from 'firebase/app';
import shortid from 'shortid';

// etc
import { boothEvaluate } from '../../constants/pointConstants';
import { storage } from '../../firebase';

//reducers
import { postCommentAction } from '../../reducers/comment';
import { updateBoothDataAction } from '../../reducers/booth';
import { updatePointAction, updateUserDataAction } from '../../reducers/user';
import { MAIN_TIME_LIMIT, BOOTH_TIME_LIMIT } from '../../constants/commentsConstants';
export default function ManageLogo({ setIsModal, isModal, channelId }) {
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { userData } = useSelector(state => state.user);
  const { globalData } = useSelector(state => state.global);
  //파일업로드관련 로직 시작
  const [selectedFile, setSelectedFile] = useState(null);
  const fileUploadHandler = async channelId => {
    const compressedFile = await imageCompression(selectedFile, {
      maxWidthOrHeight: 1000,
    });
    return new Promise(resolve => {
      const RandomText = shortid.generate();
      const fileName = `${channelId}_${RandomText}`;
      const storageRef = storage.ref(`chatImage/${channelId}/${fileName}`);
      const task = storageRef.put(compressedFile);
      task.on(
        'state_changed',
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(percentage);
        },
        function error(err) {
          console.log(err);
        },
        function complete() {
          resolve(fileName);
        },
      );
    });
  };
  //파일업로드관련 로직 끝

  const closeModal = () => {
    setIsModal(false);
    setSelectedFile(null);
  };

  const onSubmit = async (values, setSubmitting) => {
    let uploadedFileName = '';
    let imagePath = '';
    if (pathname === 'home' && globalData.isCommentBlocked?.home) {
      alert('댓글을 작성하실 수 없습니다.');
      return;
    }
    if (pathname === 'booth' && globalData.isCommentBlocked?.booth) {
      alert('학교 부스에 댓글을 작성하실 수 없습니다.');
      return;
    }
    if (pathname === 'home' && globalData.isCommentBlocked?.imageHome) {
      alert('지금은 이미지 댓글은 작성하실 수 없습니다.');
      return;
    }
    if (pathname === 'booth' && globalData.isCommentBlocked?.imageBooth) {
      alert('지금은 이미지 댓글은 작성하실 수 없습니다.');
      return;
    }
    if (userData.isBlocked) {
      alert('블록된 사용자입니다. 댓글을 작성하실 수 없습니다.');
      return;
    }
    if (!selectedFile) {
      alert('이미지를 업로드해주세요.');
      return false;
    }
    if (selectedFile !== null) {
      uploadedFileName = await fileUploadHandler(channelId);
      imagePath = `chatImage/${channelId}/${uploadedFileName}`;
    }
    storage
      .ref(imagePath)
      .getDownloadURL()
      .then(imgUrl => {
        if (pathname === 'home') {
          dispatch(
            postCommentAction(
              channelId,
              {
                uid: userData.uid,
                type: 'image',
                value: { url: imgUrl, path: imagePath },
                userName: userData.userName,
                avatar: userData.avatar,
              },
              MAIN_TIME_LIMIT,
            ),
          );
        } else if (pathname === 'booth') {
          dispatch(
            postCommentAction(
              channelId,
              {
                uid: userData.uid,
                type: 'image',
                value: { url: imgUrl, path: imagePath },
                userName: userData.userName,
                avatar: userData.avatar,
              },
              BOOTH_TIME_LIMIT,
            ),
          );
        }

        if (userData.status === 'student') {
          if (pathname === 'home') {
            //고등학생 처리. 2021 직업온라인 박람회 랭킹 점수에 고등학교는 포함되지 않음.
            if (!userData.schoolName.includes('고등학교')) {
              dispatch(updatePointAction(globalData.points.POST_MAIN_IMAGE));
            }
          } else if (pathname === 'booth') {
            dispatch(
              updateBoothDataAction(channelId, {
                target: 'point',
                value: firebase.firestore.FieldValue.increment(boothEvaluate.imgComment),
              }),
            );
            //고등학생 처리. 2021 직업온라인 박람회 랭킹 점수에 고등학교는 포함되지 않음.
            if (!userData.schoolName.includes('고등학교')) {
              dispatch(updatePointAction(globalData.points.POST_BOOTH_LIVE_IMAGE));
            }
          }
        }
        closeModal();
        setSubmitting(false);
      });
  };
  return (
    <>
      <Dialog open={isModal} onClose={closeModal} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
        <Formik
          initialValues={{
            videoUrl: '',
          }}
          onSubmit={(values, { setSubmitting }) => onSubmit(values, setSubmitting)}
        >
          {({ isSubmitting }) => (
            <Form>
              <DialogTitle id="scroll-dialog-title">이미지 첨부</DialogTitle>
              <DialogContent>
                <ImageUploadModule setSelectedFile={setSelectedFile} />
              </DialogContent>
              <DialogActions style={{ padding: '20px' }}>
                <Button disabled={isSubmitting} onClick={closeModal} color="primary" variant="outlined">
                  취소
                </Button>
                <Button disabled={isSubmitting} type="submit" color="primary" variant="contained">
                  추가
                </Button>
              </DialogActions>
              {isSubmitting && (
                <div style={{ textAlign: 'center', padding: '10px 0 30px 0' }}>
                  <BeatLoader color={'var(--primary)'} loading={true} />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}
