import { useEffect } from 'react';
import styled from 'styled-components';
import Link from '../components/Link';
import HeaderMenu from './HeaderMenu';
import { LOGO } from '../constants/asseetConstants';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles(({ breakpoints }) => ({
  logoContainer: {
    maxWidth: 'calc(100% - 60px)',
    '& img': {
      display: 'block',
      marginLeft: -1,
      width: '100%',
      maxWidth: 336,
      [breakpoints.down('md')]: {
        marginLeft: 10,
      },
      [breakpoints.only('xs')]: {
        marginLeft: 0,
        // display: window.scrollY > 0 ? 'none' : 'block',
      },
    },
  },
}));
const Container = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
  padding: 0 8px;
  height: 80px;
  align-items: center;
`;
const LogoContainer = styled.div`
  max-width: calc(100% - 60px);
  img {
    display: block;
    margin-left: -10px;
    width: 100%;
    max-width: 336px;
    /* @media screen and (max-width: 500px) {
      margin-left: -30;
    } */
  }
`;
const Header = () => {
  const classes = useStyles();

  return (
    <Container>
      <Link to="/">
        <div className={classes.logoContainer}>
          <img src={LOGO} alt="logo" />
        </div>
      </Link>
      <HeaderMenu />
    </Container>
  );
};

export default Header;
