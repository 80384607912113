import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useCallback } from "react";
import usePathname from "../hooks/usePathname";
import theme from "../styles/theme";
const Container = styled.div`
  display: block;
  color: ${(props) => (props.isCurrentPage ? theme.primaryColor : "#363838")};
  cursor: pointer;
  ${(props) => props.theme.transition};
  // background-color: ${(props) => (props.isCurrentPage ? "#FAD25A" : "white")};
  &:hover {
    font-weight: 700;
    color: ${(props) => (props.isCurrentPage ? theme.linkColor : theme.primaryColor)};
  }
  height: 36px;
  text-align: center;
  line-height: 36px;
  // 폰트 크 변경
  font-weight: 700;
  font-size: 1.1em;
  word-break: keep-all;
  /* border: 1px solid ${(props) => props.theme.primaryColor}; */
  border-radius: 4px;
  padding: 0 10px;
`;

const NavLink = ({ text, width = "100px", to, onClick }) => {
  const history = useHistory();
  const pathname = usePathname();
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const onClickHandler = useCallback(() => {
    history.push(to);
  }, []);
  useEffect(() => {
    if (to?.includes(pathname)) setIsCurrentPage(true);
    else setIsCurrentPage(false);
  }, [pathname]);
  return (
    <Container width={width} onClick={to ? onClickHandler : onClick} isCurrentPage={isCurrentPage}>
      {text}
    </Container>
  );
};

NavLink.propTypes = {
  text: PropTypes.string.isRequired,
  width: PropTypes.string,
};

export default NavLink;
