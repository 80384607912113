import { enableAllPlugins } from "immer";
enableAllPlugins();
import { produce } from "immer";
import { ADMIN_COMMENTS_KEY, ADMIN_COMMENTS_UPDATED_AT_KEY } from "../constants/storageConstants";
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus } from "./reducerUtils";

export const initialState = {
  users: [],
  userIds: new Set(),
  reportedUsers: [],
  totalComments: null,
  booths: [],
  programPlans: [],
  channelList: [],
  comments: [],
  tempComments: [],
  commentsForManageStorage: [],
  commentsForManage: [],
  allUsers: [],
  commentsForManageLoadedAt: null,
  recentlyCommentsForManageLoadedAt: null,
  commentsUpdatedAt: null,
  getUserStatus: createStatus(),
  getReportedUsersStatus: createStatus(),
  getCommentsStatus: createStatus(),
  updateUserPointStatus: createStatus(),
  updatePointsStatus: createStatus(),
  updateReportedUserDataStatus: createStatus(),
  getAllCommentsStatus: createStatus(),
  getChannelListStatus: createStatus(),
  getAllUsersStatus: createStatus(),
  resetAllUsersStatus: createStatus(),
  getAllCommentsForManageStatus: createStatus(),
};

// 액션
export const actions = {
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILURE: "GET_USER_FAILURE",
  GET_ALL_COMMENTS_FOR_MANAGE_REQUEST: "GET_ALL_COMMENTS_FOR_MANAGE_REQUEST",
  GET_ALL_COMMENTS_FOR_MANAGE_SUCCESS: "GET_ALL_COMMENTS_FOR_MANAGE_SUCCESS",
  GET_ALL_COMMENTS_FOR_MANAGE_FAILURE: "GET_ALL_COMMENTS_FOR_MANAGE_FAILURE",
  GET_ALL_USERS_REQUEST: "GET_ALL_USERS_REQUEST",
  GET_ALL_USERS_SUCCESS: "GET_ALL_USERS_SUCCESS",
  GET_ALL_USERS_FAILURE: "GET_ALL_USERS_FAILURE",
  RESET_ALL_USERS_REQUEST: "RESET_ALL_USERS_REQUEST",
  RESET_ALL_USERS_SUCCESS: "RESET_ALL_USERS_SUCCESS",
  RESET_ALL_USERS_FAILURE: "RESET_ALL_USERS_FAILURE",
  DELETE_ONE_COMMENT_FROM_LOCAL: "DELETE_ONE_COMMENT_FROM_LOCAL",
  GET_CHANNEL_LIST_REQUEST: "GET_CHANNEL_LIST_REQUEST",
  GET_CHANNEL_LIST_SUCCESS: "GET_CHANNEL_LIST_SUCCESS",
  GET_CHANNEL_LIST_FAILURE: "GET_CHANNEL_LIST_FAILURE",
  GET_ALL_COMMENTS_REQUEST: "ADMIN_GET_ALL_COMMENTS_REQUEST",
  GET_ALL_COMMENTS_SUCCESS: "ADMIN_GET_ALL_COMMENTS_SUCCESS",
  GET_ALL_COMMENTS_FAILURE: "ADMIN_GET_ALL_COMMENTS_FAILURE",
  GET_REPORTED_USERS_REQUEST: "GET_REPORTED_USERS_REQUEST",
  GET_REPORTED_USERS_SUCCESS: "GET_REPORTED_USERS_SUCCESS",
  GET_REPORTED_USERS_FAILURE: "GET_REPORTED_USERS_FAILURE",
  UPDATE_USER_POINT_REQUEST: "UPDATE_USER_POINT_REQUEST",
  UPDATE_USER_POINT_SUCCESS: "UPDATE_USER_POINT_SUCCESS",
  UPDATE_USER_POINT_FAILURE: "UPDATE_USER_POINT_FAILURE",
  UPDATE_REPORTED_USER_DATA_REQUEST: "UPDATE_REPORTED_USER_DATA_REQUEST",
  UPDATE_REPORTED_USER_DATA_SUCCESS: "UPDATE_REPORTED_USER_DATA_SUCCESS",
  UPDATE_REPORTED_USER_DATA_FAILURE: "UPDATE_REPORTED_USER_DATA_FAILURE",
  UPDATE_POINTS_REQUEST: "UPDATE_POINTS_REQUEST",
  UPDATE_POINTS_SUCCESS: "UPDATE_POINTS_SUCCESS",
  UPDATE_POINTS_FAILURE: "UPDATE_POINTS_FAILURE",
  GET_USER_ID: "GET_USER_ID",
  COMMENTS_LOCAL_SYNC: "COMMENTS_LOCAL_SYNC",
  SYNC_REPORTED_USER_DONE: "SYNC_REPORTED_USER_DONE",
  LOCAL_COMMENT_DELETE: "LOCAL_COMMENT_DELETE",
  LOCAL_COMMENT_UPDATE: "LOCAL_COMMENT_UPDATE",
  LOAD_COMMENTS_FOR_MANAGE: "LOAD_COMMENTS_FOR_MANAGE",
  SYNC_ALL_COMMENTS_FOR_MANAGE_DONE: "SYNC_ALL_COMMENTS_FOR_MANAGE_DONE",
};

// 액션 크리에이터
export const getUserAction = (id) => ({
  type: actions.GET_USER_REQUEST,
  id,
});

export const getAllUsersAction = (id) => ({
  type: actions.GET_ALL_USERS_REQUEST,
});
export const getAllCommentsAction = () => ({
  type: actions.GET_ALL_COMMENTS_REQUEST,
});
export const getChannelListAction = () => ({
  type: actions.GET_CHANNEL_LIST_REQUEST,
});

export const getReportedUsersAction = () => ({
  type: actions.GET_REPORTED_USERS_REQUEST,
});
// update 특정한 유저의 점수
export const updateUserPointAction = (id, point, classId) => ({
  type: actions.UPDATE_USER_POINT_REQUEST,
  id,
  point,
  classId,
});
export const updateReportedUserDataAction = (id, target, value) => ({
  type: actions.UPDATE_REPORTED_USER_DATA_REQUEST,
  id,
  target,
  value,
});
// update global point
export const updatePointsAction = (data) => ({
  type: actions.UPDATE_USER_POINT_REQUEST,
  data,
});
export const syncReportedUserDone = () => ({
  type: actions.SYNC_REPORTED_USER_DONE,
});
export const syncGetAllCommentsForManageDone = () => ({
  type: actions.SYNC_ALL_COMMENTS_FOR_MANAGE_DONE,
});

// 위험한 액션. 주의 필요
export const resetAllUsersAction = () => {
  const isConfirmed = confirm("정말 전체 학생 데이터를 초기화하시겠습니까?");
  if (isConfirmed)
    return {
      type: actions.RESET_ALL_USERS_REQUEST,
    };
};

// 댓글 관리
export const getAllCommentsForManageAction = (count = 10000) => ({
  type: actions.GET_ALL_COMMENTS_FOR_MANAGE_REQUEST,
  count,
});
export const loadCommentsForManage = () => ({
  type: actions.LOAD_COMMENTS_FOR_MANAGE,
});

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_USER_REQUEST:
        draft.getUserStatus = createRequestStatus();
        break;
      case actions.GET_USER_SUCCESS:
        draft.getUserStatus = createSuccessStatus();
        if (action.data) draft.users.push(action.data);
        break;
      case actions.GET_USER_FAILURE:
        draft.getUserStatus = createFailureStatus(action.error);
        break;
      case actions.GET_ALL_COMMENTS_FOR_MANAGE_REQUEST:
        draft.getAllCommentsForManageStatus = createRequestStatus();
        break;
      case actions.GET_ALL_COMMENTS_FOR_MANAGE_SUCCESS:
        draft.getAllCommentsForManageStatus = createSuccessStatus();
        draft.commentsForManageStorage = action.data;
        draft.commentsForManageLoadedAt = new Date();
        draft.recentlyCommentsForManageLoadedAt = new Date();
        break;
      case actions.GET_ALL_COMMENTS_FOR_MANAGE_FAILURE:
        draft.getAllCommentsForManageStatus = createFailureStatus(action.error);
        break;
      case actions.LOAD_COMMENTS_FOR_MANAGE:
        draft.commentsForManage = draft.commentsForManageStorage;
        if (draft.recentlyCommentsForManageLoadedAt) {
          draft.commentsForManageLoadedAt = draft.recentlyCommentsForManageLoadedAt;
          draft.recentlyCommentsForManageLoadedAt = new Date();
        }
        break;
      case actions.GET_ALL_USERS_REQUEST:
        draft.getAllUsersStatus = createRequestStatus();
        break;
      case actions.GET_ALL_USERS_SUCCESS:
        draft.getAllUsersStatus = createSuccessStatus();
        draft.allUsers = action.data;
        break;
      case actions.GET_ALL_USERS_FAILURE:
        draft.getAllUsersStatus = createFailureStatus(action.error);
        break;
      case actions.GET_CHANNEL_LIST_REQUEST:
        draft.getChannelListStatus = createRequestStatus();
        break;
      case actions.GET_CHANNEL_LIST_SUCCESS:
        draft.getChannelListStatus = createSuccessStatus();
        draft.channelList = action.data;
        break;
      case actions.GET_CHANNEL_LIST_FAILURE:
        draft.getChannelListStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_USER_POINT_REQUEST:
        draft.updateUserPointStatus = createRequestStatus();
        break;
      case actions.UPDATE_USER_POINT_SUCCESS:
        draft.updateUserPointStatus = createSuccessStatus();
        break;
      case actions.UPDATE_USER_POINT_FAILURE:
        draft.updateUserPointStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_POINTS_REQUEST:
        draft.updatePointsStatus = createRequestStatus();
        break;
      case actions.UPDATE_POINTS_SUCCESS:
        draft.updatePointsStatus = createSuccessStatus();
        break;
      case actions.UPDATE_POINTS_FAILURE:
        draft.updatePointsStatus = createFailureStatus(action.error);
        break;
      case actions.UPDATE_REPORTED_USER_DATA_REQUEST:
        draft.updateReportedUserDataStatus = createRequestStatus();
        break;
      case actions.UPDATE_REPORTED_USER_DATA_SUCCESS:
        draft.updateReportedUserDataStatus = createSuccessStatus();
        break;
      case actions.UPDATE_REPORTED_USER_DATA_FAILURE:
        draft.updateReportedUserDataStatus = createFailureStatus(action.error);
        break;
      case actions.GET_REPORTED_USERS_REQUEST:
        draft.getReportedUsersStatus = createRequestStatus();
        break;
      case actions.GET_REPORTED_USERS_SUCCESS:
        draft.getReportedUsersStatus = createSuccessStatus();
        draft.reportedUsers = action.data;
        break;
      case actions.GET_REPORTED_USERS_FAILURE:
        draft.getReportedUsersStatus = createFailureStatus(action.error);
        break;
      case actions.GET_ALL_COMMENTS_REQUEST:
        draft.getAllCommentsStatus = createRequestStatus();
        break;
      case actions.GET_ALL_COMMENTS_SUCCESS:
        if (action.firstCall) {
          draft.tempComments = action.data;
        } else {
          draft.tempComments = [...draft.tempComments, ...action.data];
        }
        if (action.lastCall) {
          draft.tempComments.sort((a, b) => b.createdAt?.seconds - a.createdAt?.seconds);
          draft.comments = [...draft.tempComments, ...draft.comments];
          draft.commentsUpdatedAt = action.updatedAt;
          draft.getAllCommentsStatus = createSuccessStatus();
          // sessionStorage.setItem(
          //   ADMIN_COMMENTS_KEY,
          //   JSON.stringify(draft.comments)
          // );
          // sessionStorage.setItem(
          //   ADMIN_COMMENTS_UPDATED_AT_KEY,
          //   draft.commentsUpdatedAt
          // );
        }
        break;
      case actions.GET_ALL_COMMENTS_FAILURE:
        draft.getAllCommentsStatus = createFailureStatus(action.error);
        break;
      case actions.DELETE_ONE_COMMENT_FROM_LOCAL:
        const targetIdx = draft.comments.findIndex((comment) => comment.id === action.id);
        draft.comments[targetIdx].isDeleted = true;
        break;
      case actions.LOCAL_COMMENT_DELETE:
        if (draft.comments.length > 0) {
          const targetIdx = draft.comments.findIndex((comment) => comment.id === action.commentId);
          if (targetIdx) draft.comments[targetIdx].isDeleted = true;
          sessionStorage.setItem(ADMIN_COMMENTS_KEY, JSON.stringify(draft.comments));
        }
        break;
      case actions.LOCAL_COMMENT_UPDATE:
        if (draft.comments.length > 0) {
          const targetIdx = draft.comments.findIndex((comment) => comment.id === action.commentId);
          if (targetIdx !== -1) {
            draft.comments[targetIdx][action.target] = action.value;
            sessionStorage.setItem(ADMIN_COMMENTS_KEY, JSON.stringify(draft.comments));
          }
        }
        break;
      case actions.GET_USER_ID:
        draft.userIds.add(action.id);
        break;
      case actions.COMMENTS_LOCAL_SYNC:
        draft.comments = action.data ?? [];
        draft.commentsUpdatedAt = action.updatedAt;
        break;
      case actions.RESET_ALL_USERS_REQUEST:
        draft.resetAllUsersStatus = createRequestStatus();
        break;
      case actions.RESET_ALL_USERS_SUCCESS:
        draft.resetAllUsersStatus = createSuccessStatus();
        break;
      case actions.RESET_ALL_USERS_FAILURE:
        draft.resetAllUsersStatus = createFailureStatus(action.error);
        break;
      default:
        break;
    }
  });

export default reducer;
