import firebase from "firebase";
import "@firebase/firestore";
import "@firebase/analytics";
import ReduxSagaFirebase from "redux-saga-firebase";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD89vVqO7Vl41kmMliZFJN1X3OZzkEZOCo",
  authDomain: "bsjobedu2023.firebaseapp.com",
  projectId: "bsjobedu2023",
  storageBucket: "bsjobedu2023.appspot.com",
  messagingSenderId: "844299084794",
  appId: "1:844299084794:web:5dd734f3d2f2292e6bba43",
  measurementId: "G-2RVK2JYY2R",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const rsf = new ReduxSagaFirebase(firebaseApp);
export const db = firebaseApp.firestore();
export const rsfDB = rsf.firestore;
export const storage = firebaseApp.storage();
export const auth = rsf.auth;
export const firestore = firebase.firestore;
firebase.analytics();
