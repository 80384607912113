import { makeStyles } from '@material-ui/core';
import theme from '../../styles/theme';
const useStyle = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    width: '100%',
    [breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    [breakpoints.down('sm')]: {},
  },
  rankingBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    [breakpoints.up('md')]: {},
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  flipper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    [breakpoints.up('md')]: {},
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  item: {
    height: 82,
    // border: `1px solid ${theme.primaryColor}`,
    borderRadius: 21,
    display: 'flex',
    alignItems: 'center',
    [breakpoints.up('md')]: {
      justifyContent: 'space-around',
      margin: '8px 0',
      width: '19%',
    },
    [breakpoints.only('sm')]: {
      justifyContent: 'center',
      width: '98%',
      margin: '3px 5px',
    },
    [breakpoints.only('xs')]: {
      justifyContent: 'space-between',
      width: '98%',
      margin: '3px 5px',
    },
    position: 'relative',
    zIndex: 0,
  },
  borderBox: {
    border: `1px solid ${theme.primaryColor}`,
    opacity: 0.3,
    borderRadius: 21,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -5,
  },
  backgroundBox: {
    backgroundColor: theme.primaryColor,
    opacity: 0.1,
    borderRadius: 21,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -5,
  },
  backgroundBoxFirst: {
    // backgroundColor: theme.primaryColor,
    backgroundColor: 'gold',
    opacity: 0.1,
    borderRadius: 21,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -5,
  },
  backgroundBoxSecond: {
    // backgroundColor: theme.primaryColor,
    backgroundColor: 'silver',
    opacity: 0.1,
    borderRadius: 21,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -5,
  },
  backgroundBoxThird: {
    // backgroundColor: theme.primaryColor,
    backgroundColor: '#864000',
    opacity: 0.1,
    borderRadius: 21,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: -5,
  },
  classTextBox: {
    padding: 5,
    [breakpoints.up('sm')]: {
      width: '86%',
      maxWidth: '190px',
    },
    [breakpoints.only('xs')]: {
      width: '88%',
    },
  },
  textBox: {
    paddingLeft: 10,
    width: 'calc(100% - 40px)',
  },
  contentBox: {
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    [breakpoints.up('sm')]: {
      maxWidth: '190px',
      width: '86%',
    },
    [breakpoints.only('xs')]: {
      width: '88%',
    },
  },
  rank: {
    display: 'block',
    textAlign: 'center',
    display: 'block',
    paddingLeft: '5px',
    [breakpoints.up('sm')]: {
      width: '14%',
      maxWidth: '32px',
    },
    [breakpoints.down('xs')]: {
      width: '12%',
    },
  },
  rankText: {
    display: 'block',
    textAlign: 'center',
    height: '20px',
    lineHeight: '20px',
    display: 'block',
    textAlign: 'center',
    [breakpoints.up('sm')]: {
      width: '14%',
      maxWidth: '20px',
    },
    [breakpoints.down('xs')]: {
      width: '12%',
    },
  },
  name: {
    fontWeight: 600,
    [breakpoints.only('xs')]: {
      fontSize: '0.9em',
    },
  },
  point: {
    fontWeight: 600,
    color: theme.primaryColor,
    '& > span': {
      fontWeight: 400,
      fontSize: '10.5px',
    },
  },
}));

export default useStyle;
