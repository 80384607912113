import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img01 from '../assets/central/logo/footer/01.png';
import img02 from '../assets/central/logo/footer/02.png';
import img03 from '../assets/central/logo/footer/03.png';
import img04 from '../assets/central/logo/footer/04.png';
import img05 from '../assets/central/logo/footer/05.png';
import img06 from '../assets/central/logo/footer/06.png';
import img07 from '../assets/central/logo/footer/07.png';
import img08 from '../assets/central/logo/footer/08.png';
const Container = styled.div`
  width: 85%;
  margin: 0 auto;
`;
const Logo = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoImg = styled.img`
  display: block;
  max-height: 60px;
  max-width: 300px;
  @media screen and (max-width: 500px) {
    max-width: 270px;
  }
  @media screen and (max-width: 350px) {
    max-width: 240px;
  }
`;
function LogoSlider() {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <Container>
      <Slider {...settings}>
        <Logo>
          <Logo>
            <LogoImg src={img01} />
          </Logo>
        </Logo>
        {/* <Logo>
          <Logo>
            <LogoImg src={img02} />
          </Logo>
        </Logo>
        <Logo>
          <Logo>
            <LogoImg src={img03} />
          </Logo>
        </Logo>
        <Logo>
          <Logo>
            <LogoImg src={img04} />
          </Logo>
        </Logo>
        <Logo>
          <Logo>
            <LogoImg src={img05} />
          </Logo>
        </Logo>
        <Logo>
          <Logo>
            <LogoImg src={img06} />
          </Logo>
        </Logo>
        <Logo>
          <Logo>
            <LogoImg src={img07} />
          </Logo>
        </Logo>
        <Logo>
          <Logo>
            <LogoImg src={img08} />
          </Logo>
        </Logo> */}
      </Slider>
    </Container>
  );
}

export default LogoSlider;
