import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';
import { createStatus, createRequestStatus, createSuccessStatus, createFailureStatus } from './reducerUtils';

export const initialState = {
  myClassData: {},
  userRanking: [],
  classRanking: [],
  getMyClassPointStatus: createStatus(),
  getClassRankingStatus: createStatus(),
  getUserRankingStatus: createStatus(),
};

// 액션
export const actions = {
  GET_MY_CLASS_DATA_REQUEST: 'GET_MY_CLASS_DATA_REQUEST',
  GET_MY_CLASS_DATA_SUCCESS: 'GET_MY_CLASS_DATA_SUCCESS',
  GET_MY_CLASS_DATA_FAILURE: 'GET_MY_CLASS_DATA_FAILURE',
  GET_CLASS_RANKING_REQUEST: 'GET_CLASS_RANKING_REQUEST',
  GET_CLASS_RANKING_SUCCESS: 'GET_CLASS_RANKING_SUCCESS',
  GET_CLASS_RANKING_FAILURE: 'GET_CLASS_RANKING_FAILURE',
  GET_USER_RANKING_REQUEST: 'GET_USER_RANKIRNG_REQUEST',
  GET_USER_RANKING_SUCCESS: 'GET_USER_RANKIRNG_SUCCESS',
  GET_USER_RANKING_FAILURE: 'GET_USER_RANKIRNG_FAILURE',
  SYNC_RANKING_DONE: 'SYNC_RANKING_DONE',
  SYNC_MY_CLASS_DATA_DONE: 'SYNC_MY_CLASS_DATA_DONE',
};

// 액션 크리에이터
export const getMyClassPointAction = id => ({
  type: actions.GET_MY_CLASS_DATA_REQUEST,
  id,
});
export const getClassRankingAction = count => ({
  type: actions.GET_CLASS_RANKING_REQUEST,
  count,
});
export const getUserRankingAction = count => ({
  type: actions.GET_USER_RANKING_REQUEST,
  count,
});

export const syncMyClassPointDone = () => ({
  type: actions.SYNC_MY_CLASS_DATA_DONE,
});
export const syncRankingDone = () => ({
  type: actions.SYNC_RANKING_DONE,
});

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case actions.GET_MY_CLASS_DATA_REQUEST:
        draft.getMyClassPointStatus = createRequestStatus();
        break;
      case actions.GET_MY_CLASS_DATA_SUCCESS:
        draft.getMyClassPointStatus = createSuccessStatus();
        draft.myClassData = action.data;
        break;
      case actions.GET_MY_CLASS_DATA_FAILURE:
        draft.getMyClassPointStatus = createFailureStatus(action.error);
        break;
      case actions.GET_CLASS_RANKING_REQUEST:
        draft.getClassRankingStatus = createRequestStatus();
        break;
      case actions.GET_CLASS_RANKING_SUCCESS:
        draft.getClassRankingStatus = createSuccessStatus();
        draft.classRanking = action.data;
        break;
      case actions.GET_CLASS_RANKING_FAILURE:
        draft.getClassRankingStatus = createFailureStatus(action.error);
        break;
      case actions.GET_USER_RANKING_REQUEST:
        draft.getUserRankingStatus = createRequestStatus();
        break;
      case actions.GET_USER_RANKING_SUCCESS:
        draft.getUserRankingStatus = createSuccessStatus();
        draft.userRanking = action.data;
        break;
      case actions.GET_USER_RANKING_FAILURE:
        draft.getUserRankingStatus = createFailureStatus(action.error);
        break;
      default:
        break;
    }
  });

export default reducer;
