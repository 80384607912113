import React, { useEffect, useState } from "react";
import { Card } from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import Marquee from "react-double-marquee";

const Container = styled.div`
  background-color: white;
  border-radius: 5px;
  ${(props) => props.theme.boxShadow};
  & span {
    font-size: 1.1em;
    font-weight: 500;
    text-align: center;
    /* color: ${(props) => props.theme.primaryColor}; */
    color: black;
  }
`;
export default function NoticeTextIndex({ announce, href = "#", ...props }) {
  const SPACE = String.fromCharCode(160);
  const [text, setText] = useState("");
  useEffect(() => {
    if (announce) {
      let announceText = "";
      if (announce?.length < 100) {
        // 글자 수가 짧을 경우
        for (let i = 0; i < 10; i++) announceText += announce + SPACE.repeat(10);
      } else {
        announceText = announce;
      }
      setText(announceText);
    }
  }, [announce]);
  const onClick = () => {
    if (href !== "#" && href !== null) window.open(href, "_blank");
  };
  if (text !== "")
    return (
      <Container {...props}>
        <Button
          variant="outlined"
          color="default"
          fullWidth
          style={{
            whiteSpace: "nowrap",
          }}
          startIcon={<NotificationsIcon />}
          onClick={onClick}
        >
          <Marquee delay={3000} speed={0.05} direction="left" childMargin={30}>
            {text}
          </Marquee>
        </Button>
      </Container>
    );
  else return <></>;
}
