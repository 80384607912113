/************************************************************************************************
 * Component   : MainBanner
 * Description : md사이즈를 기준으로 두가지 이미지를 넣을 수 있는 배너용 컴포넌트
 * Props       : mdSrc - 대형 이미지 소스. Req
 *               xsSrc - 소형 이미지 소스. Req
 *               alt Req
 *               to - 링크 있을 경우
 *               marginBottom - 임시 마진 조정용 프롭. 현재 무조건 값이 있으면 스몰로 적용됨.
 ************************************************************************************************/

import React from "react";
import styled from "styled-components";
import { Hidden } from "@material-ui/core";
import LinkComponent from "./Link";
const Container = styled.div`
  width: 100%;
  margin-bottom: ${(props) => (props.marginBottom ? props.theme.smallMargin : props.theme.bigBoxInMargin)};
  overflow: hidden;
`;
const Image = styled.img`
  border-radius: ${(props) => props.theme.borderRadius};
  width: 100%;
`;

function MainBanner({ mdSrc, xsSrc, to, alt, marginBottom = null, href = null }) {
  return (
    <Container marginBottom={marginBottom}>
      <Hidden xsDown>
        <LinkComponent to={to ? to : null} href={href ? href : null}>
          <Image src={mdSrc} alt={alt} />
        </LinkComponent>
      </Hidden>
      <Hidden smUp>
        <LinkComponent to={to ? to : null} href={href ? href : null}>
          <Image src={xsSrc} alt={alt} />
        </LinkComponent>
      </Hidden>
    </Container>
  );
}

export default MainBanner;
