import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Avatar from '../../components/Avatar';
import random from '../../utils/random';
import useStyle from './rankingStyles';
import { Flipper, Flipped } from 'react-flip-toolkit';
import theme from '../../styles/theme';

import image1 from '../../assets/images/icon/ranking/1st.png';
import image2 from '../../assets/images/icon/ranking/2nd.png';
import image3 from '../../assets/images/icon/ranking/3rd.png';
import { useSelector } from 'react-redux';

const rankImages = [image1, image2, image3];
const Ranking = () => {
  const classes = useStyle();
  const { userRanking, classRanking } = useSelector(state => state.ranking);
  const [studentData, setStudentData] = useState([]);
  const [classData, setClassData] = useState([]);
  // 임시 더미 데이터

  // setInterval 아이디 저장용 변수
  const id = useRef(null);
  const id2 = useRef(null);

  // const [dummy, setDummy] = useState([
  //   { id: '12351235', userName: 'ABC고 김 * 효', point: 300, avatar: 2 },
  //   { id: '5246', userName: 'DEF고 김 * 효', point: 200, avatar: 3 },
  //   { id: '25347', userName: 'ABC고 공 * 가', point: 400, avatar: 1 },
  //   { id: '12357', userName: 'ABC고 가 * 다', point: 500, avatar: 5 },
  //   { id: '13426', userName: 'ABC고 나 * 마', point: 300, avatar: 7 },
  //   { id: '14363', userName: 'GHI중 다 * 바', point: 200, avatar: 3 },
  //   { id: '12346', userName: 'XYZ중 라 * 하', point: 304, avatar: 4 },
  //   { id: '25372543', userName: 'DEF고 마 * 효', point: 302, avatar: 2 },
  //   { id: '134sd26', userName: 'ABC고 나 * 마', point: 300, avatar: 7 },
  //   { id: '1423363', userName: 'GHI중 다 * 바', point: 200, avatar: 8 },
  //   { id: '1212346', userName: 'XYZ중 라 * 하', point: 304, avatar: 1 },
  //   { id: '2531272543', userName: 'DEF고 마 * 효', point: 302, avatar: 3 },
  //   { id: '512246', userName: 'DEF고 김 * 효', point: 200, avatar: 3 },
  //   { id: '252347', userName: 'ABC고 공 * 가', point: 400, avatar: 1 },
  //   { id: '1223357', userName: 'ABC고 가 * 다', point: 500, avatar: 5 },
  //   { id: '131426', userName: 'ABC고 나 * 마', point: 300, avatar: 7 },
  //   { id: '1413263', userName: 'GHI중 다 * 바', point: 200, avatar: 5 },
  //   { id: '123436', userName: 'XYZ중 라 * 하', point: 304, avatar: 4 },
  // ]);
  // const [dummy2, setDummy2] = useState([
  //   { id: '1235', name: 'ABC고\n 3학년 2반', point: 3000 },
  //   { id: '524', name: 'DEF고\n 1학년 3반', point: 2000 },
  //   { id: '2537', name: '가나다중\n 2학년 7반', point: 4000 },
  //   { id: '1257', name: '라마바중\n 3학년 3반', point: 5000 },
  //   { id: '1326', name: 'ABC고\n 2학년 3반', point: 3000 },
  //   { id: '1463', name: 'GHI중\n 2학년 3반', point: 2000 },
  //   { id: '1236', name: 'XYZ중\n 3학년 5반', point: 3040 },
  //   { id: '2543', name: 'DEF고\n 2학년 10반', point: 3020 },
  //   { id: '142363', name: 'GHI중\n 2학년 3반', point: 2000 },
  //   { id: '121236', name: 'XYZ중\n 3학년 5반', point: 3040 },
  //   { id: '2512343', name: 'DEF고\n 2학년 10반', point: 3020 },
  //   { id: '12325', name: 'ABC고\n 3학년 2반', point: 3000 },
  //   { id: '54224', name: 'DEF고\n 1학년 3반', point: 2000 },
  //   { id: '253437', name: '가나다중\n 2학년 7반', point: 4000 },
  //   { id: '12357', name: '라마바중\n 3학년 3반', point: 5000 },
  //   { id: '13226', name: 'ABC고\n 2학년 3반', point: 3000 },
  //   { id: '14563', name: 'GHI중\n 2학년 3반', point: 2000 },
  // ]);

  // 더이데이터 업데이트

  // useEffect(() => {
  //   // class data 업데이트
  //   id.current = setInterval(() => {
  //     setDummy2(prev => {
  //       let temp = [...prev];
  //       temp = temp
  //         .map(e => ({
  //           name: e.name,
  //           point: e.point + random(400),
  //           id: e.id,
  //         }))
  //         .sort((a, b) => b.point - a.point);
  //       return temp;
  //     });
  //   }, 2000);
  //   return () => clearInterval(id.current);
  // }, []);
  // useEffect(() => {
  //   // student data 업데이트
  //   id2.current = setInterval(() => {
  //     setDummy(prev => {
  //       let temp = [...prev];
  //       temp = temp
  //         .map(e => ({
  //           userName: e.userName,
  //           point: e.point + random(30),
  //           id: e.id,
  //           avatar: e.avatar,
  //         }))
  //         .sort((a, b) => b.point - a.point);
  //       return temp;
  //     });
  //   }, 1000);
  //   return () => clearInterval(id2.current);
  // }, []);

  // 더미데이터 끝

  useEffect(() => {
    // class data 업데이트
    if (classRanking?.length > 0) {
      const temp = [...classRanking];
      setClassData(
        temp
          .map(data => ({
            id: data.id,
            name: `${data.shortName} ${data.grade}학년 ${data.class}반`,
            point: data.point,
          }))
          .sort((a, b) => b.point - a.point),
      );
    }
  }, [classRanking]);
  useEffect(() => {
    // student data 업데이트
    if (userRanking?.length > 0) {
      const temp = [...userRanking];
      setStudentData(temp.sort((a, b) => b.point - a.point));
    }
  }, [userRanking]);
  return (
    <>
      <Box className={classes.root}>
        <Flipper flipKey={classData.map(e => e.id).join('')} className={classes.flipper}>
          {classData.map((e, i) => {
            if (i < 5)
              return (
                <Flipped key={e.id} flipId={e.id}>
                  <Box className={classes.item} key={e.id} won={i < 3}>
                    {i < 3 && <img className={classes.rank} src={rankImages[i]} />}
                    {i >= 3 && (
                      <Typography variant="body2" className={classes.rank}>
                        {i + 1}
                      </Typography>
                    )}
                    <Box className={classes.classTextBox}>
                      <Typography variant="body2" className={classes.name}>
                        {e.name}
                      </Typography>
                      <Typography variant="body2" className={classes.point}>
                        {e.point} <span>Points</span>
                      </Typography>
                      {i < 3 && <Box className={classes.backgroundBox}></Box>}
                      <Box className={classes.borderBox}></Box>
                    </Box>
                  </Box>
                </Flipped>
              );
          })}
        </Flipper>
        <Flipper flipKey={studentData.map(e => e.id).join('')} className={classes.flipper}>
          {studentData.map((e, i) => {
            if (i < 5)
              return (
                <Flipped key={e.id} flipId={e.id}>
                  <Box className={classes.item}>
                    {i < 3 && <img className={classes.rank} src={rankImages[i]} />}
                    {i >= 3 && (
                      <Typography variant="body2" className={classes.rankText}>
                        {i + 1}
                      </Typography>
                    )}
                    <Box className={classes.contentBox}>
                      <Avatar id={e.avatar} />
                      <Box className={classes.textBox}>
                        <Typography variant="body2" className={classes.name}>
                          {e.userName}
                        </Typography>
                        <Typography variant="body2" className={classes.point}>
                          {e.point} <span>Points</span>
                        </Typography>
                      </Box>
                    </Box>
                    {i < 3 && <Box className={classes.backgroundBox}></Box>}
                    <Box className={classes.borderBox}></Box>
                  </Box>
                </Flipped>
              );
          })}
        </Flipper>
      </Box>
    </>
  );
};

export default Ranking;
