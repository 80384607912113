import { all, fork } from 'redux-saga/effects';
// import postSaga from './post';
// import userSaga from './user';
import boothSaga from './booth';
import videoSaga from './video';
import userSaga from './user';
import commentSaga from './comment';
import globalSaga from './global';
import adminSaga from './admin';
import rankingSaga from './ranking';
export default function* rootSaga() {
  yield all([
    fork(boothSaga),
    fork(videoSaga),
    fork(userSaga),
    fork(commentSaga),
    fork(globalSaga),
    fork(rankingSaga),
    fork(adminSaga),
  ]);
}
