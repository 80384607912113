import React from 'react';
import YoutubeThumb from './YoutubeThumb';
import { useHistory } from 'react-router-dom';

function YoutubeCard({ video }) {
  const history = useHistory();
  const onClickHandler = (videoId) => {
    history.push(`/video/${videoId}`);
  };
  return (
    <YoutubeThumb
      onClick={() => onClickHandler(video.id)}
      url={video.url}
      title={video.title}
    />
  );
}

export default YoutubeCard;
