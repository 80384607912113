import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Hidden, Typography } from "@material-ui/core";
import styled from "styled-components";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet";
import { ROUTES } from "../../constants/routeConstants";
import NoticeText from "../../components/NoticeText";
import Ranking from "./Ranking";
import Schedule from "./Schedule";
import Booths from "./Booths";
import Videos from "./Videos";
import Comments from "./MainComments";
import H1 from "../../components/H1";
// import EmptyBox from '../../components/EmptyBox';
import Banner from "../../components/MainBanner";
import MainBanner from "./MainBanner";
import videoPlaceholderImage from "../../assets/images/videoPlaceholder.png";
import { getClassRankingAction, getUserRankingAction, syncRankingDone } from "../../reducers/ranking";
import getYoutubeId from "../../utils/getYoutubeId";
import { BANNERS, MOBILE_BANNERS } from "../../constants/asseetConstants";
import EventResultModal from "./EventResultModal";
import { getAllLiveProgramsAction, getBoothsAction, syncLiveProgramDone } from "../../reducers/booth";
import LiveProgramCard from "./LiveProgramCard";
import LiveVideos from "./LiveVideos";
import { firestore } from "../../firebase";
const Container = styled.div``;
const VideoPlaceholder = styled.img`
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadius};
`;
const YoutubeContainer = styled(YouTube)`
  width: 100%;
  height: 56vw;
  max-height: 720px;
  border-radius: 10px;
  border: none;
  border-radius: ${(props) => props.theme.borderRadius};
  @media screen and (max-width: 500px) {
    height: 56vw;
  }
`;
const data = [
  {
    phone: "",
    isLiveZoom: false,
    address: "부산광역시 동구 고관로 68",
    announce: {
      text: "",
      url: "#",
    },
    tags: [],
    tag: ["사립 ", "영상헤어디자인과", "영상연예과", "영상그래픽과", "미용과"],
    liveSubject: "",
    isLiveYoutube: false,
    id: "3Qvvy7wC",
    metaverse: "",
    createdAt: {
      seconds: 1632726276,
      nanoseconds: 3000000,
    },
    liveZoomUrl: "",
    location: {
      lng: 129.044247392053,
      lat: 35.1250167266828,
    },
    name: "",
    links: [
      {
        url: "http://school.busanedu.net/bifh-h",
        title: "홈페이지",
      },
      {
        title: "실시간 진학상담",
        url: " https://open.kakao.com/o/gLHYSmHd",
      },
      {
        title: "오픈채팅상담",
        url: "https://open.kakao.com/o/g01HXXMe",
      },
      {
        url: "",
        title: "",
      },
    ],
    isLive: false,
    category: "고등기술",
    isActive: true,
    logo: {
      path: "boothLogo/LOGO_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_dqtXSAYLt",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_dqtXSAYLt?alt=media&token=16fcd105-b8cd-4c2f-bf65-a61d0b02c4a2",
    },
    title: "부산국제영화고등학교",
    description: " 영상연예과 ★ 영상그래픽과 ★ 영상헤어디자인과",
    sessionKey: "",
    point: 0,
    vrData: "https://vr.bsjobedu.kr/2022/15",
  },
  {
    liveStartedAt: {
      seconds: 1664160784,
      nanoseconds: 336000000,
    },
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_qM1j5hQyC?alt=media&token=eea0a787-65b5-4a1e-84c1-4c6b71a36de9",
      path: "boothLogo/LOGO_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_qM1j5hQyC",
    },
    isActive: true,
    isLive: false,
    announce: {
      text: "",
      url: "#",
    },
    liveZoomUrl: "",
    location: {
      lat: 35.1826508116626,
      lng: 129.06743651693,
    },
    links: [
      {
        title: "홈페이지",
        url: "http://www.xn--989a3b4rl4x71pvvg93cy03b.com/",
      },
      {
        title: "유튜브 페이지",
        url: "https://www.youtube.com/results?search_query=%EA%B3%84%EC%84%B1%EC%97%AC%EC%9E%90%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90",
      },
      {
        url: "https://www.instagram.com/gyesung_hs/",
        title: "인스타그램",
      },
      {
        title: "인스타그램 학생회",
        url: "https://www.instagram.com/21th_gaon_leader/",
      },
    ],
    phone: "",
    liveYoutubeUrl: "",
    liveSubject: "",
    category: "상업계",
    sessionKey: "",
    id: "3XCIlnPN",
    metaverse: "",
    tag: ["금융", "마케팅", "관광", "조리", "디저트", "컴퓨터"],
    address: "부산광역시 연제구 해맞이로109번길 47",
    point: 0,
    description: "행복한 미래를 만드는 새로운 시작! 계성여자고등학교 ",
    title: "계성여자고등학교",
    name: "",
    createdAt: {
      seconds: 1632789425,
      nanoseconds: 14000000,
    },
    isLiveZoom: false,
    vrData: "https://vr.bsjobedu.kr/2022/03",
    isLiveYoutube: false,
    tags: [],
  },
  {
    isActive: true,
    tag: ["공업계", "자동차정비과정", "부품가공과정", "생산자동화과정"],
    logo: {
      path: "boothLogo/LOGO_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_1WfSR_Y2A",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_1WfSR_Y2A?alt=media&token=2aed006f-cf5e-4125-ad10-fd5098df68c2",
    },
    liveSubject: "",
    description: "You are a Meister, You are My Star!!",
    liveYoutubeUrl: "",
    liveZoomUrl: "",
    announce: {
      text: "",
      url: "#",
    },
    sessionKey: "",
    isLiveYoutube: false,
    vrData: "https://vr.bsjobedu.kr/2022/25",
    category: "마이스터",
    name: "",
    location: {
      lat: 35.0761061694675,
      lng: 128.963467073714,
    },
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/automotive-h/main.do",
      },
      {
        url: "https://www.youtube.com/watch?v=ENp8BRqisM4",
        title: "2023 온라인 입학설명회",
      },
      {
        title: "유튜브 채널",
        url: "https://www.youtube.com/channel/UC8u7-459nzh-Jae8wvn8Xng",
      },
      {
        title: "",
        url: "",
      },
    ],
    liveStartedAt: {
      seconds: 1636692121,
      nanoseconds: 548000000,
    },
    tags: [],
    phone: "",
    point: 0,
    isLive: false,
    isLiveZoom: false,
    address: "부산광역시 사하구 하신중앙로3번나길 16",
    createdAt: {
      nanoseconds: 870000000,
      seconds: 1632718515,
    },
    id: "5wUMAW5Z",
    metaverse: "",
    title: "부산자동차고등학교",
  },
  {
    tag: ["경성", "좋아요", "특성화고", "사복데이", "취업성공", "사물인터넷", "IoT"],
    isLiveYoutube: false,
    tags: [],
    description: "미래를 준비하는 특성화 명문, 경성전자고등학교 부스입니다.",
    isLiveZoom: false,
    logo: {
      path: "boothLogo/LOGO_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_d-vTDlrCs",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_d-vTDlrCs?alt=media&token=06c1f050-85df-4ed3-901d-9c322b08ea40",
    },
    createdAt: {
      nanoseconds: 950000000,
      seconds: 1632728287,
    },
    isLive: false,
    title: "경성전자고등학교",
    phone: "",
    vrData: "https://vr.bsjobedu.kr/2022/02",
    liveYoutubeUrl: "",
    liveZoomUrl: "",
    location: {
      lat: 35.11627694225543,
      lng: 129.01190291352992,
    },
    name: "",
    liveSubject: "",
    isActive: true,
    category: "공업계",
    address: "부산광역시 서구 꽃마을로 25",
    liveStartedAt: {
      seconds: 1668151726,
      nanoseconds: 295000000,
    },
    sessionKey: "",
    point: 0,
    id: "66ND5d0w",
    metaverse: "",
    announce: {
      text: "",
      url: "#",
    },
    links: [
      {
        url: "http://school.busanedu.net/kse-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://youtu.be/EoM70-7BNSQ",
        title: "경성전자고 유튜브",
      },
      {
        url: "https://www.instagram.com/ksehighschool/",
        title: "경성전자고 인스타그램",
      },
      {
        url: "http://pf.kakao.com/_Luyxnxb",
        title: "경성전자고 오픈카톡",
      },
    ],
  },
  {
    tags: [],
    liveZoomUrl: "",
    location: {
      lat: 35.13116712219897,
      lng: 129.06809188478053,
    },
    id: "6V5KkTRs",
    metaverse: "",
    isActive: true,
    sessionKey: "-f5s9T",
    liveSubject: "",
    createdAt: {
      seconds: 1632727028,
      nanoseconds: 140000000,
    },
    tag: ["미용과", "디자인과", "경영과"],
    vrData: "https://vr.bsjobedu.kr/2022/12",
    phone: "",
    category: "상업계",
    point: 0,
    title: "배정미래고등학교",
    isLive: false,
    isLiveYoutube: false,
    announce: {
      text: "",
      url: "#",
    },
    liveYoutubeUrl: "",
    liveStartedAt: {
      nanoseconds: 185000000,
      seconds: 1636083746,
    },
    description: "미(美)와 디자인을 경영하는 배정미래고등학교 입니다.",
    name: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_6V5KkTRsrHY0Ak6OdBkxpRhD6YA2_o4SxRI4R6?alt=media&token=94570235-7cd8-4a5e-8aec-54519106efb3",
      path: "boothLogo/LOGO_IMG_6V5KkTRsrHY0Ak6OdBkxpRhD6YA2_o4SxRI4R6",
    },
    isLiveZoom: false,
    address: "부산광역시 남구 장고개로85번길 14-40",
    links: [
      {
        url: "http://school.busanedu.net/bb-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://www.youtube.com/results?search_query=%EB%B0%B0%EB%AF%B8%EA%B3%A0tv",
        title: "유튜브",
      },
      {
        url: "https://www.instagram.com/mirae__school/",
        title: "인스타그램",
      },
      {
        url: "",
        title: "",
      },
    ],
  },
  {
    name: "",
    vrData: "https://vr.bsjobedu.kr/2022/05",
    tags: [],
    point: 0,
    tag: ["3D프린팅과", "뷰티아트과", "VR콘텐츠과"],
    location: {
      lng: 128.972662969815,
      lat: 35.1013072934051,
    },
    isActive: true,
    address: "부산광역시 사하구 동매로 135",
    sessionKey: "",
    createdAt: {
      nanoseconds: 449000000,
      seconds: 1632731696,
    },
    isLiveZoom: false,
    description: "4차 산업 명문 특성화고 대광고등학교 입니다.",
    isLive: false,
    phone: "",
    announce: {
      text: "",
      url: "#",
    },
    category: "공업계",
    liveStartedAt: {
      seconds: 1636584407,
      nanoseconds: 700000000,
    },
    liveYoutubeUrl: "",
    isLiveYoutube: false,
    title: "대광고등학교",
    logo: {
      path: "boothLogo/LOGO_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_QsAujURho",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_QsAujURho?alt=media&token=8cc63d2d-2541-4ae7-9297-4cbb01c51e78",
    },
    id: "7rDVzwFT",
    metaverse: "",
    liveSubject: "",
    links: [
      {
        title: "홈페이지",
        url: "https://school.busanedu.net/daegwang-h",
      },
      {
        title: "유튜브",
        url: "https://www.youtube.com/channel/UCA9HJybDSqd3pjwn0SlPodw",
      },
      {
        title: "페이스북",
        url: "https://www.facebook.com/daegwanghs",
      },
      {
        url: "http://pf.kakao.com/_sxaxbps",
        title: "카카오채널",
      },
    ],
  },
  {
    location: {
      lat: 35.189177601913606,
      lng: 128.9033742309751,
    },
    category: "마이스터",
    phone: "",
    title: "부산소프트웨어마이스터고등학교",
    isLiveYoutube: false,
    tag: ["마이스터", "소프트웨어", "개발자", "프로그래머"],
    createdAt: {
      seconds: 1632727916,
      nanoseconds: 44000000,
    },
    links: [
      {
        url: "http://school.busanedu.net/bssm-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://instagram.com/bssm.hs?utm_medium=copy_link",
        title: "인스타그램",
      },
      {
        title: "유튜브",
        url: "https://www.youtube.com/channel/UCpLnjJVaSqMmFpi3JztdIzA/featured",
      },
      {
        url: "",
        title: "",
      },
    ],
    liveStartedAt: {
      seconds: 1635211929,
      nanoseconds: 614000000,
    },
    address: "부산광역시 강서구 가락대로 1393",
    tags: [],
    isLive: false,
    isLiveZoom: false,
    description: "2022년 개교 부울경 유일의 SW분야 마이스터고",
    vrData: "https://vr.bsjobedu.kr/2022/21",
    point: 0,
    announce: {
      url: "#",
      text: "부산소프트웨어마이스터고등학교 부스 입니다.",
    },
    sessionKey: "",
    liveYoutubeUrl: "",
    logo: {
      path: "boothLogo/LOGO_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_dSAFnl5bg",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_dSAFnl5bg?alt=media&token=d131eef4-de4f-4ace-a05e-72a89052b5d5",
    },
    liveZoomUrl: "",
    isActive: true,
    name: "",
    liveSubject: "",
    id: "AUsENcz9",
    metaverse: "",
  },
  {
    isActive: true,
    liveYoutubeUrl: "https://youtu.be/hXLsCgRcUew",
    tag: ["소방안전과", "부사관과", "전자과"],
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/digital-h/main.do",
      },
      {
        title: "공식유튜브",
        url: "https://www.youtube.com/channel/UCVH_eFMgdh51Oxnz4l6c4YA",
      },
      {
        url: "https://instagram.com/digi_hs?igshid=ODBkMDk1MTU=",
        title: "공식인스타그램",
      },
      {
        title: "온라인 상담",
        url: "https://linktr.ee/Busandigitalhs",
      },
    ],
    name: "",
    category: "공업계",
    announce: {
      text: "",
      url: "#",
    },
    liveSubject: "",
    sessionKey: "",
    isLive: false,
    point: 0,
    description: "빠르게 변화하는 직업교육의 산실 부산디지털고등학교 입니다.",
    tags: [],
    isLiveYoutube: false,
    address: "부산광역시 중구 망양로 356",
    logo: {
      path: "boothLogo/LOGO_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_vz06Xwr9h",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_vz06Xwr9h?alt=media&token=77141c9e-8d92-4590-9ccd-c7f9e707c809",
    },
    liveZoomUrl: "",
    createdAt: {
      seconds: 1632787882,
      nanoseconds: 280000000,
    },
    location: {
      lng: 129.032548078088,
      lat: 35.1088554773174,
    },
    id: "BiN3ZF9L",
    metaverse: "",
    phone: "",
    isLiveZoom: false,
    liveStartedAt: {
      seconds: 1667539295,
      nanoseconds: 37000000,
    },
    title: "부산디지털고등학교",
    vrData: "https://vr.bsjobedu.kr/2022/17",
  },
  {
    title: "해운대관광고등학교",
    description: "부산최초의 관광특성화고등학교 해운대관광고등학교입니다.",
    isActive: true,
    address: "부산광역시 해운대구 우동2로60번길 38",
    id: "CiLGQEMy",
    metaverse: "",
    isLiveZoom: false,
    tags: [],
    sessionKey: "",
    isLive: false,
    createdAt: {
      nanoseconds: 173000000,
      seconds: 1632730538,
    },
    liveSubject: "",
    vrData: "https://vr.bsjobedu.kr/2022/37",
    tag: ["관광경영과", "관광", "조리", "특성화고", "호텔", "쉐프", "관광조리과"],
    liveZoomUrl: "",
    announce: {
      text: "",
      url: "#",
    },
    isLiveYoutube: false,
    category: "상업계",
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/h-tour-h/main.do",
      },
      {
        title: "인스타그램",
        url: "https://www.instagram.com/h_tour_/",
      },
      {
        url: "",
        title: "",
      },
      {
        title: "",
        url: "",
      },
    ],
    point: 0,
    name: "",
    location: {
      lat: 35.1698151645087,
      lng: 129.15729123936,
    },
    phone: "",
    logo: {
      path: "boothLogo/LOGO_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_738292HB3",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_738292HB3?alt=media&token=4cf0ea78-15ee-4f5f-abdc-8fe2e4f40497",
    },
  },
  {
    point: 0,
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_Wbh2RKVsk?alt=media&token=89b307f0-ab22-46ea-a5cf-60014f3e503b",
      path: "boothLogo/LOGO_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_Wbh2RKVsk",
    },
    liveStartedAt: {
      seconds: 1636519202,
      nanoseconds: 629000000,
    },
    liveSubject: "",
    vrData: "https://vr.bsjobedu.kr/2022/34",
    name: "",
    liveZoomUrl: "",
    tag: ["금융정보", "외식조리", "보건간호", "바리스타", "간호사", "제과제빵"],
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/sejeong-h/main.do",
      },
      {
        title: "공식 블로그",
        url: "https://blog.naver.com/sejeonghs1973",
      },
      {
        url: "https://www.youtube.com/channel/UCogi38fIuUQ1lerlQiosQ6g",
        title: "유튜브",
      },
      {
        title: "인스타그램",
        url: "https://www.instagram.com/sejeong_highschool/",
      },
    ],
    liveYoutubeUrl: "",
    isLiveZoom: false,
    location: {
      lat: 35.17020730143927,
      lng: 129.0786140274861,
    },
    address: "부산광역시 부산진구 양정로 88-12",
    isActive: true,
    description: "내 인생 최고의 선택, 변화를 위한 용기있는 선택, 넌 최고야! 너에 대한 믿음을 가져!",
    id: "DLn0t0nl",
    metaverse: "",
    isLive: false,
    title: "세정고등학교",
    category: "상업계",
    announce: {
      text: "",
      url: "#",
    },
    phone: "",
    sessionKey: "",
    isLiveYoutube: false,
    tags: [],
    createdAt: {
      seconds: 1632730743,
      nanoseconds: 436000000,
    },
  },
  {
    isLiveYoutube: false,
    sessionKey: "",
    announce: {
      text: "",
      url: "#",
    },
    point: 0,
    createdAt: {
      nanoseconds: 59000000,
      seconds: 1632966551,
    },
    name: "",
    id: "DfhXhzqk",
    metaverse: "",
    liveZoomUrl: "",
    address: "부산광역시 해운대구 반송순환로 142-1",
    location: {
      lng: 129.156154878612,
      lat: 35.2259366597561,
    },
    description: "[웰빙조리과, 보건간호과, 사무경영과] 배움이 즐거운 학교 학생이 행복한 학교",
    vrData: "https://vr.bsjobedu.kr/2022/35",
    liveSubject: "",
    liveYoutubeUrl: "",
    phone: "",
    tag: ["부산명품특성화고", "웰빙조리과", "보건간호과", "사무경영과", "부사관과", "과정형평가운영"],
    category: "상업계",
    links: [
      {
        url: "http://school.busanedu.net/ysh-h/main.do",
        title: "홈페이지",
      },
      {
        title: "",
        url: "",
      },
      {
        title: "",
        url: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    logo: {
      path: "boothLogo/LOGO_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_DZmwQQ5TZ",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_DZmwQQ5TZ?alt=media&token=37e94420-6949-491c-9b68-9c7da1315b87",
    },
    title: "영산고등학교",
    liveStartedAt: {
      nanoseconds: 375000000,
      seconds: 1636680357,
    },
    isLiveZoom: false,
    isActive: true,
    isLive: false,
    tags: [],
  },
  {
    vrData: "https://vr.bsjobedu.kr/2022/27",
    links: [
      {
        title: "부산정보고등학교 홈페이지",
        url: "http://school.busanedu.net/psinfo-h/main.do",
      },
      {
        title: "입학상담(051-853-0951)",
        url: "051-853-0951",
      },
      {
        title: "경영금융과 / 경영회계과",
        url: "",
      },
      {
        url: "",
        title: "관광서비스과 / 소프트웨어콘텐츠과",
      },
    ],
    isLiveZoom: false,
    sessionKey: "",
    description: "즐거움이 가득한 학교, 학생이 행복한 학교! 2023년 여러분을 기다립니다. ",
    tag: ["부산정보고등학교", "경영금융과", "경영회계과", "관광서비스과", "소프트웨어콘텐츠과"],
    announce: {
      text: "",
      url: "#",
    },
    isActive: true,
    category: "상업계",
    createdAt: {
      seconds: 1632965611,
      nanoseconds: 91000000,
    },
    location: {
      lat: 35.177513313516066,
      lng: 129.0645317275855,
    },
    id: "H4BMtwro",
    metaverse: "",
    tags: [],
    name: "",
    isLiveYoutube: false,
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_H4BMtwroEWSBanPOJrj8BVlYZQc2_KuLrDEmD9?alt=media&token=f41562e1-1bd4-44e7-8caa-84c913aaadc1",
      path: "boothLogo/LOGO_IMG_H4BMtwroEWSBanPOJrj8BVlYZQc2_KuLrDEmD9",
    },
    liveStartedAt: {
      seconds: 1666576962,
      nanoseconds: 734000000,
    },
    liveZoomUrl: "",
    title: "부산정보고등학교",
    phone: "",
    liveYoutubeUrl: "",
    address: "부산광역시 부산진구 화지로 24(양정동)",
    liveSubject: "",
    isLive: false,
    point: 0,
  },
  {
    name: "",
    logo: {
      path: "boothLogo/LOGO_IMG_HZHRrY4KdzUNFAOzfUOGFz865N83_OL9QaLAPB",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_HZHRrY4KdzUNFAOzfUOGFz865N83_OL9QaLAPB?alt=media&token=c1d9670b-0e68-4646-a0f8-79fe48df6f97",
    },
    description: "세연고등학교 부스입니다.",
    liveYoutubeUrl: "",
    liveSubject: "",
    phone: "",
    sessionKey: "",
    isActive: true,
    category: "상업계",
    isLiveYoutube: false,
    liveZoomUrl: "",
    tag: ["반려동물과", "카페경영과", "웹툰콘텐츠과", "외식베이커리과"],
    isLive: false,
    announce: {
      text: "",
      url: "#",
    },
    isLiveZoom: false,
    createdAt: {
      nanoseconds: 103000000,
      seconds: 1632965291,
    },
    liveStartedAt: {
      nanoseconds: 488000000,
      seconds: 1636445307,
    },
    id: "HZHRrY4K",
    metaverse: "",
    point: 0,
    vrData: "https://vr.bsjobedu.kr/2022/22",
    tags: [],
    location: {
      lat: 35.139564857645,
      lng: 129.085501753276,
    },
    title: "세연고등학교",
    links: [
      {
        url: "http://school.busanedu.net/bts-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://www.youtube.com/channel/UCVACBnUnKGpWIXMS9sjZtOw",
        title: "유튜브",
      },
      {
        url: "https://www.instagram.com/seyeonhigh/?hl=ko",
        title: "인스타그램",
      },
      {
        title: "오픈채팅방",
        url: "https://open.kakao.com/o/sUG4TeJd",
      },
    ],
    address: "부산광역시 남구 진남로 82-19",
  },
  {
    location: {
      lng: 129.07341751677254,
      lat: 35.133328997050405,
    },
    address: "부산광역시 남구 지게골로 128-27 　☎ 051-640-0545",
    logo: {
      path: "boothLogo/LOGO_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_54nb65gbs",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_54nb65gbs?alt=media&token=c0df0b41-4907-437b-a20c-d126e12ea736",
    },
    announce: {
      url: "https://school.busanedu.net/daeyang-h/main.do",
      text: "76년의 역사와 전통을 가진 배움과 성장으로 행복한 미래를 꿈꾸는 대양고등학교입니다. 2021년 제56회 전국기능경기대회 금메달, 동메달, 장려상을 수상하였습니다. 지게골 역 1번 출구 도보 5분.",
    },
    point: 0,
    tags: [],
    tag: ["#대양고등학교 #대양 #대양고 #전기전자과 #IT네트워크과 #전기전자통신 #공업계"],
    isLiveZoom: false,
    description: "76년의 전통!! 대양고등학교. 2022년 전국기능경기대회 3년 연속 수상",
    id: "Hx03wj3i",
    metaverse: "",
    isActive: true,
    name: "",
    vrData: "https://vr.bsjobedu.kr/2022/06",
    links: [
      {
        url: "http://school.busanedu.net/daeyang-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://www.facebook.com/%EB%8C%80%EC%96%91%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90-107692651468821",
        title: "페이스북",
      },
      {
        url: "https://open.kakao.com/o/gjapsnHd",
        title: "오픈채팅상담",
      },
      {
        title: "인스타그램",
        url: "https://www.instagram.com/daeyang_hs/?hl=ko",
      },
    ],
    sessionKey: "-V5Y",
    isLive: false,
    category: "공업계",
    liveSubject: "",
    phone: "",
    liveStartedAt: {
      nanoseconds: 649000000,
      seconds: 1635742135,
    },
    liveYoutubeUrl: "",
    isLiveYoutube: false,
    createdAt: {
      nanoseconds: 783000000,
      seconds: 1632723102,
    },
    title: "대양고등학교",
  },
  {
    isLive: false,
    vrData: "https://vr.bsjobedu.kr/2022/04",
    address: "부산광역시 금정구 서금로 75",
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/geumjeong-h/",
      },
      {
        url: "https://www.youtube.com/channel/UCT29QbzbW0sFswDbcF8h1yw",
        title: "학교 유튜브",
      },
      {
        url: "https://blog.naver.com/PostList.nhn?blogId=kumjonghs&categoryNo=8&skinType=&skinId=&from=menu",
        title: "학교 블로그",
      },
      {
        title: "오픈채팅상담",
        url: "https://open.kakao.com/o/gBXcu3Id",
      },
    ],
    category: "공업계",
    logo: {
      path: "boothLogo/LOGO_IMG_Il2M2jdAKxZlQIdDQfbORDNtAIp1_uOsBNsVK3",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_Il2M2jdAKxZlQIdDQfbORDNtAIp1_uOsBNsVK3?alt=media&token=3f863d66-4862-4feb-af40-9d660cdedc22",
    },
    liveStartedAt: {
      nanoseconds: 818000000,
      seconds: 1636525114,
    },
    liveZoomUrl: "",
    description: "취업·진학 사다리 <금정전자고등학교>",
    sessionKey: "",
    title: "금정전자고등학교",
    tags: [],
    point: 0,
    tag: ["전자시스템", "전자컴퓨터", "콘텐츠디자인", "남녀공학", "전기기기", "메카트로닉스", "대학진학", "자격증"],
    createdAt: {
      nanoseconds: 94000000,
      seconds: 1632722398,
    },
    liveSubject: "",
    liveYoutubeUrl: "",
    isActive: true,
    isLiveZoom: false,
    phone: "",
    location: {
      lat: 35.221627911338736,
      lng: 129.10815622720642,
    },
    isLiveYoutube: false,
    name: "",
    id: "Il2M2jdA",
    metaverse: "",
    announce: {
      text: "",
      url: "#",
    },
  },
  {
    isActive: true,
    liveSubject: "",
    vrData: "https://vr.bsjobedu.kr/2022/07",
    liveYoutubeUrl: "",
    point: 0,
    createdAt: {
      nanoseconds: 579000000,
      seconds: 1632732283,
    },
    description: "대진전자통신고등학교 부스입니다. 많은 관심과 상담 신청 부탁드립니다.",
    title: "대진전자통신고등학교",
    sessionKey: "",
    liveStartedAt: {
      nanoseconds: 526000000,
      seconds: 1636522440,
    },
    location: {
      lat: 35.2411778014813,
      lng: 129.081377554161,
    },
    isLiveYoutube: false,
    tags: [],
    phone: "",
    name: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_U4oTso1g0?alt=media&token=14584df3-fbdd-42e5-824e-c40cec9185dd",
      path: "boothLogo/LOGO_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_U4oTso1g0",
    },
    tag: ["사립"],
    category: "공업계",
    isLive: false,
    liveZoomUrl: "",
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/pdj-h/main.do",
      },
      {
        title: "유튜브",
        url: "https://youtube.com/channel/UC8r8JyyOWaZcYGDCndp8o1g",
      },
      {
        url: "www.instagram.com/busan_daejin",
        title: "인스타그램",
      },
      {
        title: "",
        url: "",
      },
    ],
    isLiveZoom: false,
    announce: {
      url: "https://",
      text: "대진전자통신고등학교 부스입니다.",
    },
    id: "JAswRRVj",
    metaverse: "",
    address: "부산광역시 금정구 수림로 92",
  },
  {
    id: "MsgSWFNe",
    metaverse: "",
    point: 0,
    description: "취업지원 부스 입니다.",
    createdAt: {
      nanoseconds: 828000000,
      seconds: 1635920170,
    },
    isLiveYoutube: false,
    links: [
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
      {
        title: "",
        url: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    category: "",
    isLive: false,
    announce: {
      url: "",
      text: "",
    },
    tags: [],
    isLiveZoom: false,
    liveSubject: "",
    address: "",
    name: "",
    title: "교육청 취업지원센터",
    liveZoomUrl: "",
    sessionKey: "",
    phone: "",
    isActive: false,
    logo: {
      path: "boothLogo/LOGO_IMG_MsgSWFNeHkQONZIm7rtr4ZSl3yH3_uz2CPBw8l",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_MsgSWFNeHkQONZIm7rtr4ZSl3yH3_uz2CPBw8l?alt=media&token=dd697abc-da5e-43e6-9b52-45ca1c8c3539",
    },
  },
  {
    isLiveYoutube: false,
    description: "부산직업계고 연구학교성과관 입니다.",
    createdAt: {
      nanoseconds: 528000000,
      seconds: 1632724835,
    },
    category: "",
    name: "",
    order: 5,
    title: "연구학교 성과관",
    isLiveZoom: false,
    links: [
      {
        url: "https://sites.google.com/bssm.hs.kr/web2022-01/",
        title: "[부산SW마이스터고] 웹포트폴리오 1학년",
      },
      {
        url: "https://sites.google.com/bssm.hs.kr/webp/",
        title: "[부산SW마이스터고] 웹포트폴리오 2학년",
      },
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    location: {
      lat: 35.1762552507997,
      lng: 129.064745757748,
    },
    isActive: true,
    announce: {
      text: "",
      url: "#",
    },
    sessionKey: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_UZ2G5SzmjMaX718oIQ9ckoch0zI2_Lfu-Hkplx?alt=media&token=299e4b60-7c69-4c40-a3e2-8f3924034b45",
      path: "boothLogo/LOGO_IMG_UZ2G5SzmjMaX718oIQ9ckoch0zI2_Lfu-Hkplx",
    },
    tag: ["부산기계공고", "소프트웨어마이스터고", "부산해사고", "부산자동차고등학교"],
    id: "UZ2G5Szm",
    metaverse: "",
    address: "부산광역시 부산진구 화지로 12",
    isLive: false,
    point: 0,
    tags: [],
  },
  {
    address: "부산광역시 영도구 영상길 100",
    category: "상업계",
    liveYoutubeUrl: "",
    logo: {
      path: "boothLogo/LOGO_IMG_VIafRODb_Y7HcH-3gQ",
      url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/boothLogo%2FLOGO_IMG_VIafRODb_Y7HcH-3gQ?alt=media&token=847e0174-636a-4418-8445-65c4e6589e50",
    },
    isLiveZoom: false,
    liveZoomUrl: "",
    isLive: false,
    createdAt: {
      seconds: 1632788911,
      nanoseconds: 625000000,
    },
    location: {
      lng: 129.045320001779,
      lat: 35.0847306622907,
    },
    liveStartedAt: {
      seconds: 1667011017,
      nanoseconds: 41000000,
    },
    phone: "",
    sessionKey: "",
    vrData: "https://vr.bsjobedu.kr/2022/24",
    id: "VIafRODb",
    metaverse: "",
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/visual-h/main.do",
      },
      {
        url: "https://www.youtube.com/channel/UCmp5slvMnWUkXh4m7Wx1YdQ/featured",
        title: "공식 유튜브 채널",
      },
      {
        url: "https://www.instagram.com/busanvisual_hs/",
        title: "공식 인스타그램",
      },
      {
        title: "공식 카카오 채널",
        url: "https://pf.kakao.com/_cxfeHxb",
      },
    ],
    tag: ["영상제작과", "영상연출과", "연기과", "영상디자인과"],
    isLiveYoutube: false,
    point: 0,
    title: "부산영상예술고등학교",
    isActive: true,
    announce: {
      url: "#",
      text: "영상예술 인재를 육성하는 부산영상예술고등학교 입니다.",
    },
    liveSubject: "",
    name: "",
    tags: [],
    description: "바르게! 다르게! 다함께! 영상예술 인재를 육성하는 부산영상예술고등학교",
  },
  {
    point: 0,
    isLiveZoom: false,
    vrData: "https://vr.bsjobedu.kr/2022/20",
    sessionKey: "",
    isActive: true,
    phone: "",
    createdAt: {
      seconds: 1632727884,
      nanoseconds: 885000000,
    },
    liveYoutubeUrl: "",
    isLiveYoutube: false,
    description: "보건계열 특성화! 영도 흰여울길 오션뷰! 부산보건고등학교 입니다.",
    category: "상업계",
    id: "VKQfw3yG",
    metaverse: "",
    title: "부산보건고등학교",
    address: "부산광역시 영도구 절영로 163",
    liveSubject: "",
    liveZoomUrl: "",
    name: "",
    tags: [],
    isLive: false,
    links: [
      {
        title: "학교홈페이지",
        url: "http://school.busanedu.net/busanhc.com/main.do",
      },
      {
        url: "https://www.instagram.com/busan_healthcare_highschool/",
        title: "학교인스타그램",
      },
      {
        title: "학교유튜브",
        url: "https://www.youtube.com/channel/UCFZrUqalTmmTdeUYbSon5SA",
      },
      {
        title: "카카오오픈채팅",
        url: "https://open.kakao.com/o/gvw0lEKc",
      },
    ],
    logo: {
      path: "boothLogo/LOGO_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_yzke_XzCe",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_yzke_XzCe?alt=media&token=23985c13-1478-4b0b-8724-9361e9f3469e",
    },
    liveStartedAt: {
      nanoseconds: 615000000,
      seconds: 1632984630,
    },
    location: {
      lat: 35.0814885677875,
      lng: 129.04220015206,
    },
    announce: {
      url: "#",
      text: "부산보건고등학교 입니다. 2023년도 푸드디저트과가 새롭게 찾아옵니다~",
    },
    tag: ["보건 간호", "IT 경영", "푸드디저트"],
  },
  {
    tags: [],
    createdAt: {
      seconds: 1632732198,
      nanoseconds: 341000000,
    },
    liveZoomUrl: "",
    isLive: false,
    category: "공업계",
    isLiveZoom: false,
    liveYoutubeUrl: "",
    liveStartedAt: {
      seconds: 1666831717,
      nanoseconds: 643000000,
    },
    id: "XAMlGKMz",
    metaverse: "",
    description: "자신의 꿈을 디자인하는 행복한 학교",
    address: "부산광역시 사하구 괴정로260번길 79",
    announce: {
      url: "https://youtu.be/TmaH5uD2bjg",
      text: "동아공고 입학상담 200-3811",
    },
    isLiveYoutube: false,
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/donga-tec-h/main.do",
      },
      {
        url: "https://open.kakao.com/o/gkIhufKe",
        title: "오픈채팅상담",
      },
      {
        title: "줌 입학 상담",
        url: "https://us05web.zoom.us/j/6888517676?pwd=emNQNEtSVDNXY0UreXRQTnZqK1FyUT09",
      },
      {
        url: "",
        title: "",
      },
    ],
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_lsx7Ls9H6?alt=media&token=3bc78797-748b-4263-93a3-9766cbb12b7e",
      path: "boothLogo/LOGO_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_lsx7Ls9H6",
    },
    location: {
      lat: 35.0925001581358,
      lng: 128.988701162014,
    },
    tag: [
      "중소벤처기업부주관특성화고인력양성사업운영",
      "교육부고용노동부주관산학일체형도제학교사업운영",
      "중화권글로벌인재양성프로젝트추진",
    ],
    name: "",
    isActive: true,
    liveSubject: "",
    phone: "",
    title: "동아공업고등학교",
    vrData: "https://vr.bsjobedu.kr/2022/10",
    sessionKey: "",
    point: 0,
  },
  {
    announce: {
      text: "",
      url: "#",
    },
    location: {
      lat: 35.2149354581014,
      lng: 129.07471174648,
    },
    liveZoomUrl: "",
    logo: {
      path: "boothLogo/LOGO_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_DRjlefqX3",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_DRjlefqX3?alt=media&token=931c51de-86bb-4003-a80b-6c255fd3a1ca",
    },
    vrData: "https://vr.bsjobedu.kr/2022/08",
    title: "동래원예고등학교",
    liveSubject: "",
    sessionKey: "",
    phone: "",
    isLive: false,
    createdAt: {
      nanoseconds: 6000000,
      seconds: 1632720414,
    },
    isLiveZoom: false,
    address: "부산 동래구 금강로 43",
    id: "XLNAf0DA",
    metaverse: "",
    description: "꿈을 현실로! 농업이 미래다! 부산 유일의 농생명계열 특성화고등학교!",
    category: "농생명산업계",
    isActive: true,
    point: 0,
    links: [
      {
        url: "http://school.busanedu.net/wonyea-h/main.do",
        title: "학교 홈페이지",
      },
      {
        title: "동래원예고 유튜브 채널",
        url: "http://bit.ly/동래원예",
      },
      {
        url: "https://open.kakao.com/me/wonyea_hs",
        title: "오픈채팅 상담",
      },
      {
        title: "",
        url: "",
      },
    ],
    tag: ["도시원예", "화훼장식", "농산업유통", "도시조경", "산림자원", "식품가공"],
    tags: [],
    isLiveYoutube: false,
    name: "",
  },
  {
    description: "안녕하세요, 대한민국 관광산업의 미래를 책임지는 부산관광고등학교 입니다.",
    isLiveZoom: false,
    isActive: true,
    address: "부산광역시 서구 천마로 52",
    isLiveYoutube: false,
    category: "상업계",
    links: [
      {
        title: "부산관광고 홈페이지",
        url: "http://school.busanedu.net/bstour-h/main.do",
      },
      {
        url: "https://www.youtube.com/channel/UCybThRxYmIrXvPTCnhhvfUg",
        title: "부산관광고 유튜브",
      },
      {
        title: "부산관광고 인스타그램 (홍보부)",
        url: "https://www.instagram.com/bst_ambassador/",
      },
      {
        title: "",
        url: "",
      },
    ],
    id: "bjXIKSDJ",
    metaverse: "",
    tags: [],
    name: "",
    location: {
      lat: 35.082570369768256,
      lng: 129.02223392062297,
    },
    sessionKey: "",
    isLive: false,
    point: 0,
    createdAt: {
      seconds: 1632789642,
      nanoseconds: 104000000,
    },
    liveSubject: "",
    liveZoomUrl: "",
    vrData: "https://vr.bsjobedu.kr/2022/14",
    phone: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_eW_LC3j4V?alt=media&token=71596249-2a56-47d0-89e4-8589c6bdd4da",
      path: "boothLogo/LOGO_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_eW_LC3j4V",
    },
    liveYoutubeUrl: "",
    announce: {
      url: "https://zoom.us/j/3369633353?pwd=cWRVLzg4QWVzL2o4YW9jNGtUZWRjQT09",
      text: "반갑습니다 😊 부산관광고등학교 입니다. 😃",
    },
    tag: ["부산관광고등학교", "관광컨벤션과", "카페베이커리과", "한식조리과"],
    title: "부산관광고등학교",
    liveStartedAt: {
      nanoseconds: 34000000,
      seconds: 1636454753,
    },
  },
  {
    links: [
      {
        url: "http://school.busanedu.net/tm-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://www.instagram.com/p/Cj4cTlPPVF4/?igshid=YmMyMTA2M2Y=",
        title: "인스타그램 'tongmyong_school'",
      },
      {
        url: "https://blog.naver.com/PostList.nhn?blogId=tm00162&parentCategoryNo=1",
        title: "블로그",
      },
      {
        url: "https://www.youtube.com/channel/UCyDfDOcOVtu23VZ7s-bUD3Q",
        title: "유튜브 '동명공고_official''",
      },
    ],
    description: "동명으로 직진! 취직Go! 진학Go! 동명공고 부스에 오신 것을 환영합니다:)",
    point: 0,
    isLiveZoom: false,
    liveSubject: "",
    phone: "",
    sessionKey: "",
    liveStartedAt: {
      seconds: 1667775005,
      nanoseconds: 21000000,
    },
    tags: [],
    isActive: true,
    id: "bm2RGZSA",
    metaverse: "",
    liveYoutubeUrl: "https://youtu.be/2oReaBHKuSo",
    isLiveYoutube: false,
    announce: {
      text: "",
      url: "#",
    },
    location: {
      lng: 129.10079477709,
      lat: 35.1209998813019,
    },
    vrData: "https://vr.bsjobedu.kr/2022/09",
    isLive: false,
    createdAt: {
      seconds: 1632724332,
      nanoseconds: 692000000,
    },
    name: "",
    tag: ["스마트기계", "전기전자", "산학일체", "일학습병행", "선취업후진학"],
    title: "동명공업고등학교",
    category: "공업계",
    address: "부산광역시 남구 신선로 410",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_dC5yyMwH6?alt=media&token=b757826b-86bb-4e2d-aed6-47758174e6e7",
      path: "boothLogo/LOGO_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_dC5yyMwH6",
    },
  },
  {
    title: "동의공업고등학교",
    liveZoomUrl: "",
    links: [
      {
        title: "홈페이지",
        url: "https://school.busanedu.net/dongeui-tech-h/main.do",
      },
      {
        title: "부산시 공무원 합격",
        url: "http://www.gukjenews.com/news/articleView.html?idxno=2362870",
      },
      {
        title: "과정평가형 지적산업기사 2년 연속 배출",
        url: "https://www.gukjenews.com/news/articleView.html?idxno=2319501",
      },
      {
        url: "",
        title: "",
      },
    ],
    sessionKey: "",
    isLiveYoutube: false,
    vrData: "https://vr.bsjobedu.kr/2022/11",
    category: "공업계",
    phone: "",
    announce: {
      url: "https://zoom.us/j/97893520340?pwd=aGljVFNhOWIvT0djU2hnTU5OM3d3QT09",
      text: "",
    },
    name: "",
    tag: ["기계과", "전기과", "건축토목과"],
    isLiveZoom: false,
    isActive: true,
    address: "부산광역시 부산진구 양지로 50",
    id: "cesE4dRx",
    metaverse: "",
    point: 0,
    description: "4차 산업혁명에 대비한 창의적인 과학기술인 양성의 전당 동의공업고등학교!",
    location: {
      lat: 35.1657481916197,
      lng: 129.071683424695,
    },
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_cesE4dRxTjMmElP18jBxY3fMvie2_B4NU_QozO?alt=media&token=b681b4e4-6477-4a7c-b26d-2a5d7cc97387",
      path: "boothLogo/LOGO_IMG_cesE4dRxTjMmElP18jBxY3fMvie2_B4NU_QozO",
    },
    liveSubject: "",
    tags: [],
    isLive: false,
    createdAt: {
      seconds: 1632725458,
      nanoseconds: 742000000,
    },
  },
  {
    createdAt: {
      nanoseconds: 382000000,
      seconds: 1632720448,
    },
    vrData: "https://vr.bsjobedu.kr/2022/01",
    id: "cx0gxz8p",
    metaverse: "",
    title: "경남공업고등학교",
    location: {
      lng: 129.06371560139164,
      lat: 35.15286787236627,
    },
    description: "역사와 전통을 자랑하는 학교, 미래를 여는 학교",
    sessionKey: "",
    isActive: true,
    point: 0,
    phone: "",
    tag: ["기계과", "화학공업과", "건축토목과", "전기전자과", "신소재디자인과"],
    isLiveZoom: false,
    logo: {
      path: "boothLogo/LOGO_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_d46lkOLFv",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_d46lkOLFv?alt=media&token=2e91fa3d-fef4-4cd9-827b-6bedaad8b9cc",
    },
    category: "공업계",
    liveZoomUrl: "",
    liveSubject: "",
    isLive: false,
    liveYoutubeUrl: "https://youtu.be/Di42WEQgGO0",
    liveStartedAt: {
      seconds: 1666763982,
      nanoseconds: 106000000,
    },
    name: "",
    announce: {
      text: "",
      url: "#",
    },
    address: "2호선 전포역 3번 출구 도보 1분 / 서면 중심가 위치",
    isLiveYoutube: false,
    links: [
      {
        title: "홈페이지",
        url: "https://school.busanedu.net/knt-h",
      },
      {
        title: "오픈채팅상담",
        url: "https://open.kakao.com/o/gvZ6S4Je",
      },
      {
        title: "실시간 상담",
        url: "https://youtu.be/Di42WEQgGO0",
      },
      {
        url: "",
        title: "",
      },
    ],
    tags: [],
  },
  {
    address: "부산광역시 사하구 감천로73번길 36",
    sessionKey: "-",
    isLiveYoutube: false,
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_2rYeoxsnH?alt=media&token=414f0d25-349b-43e9-ae30-4760ed66cd37",
      path: "boothLogo/LOGO_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_2rYeoxsnH",
    },
    point: 0,
    tag: ["전기전자과", "컴퓨터과", "디자인과"],
    liveYoutubeUrl: "",
    links: [
      {
        title: "학교홈페이지",
        url: "https://school.busanedu.net/buil-h/main.do",
      },
      {
        title: "인스타그램",
        url: "https://www.instagram.com/buil_hs_official/",
      },
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    vrData: "https://vr.bsjobedu.kr/2022/32",
    announce: {
      url: "#",
      text: "4차 산업혁명시대를 함께하며 나아가는 우리는, 부일전자디자인고등학교입니다.",
    },
    isLiveZoom: false,
    id: "h91N2lKn",
    metaverse: "",
    phone: "",
    liveStartedAt: {
      nanoseconds: 94000000,
      seconds: 1636420768,
    },
    description: " ★미래를 준비하는 희망찬 학교★",
    isLive: false,
    title: "부일전자디자인고등학교",
    location: {
      lng: 129.00163269119,
      lat: 35.0915627441362,
    },
    name: "",
    liveSubject: "",
    createdAt: {
      seconds: 1632725739,
      nanoseconds: 222000000,
    },
    category: "공업계",
    tags: [],
    isActive: true,
  },
  {
    name: "",
    phone: "",
    isLiveZoom: false,
    point: 0,
    liveZoomUrl: "",
    description: "미래 산업을 주도하는 백산 명품 기술인 육성! 서부산공고 부스에 오신 것을 환영합니다.",
    isLiveYoutube: false,
    location: {
      lng: 128.992318628567,
      lat: 35.1720580469184,
    },
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_0C6qg22ZH?alt=media&token=c1616c80-daa7-4975-a3f4-958403630365",
      path: "boothLogo/LOGO_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_0C6qg22ZH",
    },
    category: "공업계",
    tag: ["신재생에너지분야 특성화고", "전기에너지과", "기계과", "스마트팩토리과", "취업 및 대학 진학 맞춤형 교육"],
    sessionKey: "",
    links: [
      {
        url: "https://school.busanedu.net/sth-h/main.do",
        title: "홈페이지",
      },
      {
        title: "오픈채팅상담",
        url: "https://open.kakao.com/o/giLYWVJe",
      },
      {
        title: "",
        url: "",
      },
      {
        title: "",
        url: "",
      },
    ],
    id: "i03mPbAY",
    metaverse: "",
    liveStartedAt: {
      nanoseconds: 919000000,
      seconds: 1636607724,
    },
    liveSubject: "",
    announce: {
      url: "https://",
      text: "미래 산업을 주도하는 백산 명품 기술인 육성! 서부산공고 부스에 오신 것을 환영합니다.",
    },
    isActive: true,
    isLive: false,
    liveYoutubeUrl: "",
    address: "부산광역시 사상구 백양대로768번길 115",
    createdAt: {
      nanoseconds: 196000000,
      seconds: 1632727227,
    },
    tags: [],
    title: "서부산공업고등학교",
    vrData: "https://vr.bsjobedu.kr/2022/33",
  },
  {
    title: "부산전자공업고등학교",
    address: "부산광역시 동래구 금강로59번길 51",
    liveZoomUrl: "",
    id: "iTnoR3l0",
    metaverse: "",
    liveSubject: "",
    category: "공업계",
    tags: [],
    liveStartedAt: {
      nanoseconds: 49000000,
      seconds: 1636069392,
    },
    sessionKey: "",
    isActive: true,
    vrData: "https://vr.bsjobedu.kr/2022/26",
    isLive: false,
    description: "부산전자공업고등학교 입니다.",
    isLiveZoom: false,
    name: "",
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/kumgang-h/main.do",
      },
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    announce: {
      url: "#",
      text: "부산전자공업고등학교 입니다.",
    },
    phone: "",
    liveYoutubeUrl: "",
    isLiveYoutube: false,
    point: 0,
    tag: ["국방부지정", "통합형직업교육거점학교", "전기전자과", "전자통신과", "기계자동차과"],
    logo: {
      path: "boothLogo/LOGO_IMG_iTnoR3l0rgYMc7hQWuqc76T6oIr1_khJyUk03Z",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_iTnoR3l0rgYMc7hQWuqc76T6oIr1_khJyUk03Z?alt=media&token=c6a3a139-10ce-47de-b76d-87c2824ee961",
    },
    location: {
      lng: 129.073566981198,
      lat: 35.21534437587,
    },
    createdAt: {
      seconds: 1632721164,
      nanoseconds: 405000000,
    },
  },
  {
    createdAt: {
      nanoseconds: 981000000,
      seconds: 1632725708,
    },
    announce: {
      text: "",
      url: "#",
    },
    phone: "",
    name: "",
    title: "직업계고 진로안내",
    liveYoutubeUrl: "",
    id: "kDHHuyBb",
    metaverse: "",
    isActive: true,
    tag: ["부산직업온라인박람회", "직업계고", "진로안내"],
    point: 0,
    isLiveYoutube: false,
    description: "직업계고 진로 안내 부스입니다.",
    order: 2,
    logo: {
      path: "boothLogo/LOGO_IMG_kDHHuyBb_2kwuSnARH",
      url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/boothLogo%2FLOGO_IMG_kDHHuyBb_2kwuSnARH?alt=media&token=bdf873a5-6ed4-4ca2-93ce-1c8db413b479",
    },
    links: [
      {
        title: "홈페이지",
        url: "https://hijob.pen.go.kr/dajobgo/index.asp",
      },
      {
        title: "",
        url: "",
      },
      {
        title: "",
        url: "",
      },
      {
        title: "",
        url: "",
      },
    ],
    isLive: false,
    liveSubject: "",
    liveZoomUrl: "",
    category: "",
    isLiveZoom: false,
    sessionKey: "",
    address: "부산광역시 부산진구 화지로 12",
    tags: [],
    location: {
      lat: 35.17618320788755,
      lng: 129.06474283781452,
    },
    liveStartedAt: {
      seconds: 1666277387,
      nanoseconds: 361000000,
    },
  },
  {
    location: {
      lng: 129.072943050648,
      lat: 35.1700630089435,
    },
    liveYoutubeUrl: "",
    vrData: "https://vr.bsjobedu.kr/2022/29",
    id: "qztaNBYC",
    metaverse: "",
    liveStartedAt: {
      seconds: 1636694651,
      nanoseconds: 876000000,
    },
    tags: [],
    phone: "",
    isLiveZoom: false,
    address: "부산광역시 부산진구 진남로 524",
    liveZoomUrl: "",
    point: 0,
    isActive: true,
    announce: {
      text: "",
      url: "#",
    },
    isLive: false,
    isLiveYoutube: false,
    tag: [""],
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/jin-h/main.do",
      },
      {
        title: "학생회 인스타그램",
        url: "https://instagram.com/jin_hs_official?utm_medium=copy_link",
      },
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    category: "상업계",
    sessionKey: "",
    title: "부산진여자상업고등학교",
    name: "",
    createdAt: {
      seconds: 1632726632,
      nanoseconds: 786000000,
    },
    description: "실력있고 열정적이며 매력있는 인재를 키우는 취업 명품 학교",
    liveSubject: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_qztaNBYCJXdStloLr3fA3XW2pze2_vAIkF2mqq?alt=media&token=64c3087a-8544-4edd-aa7d-8b65bf9802b4",
      path: "boothLogo/LOGO_IMG_qztaNBYCJXdStloLr3fA3XW2pze2_vAIkF2mqq",
    },
  },
  {
    isLiveZoom: false,
    liveZoomUrl: "",
    createdAt: {
      seconds: 1632724321,
      nanoseconds: 456000000,
    },
    title: "부산공업고등학교",
    tag: ["공립", "기계과", "전기과", "건축토목과", "산학일체형도제학교"],
    phone: "",
    isActive: true,
    announce: {
      url: "#",
      text: "부산공업고등학교에 방문하신 것을 환영합니다.",
    },
    isLive: false,
    links: [
      {
        url: "http://school.busanedu.net/pusan-th-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://tv.naver.com/v/9009498",
        title: "급식 맛있는 학교",
      },
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    location: {
      lng: 129.087585678983,
      lat: 35.1311698036345,
    },
    address: "부산광역시 남구 수영로196번길 80",
    name: "",
    sessionKey: "-HnrEyC",
    point: 0,
    id: "sEoItUIg",
    metaverse: "",
    category: "공업계",
    tags: [],
    liveSubject: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_UuoleQ5vA?alt=media&token=a7b408f1-8c05-4444-9b7f-e343c4c07191",
      path: "boothLogo/LOGO_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_UuoleQ5vA",
    },
    description: "학생들의 능력을 이끌어 주고, 꿈을 이루어주는 100년 역사의 부산 최고의 특성화고등학교",
    isLiveYoutube: false,
    vrData: "https://vr.bsjobedu.kr/2022/13",
  },
  {
    isLiveZoom: false,
    isLive: false,
    announce: {
      text: "",
      url: "#",
    },
    name: "",
    liveStartedAt: {
      seconds: 1632982883,
      nanoseconds: 897000000,
    },
    liveYoutubeUrl: "",
    title: "부산기계공업고등학교",
    sessionKey: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_TSQ_GL36q?alt=media&token=74b8f54d-a29e-44b7-9af8-fc75c699cf1a",
      path: "boothLogo/LOGO_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_TSQ_GL36q",
    },
    isActive: true,
    category: "마이스터",
    tag: ["국립", "취업역량강화"],
    vrData: "https://vr.bsjobedu.kr/2022/16",
    address: "부산 해운대구 해운대로 569",
    tags: [],
    id: "skpjRyhp",
    metaverse: "",
    liveSubject: "",
    phone: "",
    point: 0,
    location: {
      lng: 129.154106,
      lat: 35.164181,
    },
    liveZoomUrl: "",
    isLiveYoutube: false,
    description: "문제해결 능력을 갖춘 창의적인 기술인 육성!",
    links: [
      {
        url: "http://school.busanedu.net/bmt-h/main.do",
        title: "홈페이지",
      },
      {
        title: "",
        url: "",
      },
      {
        title: "",
        url: "",
      },
      {
        url: "",
        title: "",
      },
    ],
    createdAt: {
      nanoseconds: 218000000,
      seconds: 1632719401,
    },
  },
  {
    phone: "",
    point: 0,
    isLiveYoutube: false,
    tag: ["해기사", "항해사", "기관사", "해양마이스터"],
    name: "",
    isLive: false,
    category: "마이스터",
    announce: {
      url: "",
      text: "",
    },
    sessionKey: "",
    tags: [],
    description: "세계 최고의 해기사를 육성하는 해양 마이스터고등학교",
    isLiveZoom: false,
    address: "부산광역시 영도구 해양로 425",
    location: {
      lat: 35.0725444731644,
      lng: 129.078097813216,
    },
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/maritime-h/main.do",
      },
      {
        title: "유튜브",
        url: "https://www.youtube.com/channel/UCfi_nhbfNAQMZueefTsILYA",
      },
      {
        title: "인스타그램",
        url: "https://www.instagram.com/bnmh_go/",
      },
      {
        title: "",
        url: "",
      },
    ],
    isActive: true,
    liveZoomUrl: "",
    vrData: "https://vr.bsjobedu.kr/2022/31",
    createdAt: {
      nanoseconds: 356000000,
      seconds: 1632719441,
    },
    id: "tlt0bwaq",
    metaverse: "",
    liveSubject: "",
    logo: {
      path: "boothLogo/LOGO_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_54-mY7Ya6",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_54-mY7Ya6?alt=media&token=3f2c22eb-a192-4bd0-a6d7-08d9bb0fee33",
    },
    title: "부산해사고등학교",
  },
  {
    phone: "",
    category: "공업계",
    isLive: false,
    sessionKey: "",
    location: {
      lat: 35.1673633647901,
      lng: 129.144342868275,
    },
    id: "vJI5HSIg",
    metaverse: "",
    isActive: true,
    logo: {
      path: "boothLogo/LOGO_IMG_vJI5HSIgD5QFM305SJIkeHvs6cY2_Pu9vV2HUf",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_vJI5HSIgD5QFM305SJIkeHvs6cY2_Pu9vV2HUf?alt=media&token=973674b8-9545-4bdf-a075-261ef41f2226",
    },
    description: "센텀시티 벡스코역에 위치한 공립 해운대공업고등학교에 오신 것을 환영합니다.",
    announce: {
      url: "https://school.busanedu.net/hudt-h/main.do",
      text: "해운대공업고등학교 입니다.",
    },
    tags: [],
    tag: ["센텀", "벡스코역", "공립", "전기전자", "한국선급용접사", "글로벌 호주", "기계과"],
    isLiveZoom: false,
    isLiveYoutube: false,
    liveZoomUrl: "",
    name: "",
    createdAt: {
      nanoseconds: 315000000,
      seconds: 1632721452,
    },
    vrData: "https://vr.bsjobedu.kr/2022/36",
    title: "센텀벡스코역 위치한 해운대공업고등학교",
    address: "부산광역시 해운대구 해운대로469번길 96",
    point: 0,
    liveSubject: "",
    links: [
      {
        url: "http://school.busanedu.net/hudt-h/main.do",
        title: "홈페이지",
      },
      {
        title: "뉴스 보도",
        url: "https://search.naver.com/search.naver?where=news&sm=tab_tnw&query=%ED%95%B4%EC%9A%B4%EB%8C%80%EA%B3%B5%EA%B3%A0&sort=0&photo=0&field=0&pd=0&ds=&de=&mynews=0&office_type=0&office_section_code=0&news_office_checked=&related=1&docid=0010012747920&nso=so:r,p:all,a:all",
      },
      {
        url: "",
        title: "",
      },
      {
        url: "",
        title: "",
      },
    ],
  },
  {
    isActive: true,
    isLiveZoom: false,
    category: "상업계",
    tag: ["부산문화여고", "국제관광과", "유아교육과", "패션디자인과", "보건간호과", "급식맛집"],
    vrData: "https://vr.bsjobedu.kr/2022/19",
    description: "꿈과 희망을 키우는 학교! 부산문화여자고등학교입니다. 입학문의 051-743-6917",
    address: "부산광역시 해운대구 해운대로469번길 50",
    phone: "",
    name: "",
    isLive: false,
    title: "부산문화여자고등학교",
    sessionKey: "",
    id: "vMjlWuyV",
    metaverse: "",
    liveZoomUrl: "",
    announce: {
      url: "https://school.busanedu.net/bs-moonhwa-h/main.do",
      text: "부산문화여자고등학교입니다~^^",
    },
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/bs-moonhwa-h/main.do",
      },
      {
        url: "https://www.youtube.com/channel/UCH393MFYHCaxJ-qG1rsd0tA",
        title: "유투브",
      },
      {
        title: "인스타그램",
        url: "https://instagram.com/moonhwa_hs_?igshid=YmMyMTA2M2Y=",
      },
      {
        url: "",
        title: "",
      },
    ],
    location: {
      lng: 129.143526063042,
      lat: 35.165845881102,
    },
    createdAt: {
      seconds: 1632790892,
      nanoseconds: 515000000,
    },
    liveSubject: "",
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_zAz_egDIq?alt=media&token=7be8e309-89e7-4d94-91f7-09204a11dab2",
      path: "boothLogo/LOGO_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_zAz_egDIq",
    },
    tags: [],
    isLiveYoutube: false,
    point: 0,
  },
  {
    address: "부산광역시 부산진구 봉수로 13",
    isLiveYoutube: false,
    isLiveZoom: false,
    isLive: false,
    phone: "",
    location: {
      lat: 35.1515635458945,
      lng: 129.070441510053,
    },
    tags: [],
    links: [
      {
        title: "홈페이지",
        url: "http://school.busanedu.net/marketing-h/main.do",
      },
      {
        url: "https://www.youtube.com/channel/UC9Wd5Fk7TVLaKNOGxv2838g",
        title: "유투브채널",
      },
      {
        url: "https://open.kakao.com/o/gejxVaMe",
        title: "오픈채팅상담",
      },
      {
        url: "https://www.instagram.com/busan_marketing_highschool/",
        title: "인스타그램 공식계정",
      },
    ],
    name: "",
    isActive: true,
    title: "부산마케팅고등학교",
    logo: {
      path: "boothLogo/LOGO_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_lYv6pYYXg",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_lYv6pYYXg?alt=media&token=42de5f98-2389-4bae-8e7c-7f42e3f5436b",
    },
    id: "w4TXDfxl",
    metaverse: "",
    category: "상업계",
    point: 0,
    announce: {
      url: "http://school.busanedu.net/marketing-h/main.do",
      text: "사랑합니다! 부산마케팅고등학교 입니다. (051-803-2851)",
    },
    description: "그대의 꿈을 마케팅하라~^^ 사랑합니다. 부산마케팅고등학교 입니다.",
    liveStartedAt: {
      seconds: 1636534689,
      nanoseconds: 354000000,
    },
    tag: ["스포츠", "금융", "관광", "보건", "상업", "서면", "여고"],
    liveYoutubeUrl: "",
    createdAt: {
      nanoseconds: 842000000,
      seconds: 1632727434,
    },
    liveZoomUrl: "",
    liveSubject: "",
    vrData: "https://vr.bsjobedu.kr/2022/18",
    sessionKey: "",
  },
  {
    tag: [
      "#부산컴퓨터과학고등학교 #컴과고 #특성화고 #특성화고추천 #부산시특성화고 #소프트웨어과 #사물인터넷과 #3D콘텐츠제작과 #서비스마케팅과 #금융회계과",
    ],
    sessionKey: "",
    vrData: "https://vr.bsjobedu.kr/2022/30",
    point: 0,
    isLiveZoom: false,
    isLiveYoutube: false,
    description: "4차산업혁명시대의 창의융합인재 양성! 부산컴퓨터과학고등학교입니다.",
    isLive: false,
    liveStartedAt: {
      seconds: 1635822430,
      nanoseconds: 876000000,
    },
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/boothLogo%2FLOGO_IMG_xOz83nC9_yOKvoZqy1?alt=media&token=bae97996-d32a-4abc-8778-4d70925611b8",
      path: "boothLogo/LOGO_IMG_xOz83nC9_yOKvoZqy1",
    },
    title: "부산컴퓨터과학고등학교",
    announce: {
      text: "",
      url: "#",
    },
    phone: "",
    location: {
      lat: 35.1212462529813,
      lng: 129.033320994147,
    },
    liveSubject: "",
    createdAt: {
      nanoseconds: 999000000,
      seconds: 1632965892,
    },
    links: [
      {
        url: "http://school.busanedu.net/pcs-h/main.do",
        title: "홈페이지",
      },
      {
        title: "인스타그램",
        url: "https://www.instagram.com/pcs4600500/",
      },
      {
        title: "카카오톡채널 채팅",
        url: "http://pf.kakao.com/_fxbGxlxj/chat",
      },
      {
        url: "https://pcs.5goon.me",
        title: "컴과고 VR 온라인 투어",
      },
    ],
    name: "",
    isActive: true,
    address: "부산광역시 동구 초량로80번길 5",
    liveYoutubeUrl: "",
    tags: [],
    category: "상업계",
    id: "xOz83nC9",
    metaverse: "",
  },
  {
    phone: "",
    name: "",
    createdAt: {
      nanoseconds: 766000000,
      seconds: 1632729611,
    },
    tags: [],
    id: "xrjwWDOM",
    metaverse: "",
    isActive: true,
    liveStartedAt: {
      seconds: 1668041468,
      nanoseconds: 959000000,
    },
    vrData: "https://vr.bsjobedu.kr/2022/28",
    sessionKey: "",
    liveSubject: "",
    category: "상업계",
    liveZoomUrl: "",
    point: 0,
    tag: [
      "호텔리어",
      "호텔경영",
      "호텔조리",
      "제과제빵",
      "바리스타",
      "칵테일",
      "승무원",
      "제빵사",
      "특급호텔",
      "호텔특성화고",
      "조리사",
      "요리사",
      "쉐프",
    ],
    title: "부산정보관광고등학교",
    isLiveYoutube: false,
    liveYoutubeUrl: "",
    location: {
      lat: 35.2237781954134,
      lng: 129.09246832461,
    },
    description: "전국최초이자 전국최고 호텔특성화고등학교 <부산정보관광고등학교 입니다> ",
    announce: {
      text: "",
      url: "#",
    },
    logo: {
      path: "boothLogo/LOGO_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_kY7mDSvl0",
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_kY7mDSvl0?alt=media&token=8e7e922f-f1e4-4b58-af65-2e6272576b83",
    },
    links: [
      {
        url: "http://school.busanedu.net/pusan-ith-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://www.youtube.com/channel/UC-l4oiZ6LuKGjyuquLT3VQw",
        title: "유투브",
      },
      {
        title: "카카오톡 오픈채팅",
        url: "https://open.kakao.com/o/gnjFV1Id",
      },
      {
        title: "인스타그램",
        url: "https://www.instagram.com/bit__highschool/",
      },
    ],
    isLiveZoom: false,
    isLive: false,
    address: "부산광역시 금정구 동현로 37",
  },
  {
    liveSubject: "",
    liveZoomUrl: "",
    name: "",
    isLiveZoom: false,
    isLiveYoutube: false,
    announce: {
      url: "#",
      text: "부산여자상업고등학교 입니다.",
    },
    category: "상업계",
    createdAt: {
      seconds: 1632728782,
      nanoseconds: 824000000,
    },
    title: "부산여자상업고등학교",
    liveYoutubeUrl: "",
    sessionKey: "-KJWYov",
    tags: [],
    liveStartedAt: {
      seconds: 1668055185,
      nanoseconds: 433000000,
    },
    address: "부산광역시 수영구 금련로 43",
    point: 0,
    logo: {
      url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/boothLogo%2FLOGO_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_oZWnbYn6F?alt=media&token=d5b16a19-2aad-4ad4-9f80-067d8990f41d",
      path: "boothLogo/LOGO_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_oZWnbYn6F",
    },
    phone: "",
    description: "부산여자상업고등학교 입니다.",
    isLive: false,
    id: "y38CucaG",
    metaverse: "",
    location: {
      lat: 35.169164168845,
      lng: 129.096467107968,
    },
    links: [
      {
        url: "http://school.busanedu.net/psgch-h/main.do",
        title: "홈페이지",
      },
      {
        url: "https://open.kakao.com/o/gxRE2vId",
        title: "부산여자상업고등학교 진학상담 단톡방 ",
      },
      {
        url: "https://www.instagram.com/local.culture.sky/",
        title: "부산여자상업고등학교 지역문화답사반",
      },
      {
        title: "",
        url: "",
      },
    ],
    vrData: "https://vr.bsjobedu.kr/2022/23",
    isActive: true,
  },
];

const DUMMY_2 = {
  "3Qvvy7wC": {
    id: "3Qvvy7wC",
    documents: [
      {
        order: 1,
        title: "2022학년도 신입생 전형 요강",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FDoajfEnRK_2022%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EA%B5%AD%EC%A0%9C%EC%98%81%ED%99%94%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.hwp?alt=media&token=3f0f63ef-0422-466d-88a6-2ae837737743",
        path: "documents/DoajfEnRK_2022학년도 부산국제영화고등학교 입학전형 요강.hwp",
        id: "MU8cbRc4PWRJ3u4dfnnt",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FdRHsfPLYi_2021%ED%95%99%EB%85%84%EB%8F%84%20%EC%A4%91%ED%95%99%EC%83%9D%20%EC%A7%81%EC%97%85%EA%B3%84%EA%B3%A0%20%EC%A7%84%EB%A1%9C%EC%B2%B4%ED%97%98%20%ED%94%84%EB%A1%9C%EA%B7%B8%EB%9E%A8%20%EC%9A%B4%EC%98%81%20%EA%B3%84%ED%9A%8D%EC%84%9C_%EB%B6%80%EC%82%B0%EA%B5%AD%EC%A0%9C%EC%98%81%ED%99%94%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90(1).hwp?alt=media&token=cb34e2ff-2fd0-47e2-885c-59c8eac2f16f",
        path: "documents/dRHsfPLYi_2021학년도 중학생 직업계고 진로체험 프로그램 운영 계획서_부산국제영화고등학교(1).hwp",
        order: 2,
        title: "2021년 중학생 진로체험활동",
        id: "m3ixzEv1k7ni4jfXZlxB",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_5HcRK8lPH?alt=media&token=1a080448-a996-4aca-b55f-47585036339f",
        title: "검정형자격증 수업 - 헤어컬러링 초록",
        order: 13,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_5HcRK8lPH",
        id: "1im98nSpy369NqIgyqkQ",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_atSWp_HME",
        order: 5.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_atSWp_HME?alt=media&token=c4740ebc-236f-4cd5-80da-a218fddf5926",
        title: "부산국제영화고등학교 영상연예과",
        id: "28QIL47RjhpbZTRvPaUb",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_3dfAgU1Lk",
        title: "부산국제영화고 실용음악반 밴드활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_3dfAgU1Lk?alt=media&token=77c3ef54-4825-4e22-b4fa-ae2720a2d05e",
        order: 25,
        id: "3udpzhYWOngtEO4rl03S",
      },
      {
        title: "부산국제영화고 영상연예과 - 연극수업",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_GooarLRfd",
        order: 21,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_GooarLRfd?alt=media&token=7e0a5de1-8c62-4890-adc0-ee669a6c9062",
        id: "4JgA3rdkTe9KF7WTj7nf",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_plPoh2B6K?alt=media&token=829b8384-58bb-4de5-8889-b405f947d07c",
        order: 9.4375,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_plPoh2B6K",
        title: "부산국제영화고 동아리활동",
        id: "5Yr9WbeAfsk0vO9Dp03w",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_D_VvzFYUP?alt=media&token=e66a545e-8649-4e3a-b3b9-1d6c3eb11caf",
        order: 12.75,
        title: "과정평가형 헤어미용 수업 - 응용드라이 수업",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_D_VvzFYUP",
        id: "9yIdmka0EYa3W03nJeZl",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_L09TYrI38?alt=media&token=43e953c0-c88b-474c-9614-4de44837fe29",
        title: "부산국제영화고 영상그래픽과 -웹툰수업",
        order: 28,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_L09TYrI38",
        id: "B1Si4NK0F9juiKBeK32e",
      },
      {
        order: 10,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_BUTSFS6bV?alt=media&token=dd3ae275-fab1-4738-8696-fc2ff6b2ba5e",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_BUTSFS6bV",
        title: "과정평가형 헤어미용 수업-샴푸",
        id: "DhJdKLFUBvB7gaS9IQv6",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_sZ7aIZ9cT?alt=media&token=4675f224-36b8-4550-9d1e-d47a9c75bca6",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_sZ7aIZ9cT",
        title: "헤어아트 특강",
        order: 24.5,
        id: "INn6yYgGxAonyBPWzxJE",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_72ZWJrSq7",
        order: 9.859375,
        title: "부산국제영화고등학교 입학안내 & 졸업생 대학 진학",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_72ZWJrSq7?alt=media&token=14568bee-aea6-43c8-805b-d415ac153302",
        id: "Ja7tvblQmdWIwab2ZocU",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_UH_jP-XIp",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_UH_jP-XIp?alt=media&token=86c854ea-c8bc-43f8-8453-a4f6ad88b972",
        order: 12,
        title: "과정평가형 헤어미용 수업 - 두피, 모발관리",
        id: "NG6LZ3GqaJZOyZRvQ9bD",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_Z_J3nuprB",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_Z_J3nuprB?alt=media&token=786f723b-89b8-4e73-8eb3-e85bb90d27f7",
        order: 23,
        title: "부산국제영화고 댄스동아리 활동",
        id: "NainTr1OjOPKbCuhQ1UI",
      },
      {
        title: "과정평가형 헤어미용 수업 - 베이직헤어펌 수업",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_bKjenTf1K",
        order: 12.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_bKjenTf1K?alt=media&token=9ea54942-bc1c-4713-a0f7-24b79393ef07",
        id: "OTSv3HhAgBSIdO3Grcby",
      },
      {
        title: "부산국제영화고 영상연예과 - 연극동아리활동",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_sCANmCPG2",
        order: 19,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_sCANmCPG2?alt=media&token=0be659d7-1996-4351-82d4-f2783fe29a08",
        id: "VRMTgwe6pIklXwbvN67x",
      },
      {
        title: "부산국제영화고 영상연예과 - 뮤지컬수업",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_9wHgpUQ1m?alt=media&token=558e4245-2b7d-40e2-ac2d-7b19a2602b47",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_9wHgpUQ1m",
        order: 17,
        id: "VcGZWr8h5WcFJe7B0nOY",
      },
      {
        order: 9.9296875,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_LNEttZbuL",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_LNEttZbuL?alt=media&token=98002fe8-a6ae-449f-a3aa-01be00b13ea7",
        title: "부산국제영화고 입학전형일정 및 찾아오시는 길",
        id: "WJiwjOJHmLOTpj0rBnKR",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_k5UF5Heu4?alt=media&token=3048f5f8-ae35-4709-a7ab-0b041b90bcbf",
        order: 16,
        title: "부산국제영화고 피부관리 수업",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_k5UF5Heu4",
        id: "WKbgdyY7CXr6CMNg4b1k",
      },
      {
        title: " 부산국제영화고 무한상상실 동아리 활동",
        order: 27,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_qHScKhqYI?alt=media&token=fc71a52d-dd49-461c-b1a3-92cd842f6b9c",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_qHScKhqYI",
        id: "WMMhY3BOXVIi2FxzG20k",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_rV9LOdntw?alt=media&token=fce2aa51-eba6-4a00-96e3-8d341d461c16",
        title: "부산국제영화고에 관한 Q&A",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_rV9LOdntw",
        order: 9.71875,
        id: "Xd36C4TVFmznu8MbyifP",
      },
      {
        order: 24.25,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_6aIeDeGiC",
        title: "부산국제영화고 실용음악 동아리 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_6aIeDeGiC?alt=media&token=0e4b6580-2e6d-4460-a8e6-6764064159fd",
        id: "Yjf1T27BAkoV7Ox3RuYY",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_dc0y499Xz?alt=media&token=efe0d560-e1d1-4ab3-905d-bc40a444cc3a",
        title: "2022학년도 신입생 모집 포스터",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_dc0y499Xz",
        order: 0.5,
        id: "YmMudyiBamfaZVdO0Cv0",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_4wvgAWZgl?alt=media&token=6155779f-f838-4a51-a767-2609f333e03a",
        order: 22,
        title: "부산국제영화고 뮤지컬 동아리활동",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_4wvgAWZgl",
        id: "ZJKA58i8D0AusGFWUhDE",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_9a5pnYMZ9",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_9a5pnYMZ9?alt=media&token=dea1779e-9773-4f33-be93-3aa972bd4d30",
        order: 18,
        title: "부산국제영화고 영상연예과 - 뮤지컬수업",
        id: "cZJ5pu9IQyzBxdZrn2rw",
      },
      {
        order: 30,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1__987FSeaS?alt=media&token=1dc2c946-a262-4731-bd47-7d1503b90bee",
        title: " 부산국제영화고 e-스포츠 수상내역",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1__987FSeaS",
        id: "dVSmv1l7Old0YB9NYtto",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_6qpOKHpKy",
        order: 14,
        title: " 검정형자격증 수업 - 헤어드라이",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_6qpOKHpKy?alt=media&token=fd3201ec-acbe-4aa9-807b-551d9af1ca2d",
        id: "ebWktBwk4Gu1a93WIAWb",
      },
      {
        title: "부산국제영화고를 빛낸 Stars",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_EfHgjUqB4?alt=media&token=d9c375d5-e98a-433d-8c73-0693e29bce2d",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_EfHgjUqB4",
        order: 8.875,
        id: "ecPQw3CGIoEQi0QmBkYG",
      },
      {
        title: "부산국제영화고 밴드동아리 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_c5Z8njbuB?alt=media&token=73cf7ae0-f57c-4c57-aa15-1e595e371688",
        order: 26,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_c5Z8njbuB",
        id: "hZwlmLpSxNx8ccjU9Ygz",
      },
      {
        order: 12.875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_X-qWG6o9q?alt=media&token=17979a06-7f10-4d4c-bd04-beeb38f64543",
        title: "과정평가형 헤어미용 수업 - 베이직헤어컬러과 수업",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_X-qWG6o9q",
        id: "kFUSJTWwtDcQAsKSrCqi",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_q5PrdFMZq",
        title: "페이스페이팅 수업",
        order: 15,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_q5PrdFMZq?alt=media&token=4003c9d8-ca88-4d1c-a126-6380bbdfc302",
        id: "kyGaKnAL8DFsAKnlXZ51",
      },
      {
        order: 29,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_QH87v2_yD",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_QH87v2_yD?alt=media&token=c8a04f1c-f61d-4493-808a-9f52ca9f2632",
        title: "부산국제영화고 e-스포츠 동아리활동",
        id: "n5eFg1eW7TH18MkBBxI1",
      },
      {
        order: 20,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_J9mDSi3hv?alt=media&token=c1c37400-221f-425b-961e-6e572ae825d9",
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_J9mDSi3hv",
        title: "부산국제영화고 영상연예과 - 연극수업",
        id: "qUt7pS6tJHHdhxa8iHpL",
      },
      {
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_-HQPXwA06",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_-HQPXwA06?alt=media&token=95a49d95-4bf0-462a-9f6e-7a869da61239",
        title: "부산국제영화고등학교 영상그래픽과, 영상헤어디자인과",
        order: 7.75,
        id: "qml2NA5AtYG1PZM98TAQ",
      },
      {
        title: "과정평가형 헤어미용 수업 - 베이직헤어펌 수업",
        order: 11,
        path: "images/BOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_gKHFGjfmG",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3Qvvy7wCK1WFx7arW5c3qtqheIw1_gKHFGjfmG?alt=media&token=6e371051-911a-4f67-aef0-f3f50c75cf1f",
        id: "uYPOcA1olTt0U3r1w7yj",
      },
    ],
    videos: [
      {
        order: 3.625,
        title: "부산국제영화고등학교 밴드부 영상",
        url: "https://youtu.be/eHLVkl2XQEk",
        id: "1OasGadGVhMSjVePxELQ",
      },
      {
        title: "메인영상",
        order: 1,
        url: "https://youtu.be/lFydDlP4W84",
        id: "821SxnGqrROZxOlvo7Et",
      },
      {
        url: "https://youtu.be/f0v3YQF2GGU",
        title: "부산국제영화고등학교 강당 뮤지컬수업 VR",
        order: 8,
        id: "B2cnIPyzjZBWD1hnqAxu",
      },
      {
        order: 1.875,
        title: "부산국제영화고 댄스동아리활동",
        url: "https://www.youtube.com/watch?v=aR72R025o30",
        id: "RzGLTqUx3OGanJ91o2aA",
      },
      {
        url: "https://youtu.be/ik6HCS95xQA",
        title: "부산국제영화고등학교 무한상상실 Body_Mocap실습영상 VR",
        order: 6,
        id: "Tbh6NbSqiimrYojhA8bl",
      },
      {
        url: "https://youtu.be/VZrOrJ34X7c",
        title: "부산국제영화고등학교 메이크업 수업",
        order: 10,
        id: "Tnq1JZLBoZvmejSQmkLS",
      },
      {
        title: "부산국제영화고등학교 헤어실습실 기본형와인딩 VR",
        order: 5,
        url: "https://youtu.be/qY_zOYfgnXs",
        id: "a3D1TjHA0xyP1X797i2Z",
      },
      {
        url: "https://youtu.be/jCGmhzCI8mk",
        title: "부산국제영화고등학교 무한상상실 FacialMocap실습영상 VR",
        order: 7,
        id: "ihHmzH9mdyxxaWWNDU6v",
      },
      {
        title: "부산국제영화고등학교 공연",
        order: 2.25,
        url: "https://www.youtube.com/watch?v=3piEvUGmAMo&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=7",
        id: "mST1GqPzBVumbMn5WBDL",
      },
      {
        title: "2022 부산국제영화고등학교 홍보영상",
        url: "https://youtu.be/qa04lqK-0QA",
        order: 0.75,
        id: "tbxud09je0syAXA7yv9v",
      },
    ],
  },
  "3XCIlnPN": {
    id: "3XCIlnPN",
    documents: [],
    images: [
      {
        title: "수업사진7(디저트서비스과)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_ZL2Inv12b?alt=media&token=cf4d8077-35bf-4d3c-9774-a94384664181",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_ZL2Inv12b",
        order: 20,
        id: "3tFtz7IlHMDzRGS7ycry",
      },
      {
        order: 7,
        title: "수업사진1(금융서비스)",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_zDMw9EbGf",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_zDMw9EbGf?alt=media&token=594bfd8b-be2d-4b7c-9c97-cbf0c8a71369",
        id: "5HRYD7p0PAiK53tlOVns",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_sFxzGCkYC?alt=media&token=7c7ee4db-129f-4cf4-9c03-2bbb97aa8f9c",
        title: "금융서비스과",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_sFxzGCkYC",
        order: 1,
        id: "8lniv9Fuk6DlGoBvurLk",
      },
      {
        title: "수업사진3(마케팅서비스과)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_zN8rTibkG?alt=media&token=ffc761d2-e3d3-4dc0-ba60-ca55033adf81",
        order: 13,
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_zN8rTibkG",
        id: "8zz4uMqHipUpi9HEnMrX",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_JBqGSD69D?alt=media&token=0124ee81-10a3-4468-a1f4-02cfa32e7bdf",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_JBqGSD69D",
        order: 23,
        title: "특강1(실전 취업역량 강화)",
        id: "CrreZyx7eDLGXUVKeUzA",
      },
      {
        title: "수업사진6(디저트서비스과)",
        order: 19,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_lR2UetqYW?alt=media&token=0b0d2526-4da6-48dd-ad57-6b2a373ade86",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_lR2UetqYW",
        id: "GMmodqC89OkINjPi6PQy",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_cI3-tDnKc?alt=media&token=c2c576a8-3e3b-4c8e-bc00-3b8eb3ca071a",
        order: 4.5,
        title: "마케팅서비스과",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_cI3-tDnKc",
        id: "IDwsQEijUkTDSm8rbdDG",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_YpxNVbMm7?alt=media&token=9288485c-a84e-494c-b414-d244a793785b",
        title: "수업사진1(마케팅서비스과)",
        order: 11,
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_YpxNVbMm7",
        id: "O24Pmk1svzKTLydUmzBu",
      },
      {
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_-9OsXiacI",
        title: "유통관리실",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_-9OsXiacI?alt=media&token=50edd0cc-5e84-4e20-8ab7-5b5c982058ec",
        order: 22,
        id: "OdKmkPFreoW8uiwt0his",
      },
      {
        title: "수업사진2(금융서비스과)",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_JDJh7qc6I",
        order: 8,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_JDJh7qc6I?alt=media&token=99705585-41e6-4c91-8b2e-f32e09108585",
        id: "PJyvdzr2UEhJOkqH8nI3",
      },
      {
        title: "수업사진3(디저트서비스과)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_2z6vXNr2N?alt=media&token=78f6c365-e1d6-4cf9-ab39-377d5579d4ba",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_2z6vXNr2N",
        order: 16,
        id: "Ptm3AVrUYDeeDHgBnYJP",
      },
      {
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_l_KqZ2j-a",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_l_KqZ2j-a?alt=media&token=0cb934a6-47ee-4e75-b7a2-3325d46658c4",
        title: "매장판매실",
        order: 5,
        id: "SkrDV08IRVIB1VmmRIj1",
      },
      {
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_PiLnrMBU6",
        title: "수업사진2(마케팅서비스과)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_PiLnrMBU6?alt=media&token=3b7f766e-bb0e-4b6c-986c-bcf77f7ebfc6",
        order: 12,
        id: "UaJ8IFXDtLDtNF8YDyaJ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_MBKZ3Ora0?alt=media&token=77660a0c-4abd-4953-9828-485d3f3d7929",
        title: "수업사진1(디저트서비스과)",
        order: 14,
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_MBKZ3Ora0",
        id: "XpiiCHrJgX5WG5g8hpoG",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_lxHHZo_Pd?alt=media&token=a1bba221-fbd0-42ca-bcb2-8006d376d0df",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_lxHHZo_Pd",
        order: 21,
        title: "수업사진7(디저트서비스과)",
        id: "agoh4bChKkdjDzaBDrNk",
      },
      {
        order: 2,
        title: "디저트서비스과",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_RFPV0TOTQ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_RFPV0TOTQ?alt=media&token=83aa4049-a5c3-4999-92d2-cb0bcdf84479",
        id: "eV3XUsvJ8PWXJqDFpzs9",
      },
      {
        title: "수업사진4(디저트서비스과)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_VCGFeklwh?alt=media&token=81dc77f6-e048-4d97-8970-6647348b2f8e",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_VCGFeklwh",
        order: 17,
        id: "il8jp4suDnHIjARSGxYQ",
      },
      {
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_hQT3iH4EQ",
        title: "수업사진3(금융서비스과)",
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_hQT3iH4EQ?alt=media&token=f0ab1eaa-a647-43fc-aaf2-36e37db07c4a",
        id: "jNMLRk2AqqYG72T6I6Q0",
      },
      {
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_U7u_yeLDg",
        title: "바텐더실 & 바리스타실",
        order: 6,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_U7u_yeLDg?alt=media&token=aea5c5a8-a94d-46eb-827c-c901d3850a17",
        id: "jrZmKn03BfazsiUUobCs",
      },
      {
        title: "수업사진4(금융서비스과)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_g481aOSFP?alt=media&token=fa50a3a0-f854-4a01-99ad-d8053f5f0ae0",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_g481aOSFP",
        order: 10,
        id: "lM74PHT2lchkxWLbYIHL",
      },
      {
        title: "수업사진2(디저트서비스과)",
        order: 15,
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_6uyUraHa0",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_6uyUraHa0?alt=media&token=0cc22aa0-462c-402e-8f29-e8e908dbd2d4",
        id: "oKDP896zo9JngPSgPg83",
      },
      {
        order: 5.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_LYs_67IQL?alt=media&token=b1e0bff7-6d60-4995-b280-5f2ef81f9d57",
        title: "디저트서비스과",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_LYs_67IQL",
        id: "pDBT2G8gcJCHrglPg0aX",
      },
      {
        order: 18,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_Iww6L0FgP?alt=media&token=a279b9ed-9fff-4bec-ad0e-d1d29ab97ef4",
        path: "images/BOOTH_IMG_3XCIlnPNlHT8TgUVoORoqtUCmwC3_Iww6L0FgP",
        title: "수업사진5(디저트서비스과)",
        id: "zO1m1vlZz9KaHCz0wGkL",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/1wMD72YuRaY",
        title: "2021학년도 입학생들을 위한 계성여자고등학교 홍보영상",
        order: 3,
        id: "0pyErOpQ8gGq7RHwQLrB",
      },
      {
        order: 1.1767578125,
        url: "https://youtu.be/GFC-BYecNzo",
        title: "2022년 계성 가자니아 1탄",
        id: "1tXQlf5nxk0zwQHlYZji",
      },
      {
        url: "https://www.youtube.com/watch?v=ktYJ3gb9DZ8",
        title: "2022학년도 계성여자고등학교 홍보영상",
        order: 0.3125,
        id: "4mcDHUvBYYQYHqSmhh7y",
      },
      {
        title: "부모님과함께하는창업캠프",
        url: "https://youtu.be/rrcuRAP_k1A",
        order: 12,
        id: "6Xqgq3Md0CXduNb5GcwH",
      },
      {
        title: "2022년 계성여고 브이로그(금융서비스과편)",
        url: "https://youtu.be/UcLnNnnAzhk",
        order: 0.390625,
        id: "7KnBbzkfn8jYCiHA6DcC",
      },
      {
        order: 8.75,
        url: "https://youtu.be/55eQKr3UaJk",
        title: "용쿠가 계성여고에 떴다!!!",
        id: "FlCb79UGmeJkiZbL6c5F",
      },
      {
        order: 3.25,
        url: "https://www.youtube.com/watch?v=TEDdg5maawY&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=3",
        title: "계성여자고등학교 공연",
        id: "MvyiggIwNzzwPJM6cbfi",
      },
      {
        title: "계성여고의 혁신을 위한 선생님들의 노력",
        order: 3.5,
        url: "https://youtu.be/UqVbmypd-jg",
        id: "R1y4mom7hvphQKAiCN8C",
      },
      {
        title: "메인영상",
        url: "https://youtu.be/8I7ZClkiTcI",
        order: 1.375,
        id: "YFp8Xw4lA8EerK7mySDe",
      },
      {
        order: 1.5,
        url: "https://youtu.be/Z4O3IRcPivw",
        title: "새로운 실습실 소개 영상",
        id: "ZH7Lz7eJeBQbWmVvoqtT",
      },
      {
        title: "동물 초콜렛 만들기",
        url: "https://youtu.be/MdSMFTm40Aw",
        order: 1.1484375,
        id: "ZHsEYJndfmHO4x43fZWg",
      },
      {
        url: "https://youtu.be/8cw6T13NTRA",
        title: "2022년 계성여고 브이로그(디저트서비스과편)",
        order: 0.921875,
        id: "gJCwGZBzzL2BsG41YRno",
      },
      {
        title: "2020학년도 계성여자고등학교 중학생 언택트 직업체험 키트 소개 및 접수방법 안내",
        url: "https://youtu.be/hLGBRR1Ka88",
        order: 4,
        id: "qRgdNmixmeGR0vNfUrqT",
      },
      {
        title: "2022년 계성 가자니아 2탄",
        url: "https://youtu.be/mW3ssaG11dw",
        order: 1.205078125,
        id: "rJD24n1OyldDIYDv7mJz",
      },
      {
        title: "부산광역시 교육청 캠페인 광고 영상 계성여고 촬영 현장 스케치",
        url: "https://youtu.be/z9xfOIKNdTo",
        order: 3.75,
        id: "tH33pa53mRNJkTXOZtei",
      },
      {
        order: 0.46875,
        url: "https://youtu.be/OF1frYv6p8k",
        title: "2022년 계성여고 브이로그(마케팅서비스과)",
        id: "vP0BtQFezOmz3ZyaG4do",
      },
      {
        url: "https://youtu.be/OqV5GeTQozI",
        order: 8.5,
        title: "꿈빛파티시엘 주제곡 춤 추는 계성둥이들💚",
        id: "zHHeAAqXN0ggHpxVs6zr",
      },
    ],
  },
  "5wUMAW5Z": {
    id: "5wUMAW5Z",
    documents: [
      {
        title: "2022학년도 부산자동차고등학교 취업현황",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FcgVIzntlq_%EC%A7%81%EC%97%85%EB%B0%95%EB%9E%8C%ED%9A%8C%EC%B7%A8%EC%97%85%ED%98%84%ED%99%A9(1920_1)%EC%88%98%EC%A0%95-1.jpg?alt=media&token=63b7fe90-e983-4243-b105-3aa38332ef28",
        order: 2,
        path: "documents/cgVIzntlq_직업박람회취업현황(1920_1)수정-1.jpg",
        id: "FMow4bICReTLeuzjiKyZ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FPiz73ueW8_2023%20%EC%9E%90%EB%8F%99%EC%B0%A8%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%A0%84%ED%98%95%EC%9A%94%EA%B0%951.pdf?alt=media&token=1ec16e1c-d365-4825-a75f-bee449911d7c",
        path: "documents/Piz73ueW8_2023 자동차고등학교 전형요강1.pdf",
        order: 1,
        title: "2023 부산자동차고등학교 입학전형요강",
        id: "RW2lna6yYYHacOO5apPI",
      },
      {
        path: "documents/81NVCNyIk_직업박람회학과소개(950_4)글로벌.jpg",
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F81NVCNyIk_%EC%A7%81%EC%97%85%EB%B0%95%EB%9E%8C%ED%9A%8C%ED%95%99%EA%B3%BC%EC%86%8C%EA%B0%9C(950_4)%EA%B8%80%EB%A1%9C%EB%B2%8C.jpg?alt=media&token=e1cf9214-e589-4432-acb7-e012330b3afd",
        title: "2022학년도 글로벌 기술인재양성 프로그램",
        id: "ZGSmOSCTMSW1i00onP4L",
      },
    ],
    images: [
      {
        path: "images/BOOTH_IMG_5wUMAW5Z_GFHjC4Ht8",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_GFHjC4Ht8?alt=media&token=205bc6cb-d6d1-441c-b06e-7e273e25213f",
        order: 4.984375,
        title: "수업시간(세계지도퍼즐 맞추기)",
        id: "469kDIcA41EtpF1wvK7t",
      },
      {
        order: 4.75,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_LxSNFvPpY?alt=media&token=5ed7b88e-e131-47cf-87f8-40415235e6ad",
        path: "images/BOOTH_IMG_5wUMAW5Z_LxSNFvPpY",
        title: "2022학년도 전공과정편성설명회",
        id: "78b2pVHcIE9c78ugPAKD",
      },
      {
        path: "images/BOOTH_IMG_5wUMAW5Z_d728jq8DT",
        title: "제13회  전국자동차정비기능경진대회",
        order: 1.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_d728jq8DT?alt=media&token=a40bf20c-c6ba-4f80-bd6d-0c4333c731b5",
        id: "DJ0tMcuek6CrfS6qFAdY",
      },
      {
        title: "2022년 부산국제모터쇼 관람",
        order: 1.875,
        path: "images/BOOTH_IMG_5wUMAW5Z_dXsMsi3Qe",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_dXsMsi3Qe?alt=media&token=da6d1438-e02d-49c4-b2c9-eb15702430e6",
        id: "FhwAEJ8R4ALSWKOXRTvp",
      },
      {
        title: "부산자동차고 전경",
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_kRngMzQdl?alt=media&token=1d7e6ab7-9583-4be8-9a7d-d5017bac2d66",
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_kRngMzQdl",
        id: "JXJWnwn0GYrLUpjumpji",
      },
      {
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_hrGQQEc_i",
        order: 2,
        title: "부품가공과정 실습 장면",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_hrGQQEc_i?alt=media&token=6ea0fcc3-7ccf-46ac-9031-ced7b5eeabb0",
        id: "TkPEH7VKFnC6GO3P9hN6",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_dCIZoz5Dc?alt=media&token=5671a675-4abb-4d10-ba90-e1b7ca34a9ad",
        title: "프로젝트 수업 활동",
        order: 8,
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_dCIZoz5Dc",
        id: "WvSmNIDNBU4V6POb1dhI",
      },
      {
        order: 1.9375,
        path: "images/BOOTH_IMG_5wUMAW5Z_Lq-PqEMDG",
        title: "2022년 부산국제모터쇼 관람",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_Lq-PqEMDG?alt=media&token=2648c1a3-39c0-4d4c-9878-e7ba30b3ef9d",
        id: "XHmBWJ19JEj68hMFwZKL",
      },
      {
        title: "산업기사자격 취득 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_BsWSjJ9CH?alt=media&token=39665810-e12f-42f5-8652-5ed1c1ecf7d5",
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_BsWSjJ9CH",
        order: 5,
        id: "XSjSyY3KZa9HsXBMayxG",
      },
      {
        order: 4.5,
        title: "2022학년도 전공과정편성설명회",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_ANjWI2GsM?alt=media&token=5dbf0869-6e42-496b-8ae4-36e93030d82b",
        path: "images/BOOTH_IMG_5wUMAW5Z_ANjWI2GsM",
        id: "deA15KxEB97GfAxhHYXk",
      },
      {
        title: "2022학년도 전공과정편성설명회",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_nKbD0Ga-m?alt=media&token=1a5d1770-ff79-422c-a7b5-f1f42db505c9",
        order: 4.875,
        path: "images/BOOTH_IMG_5wUMAW5Z_nKbD0Ga-m",
        id: "f4rPrrfA1d1ro3CO5Rq2",
      },
      {
        order: 1.75,
        path: "images/BOOTH_IMG_5wUMAW5Z_iZc_BU7Xj",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_iZc_BU7Xj?alt=media&token=f079832f-1ffa-47d6-849d-4e867bb5da67",
        title: "2022년 지방기능경기대회 수상",
        id: "jFXCEHbcDDROjfpPqQKp",
      },
      {
        order: 4.96875,
        title: "수업시간(세계지도퍼즐 맞추기)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_BVot4Ol6i?alt=media&token=1ad1f3a5-1a66-429f-b46b-28d171cbaf49",
        path: "images/BOOTH_IMG_5wUMAW5Z_BVot4Ol6i",
        id: "jGSWI1L8wvEEo50RvgdN",
      },
      {
        order: 4,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_XVXFbqZ5k?alt=media&token=82b5a2b4-5520-4ea2-aa75-9a75438583aa",
        title: "자동차정비과정 실습 장면",
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_XVXFbqZ5k",
        id: "mQDyDKGBkS7tPIqbTAbv",
      },
      {
        path: "images/BOOTH_IMG_5wUMAW5Z_07yTTEUxA",
        title: "2022년 부산국제모터쇼 관람",
        order: 1.984375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_07yTTEUxA?alt=media&token=64f70b8f-b0c2-488e-b091-1dc8d88fa2bb",
        id: "nTOeMnb6dpBJLjNQ5x55",
      },
      {
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_1vDvhLDGo",
        title: "생산자동화과정 실습 장면",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_1vDvhLDGo?alt=media&token=5f828c9a-ba8b-4901-907e-bb8dd67d3311",
        order: 3,
        id: "pZqQvozJOytiDRdomZts",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5Z_I6VuvAGc4?alt=media&token=2a596f94-b924-43f6-8234-cc2f0a76c491",
        title: "2022년 부산국제모터쇼 관람",
        order: 1.9921875,
        path: "images/BOOTH_IMG_5wUMAW5Z_I6VuvAGc4",
        id: "tDv0xEIJtPDPIVITYOUk",
      },
      {
        title: "부산자동차고 해외연수 활동",
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_sO8bvfwzs",
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_sO8bvfwzs?alt=media&token=577beac6-6af6-4d94-a427-b28310ca81ce",
        id: "wJ3YHxKB9CbW3wcyOBs0",
      },
      {
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_dC_x9KVBx",
        title: "부산자동차고 아우스빌둥 전국 최다합격",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_dC_x9KVBx?alt=media&token=fe4c6593-2096-4c68-83a9-4e5da04ad8fe",
        order: 6,
        id: "xMmC6rJPJEaN27V5ERW0",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_CC0UjVw2_?alt=media&token=229d34dd-3a17-47da-878c-5fe943f302fb",
        path: "images/BOOTH_IMG_5wUMAW5ZKpcjEliZGIoh3PmUGts2_CC0UjVw2_",
        order: 4.9375,
        title: "부산자동차고 입학식",
        id: "yelXsCy8MYhxEAgoChK1",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/EFn0daSBmig",
        order: 3,
        title: "부산자동차고등학교 각 학과별 실습영상",
        id: "0cuNSnpj9zF4Bl4N0NqR",
      },
      {
        order: 0.3125,
        title: "메인영상",
        url: "https://www.youtube.com/watch?time_continue=126&v=rBYhsIOoolM&feature=emb_logo",
        id: "0rF8WdmKwvCrndYDhV2E",
      },
      {
        title: "2023학년 부산자동차고 신입생 입학전형",
        order: 1.8978271484375,
        url: " https://youtu.be/ENp8BRqisM4",
        id: "3k5xzYNB0KTgg0auj5Ez",
      },
      {
        url: "https://youtu.be/AlB7fcTKTs8",
        title: "부산자동차고 홍보영상",
        order: 1.15625,
        id: "GDYd4BEOomkAxjHqvaSc",
      },
      {
        order: 1.87310791015625,
        title: "2022학년도 대기업 합격학생 인터뷰(3학년)",
        url: "https://youtu.be/ZcjxjpQJ-aw",
        id: "KfxGM3enpHCOkiwjnomv",
      },
      {
        order: 3.5,
        title: "생산자동화과 3학년 8조 프로젝트 작품",
        url: "https://youtu.be/1ny8ZcXIWF4",
        id: "Q37lcXv6f7hKGXYWV95w",
      },
      {
        order: 6,
        title: "부산자동차고 자동차과 자차제작 과정 설명 및 주행영상",
        url: "https://youtu.be/cxDggsTKpLA",
        id: "Qcb3pBsHhcA7dKWDksu2",
      },
      {
        title: "2022학년도 3학년 프로젝트 영상",
        url: "https://youtu.be/hM_h7N5G8nc",
        order: 1.848388671875,
        id: "Uc8OoK6OHu0SQ7Ay1VK8",
      },
      {
        title: "2학년 정oo학생이 촬영, 편집한 영상",
        order: 2,
        url: "https://youtu.be/zfsRFwszGyY",
        id: "WeAYNMRynXAhuleWXVjd",
      },
      {
        url: "https://youtu.be/4VojxhSdmbA",
        title: "Hit and Run(부자고 야구동아리)",
        order: 1.947265625,
        id: "eHsjRZ1eOP4JgX9BgJC7",
      },
      {
        order: 1.74951171875,
        url: "https://youtu.be/67O7LSAwASA",
        title: "2022학년도 전국기능경기대회 수상에 빛나는 부자고 기능인",
        id: "qSTPkfONcxdZbiku00bv",
      },
      {
        order: 1.885467529296875,
        url: "https://youtu.be/n8vwmv8yVrI",
        title: "2022학년도 대기업 합격학생 인터뷰(2학년)",
        id: "qz3eOUKBccPmv2s1mzMy",
      },
      {
        title: "2023학년도 미리보는고교생활 부산자동차고",
        url: "https://youtu.be/re0vBAunb2U",
        order: 1.92254638671875,
        id: "wl6Inf7zB5wXwkffBp9j",
      },
    ],
  },
  "66ND5d0w": {
    id: "66ND5d0w",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FERsrqr1uq_2023%ED%95%99%EB%85%84%EB%8F%84%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%EB%AA%A8%EC%A7%91%20%ED%8F%AC%EC%8A%A4%ED%84%B0.jpg?alt=media&token=54a7880c-9c77-48b5-bdbd-b046e7332ab4",
        order: 1,
        path: "documents/ERsrqr1uq_2023학년도 신입생 모집 포스터.jpg",
        title: "2023학년도 경성전자고등학교 입학안내",
        id: "evviVigyP1SEGLUIVjIv",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_j3xs9w09G?alt=media&token=6e61a185-bcec-4c3f-87d5-f828523b3669",
        order: 39,
        title: "뉴질랜드 왕가레이 학교와 국제교류협약식",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_j3xs9w09G",
        id: "0NsCkDWHbLOxW8yDetTR",
      },
      {
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_5altS5BCT",
        title: "SW선도학교 - 소프트웨어 박람회 출품",
        order: 23,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_5altS5BCT?alt=media&token=52795bc5-430b-48c6-8eb9-9cf58b039089",
        id: "0gtnK4yKM0wSzzYD1fgH",
      },
      {
        title: " 소프트웨어야 놀자! - 아두이노 1팀 1프로젝트",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_g0IY7PoqQ?alt=media&token=a51567fb-fd14-4070-8ec9-295b6f3e2dd7",
        order: 31,
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_g0IY7PoqQ",
        id: "4BB9mzYN5Ryx7NMWIXOZ",
      },
      {
        order: 16,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_p9nus8hr9?alt=media&token=311f280e-0ed2-4e8a-b324-2df0c902446e",
        title: "비즈쿨 창업동아리 발대식",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_p9nus8hr9",
        id: "4q9oO6AJO3zv7Kht279U",
      },
      {
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_Wae4Rrm9v",
        title: "2020 부산 청소년 비즈쿨 크라우드 펀딩 창업아이디어 경진대회 최우수상",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_Wae4Rrm9v?alt=media&token=b2aa8056-9b05-4660-af29-b193c5bf9f37",
        order: 20,
        id: "8ZHmOVGQJmllHjv3nLOA",
      },
      {
        title: "호주 글로벌 현장학습 2년 연속 합격",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_YRnaNz_-9?alt=media&token=4c9f2942-2dca-4d54-89a0-14e73b71b2e5",
        order: 30,
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_YRnaNz_-9",
        id: "9vpzQRcJjcLhsb8kaEXp",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_KqyCdqbPX?alt=media&token=77cf40ab-93e6-492d-8e0d-5b57b2eb9521",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_KqyCdqbPX",
        order: 33,
        title: "AI 개발대회 고등부 대상",
        id: "DFdEgNSU1f0ZPaSkEBr9",
      },
      {
        order: 6,
        title: "학교에서 하는 다양한 '전문 동아리 활동!'",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_HkEuBwzlN",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_HkEuBwzlN?alt=media&token=565a737c-163e-42c6-8016-7e0d9dcad66d",
        id: "G6VMSqK3JSUPt1l2DR9R",
      },
      {
        order: 22,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2__q92u8PzU?alt=media&token=fdc9848d-7879-4477-916b-5562c3a5bb1c",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2__q92u8PzU",
        title: " 2020 부산 청소년 비즈쿨 크라우드 펀딩 창업아이디어 경진대회 시상식 - 최우수상",
        id: "HCbm5YSMDb2XBo2THH9y",
      },
      {
        order: 32,
        title: "부산문화방송 청소년상 대상 (2관왕)",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_NsW0Bkpor",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_NsW0Bkpor?alt=media&token=472b6b04-55d0-4b68-8af8-3d58edc11d11",
        id: "IRxailgKveAuVnXNgFQb",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_6D1bdUJy0?alt=media&token=ada7e8bb-5043-4ef5-a031-d2276bfa91a7",
        order: 7,
        title: "전기제어과 공압제어실습",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_6D1bdUJy0",
        id: "LFNp3mDBjKPYrMWekdDs",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_9NQuD5mT4?alt=media&token=5a166324-4d02-413c-8e95-f857eaaf1c0e",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_9NQuD5mT4",
        title: "경성전자고 독서교육활동 프로그램 - 문학기행",
        order: 36,
        id: "MS5CG5Yhr2QV1HggPciE",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_1u1fQyynZ?alt=media&token=6fe8a877-a0a1-46bf-b133-8cc733df91e2",
        title: "동아대학교 청소년 창업경진대회 그랑프리상",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_1u1fQyynZ",
        order: 15,
        id: "MmLGk731GloBhUZPEuSQ",
      },
      {
        order: 25,
        title: "도제학교 내선공사과정 수료식",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_LH0XmN6nB?alt=media&token=6e66b918-9e4c-4235-be2b-ad1401e278b4",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_LH0XmN6nB",
        id: "N30e2jw3UeuUm7XFnm1s",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_KN8AhxPJr?alt=media&token=7d8437e5-bf4b-4e32-91c9-f3e25f800de0",
        order: 9,
        title: "학생들의 힘찬 하루를 응원하는 하이파이브 데이! (High Five Day!)",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_KN8AhxPJr",
        id: "NSzK289kYiptZvOoUjnh",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_ks1dME-S_?alt=media&token=6609ce11-693c-4647-8c03-e3438d9f8934",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_ks1dME-S_",
        order: 37,
        title: "경성전자고 독서교육활동 프로그램 - 문학기행",
        id: "PAxzEyFs8iVj0oxm7VEo",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_9i1s0oL5C?alt=media&token=a1b5ce91-1bb8-422b-a66c-3343705f1e89",
        title: "학생들의 힘찬 하루를 응원하는 하이파이브 데이! (High Five Day!)",
        order: 12,
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_9i1s0oL5C",
        id: "PqOeo1s8L0uKgfC3Huw6",
      },
      {
        title: "동남권 비즈쿨 창업경진대회 대상",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_L4RSq0XmG",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_L4RSq0XmG?alt=media&token=6772f3b2-cf2d-4b27-983e-4d0c1ca9b78f",
        order: 18,
        id: "S1NtHWv2lgZ3Wd1bnzUe",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_c8YSJig4N?alt=media&token=548cd992-b435-4b4c-beab-481d07257585",
        title: "대한민국 영어말하기대회 최우수상 시상",
        order: 29,
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_c8YSJig4N",
        id: "SlgLX4fe96lbBMnlFuY1",
      },
      {
        title: "학생들의 힘찬 하루를 응원하는 하이파이브 데이! (High Five Day!)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_Fzjj1Z_DW?alt=media&token=a98a31f3-b14a-4d83-8bbc-89570374706b",
        order: 11,
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_Fzjj1Z_DW",
        id: "TOlwL4PTaTzTbkwxBy81",
      },
      {
        order: 34,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_fYnIUQub-?alt=media&token=fd1130c5-a7dc-4ff1-bb6f-185e9369587b",
        title: "제5회 기업가정신 - 전국 콘텐츠 공모전 장관상 수상",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_fYnIUQub-",
        id: "TcYD7cQraAA2GKFwNrPQ",
      },
      {
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_C82UFHtas",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_C82UFHtas?alt=media&token=0aaf601c-2f3a-4072-8cb5-b1ac393bd02f",
        title: "전기제어과",
        order: 1.75,
        id: "Tf7UVv6ULwBJ8IWm8XB4",
      },
      {
        title: "동아대학교 창업아이디어 아카데미",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_IxN9kYBUg?alt=media&token=60a14dc8-e12a-4bc5-88e8-32adc6df1fcc",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_IxN9kYBUg",
        order: 31,
        id: "VoKkq3tHEpMgvBlMW8Gq",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_HQR0jvUQj?alt=media&token=e63d224c-58f3-47d7-9be5-6d42c4cc1482",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_HQR0jvUQj",
        title: "전자제어과",
        order: 2,
        id: "e7zgwqYIUkODOzXWrJdQ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_ZTGVYOXt7?alt=media&token=6e567163-bd85-4d01-a2ac-2ffbcc52dc14",
        order: 27,
        title: '"마음을 하나로🙂" - 경성학생회 간부수련활동',
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_ZTGVYOXt7",
        id: "fJ4qbu4LdRqeFWJbgO86",
      },
      {
        order: 14,
        title: "경성전자고등학교 소프트웨어박람회 선도학교",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_kmIFyP3iq",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_kmIFyP3iq?alt=media&token=ec1b9b12-fa26-4daa-ba1e-fa24ef9bb42a",
        id: "gKcgrUqM1t0WrB7hL8wV",
      },
      {
        order: 26,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_37QdBb9MZ?alt=media&token=1c698030-5498-462b-8678-ef873140278a",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_37QdBb9MZ",
        title: "학교의 리더! 경성학생회 간부수련활동",
        id: "hNKUUl1hal2Xjv8xFpqn",
      },
      {
        order: 35,
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_zHbtspVbH",
        title: "경성전자고 독서교육활동 프로그램 - 문학기행",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_zHbtspVbH?alt=media&token=b24df638-9d3a-4944-9496-e1ae1265ee95",
        id: "hdo27W40Zdnf1OeS7DAN",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_66ND5d0w_uKGfj86sc?alt=media&token=1bc82ec3-e3ce-4c33-a6e3-6d31aad76882",
        order: 0.875,
        title: "2023학년도 경성전자고등학교 입학안내",
        path: "images/BOOTH_IMG_66ND5d0w_uKGfj86sc",
        id: "iylpuIsDpll4BAJOchBi",
      },
      {
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_7XPlwYH1h",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_7XPlwYH1h?alt=media&token=c43323d5-e70c-49a9-91e1-77149c7d8a20",
        title: " 전자제어과 생산자동화실습",
        order: 8.25,
        id: "kDuTHadO9MmPmwdd2MqH",
      },
      {
        order: 13,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_ySvM6cMSZ?alt=media&token=27f35318-c762-4416-8c46-d852dee1334b",
        title: "인공지능기반 교육 청소년 캠프 - 부산대학교 인공지능 경진대회 1위",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_ySvM6cMSZ",
        id: "lq4HBXiV1JGwiK2lrCfC",
      },
      {
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_IQi1FszUb",
        title: " 2020 부산 청소년 비즈쿨 크라우드 펀딩 창업아이디어 경진대회 시상식 - 최우수상",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_IQi1FszUb?alt=media&token=98a8cab2-89df-4582-9091-da9650f015eb",
        order: 21,
        id: "mPZRMbGn2vCn9zVZeo5w",
      },
      {
        title: "경성전자고 독서교육활동 프로그램 - 문학기행",
        order: 38,
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_kBL-OaMas",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_kBL-OaMas?alt=media&token=9a89c52d-cb56-4835-91e7-c0b5a2fe15f2",
        id: "mxpewdZNkBamESDBmgNO",
      },
      {
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_oNAM3ScdI",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_oNAM3ScdI?alt=media&token=e1880a15-f639-44ef-a909-88484f19e6bb",
        title: "학생들의 힘찬 하루를 응원하는 하이파이브 데이! (High Five Day!)",
        order: 10,
        id: "q0GaF71e2IZCfvqGXw8G",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_f9BkhK7NJ?alt=media&token=88ee013d-8e52-43a5-a3f8-ea7759ac5c65",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_f9BkhK7NJ",
        title: "경성 한마음축제 행사",
        order: 19,
        id: "saV8UWr3Zo2FCta7vOLA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_Ks002kywq?alt=media&token=d39a31ac-0ec4-462e-a3ab-1b40eb3accf8",
        order: 24,
        title: "SW교육 - 선도학교 부산 글로벌 스마트시티 체험활동",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_Ks002kywq",
        id: "w83ZDjN2yopeu1uzWXKg",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_rijZg3xfa?alt=media&token=777f376e-0dbe-443a-ac6d-1b8a1cad28ba",
        title: "청소년 비즈쿨 창업동아리 리더쉽캠프",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_rijZg3xfa",
        order: 17,
        id: "xrn4qrNOVnu9sDt9fXpE",
      },
      {
        title: "해외취업 활동",
        order: 40,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_602mZXoJH?alt=media&token=bb73380e-3107-4e90-869d-26da180c76b1",
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_602mZXoJH",
        id: "z52fJMuKicyjqLwN1kGa",
      },
      {
        path: "images/BOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_J8HP6XbVy",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_66ND5d0wvHVW7IkgYVPtGg30ptp2_J8HP6XbVy?alt=media&token=0d97eda0-0565-438c-a2cb-dbd3b5ab35d9",
        title: " 경성전자고 특색사업",
        order: 5,
        id: "zY2AyjQBQaNo0p8oMO0i",
      },
    ],
    videos: [
      {
        order: 8,
        url: "https://youtu.be/WNY--mKPhs8",
        title: "[경성전자고 전기제어과 홍보영상]요즘 어떤 과가 제일 잘나감? 경성전자고 전기제어과지!😎",
        id: "27S4NlytXyNjoVe0S2lJ",
      },
      {
        url: "https://youtu.be/PSq8Fn_ancU",
        order: 4,
        title: '"내일을 향해 날아라! 경성전자고 드론동아리를 소개합니다😎"',
        id: "3YXkWnf4VpZo5P7OZQIa",
      },
      {
        order: 0.25,
        url: "https://youtu.be/jKZQLLA6D_4",
        title: "메인 영상",
        id: "40hPEBkf9DmxgS11I5rR",
      },
      {
        url: "https://www.youtube.com/watch?v=4Nxqmzrdl9g",
        title: "알로이시오 기지 1968 현장체험학습",
        order: 2.625,
        id: "7ayCLZ5lhBOSbaEDIc99",
      },
      {
        url: "https://youtu.be/boji-fpuj5E",
        title: "부산대학교와 소프트웨어교육 MOU 체결하다! 소프트웨어 교육, 화이팅!",
        order: 3,
        id: "B4UbkCokIgW67YUzkJhT",
      },
      {
        url: "https://www.youtube.com/watch?v=2XYX5fEH-es",
        title: "경성전자고등학교 10월 하이파이브데이",
        order: 2.703125,
        id: "DYZVEP8gy6dWdEx0VkF5",
      },
      {
        order: 2.7109375,
        url: "https://www.youtube.com/watch?v=IzhYiDW4RIk",
        title: "경성전자고등학교 5월 현장체험학습",
        id: "F8o2d8h9TU2mJacMeZu5",
      },
      {
        url: "https://www.youtube.com/watch?v=eY2xl5LQyAs",
        title: "2022 미리보는 고교생활",
        order: 2.0390625,
        id: "FNCJa4W9ruFWMSLVNUgu",
      },
      {
        order: 2.390625,
        title: "진로체험활동 - LED 무드등 만들기, 네온싸인 디자인",
        url: "https://www.youtube.com/watch?v=taLqK8Nv9Ho",
        id: "I7o3fJGAW07g6XdjqeUD",
      },
      {
        title: "가수 육중완님이 우리학교 출신이라구? 🤭 우리를 위해 응원영상까지〰️😍",
        order: 2,
        url: "https://youtu.be/2C8FnM5YwlU",
        id: "IjrRmAqzG4GGzf1quCNn",
      },
      {
        title: "2023학년도 입학안내",
        url: "https://www.youtube.com/watch?v=EcVyE9r8uEA",
        order: 2.078125,
        id: "Jww8puMUoXJXe8brxuGc",
      },
      {
        title: "[경성전자고 홍보영상] 경성전자고 내 후배할래? 반가워👋 경성전자고는 처음이지? 😄",
        order: 7,
        url: "https://youtu.be/2ew2UNn57CI",
        id: "Sm80xzYoOFqcDs0JwcfM",
      },
      {
        order: 10,
        url: "https://youtu.be/SSGHdKihGRs",
        title: "[경성전자고 전자제어과 홍보영상] 전자제어과의 모든것을 알려드립니다~ 어서오세요😉",
        id: "UOXCMkuAgwSTO01xvoF1",
      },
      {
        title: "경성전자고등학교 4월 하이파이브데이",
        url: "https://www.youtube.com/watch?v=Ywm3gtnnSXY",
        order: 2.71875,
        id: "YU8tg3ksVkdEKrk0s7id",
      },
      {
        order: 6,
        url: "https://youtu.be/VtUNPaB6LDQ",
        title: "고3개학 브이로그 / 🏫경성전자고 온-오프 개학모습은 어땠을까❓ / 특성화고 개학",
        id: "aMijd7w4np53a0nWOx9L",
      },
      {
        url: "https://youtu.be/UxdJvRCfcpo",
        order: 5,
        title: "[경성전자고등학교]우리학교의 전기과 전자과를 알립니다! 많은기술을 배울 수 있는!",
        id: "asVbDdle47TCSIDIiAHM",
      },
      {
        order: 11,
        title: "동남권 비즈쿨 창업경진대회 대상★ | 난 CEO! 창업을 목표로 하는 학생들 주목!!😎",
        url: "https://youtu.be/ti-Ipwohth8",
        id: "bvEp7ro8fHsHK3vSSY8n",
      },
      {
        url: "https://www.youtube.com/watch?v=NukdQaBtPT0",
        order: 0.5,
        title: "띵동! AFC챔피언 '배명호', 선배님으로부터 영상메세지가 도착했습니다!♥",
        id: "coEMikvu0gBftWjHv2yn",
      },
      {
        order: 2.859375,
        url: "https://www.youtube.com/watch?v=7NdoAufqqyQ",
        title: "2021 경성전자고 청소년 비즈쿨 활동",
        id: "fMJ0rOYGVoypft0MZdEn",
      },
      {
        title: "2022 바리스타 동아리",
        url: "https://www.youtube.com/watch?v=vHKUqm_yU8A",
        order: 4.5,
        id: "rXPa4JIrCV1hFR0i8NFU",
      },
      {
        title: "2022 겨울방학 특강 벽화그리기",
        url: "https://youtu.be/KSQFxHRdZRM",
        order: 12,
        id: "xaSYglVkl76k97MwAdlk",
      },
      {
        url: "https://youtu.be/eWHxqS006DI",
        title: "경성의 도제반을 소개합니다!🥰 | 도제반 Vlog",
        order: 10,
        id: "ym9UUM9YYUKTmvguIGMy",
      },
    ],
  },
  "6V5KkTRs": {
    id: "6V5KkTRs",
    documents: [],
    images: [
      {
        order: 4,
        title: "디자인과",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_6V5KkTRs_o0P09Fr_o?alt=media&token=8cd53c29-e3f8-4e83-ba8d-f8eace4f3fab",
        path: "images/BOOTH_IMG_6V5KkTRs_o0P09Fr_o",
        id: "FOZ2ZTSsVSLoFx5DEP69",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_6V5KkTRs_TxtTQzyuw?alt=media&token=616edd4c-39c5-487f-a092-86c0daac192f",
        order: 3,
        path: "images/BOOTH_IMG_6V5KkTRs_TxtTQzyuw",
        title: "미용과",
        id: "Maa1CYbtjGOOWjQpObiy",
      },
      {
        path: "images/BOOTH_IMG_6V5KkTRs_eTy6awaU4",
        order: 1.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_6V5KkTRs_eTy6awaU4?alt=media&token=c9613f8e-19e2-45db-9593-dfb7e250e540",
        title: "2023학년도 신입생 입학전형 요강",
        id: "PBdx71cA2Vg0XlNfKzQD",
      },
      {
        order: 5,
        title: "배정미래고 오시는길",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_6V5KkTRsrHY0Ak6OdBkxpRhD6YA2_LrIhM29mW?alt=media&token=d3ded9fc-82e8-4022-90e1-1e6af3d73c06",
        path: "images/BOOTH_IMG_6V5KkTRsrHY0Ak6OdBkxpRhD6YA2_LrIhM29mW",
        id: "ld7FxWCyNhOvSCQnnYuB",
      },
      {
        path: "images/BOOTH_IMG_6V5KkTRs_QzT0ImDPs",
        order: 4.5,
        title: "경영과",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_6V5KkTRs_QzT0ImDPs?alt=media&token=d40a53cf-b7f5-46d5-910d-d9f7cafdd718",
        id: "rzkuCldm6cMnlcBU0OgQ",
      },
      {
        title: "2023학년도 신입생 입학전형 요강",
        order: 2.25,
        path: "images/BOOTH_IMG_6V5KkTRs_Sw4jo44Rg",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_6V5KkTRs_Sw4jo44Rg?alt=media&token=cfc6cd94-d96e-4f2a-b19a-82c32ed733c4",
        id: "xePha54pCSKl4LVohTgu",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/H7EKYRTVj6U",
        order: 0.03125,
        title: "메인영상",
        id: "5y4Sxw8fVB0Ogyz7q3qz",
      },
      {
        url: "https://www.youtube.com/watch?v=cCBDIqXr5XM",
        order: 4,
        title: "배미고 아침등교맞이 먹거리한마당",
        id: "6JmHMObYAYh5nKIydAGn",
      },
      {
        order: 2.33203125,
        title: "디자인과 진로체험",
        url: "https://www.youtube.com/watch?v=0NaYTI2dhLs",
        id: "CrY3XQmTAwnmlm9IurDa",
      },
      {
        title: "미래 Q&A 미용과",
        url: "https://youtu.be/-dvdhhl04aI",
        order: 0.125,
        id: "KxaGZIY51mNj2YtgK9j5",
      },
      {
        order: 0.21875,
        url: "https://youtu.be/aqxfCGhNjOY",
        title: "미래 Q&A 경영과",
        id: "LOnE3Dckp4BpMgBcKAmR",
      },
      {
        url: "https://youtu.be/tiVkEm5Fd2U",
        order: 0.1875,
        title: "미래 Q&A 디자인과",
        id: "MdP9CnBTNQahNriKOWiw",
      },
      {
        order: 0.0625,
        url: "https://www.youtube.com/watch?v=a19zYRkbRwQ",
        title: "배정미래고 공식 홍보영상",
        id: "Rad97yRBWL7zqtLeNwFm",
      },
      {
        title: "2022년도 할로윈 행사",
        order: 0.234375,
        url: "https://youtu.be/ypbz4GvxzO4",
        id: "SM82BQm5ML3sDeeFOcTq",
      },
      {
        order: 0.25,
        title: "2021학년도 할로윈 행사",
        url: "https://www.youtube.com/watch?v=Eg2ODGQaeU8",
        id: "aMpV3D9uoNWQXJcOLhS9",
      },
      {
        order: 2.291015625,
        title: "미용과 진로체험",
        url: "https://youtu.be/rYLGehG3R5w",
        id: "dsH4pgb98nwRbO1p9Tzq",
      },
      {
        title: "미래한마당 댄스부 공연",
        url: "https://www.youtube.com/watch?v=3P_tOfeQkLY",
        order: 2.25,
        id: "oufHSEC3EVZFGhBeAx4b",
      },
      {
        order: 1.25,
        url: "https://youtu.be/RbiIvFYIyf0",
        title: "2021년도 크리스마스 이벤트",
        id: "sKfMxT6IoZs2MxdVnrrS",
      },
      {
        order: 0.2421875,
        url: "https://youtu.be/WH-OoJ4ZXFw",
        title: "배정미래고 댄스부 공연",
        id: "tTWLxdkK5OgI8PgXt20Y",
      },
    ],
  },
  "7rDVzwFT": {
    id: "7rDVzwFT",
    documents: [
      {
        path: "documents/q75RgxNuQ_2023_대광고등학교_입학전형요강.jpg",
        title: "2023학년도 대광고 입학전형요강",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Fq75RgxNuQ_2023_%EB%8C%80%EA%B4%91%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90_%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%EC%9A%94%EA%B0%95.jpg?alt=media&token=6b62fa67-c672-438f-a80f-f34202a91a44",
        order: 1,
        id: "8enrzoVbqfsUaf83Wah6",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_cmxBMOhBs?alt=media&token=06180a43-abdc-4346-b589-e2eb2444103d",
        title: "최고가 산업용 프린터를 활용한 출력 수업",
        order: 7.75634765625,
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_cmxBMOhBs",
        id: "8l1D1pYh30vxJSTDtmFy",
      },
      {
        order: 7.793426513671875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_-s1oXFoCi?alt=media&token=0b2f3ee4-6891-43d9-b896-b1bfb6a2bf4a",
        title: "미래의 메이커 CEO가 될 수 있는 창업동아리 활동",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_-s1oXFoCi",
        id: "8m87QTbxZ0viAR9UXD4P",
      },
      {
        title: "VR관련 유명 기업 CEO 및 전문가 릴레이 특강",
        order: 11.75,
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_6iliM51hI",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_6iliM51hI?alt=media&token=574b04a2-a28c-475a-94ff-7389506fae02",
        id: "Ap4n8GXsm5cuxqd5NAqF",
      },
      {
        title: "창작 헤어 업스타일 동아리 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_VOmya4v1F?alt=media&token=9093db98-7d68-45ae-aa6d-463ccd0dd99d",
        order: 7.716796875,
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_VOmya4v1F",
        id: "Ei4flsgUJkkyQ5540HzP",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_JVLFLdtCn?alt=media&token=dd68deff-f52e-4d46-be70-07924a341ae4",
        title: "러블리 메이크업 수업",
        order: 3,
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_JVLFLdtCn",
        id: "YW8oj9BmCf6LVOqdkUcp",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_-RLRDK_Q3?alt=media&token=62fe1d48-1405-45f3-950b-dfdf6bade39b",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_-RLRDK_Q3",
        title: "3D 공간에서 촉각으로 진행되는 캐릭터 모델링 수업",
        order: 7.776123046875,
        id: "YsZ6tDT84Z1YMpj4ZHqy",
      },
      {
        order: 7.79095458984375,
        title: "재료의 혁신! 푸드 프린터&바이오 프린터 수업",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_xppVNZkp8",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_xppVNZkp8?alt=media&token=7dc1c79d-744c-419a-9206-6183659a6740",
        id: "c2wlsW4W4W1y05NfLj7r",
      },
      {
        order: 2.5,
        title: "모델에게 직접 시연하는 헤어커트 수업",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_S030dy9AL",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_S030dy9AL?alt=media&token=74d936ef-2f28-4483-a1bb-0b6170ba19c1",
        id: "lYrwGHpvwhHPdymv6Kov",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_bW9Uqb7B1?alt=media&token=e8ae58d6-05b7-4ec8-9694-d3d297191672",
        title: "예쁜 피부를 위한 초음파 얼굴 관리",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_bW9Uqb7B1",
        order: 4,
        id: "nltXcwOMtRLQRP3v1edK",
      },
      {
        title: "상상한 제품을 제작할 수 있는 3D 제품개발 수업",
        order: 7.7860107421875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_U9jWYGqhu?alt=media&token=90467cba-c408-42af-b21e-fc635acb53f8",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_U9jWYGqhu",
        id: "oGpZjH8zugtQofZZ79Sl",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_XMTzd5EaO?alt=media&token=2e647e0b-528a-462a-a5e9-a79a171da356",
        order: 8,
        title: "6축 모션시뮬레이터를 활용한 가상현실 구현",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_XMTzd5EaO",
        id: "qTdUPUQdEtYUdzoGeUt8",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFT_0KzRrxtwB?alt=media&token=bb4ff868-0b44-407f-9f84-70011b048d55",
        order: 7.736572265625,
        path: "images/BOOTH_IMG_7rDVzwFT_0KzRrxtwB",
        title: "'손끝으로 전하는 사랑나눔' 뷰티아트과 미용봉사",
        id: "qYtuNJPbhaV4150HW3Ny",
      },
      {
        title: "모션캡쳐 장비를 활용한 애니메이션 작업",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_nqRW8kw6e?alt=media&token=434dc64b-f5d2-468e-970a-cfa07a2f36c1",
        order: 7.7958984375,
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_nqRW8kw6e",
        id: "trOfRZGbnZxBMlYT1fKh",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_ISO8T5pql?alt=media&token=5d7b4f51-c64c-4bb1-8355-467920dd079f",
        title: "전용 HMD 장비를 활용한 수업",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_ISO8T5pql",
        order: 10,
        id: "v1GyBnwVX8wWaP9GGYuh",
      },
      {
        title: "모션캡쳐 장비를 활용한 애니메이션 작업",
        order: 7.875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_ts8tFuPl0?alt=media&token=d1f9bdb8-5d59-4ef5-8f30-4d3537f7a443",
        path: "images/BOOTH_IMG_7rDVzwFTZFcuCvmxJkkDDyLGB672_ts8tFuPl0",
        id: "xA0UmNGZ7VWrWF8DeA0i",
      },
    ],
    videos: [
      {
        title: "메인 영상",
        order: 1,
        url: "https://youtu.be/sP22vVGYS5k",
        id: "1roBtJVemaIwnGtaIDKl",
      },
    ],
  },
  AUsENcz9: {
    id: "AUsENcz9",
    documents: [
      {
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FZ9H885Q_2_2022%20%EB%B6%80%EC%82%B0%EC%86%8C%ED%94%84%ED%8A%B8%EC%9B%A8%EC%96%B4%EB%A7%88%EC%9D%B4%EC%8A%A4%ED%84%B0%EA%B3%A0%20%EB%B8%8C%EB%A1%9C%EC%8A%88%EC%96%B4.pdf?alt=media&token=80379851-b93c-4139-a524-2880a87f5213",
        title: "2022학년도 부산소프트웨어마이스터고등학교 브로슈어",
        path: "documents/Z9H885Q_2_2022 부산소프트웨어마이스터고 브로슈어.pdf",
        id: "ZQBdIE5OlIexEOkzYNCB",
      },
    ],
    images: [
      {
        order: 2,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_KaF5UacVC?alt=media&token=64326c37-81ed-4caa-b239-0aeee37d1af7",
        title: " 부산소프트웨어마이스터고 조감도2",
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_KaF5UacVC",
        id: "2vZIajVaTPx10ZDnKkLW",
      },
      {
        order: 8,
        title: "산업체 전문가 특강",
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_aAB-LXdX8",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_aAB-LXdX8?alt=media&token=312a59c3-0e26-479a-adc6-b8601709c692",
        id: "A0vdooUxy0FzgbX3q7hG",
      },
      {
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_oNs2lK201",
        title: "'우아한 형제들'과 '전국 IT 관련 마이스터고' 협약식",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_oNs2lK201?alt=media&token=f8638b17-db5e-49d3-ab7b-574e23b083c9",
        order: 7,
        id: "AvxAFY1GFFpE6R5r80ob",
      },
      {
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_IRuZqL9iV",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_IRuZqL9iV?alt=media&token=547e3830-8801-4916-a10e-2b3dc2ae4773",
        order: 4.25,
        title: "전공동아리 프로젝트 계획 발표회",
        id: "VBUXPvEyyERAAcwwcKsp",
      },
      {
        title: "체육대회",
        order: 6,
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_ExsNFA3ZH",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_ExsNFA3ZH?alt=media&token=e379ff7f-ca55-47bc-b734-8d6dbf046b19",
        id: "oEJNRSs7tcwufspzxb6o",
      },
      {
        order: 5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_4K4nnJdII?alt=media&token=29dfaf92-96d8-4677-8c36-21d41e07dd6f",
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_4K4nnJdII",
        title: "여름방학 전공 및 영어 캠프",
        id: "oFhn1UNKbBGTBHdF4Z8x",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_lMZD4DEO7?alt=media&token=488b63e5-362c-49ce-b356-16e98cd4b09f",
        order: 1,
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_lMZD4DEO7",
        title: "부산소프트웨어마이스터고 조감도1",
        id: "psWGuhEeZrzOnhndz2HC",
      },
      {
        order: 3.6875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_BlkISSeLb?alt=media&token=25e190a9-bc33-4e0f-9251-05bfde118b87",
        title: "언론 속 부산소프트웨어마이스터고",
        path: "images/BOOTH_IMG_AUsENcz9nSOTtSvtiqTg32C7g7G3_BlkISSeLb",
        id: "uaRN59NAEzR8081pk5vV",
      },
    ],
    videos: [
      {
        title: "부산소프트웨어마이스터고등학교 홍보영상",
        order: 2.25,
        url: "https://youtu.be/DIvVZouIVpQ",
        id: "5LIGUw9guEbUcdMY8Q7I",
      },
      {
        title: "개관식",
        order: 6,
        url: "https://youtu.be/2GZgnPiZp7Q",
        id: "Kp3AC8ZTh5baK1PJbuvL",
      },
      {
        title: "소프트웨어마이스터고 온라인 토크콘서트",
        order: 2.625,
        url: "https://youtu.be/mtnzetJHNUA",
        id: "MyEffjZc8luQD3uzGdYv",
      },
      {
        order: 5,
        title: "학생 출품작(VR)",
        url: "https://youtu.be/6KtiIFIEqgw",
        id: "ObZCfLVp1GhbKjYb61Iz",
      },
      {
        title: "학생 출품작(챗봇AI)",
        url: "https://youtu.be/pKvK3G9Jc0I",
        order: 6,
        id: "OlXckCjZfZjXSsBARquZ",
      },
      {
        title: "부산소프트웨어마이스터고 전공캠프 하이라이트",
        url: "https://youtu.be/Z3Jkg06BcpA",
        order: 1.5,
        id: "utP9OLFpswzE8mmRqlCo",
      },
    ],
  },
  BiN3ZF9L: {
    id: "BiN3ZF9L",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FXNBArERpr_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EB%94%94%EC%A7%80%ED%84%B8%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%95%88%EB%82%B4(%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%EC%9A%94%EA%B0%95%20%ED%8F%AC%ED%95%A8).pdf?alt=media&token=72883b42-2fca-44ce-8384-f9bf89ffd56e",
        order: 1,
        path: "documents/XNBArERpr_2023학년도 부산디지털고등학교 안내(입학전형요강 포함).pdf",
        title: "2023학년도 부산디지털고등학교 신입생 요강",
        id: "umkAT9XqxOipbNtNvUjX",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_z4hybzCEf?alt=media&token=561c9646-3e1c-461f-8765-a4b2c7f8a4bd",
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_z4hybzCEf",
        order: 15.125,
        title: "부산디지털고 디지털전자과",
        id: "0Dg7iecg00dD2iDdrNu8",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9L_PzbM8GF4S",
        order: 15.109375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_PzbM8GF4S?alt=media&token=42245a6a-0672-4cc8-a4f8-f5265886ccba",
        title: "학교 신입생 입학 안내 10",
        id: "1s12WcdJfrkbhMD1kySP",
      },
      {
        order: 15.1171875,
        title: "학교 신입생 입학 안내 11",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_X_9CpUObS?alt=media&token=1cbff8a0-ab25-4829-82ef-de407e86a09c",
        path: "images/BOOTH_IMG_BiN3ZF9L_X_9CpUObS",
        id: "2FQM1nbA1CQh77OctAXd",
      },
      {
        title: "학교 신입생 입학 안내 6",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_CKBBlOhI2?alt=media&token=c1d0702c-9c7e-47b7-bac0-522afe2b99b5",
        path: "images/BOOTH_IMG_BiN3ZF9L_CKBBlOhI2",
        order: 14,
        id: "6qNK5A5du5M0w2yGuQaf",
      },
      {
        title: "부산디지털고 정독실",
        order: 15.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_npHOY0kEg?alt=media&token=002caaf3-f7ec-420c-a887-49cb09521a90",
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_npHOY0kEg",
        id: "82gajGBnpzVZPYuF4fKu",
      },
      {
        order: 15,
        title: "학교 신입생 입학 안내 7",
        path: "images/BOOTH_IMG_BiN3ZF9L_i7Fot32E_",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_i7Fot32E_?alt=media&token=6bba1e40-ed27-4122-814e-d2f7698fe4e6",
        id: "AtTQntrrAqb16DQbknJt",
      },
      {
        title: "부산디지털고 디지털전자과",
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_fADayuiN8",
        order: 15.25,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_fADayuiN8?alt=media&token=d37cc44d-fccd-4078-b286-7f9bd1496a9f",
        id: "BaFouRMQyAGOFWaqfGPl",
      },
      {
        title: "부산디지털고 소방안전과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_jkao2Jbgx?alt=media&token=0e1c124a-328d-442f-8fdd-03be54bda93a",
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_jkao2Jbgx",
        order: 15.124526977539062,
        id: "K43pQgCeIXqqqMtpd6qt",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_SqQ-MnxZ8?alt=media&token=0cc13b3c-5ab5-48c0-aa05-bfe024dd7e34",
        title: "학교 신입생 입학 안내 5",
        order: 13,
        path: "images/BOOTH_IMG_BiN3ZF9L_SqQ-MnxZ8",
        id: "NvZLm1QU7EGWFAKuAZ10",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_7OLpMYF0E?alt=media&token=3f8f3e4c-0e74-44ec-8630-0238caf2d54f",
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_7OLpMYF0E",
        title: "부산디지털고 소방안전과",
        order: 15.124542236328125,
        id: "OB3JG5L9OEFLCzJHyzAa",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9L_xrlwwPROO",
        title: "학교 신입생 입학 안내 9",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_xrlwwPROO?alt=media&token=73e352f6-81af-40f4-a759-99fd3ea05f82",
        order: 15.09375,
        id: "PG91rGgoV2Og3TBlfOM6",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9L_eRZUVtqQM",
        title: "학교 신입생 입학 안내 15",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_eRZUVtqQM?alt=media&token=6f1b40dd-4fad-4a6b-a936-38070f41d278",
        order: 15.12451171875,
        id: "PUMe5tJsWWNMAJLRDI1A",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_srTERbdlz?alt=media&token=0040bbe3-4b29-4eed-91ae-c75d376a23c2",
        path: "images/BOOTH_IMG_BiN3ZF9L_srTERbdlz",
        order: 15.12109375,
        title: "학교 신입생 입학 안내 12",
        id: "SB5BrkeMK5ekz62Icy0c",
      },
      {
        order: 11,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_O8mLTRW4Q?alt=media&token=66ce44af-b54b-42ab-a4a6-2eac9dd0b382",
        path: "images/BOOTH_IMG_BiN3ZF9L_O8mLTRW4Q",
        title: "학교 신입생 입학 안내 3",
        id: "UEZIGJhnV7qdE3UgNQCn",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_zOKxC8QQA",
        order: 15.12457275390625,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_zOKxC8QQA?alt=media&token=b6feed22-f9fe-4e6d-aa50-1442623a2f8e",
        title: "부산디지털고 전자부사관과",
        id: "fxVHRcNsHvLLYtlgrTDf",
      },
      {
        title: "부산디지털고 전자부사관과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_f-Ufr6tbB?alt=media&token=e0b1f5fd-89ce-437d-8837-91a84c92118e",
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_f-Ufr6tbB",
        order: 15.124755859375,
        id: "ml1eQw5IeS5MRKP5rQ1U",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_FGgIpyezk",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9LFJWz9q5KfjFkBRSgTVv1_FGgIpyezk?alt=media&token=c32c607d-d837-4a39-a768-53deba167935",
        order: 15.1246337890625,
        title: "부산디지털고 전자부사관과",
        id: "oMDJmJiiaRkjCbONb66U",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_g6Ll8fOLY?alt=media&token=3ae8eb92-eb66-4c33-86d5-f7e9ff8b5d32",
        order: 15.123046875,
        title: "학교 신입생 입학 안내 13",
        path: "images/BOOTH_IMG_BiN3ZF9L_g6Ll8fOLY",
        id: "oscIrf6jKMcElT8uXlSN",
      },
      {
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_N9cgEvW4-?alt=media&token=f1b20feb-224f-49f3-8f54-f0c8872b5e73",
        path: "images/BOOTH_IMG_BiN3ZF9L_N9cgEvW4-",
        title: "학교 신입생 입학 안내 1",
        id: "pcNVPpWRoQTHD6aX5P1v",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9L_lUpAkmkK_",
        order: 15.0625,
        title: "학교 신입생 입학 안내 8",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_lUpAkmkK_?alt=media&token=8e2e96da-ccc2-406d-9b65-a4ce54a0d7c5",
        id: "pzF6aC5he0TrbTthST6S",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_diUMTY7-O?alt=media&token=76d75ab4-7813-42d8-b65e-9bbfd7fe6f8a",
        path: "images/BOOTH_IMG_BiN3ZF9L_diUMTY7-O",
        title: "학교 신입생 입학 안내 2",
        order: 10,
        id: "q0u4wbnZZFRsk5whri0M",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9L_EpYWt0gAI",
        order: 15.1240234375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_EpYWt0gAI?alt=media&token=471bd368-f696-42c0-8eb5-c24d75b8408b",
        title: "학교 신입생 입학 안내 14",
        id: "q3lQDeCRf7DZfuAqL6ZH",
      },
      {
        path: "images/BOOTH_IMG_BiN3ZF9L_HYlHPPYYW",
        title: "학교 신입생 입학 안내 4",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_BiN3ZF9L_HYlHPPYYW?alt=media&token=c58a1d82-abbe-454b-9625-fbe3301535e4",
        order: 12,
        id: "tDCV8Fhmdi7v066gRdkn",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/guB2IZe8E8I",
        title: "디지털전자과 학과 소개",
        order: 1.5,
        id: "3zOwazx2wrzQggjgbFHm",
      },
      {
        title: "부산디지털고등학교 전자과 소개",
        url: "https://youtu.be/GhDq8D3158w",
        order: 6,
        id: "ACrpnBQeM9bvByyIjhUQ",
      },
      {
        order: 1.875,
        url: "https://youtu.be/3QZKbJX53Ag",
        title: "부산디지털고 홍보부 공연",
        id: "CwAKBMPjY8ArEFyH5GG2",
      },
      {
        order: 3,
        title: "부사관 합격 비법공개 최다합격",
        url: "https://youtu.be/sKHv8Dk1HbA",
        id: "DCJitT7oSSkopZ0tsW7K",
      },
      {
        url: "https://youtu.be/Ny8eyDFwNHM",
        title: "부산디지털고등학교 2022학년도 신입생",
        order: 2,
        id: "H0vlY8o2DImIlLUeO57r",
      },
      {
        order: 14,
        url: "https://youtu.be/_WT6t5FEOPE",
        title: "다녀보면 실망않는 부산디지털고등학교",
        id: "HZ0BUeICNgTLJtZvOXDg",
      },
      {
        title: "부사관과 체력검정시럼(국민체력100)교내실시",
        order: 9,
        url: "https://youtu.be/DF2mvgVif0w",
        id: "IDlSZOLZcZzvWLnmMM2d",
      },
      {
        title: "부산디지털고등학교 오징어게임",
        order: 2.5,
        url: "https://youtu.be/owJ4gIBnWkM",
        id: "JgDu2bYZ0kj0SvTfSgMR",
      },
      {
        title: "부산교육뉴스",
        url: "https://youtu.be/4hEzdp-Hdvw",
        order: 10,
        id: "OOrWlUlZf0ejTiIm8dsw",
      },
      {
        url: "https://youtu.be/7wf5UQiGSxU",
        order: 1.375,
        title: "전자부사관과 학과 소개",
        id: "OxGAQVAk7DU1v2Df7apE",
      },
      {
        title: "메인영상",
        url: "https://youtu.be/5FMimb6ooLs",
        order: 1,
        id: "R6EaCN4uxe0IRMk1tFOH",
      },
      {
        title: "부산디지털고등학교 딱지치기 & E스포츠",
        order: 13.75,
        url: "https://youtu.be/lHjxIX2hz_w",
        id: "VpVjuBWSvASxaqAxpNss",
      },
      {
        order: 15,
        title: "부산디지털고등학교 현장체험학습",
        url: "https://youtu.be/39IrfHQ25NM",
        id: "VqLKb2BoYvX8BtMpwlYN",
      },
      {
        order: 4,
        url: "https://youtu.be/B0kK5HVsQuo",
        title: "BDHS 뉴스 부산디지털고등학교",
        id: "YxwjlFJiRzoxBDpEJwoO",
      },
      {
        title: "부산디지털고 댄스 동아리 공연",
        url: "https://youtu.be/vbpVMu3tQDA",
        order: 1.8125,
        id: "cAbMWOQ0fEfpJ8xxgkFF",
      },
      {
        title: "부산디지털고등학교 메이커스페이스",
        order: 11,
        url: "https://youtu.be/_yysdEksv-k",
        id: "cpObKO3VBWraiH37zlUV",
      },
      {
        url: "https://youtu.be/_rbhwdW6SrA",
        title: "부산디지털고등학교 소방안전과 소개영상",
        order: 5.5,
        id: "eQ4dZw6ckwDgLwSVjVNs",
      },
      {
        order: 11.5,
        title: "부산디지털고등학교 등교행사",
        url: "https://youtu.be/ar7b3aZZz6c",
        id: "fijgJXAXjAFzn0h9DYkx",
      },
      {
        order: 1.75,
        title: "독도 플래시몹",
        url: "https://youtu.be/GHUzQS9ixOE",
        id: "fjIgALXpqGuxE3feRrEz",
      },
      {
        url: "https://youtu.be/sXBVU1MMUZY",
        order: 1.625,
        title: "22학년도 1학년 현장체험학습",
        id: "gyeLygKSnHKKqwdru2ga",
      },
      {
        order: 8,
        url: "https://youtu.be/U0ZH79KWOR0",
        title: "부산디지털고등학교 방역준비",
        id: "jlY3zTBLwD4ci0f7MDl4",
      },
      {
        order: 1.25,
        url: "https://youtu.be/GoG_w6w-C94",
        title: "소방안전과 학과 소개",
        id: "mWmG6KJ1qOI6vGnC9vXi",
      },
      {
        order: 1.125,
        title: "부산디지털고 소개 인트로",
        url: "https://youtu.be/YSg2uWOaxPo",
        id: "rHIKbV1K4laRUOTYbVuy",
      },
      {
        url: "https://youtu.be/R1aTn4VgOeY",
        title: "부산디지털고등학교 부사관 소개영상",
        order: 5,
        id: "vC4K4vJHsBUe2s43NpJL",
      },
      {
        url: "https://www.youtube.com/watch?v=guB2IZe8E8I",
        order: 25,
        title: "2023학년도 부산디지털고등학교 디지털전자과 소개",
        id: "ySxDgl79fxWn4E5P4rAU",
      },
    ],
  },
  CiLGQEMy: {
    id: "CiLGQEMy",
    documents: [
      {
        order: 2,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FlXEcXqDbo_%ED%95%B4%EC%9A%B4%EB%8C%80%EA%B4%80%EA%B4%91%EA%B3%A023_%ED%8C%9C%ED%94%8C%EB%A0%9B_04(%EC%B5%9C%EC%A2%85).pdf?alt=media&token=b53e3efd-293c-44d5-8ab8-fbb70decc84f",
        path: "documents/lXEcXqDbo_해운대관광고23_팜플렛_04(최종).pdf",
        title: "2023학년도 해운대관광고등학교 홍보책자",
        id: "3lGsWnwimVJ5KOetd660",
      },
      {
        order: 1,
        title: "2023학년도 해운대관광고등학교 입학전형 요강",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FV03pL-QCk_2023%ED%95%99%EB%85%84%EB%8F%84%20%ED%95%B4%EC%9A%B4%EB%8C%80%EA%B4%80%EA%B4%91%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.pdf?alt=media&token=c2db680c-7cda-472b-be1a-39cf28dda8ba",
        path: "documents/V03pL-QCk_2023학년도 해운대관광고등학교 입학전형 요강.pdf",
        id: "5zdkruQ090iYegqMbjdG",
      },
      {
        title: "2023학년도 해운대관광고등학교 리플렛",
        order: 3,
        path: "documents/CX2wImF1i_해운대관광고(23)_3단리플렛_04(최종).pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FCX2wImF1i_%ED%95%B4%EC%9A%B4%EB%8C%80%EA%B4%80%EA%B4%91%EA%B3%A0(23)_3%EB%8B%A8%EB%A6%AC%ED%94%8C%EB%A0%9B_04(%EC%B5%9C%EC%A2%85).pdf?alt=media&token=40cafe0a-944a-4092-84ef-c20c941c2dab",
        id: "IE6miviX5lsyv5xGZavK",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_19QpyvSC3?alt=media&token=d518ccd8-be09-467d-bc9d-748cd8c1e588",
        path: "images/BOOTH_IMG_CiLGQEMy_19QpyvSC3",
        order: 6.986896514892578,
        title: "관광외국어과",
        id: "4VNZySiPwLauUB2BnZKQ",
      },
      {
        title: "아웃도어클래스(칵테일)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_Hw2UiURzj?alt=media&token=eb34dbef-cf5e-42ab-8f95-ec8b744b9f5e",
        path: "images/BOOTH_IMG_CiLGQEMy_Hw2UiURzj",
        order: 9.5,
        id: "6i51kZRfsw4GMhtHnAdA",
      },
      {
        title: "관광조리과",
        path: "images/BOOTH_IMG_CiLGQEMy_xZOlKfLKD",
        order: 3.6029052734375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_xZOlKfLKD?alt=media&token=2212154f-df90-4e88-a6da-d2bc4a6ee5ba",
        id: "Dm2fZXBtooG4cnMnQMks",
      },
      {
        path: "images/BOOTH_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_0qZnywkBJ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_0qZnywkBJ?alt=media&token=830b9af8-2581-4afb-a23c-06cbd9b1b82d",
        order: 8,
        title: "아름다운 학교전경",
        id: "FZrq2ldGT5KkyUBQqzUx",
      },
      {
        path: "images/BOOTH_IMG_CiLGQEMy_--n6HgcS6",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_--n6HgcS6?alt=media&token=0a9935aa-d409-4d41-8156-2d28b80c0ec4",
        order: 1.6953125,
        title: "관광경영과_바리스타수업",
        id: "OgD4hoIUvlJMOEMKpZvk",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_qHxUvxjp0?alt=media&token=18f0454e-4ea4-4386-99b6-93bf2073723e",
        title: "[언론보도]부산 해운대관광고, 지역사회에 친환경 채소 기부",
        order: 11.5,
        path: "images/BOOTH_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_qHxUvxjp0",
        id: "Q6PmaKlqEccUst8K89Df",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_OYoMOKxpt?alt=media&token=6c362704-089e-4704-830a-a22a74967d4c",
        title: "관광외국어과_수업",
        order: 7.493448257446289,
        path: "images/BOOTH_IMG_CiLGQEMy_OYoMOKxpt",
        id: "SK8dj35XLpqVd1LKzNb2",
      },
      {
        title: "관광조리과_제과제빵",
        path: "images/BOOTH_IMG_CiLGQEMy_vMmxOP-iz",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_vMmxOP-iz?alt=media&token=7146c512-6963-4b3e-9061-2069aaee41cb",
        order: 3.79986572265625,
        id: "W8Orc9WH0RFD2WAZFCFy",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_5_27DqeCS?alt=media&token=465981b3-f341-4e24-ba64-74180d8223c2",
        order: 7.240172386169434,
        title: "관광외국어과_외국어화상면접",
        path: "images/BOOTH_IMG_CiLGQEMy_5_27DqeCS",
        id: "XWB2M33YVeoTKs5Fi0nk",
      },
      {
        title: "관광조리과_제과제빵",
        order: 3.898345947265625,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_2_WSz_UXf?alt=media&token=cbae49f2-9cb5-43ab-90c5-d8e370adb678",
        path: "images/BOOTH_IMG_CiLGQEMy_2_WSz_UXf",
        id: "ZRYEjkcCsNZprrOiCnK2",
      },
      {
        title: "관광조리과_서양조리",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy__BiV4qqaA?alt=media&token=dc7272b0-8d9e-4158-93b5-a67864b85b76",
        path: "images/BOOTH_IMG_CiLGQEMy__BiV4qqaA",
        order: 3.9475860595703125,
        id: "ctaG1GAQVxekEyjnXK5a",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_hgyN-9yrI?alt=media&token=bb18126e-0acc-4a1a-8bf4-9dc72b5e54e5",
        title: "관광경영과_칵테일수업",
        order: 2.421875,
        path: "images/BOOTH_IMG_CiLGQEMy_hgyN-9yrI",
        id: "jn5bk5cSuGSRyWXkS2Y6",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_T0IIXF3oe?alt=media&token=12696e74-8bd7-46f5-b092-a489d0703377",
        path: "images/BOOTH_IMG_CiLGQEMy3leFfgFVFIN0yEJa7Rt1_T0IIXF3oe",
        title: "2021학년도 한우 창작 조리경연대회 실시",
        order: 11,
        id: "mAW9roddsNVpqBcueHQU",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_CiLGQEMy_69vgna0oD?alt=media&token=ab305c27-441b-40f7-befc-7445851dd1bc",
        path: "images/BOOTH_IMG_CiLGQEMy_69vgna0oD",
        order: 0.96875,
        title: "관광경영과",
        id: "mNZF76jgcgmQslxrmj4n",
      },
    ],
    videos: [
      {
        order: 2,
        title: "해운대관광고등학교 공연",
        url: "https://www.youtube.com/watch?v=pB3jTXkf-9k&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=15",
        id: "2awIUvT7PwkLP4pLYNgJ",
      },
      {
        title: "관광경영과 식음료수업 - 테이블셋팅",
        url: "https://youtu.be/lz1FCYlKpPo",
        order: 10,
        id: "714Ghm7c9dXcj6EgHigX",
      },
      {
        order: 7,
        title: "중학교 직업 체험 활동",
        url: "https://youtu.be/DxCnB90rcwA",
        id: "7Fh9v2SsZ6ZEMedkuTW1",
      },
      {
        title: "2021학년도 명장공방 전시회 및 학과발표회",
        url: "https://youtu.be/zpHaC3QdkVo",
        order: 2.375,
        id: "BHJOrIe6ZVwkR7qVsCfi",
      },
      {
        title: " 난타공연",
        url: "https://youtu.be/50PPoveCmq8",
        order: 2.75,
        id: "PNZP3d32uNOlOeOIWGAY",
      },
      {
        url: "https://youtu.be/hOMYOZgvEWg",
        title: "스쿨어택 해운대관광고등학교편",
        order: 6,
        id: "TcGvvvDV4PKVf6QD2G8g",
      },
      {
        order: 3.5,
        title: "CJ도너스캠프 문화꿈지기 청소년동아리 - 해운대관광고등학교 - Herb Cook",
        url: "https://youtu.be/ZgtPXvdm04A",
        id: "TqpgHd4SyfL9Z0H1NBAA",
      },
      {
        order: 9,
        url: "https://youtu.be/07P00y0v_B8",
        title: "관광경영과 식음료수업 - 핸드드립",
        id: "WqxT9avuzZfh1fQay2kK",
      },
      {
        title: "학교폭력 예방 캠페인 in 부산해운대관광고등학교",
        url: "https://youtu.be/aktMRh7inRc",
        order: 3.9375,
        id: "XRONAjDN46Fj5CkOHgSb",
      },
      {
        order: 11,
        url: "https://youtu.be/B_KSiR-Q5o8",
        title: "관광경영과 카지노수업",
        id: "ZzAvQGdEQvT0d9hDApQw",
      },
      {
        url: "https://youtu.be/s2NEsXGpkp8",
        title: "CJ 도너스 캠프 청소년 문화동아리 요리 부문 지원  - Herb Cook",
        order: 3.75,
        id: "dXGbULLjZmbluMUdCxHM",
      },
      {
        order: 1,
        url: "https://youtu.be/0tDePD8TEzk",
        title: "메인영상",
        id: "fHJlDsdAEQW5MWjMSGKX",
      },
      {
        url: "https://www.youtube.com/watch?v=atGOG6DqEGY",
        order: 0.625,
        title: "2023학년도 해운대관광고등학교 비즈쿨&팜파티",
        id: "i782BchYkjopmgYQXAzI",
      },
      {
        order: 8,
        url: "https://youtu.be/ZPK0PHk1Re8",
        title: "제12회 전국학교스포츠클럽대회 힙합댄스",
        id: "nQ3gZLP1qh8LKEBI1lLi",
      },
      {
        order: 0.75,
        url: "https://www.youtube.com/watch?v=Zj9GTU3a6c0",
        title: "미리보는 고교생활",
        id: "o7G492nVUCITAT1PCHKT",
      },
      {
        title: "해운대관광고 댄스동아리(KOD)",
        order: 4,
        url: "https://youtu.be/XyqG4xE4Klo",
        id: "qNokjXofLCYKTA4e6hYx",
      },
      {
        title: "2023학년도 해운대관광고등학교 홍보영상",
        url: "https://www.youtube.com/watch?v=poeOvtLLn3c",
        order: 0.5,
        id: "xc4dVuRxmNcEUGUildBD",
      },
      {
        url: "https://youtu.be/5jPT8yUoe2c",
        title: "해운대관광고등학교 팜파티 영상",
        order: 5,
        id: "zc91inr6pbADxpItAd9r",
      },
    ],
  },
  DLn0t0nl: {
    id: "DLn0t0nl",
    documents: [
      {
        title: "2023학년도 세정고등학교 신입생 모집 전단지 (앞)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F_g3Ku0QSZ_%EC%84%B8%EC%A0%95%EA%B3%A0%202023%EC%8B%A0%EC%9E%85%EB%AA%A8%EC%A7%91%20%EC%A0%84%EB%8B%A8(%EC%95%9E).jpg?alt=media&token=fb9c92fc-33f5-4963-8b94-90b3351d19dd",
        order: 4,
        path: "documents/_g3Ku0QSZ_세정고 2023신입모집 전단(앞).jpg",
        id: "5wTLcPr92sz3OcDzeJTc",
      },
      {
        order: 4,
        title: "2023학년도 세정고등학교 신입생 모집 전단지 (뒤)",
        path: "documents/wD3abFS8q_세정고 2023신입모집 전단(뒤).jpg",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FwD3abFS8q_%EC%84%B8%EC%A0%95%EA%B3%A0%202023%EC%8B%A0%EC%9E%85%EB%AA%A8%EC%A7%91%20%EC%A0%84%EB%8B%A8(%EB%92%A4).jpg?alt=media&token=892a8ecc-b06c-4588-a220-9992c969def5",
        id: "6sd4ai6FaPxEQTsNZ1fk",
      },
      {
        title: "2023학년도 모집요강",
        path: "documents/8IAzn0Gky_세정고 모집요강-24p(보관용)_페이지_22.jpg",
        order: 4,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F8IAzn0Gky_%EC%84%B8%EC%A0%95%EA%B3%A0%20%EB%AA%A8%EC%A7%91%EC%9A%94%EA%B0%95-24p(%EB%B3%B4%EA%B4%80%EC%9A%A9)_%ED%8E%98%EC%9D%B4%EC%A7%80_22.jpg?alt=media&token=ef5df122-8aed-4c7e-ad39-7c4730a2d41e",
        id: "h9v7MeUNvTFSS1GUYWkh",
      },
      {
        path: "documents/0_UTTZLOF_2023 세정고 신입생 포스터.jpg",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F0_UTTZLOF_2023%20%EC%84%B8%EC%A0%95%EA%B3%A0%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%ED%8F%AC%EC%8A%A4%ED%84%B0.jpg?alt=media&token=04222228-4de3-416e-8783-8dc659b74e24",
        order: 4,
        title: "2023학년도 세정고등학교 신입생 포스터",
        id: "uA36r7VUkIlt9Qx9vYao",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_2w7b1U2S1?alt=media&token=46e109a6-5f63-4f6f-a7a2-839d0ca92478",
        order: 22,
        title: "학교시설 1",
        path: "images/BOOTH_IMG_DLn0t0nl_2w7b1U2S1",
        id: "4Uvy463fB9sZkqjKsYN4",
      },
      {
        title: "동아리활동, 행사활동",
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_seidhIqHw",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_seidhIqHw?alt=media&token=f92417be-8045-49fe-b6db-fa937e47a021",
        order: 0.65625,
        id: "7uUrHQAZAWML4TkYoTx0",
      },
      {
        order: 5,
        title: "매년 세정 문화의 날은 우리들의 축제날이에요 ☆",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_R7fSZI6-5?alt=media&token=4f626b07-9b3f-4e76-8529-5c687adbce21",
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_R7fSZI6-5",
        id: "BcyvdOlkJ95xUcy0l5Fz",
      },
      {
        order: 4.46875,
        title: "축구장이 멋진 우리학교",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_KnOTjU4-e?alt=media&token=2ac15edf-2975-416f-90a1-365da68af52d",
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_KnOTjU4-e",
        id: "EUN3abUkzUPre7reE3zZ",
      },
      {
        path: "images/BOOTH_IMG_DLn0t0nl_IkiLObvf-",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_IkiLObvf-?alt=media&token=c7fb6f83-bca5-442c-b151-02c0b2f29de5",
        title: "학교생활 2",
        order: 21,
        id: "JaCerK5jjKUVxYr4znH4",
      },
      {
        order: 14,
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_mGdu1qOPP",
        title: "잇힝!~ 기부니가 좋앙 ♥♡♥",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_mGdu1qOPP?alt=media&token=96caf092-4e7e-46bc-9c53-f18607860a2d",
        id: "K1FHYlmijva2rj7n8JrV",
      },
      {
        title: "선취업 후진학을 위한 명사특강",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_fB5dnzQkg?alt=media&token=1c381770-1699-4f19-ab2b-8eab5f52d574",
        order: 7,
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_fB5dnzQkg",
        id: "MV1636PxKwKcXXDajVTo",
      },
      {
        order: 20,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_LcRhfetjS?alt=media&token=d462d44e-c75c-4e92-91bd-78f0a3f8608e",
        title: "대학진학",
        path: "images/BOOTH_IMG_DLn0t0nl_LcRhfetjS",
        id: "MjehfjtFvkahmyAtlC5d",
      },
      {
        title: "학교시설 2",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_WD3Khk7TY?alt=media&token=ac7d9ef9-6ba2-481d-906b-bb5e218f2a13",
        path: "images/BOOTH_IMG_DLn0t0nl_WD3Khk7TY",
        order: 23,
        id: "NGI6VYsQk7cB0PH6Pp8D",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_U5zU3DC_7?alt=media&token=9498808b-32b1-4a31-a60e-a49d1d1b2c24",
        order: 3,
        title: "세정고등학교에 오신것을 환영합니다.",
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_U5zU3DC_7",
        id: "OA6dLGyYbN8dObkTd8Pg",
      },
      {
        order: 16,
        path: "images/BOOTH_IMG_DLn0t0nl_QWt56IIat",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_QWt56IIat?alt=media&token=49e72291-73af-4c42-84a5-f479b890d90b",
        title: "금융정보과 1",
        id: "RliDQS1WpffnU83yTEEk",
      },
      {
        order: 18,
        path: "images/BOOTH_IMG_DLn0t0nl_sYNp9sgHm",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_sYNp9sgHm?alt=media&token=7fcef4e8-1277-4d1e-a92e-0ec7bd83bde6",
        title: "외식조리과 2",
        id: "Wa40LF7fvaQyod5KB85V",
      },
      {
        path: "images/BOOTH_IMG_DLn0t0nl_FZsGekC5i",
        order: 20,
        title: "학교생활 1",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_FZsGekC5i?alt=media&token=15b37c34-8c8e-4019-a499-1b91542eba8d",
        id: "XYi8oQKjkq5xu4LEkzU3",
      },
      {
        order: 25,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_AkuoNjHfy?alt=media&token=8917a4e8-da1c-408a-866a-145b8ad5189d",
        title: "학교시설 4",
        path: "images/BOOTH_IMG_DLn0t0nl_AkuoNjHfy",
        id: "d4O7mX9YjjNSphOIKIO3",
      },
      {
        path: "images/BOOTH_IMG_DLn0t0nl_47JRihhVu",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_47JRihhVu?alt=media&token=3e6ec534-af01-4541-9f40-c3921a669d05",
        order: 19,
        title: "보건간호과 1",
        id: "e1ww0pOeloOK0BLFIgOQ",
      },
      {
        title: "취업현황",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_kuVfnifYG?alt=media&token=bab12410-c600-4022-bcd4-2f969ac2cd93",
        order: 19,
        path: "images/BOOTH_IMG_DLn0t0nl_kuVfnifYG",
        id: "ej2LRkBKrx91TIMdcAnx",
      },
      {
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_KSy0N8xvd",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_KSy0N8xvd?alt=media&token=21d2d30b-9f62-4256-b650-0fcc172c3d3a",
        order: 6,
        title: "신나는 5월! 체육대회에서 스트레스 해소~ ♬",
        id: "jIfmalwXyXSmn1eN8tqg",
      },
      {
        path: "images/BOOTH_IMG_DLn0t0nl_50GHbrT0b",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_50GHbrT0b?alt=media&token=e6a8a772-6727-4121-9766-0a4e1f1f5de7",
        order: 17,
        title: "외식조리과 1",
        id: "l6KORiUxPVWINHKLGovD",
      },
      {
        path: "images/BOOTH_IMG_DLn0t0nl_HZRRo6UT6",
        title: "보건간호과 2",
        order: 20,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_HZRRo6UT6?alt=media&token=ac62241a-9c86-4bd7-97d8-1b6272318635",
        id: "l7k7MvCaKShWmbGac4P8",
      },
      {
        title: "학교시설 3",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_wxSYJ6eSB?alt=media&token=55e39d5f-1ad3-42c1-9da1-275621acbfc5",
        order: 24,
        path: "images/BOOTH_IMG_DLn0t0nl_wxSYJ6eSB",
        id: "mKea1xttE0f5JEMtA0o8",
      },
      {
        title: "아침등굣길! 허기를 채워주세요 ^0^",
        order: 13,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_QT8qzAmiC?alt=media&token=60d0e1c6-3b46-4e33-b17f-7c650c1c3c7a",
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2_QT8qzAmiC",
        id: "vKeaNeyt5FXbNSTuBvZL",
      },
      {
        order: 12,
        path: "images/BOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2__WTmy0BsL",
        title: "등굣길 음악회가 열렸어요~♬♪",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nlslOpnFpw8cq5MwYGIgb2__WTmy0BsL?alt=media&token=b06fa4a3-e9bc-4cde-ba11-fe36a6f21242",
        id: "x6zL1i6c13f4fDcFh7J6",
      },
      {
        path: "images/BOOTH_IMG_DLn0t0nl_lzuRDtHPZ",
        title: "금융정보과 2",
        order: 17,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DLn0t0nl_lzuRDtHPZ?alt=media&token=410a2811-6e42-4c51-adfd-a8cee2a874da",
        id: "zQWT6APltBHmZvC9Wiij",
      },
    ],
    videos: [
      {
        order: 3,
        url: "https://youtu.be/WivKdUCu50I",
        title: "부산교육의 리더 세정고!",
        id: "2kNWQhlzPfcfwfiTkW3x",
      },
      {
        title: "등굣길에 멋진 음악회가 열렸어요~♬",
        url: "https://youtu.be/ZB5Mj9eVoiA",
        order: 12,
        id: "35KhXQdy7VNJB4fwUkZ3",
      },
      {
        order: 1.1875,
        title: "1타 학생의 조리법(Feat. 조리기능경기대회)",
        url: "https://www.youtube.com/watch?v=ZVrgtaeCJAc&t=27s",
        id: "BD0erq2lekVvZgcCWgEe",
      },
      {
        url: "https://youtu.be/hFrGsIj1_NQ",
        title: "메인영상",
        order: 1,
        id: "G6OOKfOHkMwikHgvjdYI",
      },
      {
        url: "https://youtu.be/sU6illQs9A8",
        order: 10,
        title: "금연은 사랑입니다.♥",
        id: "HEUOW0T47BL2SuYqJcQc",
      },
      {
        url: "https://www.youtube.com/watch?v=TBRMgIcftZI",
        title: "2021대한민국 첼린지컵 국제요리경연대회 준비 및 입상 영상",
        order: 1.125,
        id: "KnJCHavOl512vXuRnuAo",
      },
      {
        order: 4,
        url: "https://youtu.be/C7ZraCt7lrA",
        title: "중소기업 브이로그 '세정고로 go!go!'",
        id: "Qplm8ONFw83L8n5foSAt",
      },
      {
        order: 1.734375,
        title: "세정고등학교 홍보영상 ♥",
        url: "https://youtu.be/87_qH-uFwwA",
        id: "UW6U8h9A2nINH6cM1d6D",
      },
      {
        url: "https://www.youtube.com/watch?v=yRYbkpjmH2c&t=57s",
        order: 1.25,
        title: "보건간호과 실습 후기",
        id: "Wic4G8KVxNNjU3dJmTfk",
      },
      {
        order: 17,
        title: "세정고등학교 공연",
        url: "https://www.youtube.com/watch?v=53da1EU1fsk&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=14://youtu.be/D3WX-BtgaGI",
        id: "g2q2asKVbG1qQPbj2ri5",
      },
      {
        title: "우리 1일 1깡 해요 ♬",
        url: "https://youtu.be/XgcEGCjC8dU",
        order: 4.75,
        id: "khxrZO0UgIxKMWDAdKzO",
      },
      {
        url: "https://www.youtube.com/watch?v=B8LfvkmweeU",
        title: "등굣길 음악회",
        order: 1.625,
        id: "mhiILnOAX7xgF3mUj0UF",
      },
      {
        url: "https://youtu.be/lqn22xYRhjM",
        title: "세정 바리스타 ^∇^",
        order: 8,
        id: "rHtcTzdGIqZL4yz75vE2",
      },
      {
        url: "https://www.youtube.com/watch?v=oml61nYOJ6M",
        order: 1.4375,
        title: "세정고 진로체험 찾아Dream",
        id: "tblXxPIUwUwfGRjwSz8h",
      },
      {
        title: "예비 세정인들의 즐거운 진로체험 ⅱ *^__^*",
        order: 6,
        url: "https://youtu.be/MV15H3WuWIg",
        id: "vSPH031T94mxLXOMDtRG",
      },
      {
        order: 7,
        title: "칠리새우를 만들어 보아요!",
        url: "https://youtu.be/BeIsXJ1xWYY",
        id: "xJ8Fol0TRw430iufN6up",
      },
      {
        title: "미리보는 고교생활",
        url: "https://www.youtube.com/watch?v=D-_s3FR0b_0&t=18s",
        order: 1.34375,
        id: "xskKcs7z4k7yvD79mSFk",
      },
    ],
  },
  DfhXhzqk: {
    id: "DfhXhzqk",
    documents: [
      {
        path: "documents/WcHf1wblS_팜플렛.pdf",
        title: "2023학년도 신입생모집 팜플렛",
        order: 2,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FWcHf1wblS_%ED%8C%9C%ED%94%8C%EB%A0%9B.pdf?alt=media&token=4570192e-7ba2-4999-ba31-47e241b5090a",
        id: "BSGed11bY25w0h9k6fUE",
      },
      {
        title: "2023학년도 신입생모집 포스터",
        path: "documents/tm9SQhCHt_포스터.pdf",
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Ftm9SQhCHt_%ED%8F%AC%EC%8A%A4%ED%84%B0.pdf?alt=media&token=8e4c4d33-8825-496d-91aa-cc9b9481e120",
        id: "ILSviPhfCIK0pJPh5kpd",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FAPFPVc-CP_%EC%A0%84%EB%8B%A8%EC%A7%80.pdf?alt=media&token=6dab8908-0210-4fd0-a4a5-94b648c6431d",
        title: "2023학년도 신입생모집 전단지",
        order: 2,
        path: "documents/APFPVc-CP_전단지.pdf",
        id: "VWqTi7NpKpUlceGkyOTg",
      },
    ],
    images: [
      {
        title: "행복한 아침맞이(사랑한DAY)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_6__PDJ0n7?alt=media&token=e8f4234e-f4c2-4ffa-a66c-684c528a86fb",
        order: 14,
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_6__PDJ0n7",
        id: "2IcfjSLI2dYevMo36XBK",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_FaO5v6q6P?alt=media&token=d92e80ef-e95b-4310-acb3-fac733625572",
        order: 8,
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_FaO5v6q6P",
        title: "직업계고를 선도하는 학교",
        id: "3Z2Kk8iczCTl0dLxx8Cy",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_NiRuDs2ds?alt=media&token=366914a4-df98-4905-a418-a7d75dd39734",
        title: "학생이 행복한 학교",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_NiRuDs2ds",
        order: 12,
        id: "6pDP763Fb25mo3RRuAkQ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_CK5Ja-Pp3?alt=media&token=cace8397-23c4-4e4d-bf4a-5a295aa27288",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_CK5Ja-Pp3",
        order: 6,
        title: "교정이 아름다운 학교",
        id: "7zuDgyc14VjAX2p01opq",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk_y_U9yvbXr?alt=media&token=b5c110e6-2a8b-4535-9143-3f5ba5c06c0f",
        order: 4.75,
        path: "images/BOOTH_IMG_DfhXhzqk_y_U9yvbXr",
        title: "부사관과",
        id: "8btUqZkz6Bygl9kzgsoQ",
      },
      {
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_CmFVHSkdB",
        order: 17,
        title: "이웃과 함께하는 학교",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_CmFVHSkdB?alt=media&token=eafea89e-8f5d-475a-b36e-99eed77fe964",
        id: "914AzDleAbQSParOI5R6",
      },
      {
        title: "학생이 즐거운 학교",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_r8oHnrKH2",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_r8oHnrKH2?alt=media&token=ad8ceb3a-7372-40dc-b121-13edcec9c655",
        order: 5,
        id: "Au85rAQEhe0UYbIfKYcC",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_hOTn_WJsn?alt=media&token=40551a21-740c-4cfa-bc6d-5dc2117e7f47",
        order: 23,
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_hOTn_WJsn",
        title: "언론보도(행복한 아침맞이 작은음악회)",
        id: "Cbw9TanO5yt65UF4cz0u",
      },
      {
        order: 11.5,
        title: "부사관과",
        path: "images/BOOTH_IMG_DfhXhzqk_ZdoxgbINA",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk_ZdoxgbINA?alt=media&token=e52c0d99-740d-4718-b443-1a04deec17cc",
        id: "DBUZTyrGyinoeuFwQXtb",
      },
      {
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_BpuUXYN_l",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_BpuUXYN_l?alt=media&token=f8da81a8-ac98-46bd-8374-8a46dc3fd90e",
        order: 22,
        title: "학교 헬스장(체육실)",
        id: "FTAJsYkoXo8K7MbX7CjS",
      },
      {
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_Z_-A7965i",
        order: 15,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_Z_-A7965i?alt=media&token=78e2a5a6-f755-4dae-876e-bc6da4518667",
        title: "사랑의 아침밥상(밥심)",
        id: "Hyd7FrBEafZS0oxXyVWj",
      },
      {
        title: "사무경영과",
        order: 4,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_1EiRgTp0k?alt=media&token=c9c3e622-d8c0-4bb4-b22c-f39f2366b8a8",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_1EiRgTp0k",
        id: "Lhm5Ju0ax1mp0n3LX3wX",
      },
      {
        title: "언론보도",
        order: 20,
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_Wu9HVEORs",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_Wu9HVEORs?alt=media&token=38ad1e1f-ab32-4227-9b29-fe6ec16200ef",
        id: "Lr4AaD4F3QgG2qirmTEH",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_1vBBcU4dL?alt=media&token=aed319e9-44a4-4452-8b2e-a15563d3f0f7",
        title: "MBC드라마 촬영학교(NCT출연)",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_1vBBcU4dL",
        order: 13,
        id: "OprHvmelAloIf93L3sWs",
      },
      {
        title: "웰빙조리과",
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_98mbz4qMH?alt=media&token=70aaeedb-f20e-4122-b42b-1a6c0bb9a26e",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_98mbz4qMH",
        id: "R1eFNOy9pCxg0klxOPWA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_dpoPBIcx9?alt=media&token=ea35bd46-08b8-4dbe-9a2c-deef1fc6244b",
        order: 24,
        title: "영산고 사회적협동조합 언론보도",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_dpoPBIcx9",
        id: "S3jCPyhvsH26GxHmM2s3",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_TZwlT7u5K?alt=media&token=258362fe-b091-48b4-bf37-556b3c7175a7",
        order: 2,
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_TZwlT7u5K",
        title: "웰빙조리과",
        id: "Txptex4MAYaMlUar3CYO",
      },
      {
        title: "보건간호과",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_UqcjjyYct",
        order: 10,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_UqcjjyYct?alt=media&token=f3734752-5409-47fd-980d-3dd570de9d53",
        id: "UOxaUzOKVCwSBX0qW4wd",
      },
      {
        title: "제3회 영산고 중3 랜선 조리대회 언론보도(부산일보 11.16일자)",
        order: 25,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_y3POdZ8__?alt=media&token=fb8f08f4-dec6-4110-a398-658ed01e6e9e",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_y3POdZ8__",
        id: "Wkp33qRXQMzCOaEtbq6L",
      },
      {
        order: 11,
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_agcjE6ryE",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_agcjE6ryE?alt=media&token=94da1d94-a4fb-4f08-be20-156f14092733",
        title: "사무경영과",
        id: "gS1bl6YaN7v5cb8bPpqx",
      },
      {
        order: 21,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_6rcC0cAcw?alt=media&token=7fe91f79-3f83-4dc7-9894-1b5a446743e3",
        title: "학교 헬스장(체육실)",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_6rcC0cAcw",
        id: "heeupyFNFGXwqY2KfPNO",
      },
      {
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_44lYqhoh0",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_44lYqhoh0?alt=media&token=292c5d5f-3890-4dc9-bd0b-8e55996a2f8b",
        order: 1,
        title: "행복한 아침맞이 사랑한DAY",
        id: "itQihxz1LfsxHesJvthN",
      },
      {
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_fVYuPwf2h",
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_fVYuPwf2h?alt=media&token=69d0aed6-9d81-4ea9-8f3e-7cf6529c78da",
        title: "보건간호과",
        id: "kDffBJ8FV7btadxvQSXJ",
      },
      {
        title: "영산놀이마루",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_W4TadmjkE",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_W4TadmjkE?alt=media&token=36178b56-d9c4-4d0a-960d-103ca5c186bd",
        order: 16,
        id: "qRSwpJzEVnfpWUyPGyeI",
      },
      {
        order: 19,
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_eo0NFHSAY",
        title: "교정이 아름다운 학교",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_eo0NFHSAY?alt=media&token=9f8bb2ab-2cae-471b-88ec-3232e16e329a",
        id: "qhYpDDlYiYHv47xHt8jC",
      },
      {
        title: "직업계고를 선도하는 학교",
        order: 18,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_PIOwhV-wy?alt=media&token=0df28b1c-8e78-415c-8011-c5a0c5294e4a",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_PIOwhV-wy",
        id: "r38N3OQb4Dg3NhZZP4US",
      },
      {
        title: "교정이 아름다운 학교",
        path: "images/BOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_REMTSp767",
        order: 7,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_DfhXhzqk1obfuIaJoybyn8yFwci2_REMTSp767?alt=media&token=a92e9f53-6c51-42ff-a893-813f7e6e9a36",
        id: "zSgW2em8sHpOsOoOBZo2",
      },
    ],
    videos: [
      {
        order: 2.546875,
        url: "https://www.youtube.com/watch?v=L7pfWChigec",
        title: "똥 밟았네_패러디_포텐독챌린지",
        id: "2wHQyBEj0gMrppMVMqCB",
      },
      {
        order: 21.5,
        url: "https://youtu.be/OKUIgbrmpYU",
        title: "2018 영산고(성심보건고) 뻔뻔데이",
        id: "5aTfQh78GvzmfkFeB3PH",
      },
      {
        url: "https://youtu.be/RSCeh9oPKwI",
        order: 3.31103515625,
        title: "2021년 부사관 견장수여식",
        id: "5spQHxkxaH8d3dgtBuao",
      },
      {
        url: "https://youtu.be/-fxLmWkVvU8",
        title: "스타트-업 꿈노리",
        order: 2.37890625,
        id: "7XV359DEeBqeJjxas6uC",
      },
      {
        order: 8.5,
        title: "영산고 2020 신입생모집 홍보 영상 20190622",
        url: "https://youtu.be/4mGa8vh6eFo",
        id: "8M8ITUDTIDuZqm1jaD13",
      },
      {
        title: "영산고#꿈틀#점심시간#흔히 보는 댄스실력",
        url: "https://youtu.be/_C_LXyzqUmM",
        order: 3.259765625,
        id: "AtbyqcUbCaI8ancMNFyO",
      },
      {
        order: 3.26416015625,
        title: "조리경진대회_중3대상",
        url: "https://youtu.be/HfpNLQr75as",
        id: "CBJfrnTTR6SoigImOIZW",
      },
      {
        title: "영산고 벚꽃 축제",
        url: "https://youtu.be/vYNItqecfJY",
        order: 7,
        id: "CM1XPZyws6r7pevfp6Q6",
      },
      {
        title: "영산고 신입생 입학설명회",
        order: 3.3110408782958984,
        url: "https://youtu.be/TiO39cDGQCY",
        id: "D0GgIgHXvSPJSY3k6nbV",
      },
      {
        title: "영산고# 20190517 뻔뻔데이(fun fun day)",
        url: "https://youtu.be/MACiIcCQ35I",
        order: 21.25,
        id: "DuDjuqIr4RotJn8LZQbs",
      },
      {
        order: 1.875,
        url: "https://youtu.be/8F0sQlw45Go",
        title: "2021년 영산고 학교소개",
        id: "EPYHPVpdSZ2tQk9LaiIx",
      },
      {
        title: "2021학년도 벚꽃DAY",
        order: 3.3026123046875,
        url: "https://www.youtube.com/watch?v=DfCi4K7gQwU",
        id: "FhbGLLWd89KxuBzCTj2Y",
      },
      {
        url: "https://youtu.be/JofQqSOgiN0",
        title: "2021년 다도ㆍ예절교실 부산교통방송 소개",
        order: 3.27001953125,
        id: "JBikgeaN2Y6sKMgIeDKl",
      },
      {
        order: 3.3125,
        url: "https://youtu.be/taeVhPNVbec",
        title: "영산고 작은음악회(11월 아침맞이)",
        id: "KlS230SjBjxa5JJdY5Qd",
      },
      {
        url: "https://youtu.be/xmFq3pqF-1A",
        order: 3.311126708984375,
        title: "#영산고#부산#MBC라디오#학교장인터뷰#꿈틀#학교장노래#노래방#12월12일방송예정",
        id: "LT030JJxMcdhwOm4ne8j",
      },
      {
        url: "https://youtu.be/B_vUnQ-w5xM",
        title: "2학년 부사관반 아침구보_드론촬영",
        order: 3.2744140625,
        id: "NdEP4YfWVQ19SnhJMBQ9",
      },
      {
        order: 3.3037109375,
        url: "https://youtu.be/nO89P3kR-M4",
        title: "영산고_자유공간 꿈틀 공연",
        id: "PF3R8aWS5Ulfy3Ot7aTS",
      },
      {
        title: "중3대상 조리캠프",
        url: "https://youtu.be/hy02zzUuW9k",
        order: 3.265625,
        id: "PunGUa9IXxTthGbf2I4K",
      },
      {
        order: 1.78125,
        url: "https://www.youtube.com/watch?v=u_4pNGmRi7s",
        title: "영산고 2022학년도 신입생 모집",
        id: "QWrLm5DHMVVh1EKj9Fzj",
      },
      {
        order: 3.2626953125,
        title: "자유공간 꿈틀에서 놀자",
        url: "https://www.youtube.com/watch?v=JQom28GnYO0",
        id: "SWKBK4z9ykTDi5UIAHPy",
      },
      {
        url: "https://youtu.be/ME7vBkx7PnE",
        order: 3.311046600341797,
        title: "학교소개",
        id: "VCjR8qnkXGnLQAJs4QH4",
      },
      {
        title: "2021년 1학년 현장체험_알로이시오 기지 1968",
        order: 3.2637939453125,
        url: "https://www.youtube.com/watch?v=NVKVjWKFY6c",
        id: "VHrMNKCN5hfvMVQtpN7R",
      },
      {
        title: "#영산고​#부산​#부사관반​#부사관​#정복​#사무경영과​#웰빙조리과",
        url: "https://youtu.be/ar-g3wSN0A4",
        order: 3.3110580444335938,
        id: "YDlMiCjdunsI3z2uJUSs",
      },
      {
        order: 3.3114013671875,
        title: "#영산고#부산#MBC#부라보#교복관련인터뷰#행복한아침맞이작은음악회",
        url: "https://youtu.be/-odXq0gF2YI",
        id: "YZDaVdi312lzknJvZ7tm",
      },
      {
        url: "https://youtu.be/KUOXpQoYTkg",
        title: "학교 홍보영상",
        order: 1.8125,
        id: "YwZ9J9VRTzagLVAKG5Qu",
      },
      {
        order: 3.30224609375,
        title: "부사관반 국민체력100 측정",
        url: "https://youtu.be/PtGUlJYtTbw",
        id: "b49Xy29uNNVcV8Pt4GxM",
      },
      {
        url: "https://youtu.be/l4vvYq0FhYU",
        title: "영산고 댄스부",
        order: 4.9921875,
        id: "eRhavLVdHFiljHYw0rhC",
      },
      {
        order: 2.8828125,
        url: "https://youtu.be/25u_EanyGug",
        title: "꿈내려온다 1편",
        id: "f5fHUjmnW17qSmaz2f7M",
      },
      {
        url: "https://youtu.be/OGYWbC0Vwc0",
        title: "영산고 2019년 부산 직업교육박람회",
        order: 21.125,
        id: "gHTJl3pY6TTeK0qfaQil",
      },
      {
        order: 3.311767578125,
        title: "영산고#부산#제3회 영산고 중3대상 랜선 조리대회#주요장면#조리과#라면#조리대회#웰빙조리과",
        url: "https://youtu.be/s96kZJrl958",
        id: "gKIBpjkRCoSs7vaVbDeK",
      },
      {
        order: 3.984375,
        url: "https://youtu.be/qyzM2i01bnU",
        title: "영산고#부산#독도사랑#MBC#한국사 미술 융합수업#독도마스크",
        id: "hFEcuStsdjd0rg9rSTTu",
      },
      {
        title: "#영산고#부산#2021학년도#학생회장선거",
        url: "https://youtu.be/Zp0EiMnyAWg",
        order: 3.31121826171875,
        id: "m3VLXl4PGKepEOCgAB5e",
      },
      {
        title: "2021년 MBC출연_다도&예절교육",
        url: "https://youtu.be/P2JCnq9SVKA",
        order: 3.283203125,
        id: "m4fe2PZfCzBXxspP8bmQ",
      },
      {
        title: "영산고 2023학년도 신입생 모집 진로체험",
        order: 0.875,
        url: "https://www.youtube.com/watch?v=G2bMiMrEvxQ",
        id: "nh52cygIsfvHYj1xdxp4",
      },
      {
        title: "영산고 2022 전국상업경진대회 출전",
        order: 1.3125,
        url: "https://www.youtube.com/watch?v=7o99ftzgM9g",
        id: "od1lpFwirCrhTEp50gHj",
      },
      {
        url: "https://youtu.be/6GTkjAughXc",
        order: 4.65625,
        title: "영산고#부산#직업계고학점제#학교밖교육과정#영산대 조리과",
        id: "on3YMIGyitFGBAiB5upl",
      },
      {
        order: 3.3110809326171875,
        title: "#영산고#부산#2021학년도#입학설명회#웰빙조리과#보건간호과#사무경영과#부사관반",
        url: "https://youtu.be/6BxAIBThcDA",
        id: "owg6fI0xzOGCJrVgW4vw",
      },
      {
        order: 3.302978515625,
        url: "https://www.youtube.com/watch?v=9X9TFnL_TeE",
        title: "2021년 제11회 등불의천사 선서식",
        id: "pD10Gg0p9VjKUx7vJYpV",
      },
      {
        order: 3.31158447265625,
        url: "https://youtu.be/j-x6Nths5BQ",
        title: "#영산고#부산#중학교선생님대상#제과제빵연수#학교투어#놀이마루#이정혁#박성기#영산고댄스실력",
        id: "tosKnaHwd8cEd1bCFzMy",
      },
      {
        title: "2021년 스승의날 행사",
        order: 3.30078125,
        url: "https://youtu.be/NH1wodE1sfc",
        id: "yYuZKuvHfM0zAs6E548a",
      },
      {
        title: "제 11회 전국상업경진대회 출품작_군사랑 동아리",
        url: "https://www.youtube.com/watch?v=CJOJG6eEdmo",
        order: 2.294921875,
        id: "zpUlIx71xyG0EoHsZ5ON",
      },
    ],
  },
  H4BMtwro: {
    id: "H4BMtwro",
    documents: [
      {
        order: 0.5,
        title: "2023학년도 부산정보고등학교 입학 전형 요강",
        path: "documents/ekV-Lp-Bo_2023학년도 부산정보고등학교 입학 전형 요강.hwp",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FekV-Lp-Bo_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EC%A0%95%EB%B3%B4%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%20%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.hwp?alt=media&token=0d4d1d8c-37f5-48be-8866-c3652c0d4966",
        id: "Oz2xf7169wtoF7bn4UM8",
      },
      {
        path: "documents/clsr5xqwe_부산정보고등학교 모집학과안내.pdf",
        title: "부산정보고등학교 모집학과안내",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2Fclsr5xqwe_%EB%B6%80%EC%82%B0%EC%A0%95%EB%B3%B4%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EB%AA%A8%EC%A7%91%ED%95%99%EA%B3%BC%EC%95%88%EB%82%B4.pdf?alt=media&token=6285056a-eeec-43de-91fa-27c6dcf3cd61",
        order: 1,
        id: "TY55ALHN2kGNO9u2lTKH",
      },
      {
        title: "부산정보고등학교 진학안내",
        path: "documents/gpc_5yAAX_부산정보고등학교진학 안내문.pdf",
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2Fgpc_5yAAX_%EB%B6%80%EC%82%B0%EC%A0%95%EB%B3%B4%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%EC%A7%84%ED%95%99%20%EC%95%88%EB%82%B4%EB%AC%B8.pdf?alt=media&token=6e62250e-1d2a-4067-9f2d-5235a1d601a7",
        id: "ooIObuqWydOiOrP4YrIv",
      },
      {
        title: "부산정보고등학교 학교자랑",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FaKHJS_ZPQ_WQ-k7lUVj_03.%ED%95%99%EA%B5%90%EC%9E%90%EB%9E%913.jpg?alt=media&token=0eb0c01c-c0a7-40df-9f9e-49c6bce859ce",
        order: 5,
        path: "documents/aKHJS_ZPQ_WQ-k7lUVj_03.학교자랑3.jpg",
        id: "sgoY8aGe1mt2QYE536K7",
      },
    ],
    images: [
      {
        order: 10,
        path: "images/BOOTH_IMG_H4BMtwro_xP63VYzGp",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_xP63VYzGp?alt=media&token=1d704018-9b4a-44c3-95af-4fdef39b1921",
        title: "09.학과소개(관광서비스과)",
        id: "5klNt4xqtGD8TXd29nX4",
      },
      {
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_NMsgQZne5?alt=media&token=8c2487da-1d0d-46c5-aaaf-6f19fcf17e27",
        path: "images/BOOTH_IMG_H4BMtwro_NMsgQZne5",
        title: "00.포스터",
        id: "Aa5VINRKr5iKWW4v2yZ1",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_uYS4rT9Dn?alt=media&token=5d1dbe02-26b8-4abf-9af1-dfba371c8b3b",
        order: 2,
        path: "images/BOOTH_IMG_H4BMtwro_uYS4rT9Dn",
        title: "01.표지",
        id: "OZVEIZD2qAMcIhuJHosZ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_qCL-18nHx?alt=media&token=f3c2213c-4506-4765-b696-18a06f71be60",
        path: "images/BOOTH_IMG_H4BMtwro_qCL-18nHx",
        title: "02.인트로",
        order: 3,
        id: "P8zdFoOEUmW6TKJcxe4n",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_7MSoWaw16?alt=media&token=99e0bb4d-47f6-4f0c-bd61-e5dfc98da77b",
        order: 20,
        title: "19.입학전형요강",
        path: "images/BOOTH_IMG_H4BMtwro_7MSoWaw16",
        id: "RBxJkl1pnilUniEEhgeL",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_IjpO0760Y?alt=media&token=e31fc8be-41ef-48af-b037-605e431db0a3",
        path: "images/BOOTH_IMG_H4BMtwro_IjpO0760Y",
        title: "06.학교시설",
        order: 7,
        id: "St7VOHCcN49wx57xE1iS",
      },
      {
        order: 21,
        title: "20.에필로그",
        path: "images/BOOTH_IMG_H4BMtwro_N9Upmchjw",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_N9Upmchjw?alt=media&token=e1a4db92-0e3c-483f-970a-808db873a95b",
        id: "UJwkuRKeO6uSTgTXDLJl",
      },
      {
        order: 18,
        title: "17.취업및진학현황",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_gb3zsAL5k?alt=media&token=32446645-83c0-4ff2-8145-4841adbcd38f",
        path: "images/BOOTH_IMG_H4BMtwro_gb3zsAL5k",
        id: "XCPiwDxYNxA78rIm2XiR",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_viMoQiUYa?alt=media&token=ae4345ba-c6b5-40ef-8d33-e1bcbe9e6005",
        order: 15,
        title: "14.재학생인터뷰",
        path: "images/BOOTH_IMG_H4BMtwro_viMoQiUYa",
        id: "Yyo7pllds1Ul30VdKqmQ",
      },
      {
        title: "08.학과소개(경영회계과)",
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_ediSAopVI?alt=media&token=127b008c-a3d3-40a5-9f0b-783250220c4c",
        path: "images/BOOTH_IMG_H4BMtwro_ediSAopVI",
        id: "ZrEhJzUys21EFA7uLTq6",
      },
      {
        path: "images/BOOTH_IMG_H4BMtwro_NuzuzN76H",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_NuzuzN76H?alt=media&token=6357d822-1066-4b58-a66f-057957ba6204",
        title: "04.학교행사",
        order: 5,
        id: "dzkmnCjHu14LcMVNrArx",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_dxy4V_t5x?alt=media&token=c3dbad39-255c-4d5a-976d-d1344fee17f1",
        title: "16.취업성공영광의얼굴",
        path: "images/BOOTH_IMG_H4BMtwro_dxy4V_t5x",
        order: 17,
        id: "fmry8owVAh4Og5VwzhBF",
      },
      {
        path: "images/BOOTH_IMG_H4BMtwro_1ai-O5tos",
        title: "03.입학부터졸업까지",
        order: 4,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_1ai-O5tos?alt=media&token=060619e7-0d81-4a37-9121-ea69dd7a3948",
        id: "frOz9IpmNs9qOMoLJqvB",
      },
      {
        path: "images/BOOTH_IMG_H4BMtwro_KcyTwI3K0",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_KcyTwI3K0?alt=media&token=c8964740-c397-466f-bb83-c87e920d47b9",
        order: 19,
        title: "18.학교사업",
        id: "gIbZcj4siGFtjDm5VYYa",
      },
      {
        title: "11.주요실습장비소개",
        path: "images/BOOTH_IMG_H4BMtwro_ddGnfq3OL",
        order: 12,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_ddGnfq3OL?alt=media&token=db6d21a8-ec3d-4c48-b011-0ef158dc391d",
        id: "jQgIjeUgtOkFAmNDx4Gv",
      },
      {
        title: "15.취업생인터뷰",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_tJEwEzSVM?alt=media&token=b8412178-8d43-4a0f-a7cc-285eba726ea5",
        order: 16,
        path: "images/BOOTH_IMG_H4BMtwro_tJEwEzSVM",
        id: "mGZ5z6URbk3AFWRkCagL",
      },
      {
        title: "12.동아리소개-1",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_1J6FC4_V1?alt=media&token=a8fbeab2-d290-47c5-9826-766a29f5b27e",
        path: "images/BOOTH_IMG_H4BMtwro_1J6FC4_V1",
        order: 13,
        id: "pEjlR3onPXumoz2mbFhN",
      },
      {
        order: 6,
        path: "images/BOOTH_IMG_H4BMtwro_YjrSrbOTh",
        title: "05.학교자랑",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_YjrSrbOTh?alt=media&token=75aced36-f795-4ec9-b404-32fa690ac5c4",
        id: "tqe5MUfYjM2trSd7bSHu",
      },
      {
        order: 8,
        title: "07.학과소개(경영금융과)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_ffn9REd16?alt=media&token=0ff961d7-54b8-4447-ad3b-ae6a159aac35",
        path: "images/BOOTH_IMG_H4BMtwro_ffn9REd16",
        id: "vxX3vCR50JYL0uCwF0p2",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_C58CKuhun?alt=media&token=7313a8d2-976f-4569-a6c9-3f3baba4aaa7",
        order: 14,
        path: "images/BOOTH_IMG_H4BMtwro_C58CKuhun",
        title: "13.동아리소개-2",
        id: "xaKQlXjRZ2sz3r3tWDmd",
      },
      {
        title: "10.학과소개(소프트웨어콘텐츠과)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_H4BMtwro_UcVyFEM9t?alt=media&token=b883846e-a964-493a-9288-d697139499a5",
        order: 11,
        path: "images/BOOTH_IMG_H4BMtwro_UcVyFEM9t",
        id: "xiOMzIyeC7dNNQYHKBGH",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/0kKeq233MMU",
        title: "KB국민은행 합격자 인터뷰 영상",
        order: 4,
        id: "0Kg6cDV83gOCglh3BnTV",
      },
      {
        url: "https://www.youtube.com/watch?v=EWuD3X-FpNo&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=11",
        title: "부산정보고등학교 공연",
        order: 6.5,
        id: "1DHhmc0UXILR0D5Kq0Xo",
      },
      {
        url: "https://youtu.be/-XTTKzndCDo",
        order: 1.75,
        title: "부산정보고등학교 학교 시설 안내",
        id: "5twvEaBR84PZnvgOvm1m",
      },
      {
        url: "https://youtu.be/9xWRtS6Yl6M",
        title: "부산정보고등학교 밴드부",
        order: 20,
        id: "8T5zP9oiXwr8PvtBHDo2",
      },
      {
        title: "부산정보고등학교 댄스부",
        order: 10,
        url: "https://youtu.be/2mkJVg9E7rc",
        id: "A2cOpILjP8ZOIqBMkfwu",
      },
      {
        title: "부산정보고의 자랑_웹툰편",
        order: 1.5,
        url: "https://youtu.be/zoHXwiZEzmk",
        id: "CA7S2gAj138mEGmb7yXb",
      },
      {
        order: 11,
        title: "부산정보고등학교 청소년 연극제 영상 - '흔적'",
        url: "https://youtu.be/ufRkWphp6Ag",
        id: "CPPdskehRzIy4VQeXvAg",
      },
      {
        url: "https://youtu.be/Q0aC2hAbLRw?t=8",
        title: "부산정보고등학교 방송부",
        order: 18,
        id: "ENjP21E3yPUarH0rtB4E",
      },
      {
        order: 9,
        url: "https://youtu.be/eIJcyLOIto4",
        title: "부산정보고등학교 홍보부",
        id: "IPbZSDwC421Bj0env3wc",
      },
      {
        title: "부산정보고등학교 RCY",
        url: "https://youtu.be/Ggxbj1m7pYA",
        order: 13,
        id: "LdzeKubZHlUsXM72hucE",
      },
      {
        url: "https://youtu.be/AhI0WNFDek8",
        title: "2022 부산정보고등학교 홍보 영상",
        order: 0.125,
        id: "NQQ5idOosscfVhjKgrX0",
      },
      {
        title: "부산정보고등학교 파라미타반",
        url: "https://youtu.be/qVdLoVXCZLE",
        order: 12,
        id: "R7qiCTo5rDy5PWH4KSxq",
      },
      {
        order: 16,
        title: "부산정보고등학교 금융동아리",
        url: "https://youtu.be/tZfKIP4za_g",
        id: "TF8S8oGODWJFzitO13iS",
      },
      {
        url: "https://youtu.be/BABb_lv_86I",
        title: "부산정보고등학교 학교홍보영상",
        order: 7,
        id: "Vgfy3dyY7vHmqck0NiJg",
      },
      {
        url: "https://youtu.be/oEIrozrjqGk",
        title: "메인영상",
        order: 0.25,
        id: "ZhEe67EHBfoD2Acb8xNF",
      },
      {
        order: 14,
        url: "https://youtu.be/sIwOUyDjYyM",
        title: "부산정보고등학교 3D프린팅반",
        id: "bnO5o1GzG0vTC7yNs1tc",
      },
      {
        order: 19,
        url: "https://youtu.be/qsE3wQu6lCk",
        title: "부산정보고등학교 연극부",
        id: "kRYuubtcIUoRtY5kwhDl",
      },
      {
        title: "180113 금융고 장학퀴즈 부산정보고",
        url: "https://youtu.be/oAgkxOhroLQ",
        order: 8,
        id: "lykWt9pxeRmFvZhLa4zZ",
      },
      {
        order: 17,
        url: "https://youtu.be/9CqdH7XufBs",
        title: "부산정보고등학교 취업코칭반",
        id: "reXZrop0SEEpoymzBCTY",
      },
      {
        url: "https://youtu.be/3-_kXUeQaHg",
        title: "2021 부산정보고등학교 홍보영상 ",
        order: 0.5,
        id: "zHd85PWSecfjSrpK4wlk",
      },
      {
        title: "부산정보고등학교 창업부",
        url: "https://youtu.be/nwICg-VonBI",
        order: 15,
        id: "zjVqaJV42UKDgHjQR4y0",
      },
    ],
  },
  HZHRrY4K: {
    id: "HZHRrY4K",
    documents: [],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_HZHRrY4K_ij9BkFkbx?alt=media&token=1924d9e8-01fc-4a10-a071-4ad5fcf92893",
        title: "반려동물과(미용)",
        order: 3.75,
        path: "images/BOOTH_IMG_HZHRrY4K_ij9BkFkbx",
        id: "7QehLNpI2lwHvuq0vy6f",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_HZHRrY4K_rE3T3umeH?alt=media&token=d591d474-0c2a-409e-844f-34bb36712d4b",
        title: "외식베이커리과",
        order: 7,
        path: "images/BOOTH_IMG_HZHRrY4K_rE3T3umeH",
        id: "JKgHyW97KXnpKfRH677R",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_HZHRrY4K_-F6O3dZtL?alt=media&token=68b2d7f3-c414-418f-b845-a7bd1e989ed5",
        title: "카페경영과(바리스타)",
        order: 4,
        path: "images/BOOTH_IMG_HZHRrY4K_-F6O3dZtL",
        id: "T4QptukzTSvDao7IakUe",
      },
      {
        path: "images/BOOTH_IMG_HZHRrY4K_KOlBM1RFV",
        title: "세연고등학교 전경",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_HZHRrY4K_KOlBM1RFV?alt=media&token=319115fa-e492-47a5-867c-b9380f91df26",
        order: 2.75,
        id: "b0rEysTdEWgLYMBGMBOU",
      },
      {
        title: "카페경영과(서양조리)",
        order: 6,
        path: "images/BOOTH_IMG_HZHRrY4K_CeJk6wPUw",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_HZHRrY4K_CeJk6wPUw?alt=media&token=83e23fe0-5a6b-4bf2-9b52-e6db61fd445d",
        id: "bX0Z8BFZDQIm1ZYIEhJ1",
      },
      {
        title: "반려동물과(훈련)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_HZHRrY4K_Y1vVb-44t?alt=media&token=509c768b-bfa9-4c39-91a8-74f285248722",
        order: 3.5,
        path: "images/BOOTH_IMG_HZHRrY4K_Y1vVb-44t",
        id: "jhYWf1P1x0L9ZPc9xQ8b",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_HZHRrY4K_F4UWqVGat?alt=media&token=52af19c7-1631-49f5-bc34-1e227f1cd242",
        order: 3.125,
        title: "웹툰콘텐츠과",
        path: "images/BOOTH_IMG_HZHRrY4K_F4UWqVGat",
        id: "tt3K5ILL9WTcjpJ4D84w",
      },
    ],
    videos: [
      {
        title: "웹툰콘텐츠과",
        order: 3.5,
        url: "https://youtu.be/cZyerEDR4fM",
        id: "3bmSY0L342ZLW357d3pE",
      },
      {
        title: "세연고등학교 ",
        order: 13,
        url: "https://youtu.be/4ywN3HJVUH0",
        id: "6mrJKfQqGriGpapGlXkG",
      },
      {
        order: 11,
        title: "[세연고] 중학생진로체험(반려동물 미용)",
        url: "https://youtu.be/wCOXiT7eIXU",
        id: "CnoVcyfqYWjmxejesUUu",
      },
      {
        title: "카페경영과",
        url: "https://youtu.be/t5ld6MB1e6k",
        order: 6,
        id: "HVc4zt9Ua3RXhDQrs68z",
      },
      {
        url: "https://youtu.be/MxuhFjlCL7o",
        order: 3.25,
        title: "[세연고] 사제동행 예술축제",
        id: "J4J1LBSwmR1CNJUTt5Xv",
      },
      {
        title: "[세연고] 중학생진로체험(카페경영 서양조리)",
        order: 12.5,
        url: "https://youtu.be/IXyIES_6xAw",
        id: "MtieKlCdbp13d0O8axBP",
      },
      {
        order: 12,
        title: "[세연고] 중학생진로체험(카페경영 바리스타)",
        url: "https://youtu.be/OpomUQSoZVw",
        id: "R5cus9Ci3pNsF60Bickp",
      },
      {
        url: "https://youtu.be/7jdepedlehM",
        order: 12.75,
        title: "[세연고] 중학생진로체험(외식베이커리과)",
        id: "VtdDH3OfN7FDCcs5RmsB",
      },
      {
        order: 10.53125,
        url: "https://youtu.be/UVB_vJH7yjw",
        title: "[세연고] 중학생진로체험(웹툰콘텐츠)",
        id: "c2g0FgUNtYm44Dx27ru5",
      },
      {
        order: 9.5,
        title: "외식베이커과",
        url: "https://youtu.be/MprjFqz4kIg",
        id: "dja13AVwtix2E8crIPCY",
      },
      {
        title: "반려동물과",
        order: 4,
        url: "https://youtu.be/Ws76kOzCxlo",
        id: "tuzQtQZdrJc7m9p4Odg3",
      },
      {
        order: 10.6875,
        url: "https://youtu.be/ctaFVerqKMk",
        title: "[세연고] 중학생진로체험(반려동물 훈련)",
        id: "xI59cCiQCedKrwldWQN4",
      },
    ],
  },
  Hx03wj3i: {
    id: "Hx03wj3i",
    documents: [
      {
        path: "documents/AaoVd5XEF_대양고등학교_2023학년도 대양고등학교 입학전형 요강(안)_최종 확정본.hwp",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FAaoVd5XEF_%EB%8C%80%EC%96%91%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%8C%80%EC%96%91%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95(%EC%95%88)_%EC%B5%9C%EC%A2%85%20%ED%99%95%EC%A0%95%EB%B3%B8.hwp?alt=media&token=ad80a418-268e-4d2d-b2a5-feccd02c58f6",
        order: 2,
        title: "2023학년도 대양고등학교 입학전형 요강",
        id: "bCWKzH1RegZrzCLKo7aU",
      },
    ],
    images: [
      {
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_FNVJ6CJ5Y",
        title: "감사의 날 행사",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_FNVJ6CJ5Y?alt=media&token=1d870ef4-daa8-42f2-9d5f-fe5acd3ed299",
        order: 18,
        id: "17nrdvhrYRqG4sYWSJbe",
      },
      {
        order: 24,
        title: "대양소식지5월",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_dcN82SUJ1?alt=media&token=8c2191b3-61b8-42d8-b117-8012b6834a19",
        path: "images/BOOTH_IMG_Hx03wj3i_dcN82SUJ1",
        id: "1RSeNooxgJSArj9WOsQb",
      },
      {
        title: "체력단련실",
        path: "images/BOOTH_IMG_Hx03wj3i_S-c7f0arc",
        order: 30,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_S-c7f0arc?alt=media&token=fd02cb7d-a634-4015-90c4-921f0fb4d585",
        id: "4EvMljdjgEbMwUS29Z0R",
      },
      {
        title: "대양소식지8~9월",
        path: "images/BOOTH_IMG_Hx03wj3i_xzLBQQ5fa",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_xzLBQQ5fa?alt=media&token=23bc4ab3-6056-453d-8454-4dffc2cedd2d",
        order: 26,
        id: "7EHyFH3I6Y9b7lnxCc3x",
      },
      {
        title: "실습실 수업",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_m6itpjWO-?alt=media&token=0ac72018-2248-46ac-9cc6-cb7e514a2794",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_m6itpjWO-",
        order: 19,
        id: "7NLtVwA5xco4BLBrmYl1",
      },
      {
        order: 23,
        title: "대양소식지4월",
        path: "images/BOOTH_IMG_Hx03wj3i_mf39wDCMb",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_mf39wDCMb?alt=media&token=df9d2024-14e7-42e9-b583-9d82f2f00547",
        id: "8qYFE7HoPSwF9wai3cL4",
      },
      {
        order: 28,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_qH8DicsZg?alt=media&token=112205af-6c34-4e38-abfa-50e703cff504",
        title: "e스포츠동아리",
        path: "images/BOOTH_IMG_Hx03wj3i_qH8DicsZg",
        id: "AuASifymrKSmWKWcTcAt",
      },
      {
        order: 31,
        path: "images/BOOTH_IMG_Hx03wj3i_F2_8ZQOEF",
        title: "실습실수업",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_F2_8ZQOEF?alt=media&token=1c8c7dbc-25b4-4f55-aa3e-570288c03eea",
        id: "AvaVFsWRicrnTtUfXspG",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_JCEGMu5LI?alt=media&token=3849377d-2bc2-4adc-a8eb-6958e541bd97",
        order: 5,
        title: "IT네트워크과",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_JCEGMu5LI",
        id: "BijM2otDtRj5AlrZBwaz",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_ozhZQLZ-E?alt=media&token=42d5efb9-2294-4c46-9176-6c5209f131f7",
        title: "실습실 수업",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_ozhZQLZ-E",
        order: 20,
        id: "D3UP2dW0PNbZuwInqLA7",
      },
      {
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_k2neVvsDU",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_k2neVvsDU?alt=media&token=bdf15891-e28e-4373-a36a-56736f2b53e4",
        title: "전기전자과",
        order: 2,
        id: "Fy798ke3ws2GqyD9p0Tg",
      },
      {
        path: "images/BOOTH_IMG_Hx03wj3i_InA8fCRIW",
        order: 25,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_InA8fCRIW?alt=media&token=17eae77a-fc17-4c79-b03c-46492cd7b0f5",
        title: "대양소식지6~7월",
        id: "IlJAa4FdFXA40W4Swp4n",
      },
      {
        order: 6,
        title: "남천성당(신입생 환영 미사)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_ZHi9eHZ0_?alt=media&token=9c0cba1f-7262-4f7f-b493-8481b7f1cd3d",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_ZHi9eHZ0_",
        id: "Jw9BKKcbxUw91GvZmlt4",
      },
      {
        order: 20,
        path: "images/BOOTH_IMG_Hx03wj3i_Lx9hnbq8r",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_Lx9hnbq8r?alt=media&token=2a812a0e-da97-4e8d-8047-fba9e3d6c044",
        title: "학교리플렛",
        id: "MC08sra0sf5aSr7oSsfx",
      },
      {
        order: 7,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_8ioaGWwrH?alt=media&token=9be00965-18ae-446e-95f0-3cbb36f0a1f7",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_8ioaGWwrH",
        title: "체육대회",
        id: "PNNzqLUfAlgzmfwfTjHL",
      },
      {
        order: 28,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_8U92oK8g2?alt=media&token=44c013bf-ef98-428f-b759-adb1885b6b86",
        path: "images/BOOTH_IMG_Hx03wj3i_8U92oK8g2",
        title: "실습실수업",
        id: "PQLeRzW2xZ050Ugqx9JE",
      },
      {
        order: 10.5,
        title: "수업활동",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_aqFkcRxIu",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_aqFkcRxIu?alt=media&token=47c8a001-8417-409f-8faf-b265247757d0",
        id: "PQNbwPfwsZsW6uaVe6zG",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_a6mjEkjK_?alt=media&token=5a56db9a-6775-4246-8d91-54bd65f6c9a9",
        path: "images/BOOTH_IMG_Hx03wj3i_a6mjEkjK_",
        order: 26,
        title: "가을음악회",
        id: "QPV1es6pXMMCWU5npY0B",
      },
      {
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_zXYdEAV5q",
        title: "IT네트워크과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_zXYdEAV5q?alt=media&token=8a661bc8-6967-4d8b-8b3b-187f228dcfc4",
        order: 4,
        id: "UJWjad0LfLqRTdQlj5H7",
      },
      {
        order: 29,
        title: "실습실수업",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_3VuNuaN6I?alt=media&token=fa91cac7-220d-4d7d-addc-da9b3c480a9f",
        path: "images/BOOTH_IMG_Hx03wj3i_3VuNuaN6I",
        id: "Wq9WVw6AgcOZ9Kp0NViv",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_6dkwCO0A_?alt=media&token=d6038ca8-d082-4700-b236-f848c32d185b",
        title: "수업활동",
        order: 11,
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_6dkwCO0A_",
        id: "aG7ABKenaCB1UO1A8IZw",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2___T8y9q--?alt=media&token=80dd3518-2f72-45e8-a8ef-a9b3f321d850",
        order: 13,
        title: "2021년 부산광역시 기능경기대회 입상",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2___T8y9q--",
        id: "cwYqPqGwWVOVgTXzd9Dq",
      },
      {
        title: "학교 운동장",
        order: 24,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_l1ML5TA2R?alt=media&token=be6ae966-8c30-4f8e-8d6f-1b1d46e078ac",
        path: "images/BOOTH_IMG_Hx03wj3i_l1ML5TA2R",
        id: "he66b7eXpX5IEbW6x2m7",
      },
      {
        title: "전국e스포츠대회 시상",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_ltWwWxAnC?alt=media&token=e9b2537d-1538-4ece-a565-cf7d9599bfd1",
        order: 19,
        path: "images/BOOTH_IMG_Hx03wj3i_ltWwWxAnC",
        id: "iApwsjZLyPRh5mPkV4jq",
      },
      {
        path: "images/BOOTH_IMG_Hx03wj3i_71oUQLz5S",
        order: 21,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_71oUQLz5S?alt=media&token=a2274707-44f4-418c-bf61-85b91dea0a78",
        title: "학교리플렛2",
        id: "jQD1fLAjEvnm7wG8divi",
      },
      {
        title: "2023년 입학전형요강",
        order: 27,
        path: "images/BOOTH_IMG_Hx03wj3i_ezW95KTds",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_ezW95KTds?alt=media&token=6eb9e93b-41c4-4c0d-bcc3-5e1b4f6d9a88",
        id: "nPT2eBB83Xs2qNBjcVM9",
      },
      {
        order: 21,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_sikTn8drH?alt=media&token=bb38a9b7-fe04-40ad-9468-5c871108e32b",
        title: "실습실 수업",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_sikTn8drH",
        id: "nd0hrXuF3aILPRg5XTzi",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_2Py6vHuGI?alt=media&token=e7037578-2b63-4433-b5c3-25261f53c311",
        order: 3,
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_2Py6vHuGI",
        title: "전기전자과",
        id: "pg2y9llG1DOau0nqMBN2",
      },
      {
        title: "대양소식지3월",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_VObCGvZLc?alt=media&token=1f239f14-7c08-490a-a5fa-dc6112e40216",
        order: 22,
        path: "images/BOOTH_IMG_Hx03wj3i_VObCGvZLc",
        id: "qOzUJEcyQwnjeYEkdf67",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_aUK-2WRHN?alt=media&token=0c4f641b-2862-4103-95bf-b4039800047b",
        path: "images/BOOTH_IMG_Hx03wj3in6Vmd7Ov4oqdCNTl5Ds2_aUK-2WRHN",
        title: " 대양고등학교 전경",
        order: 1.25,
        id: "s82YqQx1reTWsRXID5p5",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Hx03wj3i_AfFD8VENA?alt=media&token=df17d6b7-105c-4032-a18a-130c2cac8ff7",
        order: 31,
        path: "images/BOOTH_IMG_Hx03wj3i_AfFD8VENA",
        title: "2022년 전국기능경기대회 입상",
        id: "uXjgc1rIK7P0OqAtC6Ev",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/fVAD09JiCno",
        order: 5,
        title: "중소기업 취업처 안내",
        id: "GW3cZVca6r1y6qaCrQpg",
      },
      {
        title: "KNN 방송 우리동네올림픽 가위. 바위. 보. 이기면 만원!!!",
        order: 3,
        url: "https://youtu.be/X_MVHIdPZYs",
        id: "QlMbqHFAFFrtgHRESzJ0",
      },
      {
        title: "대양음악회",
        order: 6,
        url: "https://youtu.be/j-oH5WdQG6c",
        id: "VLVmwUaJjNF7KGc3bGiT",
      },
      {
        order: 7,
        title: "대양체육활동",
        url: "https://youtu.be/Unau-73OYo4",
        id: "gQDqQDMoSTjXNpZSFefR",
      },
      {
        order: 4,
        url: "https://youtu.be/iKU0Z2ybcjg",
        title: "취업 프로그램 운영",
        id: "ivT94khgLTiHI59bEpDn",
      },
      {
        url: "https://youtu.be/Qvkl3CmIUgw",
        order: 2.5,
        title: "대양고등학교 홍보동영상",
        id: "ldUjNTaXWoyq1rsiAyx9",
      },
      {
        title: "메인영상",
        order: 1,
        url: "https://youtu.be/UMOISXA2j9A",
        id: "tvxoM5i8exZ5g13riTF5",
      },
      {
        title: "도제홍보영상",
        order: 8,
        url: "https://youtu.be/j2flYdsq_t0",
        id: "v99GJkMIdZ8c9xH4ZJ0m",
      },
    ],
  },
  Il2M2jdA: {
    id: "Il2M2jdA",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Fz_trqnI5w_2023%EB%85%84%20%EA%B8%88%EC%A0%95%EC%A0%84%EC%9E%90%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%ED%8C%9C%ED%94%8C%EB%A0%9B%20%EC%A0%9C%EC%9E%91%EB%82%B4%EC%9A%A9.pdf?alt=media&token=f12cdb57-3d0e-42fb-a37f-94643cd075b7",
        order: 1,
        title: "금정전자고등학교 팜플렛",
        path: "documents/z_trqnI5w_2023년 금정전자고등학교 팜플렛 제작내용.pdf",
        id: "Q7vzp5reWlLcwRgYo0rm",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_4uz3jpH-Q?alt=media&token=f1423634-4151-4543-af39-a04f72d0d306",
        title: "금정전자고등학교는 전자·컴퓨터·디자인 특성화고(남녀공학)입니다.",
        path: "images/BOOTH_IMG_Il2M2jdA_4uz3jpH-Q",
        order: 18,
        id: "0vB2RCH13m2VD8hqH0lV",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_R52-MNsCi?alt=media&token=a17322dd-793e-45e5-94ab-142d1a28b8a7",
        title: "금정전자고등학교에서 남녀 신입생을 모집합니다.",
        order: 13.5,
        path: "images/BOOTH_IMG_Il2M2jdA_R52-MNsCi",
        id: "35G4AS6XnWSZGDVVtY8w",
      },
      {
        path: "images/BOOTH_IMG_Il2M2jdA_CYPHT3SUO",
        title: "금정전자고등학교는 미래를 준비하는 꿈이 있는 학교!",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_CYPHT3SUO?alt=media&token=73282872-46fb-4a8b-86a3-cb4623871c0b",
        order: 18,
        id: "JqecRAVlpJYUrMbZ0Ay1",
      },
      {
        title: "선택, 금정전자고등학교! 여러분의 확실한 미래를 설계할 수 있습니다.",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_9Rzh-V_u_?alt=media&token=95c4d68d-7bc2-44cb-80e9-37f47bd515dc",
        order: 18,
        path: "images/BOOTH_IMG_Il2M2jdA_9Rzh-V_u_",
        id: "MZ1R0jAsMfzr8C43Lz0f",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_-Rp-7myLc?alt=media&token=231cb917-88f6-4746-a8c4-af515708c08c",
        path: "images/BOOTH_IMG_Il2M2jdA_-Rp-7myLc",
        title: "2022학년도 신입생 모집안내",
        order: 19,
        id: "PK6aTkFRNcKVA75vrG8N",
      },
      {
        order: 22.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdAKxZlQIdDQfbORDNtAIp1_RvlJYrf00?alt=media&token=0e068e69-2075-4158-99e8-5211f031e337",
        path: "images/BOOTH_IMG_Il2M2jdAKxZlQIdDQfbORDNtAIp1_RvlJYrf00",
        title: "금정전자고는 온천장, 서동역에서 6번 마을버스로 쉽게 올 수 있습니다.",
        id: "Y81k6TqW6gVZWStCIQNx",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_HyxoojAa1?alt=media&token=dfa37d99-3ecd-4b1a-9292-c96629bc4925",
        order: 18.75,
        title: "금정전자고의 자랑, 전공심화동아리, 다양한 체험활동으로 즐거운 학교생활",
        path: "images/BOOTH_IMG_Il2M2jdA_HyxoojAa1",
        id: "c6aWXYoSxBO9cV8BlElh",
      },
      {
        path: "images/BOOTH_IMG_Il2M2jdA_O3DjuEdtS",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_O3DjuEdtS?alt=media&token=bf01ed5b-f2ec-46ca-8fd9-5f18823a5958",
        order: 15.75,
        title: "취업·진학 사다리  금정전자고등학교",
        id: "l7I52RtETLbtPOyf72RW",
      },
      {
        title: "나만의 전문기술로 안정적인 취업, 철저한 내신관리로 성공적인 대학진학",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_Il2M2jdA_f3brbXqqg?alt=media&token=97cf5225-5554-41d0-8510-3be5fafcb0cb",
        order: 18.5,
        path: "images/BOOTH_IMG_Il2M2jdA_f3brbXqqg",
        id: "vLGSskvhA1ktyXnlh98N",
      },
    ],
    videos: [
      {
        order: 2.125,
        title: "쏴라~있네, 방과후! (금정전자고 방과후활동)",
        url: "https://www.youtube.com/watch?v=VvjC6q7wL4M",
        id: "11Na02f9OpoJyusDXEaZ",
      },
      {
        title: "메인영상",
        url: "https://youtu.be/uoPDolnyqFI",
        order: 1,
        id: "3XG7Ohskz0u82ocu1mUT",
      },
      {
        order: 2.5,
        title: "난, 금정전자고에서 이렇게 성공했다",
        url: "https://youtu.be/Hwqk01xo180",
        id: "90zzzXaK6Lpr6W0EF4pU",
      },
      {
        url: "https://www.youtube.com/watch?v=_KCYPrHoGq8",
        order: 1.9375,
        title: "미리보는 금정전자고(홍보영상)",
        id: "BYt9PtEqHzj6tvWcIjwi",
      },
      {
        title: "금정전자고 단체헌혈",
        url: "https://www.youtube.com/watch?v=qb7m_Kz4pno",
        order: 2.3125,
        id: "MBkc23Vdz3DcFZVvCYmj",
      },
      {
        title: "미리보는 금정전자고(홍보영상)",
        order: 2.03125,
        url: "https://youtu.be/b9lwKVuiDUo",
        id: "iYT0hzRZVC7zykooG84h",
      },
      {
        url: "https://youtu.be/QIfSTLaX7pw",
        title: "금정전자고 산학일체형 도제학교",
        order: 3,
        id: "ksmfrHlURcfszPYhXtbp",
      },
      {
        title: "금정전자고에서 미래 기술인을 모집합니다.",
        order: 4.5,
        url: "https://youtu.be/Xeu52BdtzR8",
        id: "s7tBRjXgc2iwPvB8gyC1",
      },
    ],
  },
  JAswRRVj: {
    id: "JAswRRVj",
    documents: [
      {
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Ft1m6fgDbg_2023%20%E1%84%83%E1%85%A2%E1%84%8C%E1%85%B5%E1%86%AB%E1%84%8C%E1%85%A5%E1%86%AB%E1%84%8C%E1%85%A1%E1%84%90%E1%85%A9%E1%86%BC%E1%84%89%E1%85%B5%E1%86%AB%E1%84%80%E1%85%A9%20%E1%84%92%E1%85%A1%E1%86%A8%E1%84%80%E1%85%AD%20%E1%84%92%E1%85%A9%E1%86%BC%E1%84%87%E1%85%A9%20%E1%84%85%E1%85%B5%E1%84%91%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A6%E1%86%BA.pdf?alt=media&token=46c036d9-2a69-4ea4-b0ff-024613c7df8b",
        title: "2023학년도 대진전자통신고 학교 홍보 리플렛",
        path: "documents/t1m6fgDbg_2023 대진전자통신고 학교 홍보 리플렛.pdf",
        id: "tq3UJTvg0DnBH9QYDzGB",
      },
    ],
    images: [
      {
        order: 13,
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_sUHPdNCHP",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_sUHPdNCHP?alt=media&token=21ef5877-3deb-4b44-b5c6-381fdf00b696",
        title: "컴퓨터소프트웨어과 취득 자격증",
        id: "0rH1Sml2f3F5Bzr7wv1S",
      },
      {
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_NnFeIcfaX",
        title: "산업디자인과 취득 자격증",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_NnFeIcfaX?alt=media&token=a3afd0f5-d1f7-4d36-b9ee-33eeb54ff34d",
        order: 15,
        id: "5zBJNeRWyifmsr57NWr1",
      },
      {
        title: "스마트콘텐츠과1",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_mfmffXoio?alt=media&token=d4f7e5be-2fe7-4be7-b8be-63c982b4daf7",
        order: 12,
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_mfmffXoio",
        id: "CeAhtPVPB6bP2gotwiCd",
      },
      {
        title: "전기전자과1",
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_apINGsEMj",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_apINGsEMj?alt=media&token=d18f8ef6-cb2e-4af1-aa1b-bdeebf86f804",
        order: 10.75,
        id: "DSybDaPU2T4hgeWUBP5K",
      },
      {
        order: 14,
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_UVtTzYsKv",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_UVtTzYsKv?alt=media&token=b4373baa-c61e-44a6-ad7c-5b69f5adf244",
        title: "전자통신과 취득 자격증",
        id: "EDfrErFT3UXC9edqHDrm",
      },
      {
        title: "전기전자과 취득 자격증",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_kyIlfsQbz?alt=media&token=ca7686d5-9d33-494b-9266-f14d881d7940",
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_kyIlfsQbz",
        order: 12,
        id: "EdA8UlAl1CbkVi5aCjRk",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_V-K6c9Krc?alt=media&token=3d6fa808-fa4c-49dc-a909-f87d3daf79c2",
        title: "대진 홍보하러 갑니다2",
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_V-K6c9Krc",
        order: 11.5,
        id: "ICEfQZz5LOUFOL0mzOmY",
      },
      {
        order: 15,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_ufHMiB4kV?alt=media&token=58d8bfaf-5423-4b64-8ded-0eb58fe1b4b2",
        title: "산업디자인과2",
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_ufHMiB4kV",
        id: "QJelOQ3qEDcrhJWVVDEd",
      },
      {
        order: 11,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_1-d7Sq7yF?alt=media&token=92b3d38e-5d58-446c-bb13-ccf7632ebbea",
        title: "대진 홍보하러 갑니다1",
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_1-d7Sq7yF",
        id: "QMM50zDnatjYttnYgwa5",
      },
      {
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_AlDn6ICZC",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_AlDn6ICZC?alt=media&token=f652c6d6-b40e-4c83-aeae-1431c65779c5",
        order: 11,
        title: "컴퓨터소프트웨어과2",
        id: "emSI3rExNVlzQt66Mjwx",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_5uYK0C21E?alt=media&token=2925e15e-5a19-4935-abb3-ded098a3c056",
        title: "컴퓨터소프트웨어과1",
        order: 10,
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_5uYK0C21E",
        id: "inzx4UyWD1GjZbDGJI72",
      },
      {
        order: 14,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_d0AglB0Gf?alt=media&token=0038e487-c87d-4d3c-984f-514736c3a9b8",
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_d0AglB0Gf",
        title: "산업디자인과1",
        id: "kq03V1X9koxZ9KNM4i3M",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_xaBdd6v7E?alt=media&token=f0669e09-5948-457a-a698-2e4e74ba0abb",
        title: "대진전자통신고 SNS QR코드",
        order: 16,
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_xaBdd6v7E",
        id: "qCxywfhwNgfyppmZeHpA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_SIG6Alfze?alt=media&token=453be2c4-5aac-462a-86fa-61534c755205",
        title: "전기전자과2",
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_SIG6Alfze",
        order: 11,
        id: "x0neYnzbj8IBTT9S1wuC",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_sQCVRoYNi?alt=media&token=8909f710-c355-4fe3-b343-73468379a68b",
        order: 13,
        path: "images/BOOTH_IMG_JAswRRVj40MqfNuf4pCwp5ivDe93_sQCVRoYNi",
        title: "스마트콘텐츠과2",
        id: "xTx5dqvK03ymZUWck2Yg",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/lO5bq1Gukm8",
        order: 1,
        title: "메인 영상",
        id: "Ryftn6WxYLZJ1vyetKW2",
      },
      {
        url: "https://youtu.be/w7N7p3sOI6A",
        title: "대진전자통신고등학교 홍보 영상",
        order: 2,
        id: "djcUhy21YDE7bBwkWjNo",
      },
      {
        url: "https://youtu.be/55xoZUxqFsw",
        order: 3,
        title: "대진전자통신고등학교 2021 입시요강",
        id: "whLMvpWRwa250GubSO8Z",
      },
    ],
  },
  MsgSWFNe: {
    id: "MsgSWFNe",
    documents: [],
    images: [],
    videos: [],
  },
  UZ2G5Szm: {
    id: "UZ2G5Szm",
    documents: [
      {
        path: "documents/ET24rEXhV_[부산해사고 ]2022학년도 연구학교 계획서.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FET24rEXhV_%5B%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%92%E1%85%A2%E1%84%89%E1%85%A1%E1%84%80%E1%85%A9%20%5D2022%E1%84%92%E1%85%A1%E1%86%A8%E1%84%82%E1%85%A7%E1%86%AB%E1%84%83%E1%85%A9%20%E1%84%8B%E1%85%A7%E1%86%AB%E1%84%80%E1%85%AE%E1%84%92%E1%85%A1%E1%86%A8%E1%84%80%E1%85%AD%20%E1%84%80%E1%85%A8%E1%84%92%E1%85%AC%E1%86%A8%E1%84%89%E1%85%A5.pdf?alt=media&token=da474dfd-a7ea-445c-8a23-78c321e5d495",
        order: 6,
        title: "[부산해사고] 2022학년도 연구학교 계획서",
        id: "ARUwKuhoRbUY1W6LXn3y",
      },
      {
        title: "[부산SW마이스터고]계획단계연구계획서",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F520bDVl5R_2022%E1%84%92%E1%85%A1%E1%86%A8%E1%84%82%E1%85%A7%E1%86%AB%E1%84%83%E1%85%A9%20%E1%84%80%E1%85%A8%E1%84%92%E1%85%AC%E1%86%A8%E1%84%83%E1%85%A1%E1%86%AB%E1%84%80%E1%85%A8%20%E1%84%8B%E1%85%A7%E1%86%AB%E1%84%80%E1%85%AE%E1%84%80%E1%85%A8%E1%84%92%E1%85%AC%E1%86%A8%E1%84%89%E1%85%A5(%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A9%E1%84%91%E1%85%B3%E1%84%90%E1%85%B3%E1%84%8B%E1%85%B0%E1%84%8B%E1%85%A5%E1%84%86%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%89%E1%85%B3%E1%84%90%E1%85%A5%E1%84%80%E1%85%A9).hwp?alt=media&token=18c1994e-d5aa-4231-8489-7fd3ad1335a5",
        order: 2,
        path: "documents/520bDVl5R_2022학년도 계획단계 연구계획서(부산소프트웨어마이스터고).hwp",
        id: "Q9YQuVdFTr84ddS5RI1F",
      },
      {
        title: "[부산SW마이스터고]실천단계연구계획서",
        order: 5,
        path: "documents/uPBzCDwB0_2022학년도 실천단계 연구계획서(부산소프트웨어마이스터고) .hwp",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FuPBzCDwB0_2022%E1%84%92%E1%85%A1%E1%86%A8%E1%84%82%E1%85%A7%E1%86%AB%E1%84%83%E1%85%A9%20%E1%84%89%E1%85%B5%E1%86%AF%E1%84%8E%E1%85%A5%E1%86%AB%E1%84%83%E1%85%A1%E1%86%AB%E1%84%80%E1%85%A8%20%E1%84%8B%E1%85%A7%E1%86%AB%E1%84%80%E1%85%AE%E1%84%80%E1%85%A8%E1%84%92%E1%85%AC%E1%86%A8%E1%84%89%E1%85%A5(%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%89%E1%85%A9%E1%84%91%E1%85%B3%E1%84%90%E1%85%B3%E1%84%8B%E1%85%B0%E1%84%8B%E1%85%A5%E1%84%86%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%89%E1%85%B3%E1%84%90%E1%85%A5%E1%84%80%E1%85%A9)%20.hwp?alt=media&token=dc96d546-eace-44f9-a1c7-aa4bfb9404d6",
        id: "cqFWg12L7SW6KpXLEPR8",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F9oiuM5Pm2_2022%E1%84%92%E1%85%A1%E1%86%A8%E1%84%82%E1%85%A7%E1%86%AB%E1%84%83%E1%85%A9%20%E1%84%89%E1%85%B3%E1%84%91%E1%85%B3%E1%84%85%E1%85%B5%E1%86%BC%20%E1%84%92%E1%85%AA%E1%86%AF%E1%84%8B%E1%85%AD%E1%86%BC%20%E1%84%8B%E1%85%B0%E1%86%B8%20%E1%84%89%E1%85%A5%E1%84%87%E1%85%B5%E1%84%89%E1%85%B3%20%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%8C%E1%85%A6%E1%86%A8%E1%84%90%E1%85%B3%20%E1%84%80%E1%85%A7%E1%86%BC%E1%84%8C%E1%85%B5%E1%86%AB%E1%84%83%E1%85%A2%E1%84%92%E1%85%AC.hwpx?alt=media&token=1bad9964-e611-4fd7-809f-8c532ec6170a",
        order: 4,
        title: "[부산SW마이스터고]스프링활용웹서비스프로젝트",
        path: "documents/9oiuM5Pm2_2022학년도 스프링 활용 웹 서비스 프로젝트 경진대회.hwpx",
        id: "gUc9KIWxtu9YAjjkOKyQ",
      },
      {
        title: "[부산자동차고] 연구학교 실천단계 운영보고서",
        order: 7,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F8_gVAqBR5_%5B%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%8C%E1%85%A1%E1%84%83%E1%85%A9%E1%86%BC%E1%84%8E%E1%85%A1%E1%84%80%E1%85%A9%5D%20%E1%84%8B%E1%85%A7%E1%86%AB%E1%84%80%E1%85%AE%E1%84%92%E1%85%A1%E1%86%A8%E1%84%80%E1%85%AD%20%E1%84%89%E1%85%B5%E1%86%AF%E1%84%8E%E1%85%A5%E1%86%AB%E1%84%83%E1%85%A1%E1%86%AB%E1%84%80%E1%85%A8%20%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%87%E1%85%A9%E1%84%80%E1%85%A9%E1%84%89%E1%85%A5.hwp?alt=media&token=24308ffe-778a-4090-958c-19f8ef51a2a0",
        path: "documents/8_gVAqBR5_[부산자동차고] 연구학교 실천단계 운영보고서.hwp",
        id: "hiBdaVgP30hpWgyuUva6",
      },
      {
        path: "documents/SyZtYePZ9_2022학년도 산학협력 학생주도 프로젝트 수업 계획(웹프로그래밍).hwp",
        order: 3,
        title: "[부산SW마이스터고]산학협력학생주도프로젝트",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FSyZtYePZ9_2022%E1%84%92%E1%85%A1%E1%86%A8%E1%84%82%E1%85%A7%E1%86%AB%E1%84%83%E1%85%A9%20%E1%84%89%E1%85%A1%E1%86%AB%E1%84%92%E1%85%A1%E1%86%A8%E1%84%92%E1%85%A7%E1%86%B8%E1%84%85%E1%85%A7%E1%86%A8%20%E1%84%92%E1%85%A1%E1%86%A8%E1%84%89%E1%85%A2%E1%86%BC%E1%84%8C%E1%85%AE%E1%84%83%E1%85%A9%20%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%8C%E1%85%A6%E1%86%A8%E1%84%90%E1%85%B3%20%E1%84%89%E1%85%AE%E1%84%8B%E1%85%A5%E1%86%B8%20%E1%84%80%E1%85%A8%E1%84%92%E1%85%AC%E1%86%A8(%E1%84%8B%E1%85%B0%E1%86%B8%E1%84%91%E1%85%B3%E1%84%85%E1%85%A9%E1%84%80%E1%85%B3%E1%84%85%E1%85%A2%E1%84%86%E1%85%B5%E1%86%BC).hwp?alt=media&token=a83cfade-65c6-4ad6-98c3-5eb08530c053",
        id: "keXX8fzIOdDokl3ei09O",
      },
      {
        path: "documents/OptOX5iiD_2022학년도 부산기계공업고등학교 연구학교 운영보고서(직업박람회).pdf",
        title: "[부산기계공고] 연구학교 운영보고서",
        order: 0.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FOptOX5iiD_2022%E1%84%92%E1%85%A1%E1%86%A8%E1%84%82%E1%85%A7%E1%86%AB%E1%84%83%E1%85%A9%20%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%80%E1%85%B5%E1%84%80%E1%85%A8%E1%84%80%E1%85%A9%E1%86%BC%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%80%E1%85%A9%E1%84%83%E1%85%B3%E1%86%BC%E1%84%92%E1%85%A1%E1%86%A8%E1%84%80%E1%85%AD%20%E1%84%8B%E1%85%A7%E1%86%AB%E1%84%80%E1%85%AE%E1%84%92%E1%85%A1%E1%86%A8%E1%84%80%E1%85%AD%20%E1%84%8B%E1%85%AE%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%87%E1%85%A9%E1%84%80%E1%85%A9%E1%84%89%E1%85%A5(%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%8B%E1%85%A5%E1%86%B8%E1%84%87%E1%85%A1%E1%86%A8%E1%84%85%E1%85%A1%E1%86%B7%E1%84%92%E1%85%AC).pdf?alt=media&token=18678c4d-3bb3-4699-bd78-609e10c09497",
        id: "rbDxs90toBKw1S2kUjr2",
      },
    ],
    images: [
      {
        title: "[부산SW마이스터고] 수업사진 07",
        order: 9,
        path: "images/BOOTH_IMG_UZ2G5Szm_UNYYm4gj4",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_UNYYm4gj4?alt=media&token=2835770d-5413-48b8-ad9c-8d6b94c67e99",
        id: "1dE9fZxaSx56eTK9tdgb",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_plwu7xeU9?alt=media&token=bda2a392-bb3e-4125-b83d-66c2c59815ff",
        order: 3,
        title: "[부산SW마이스터고] 수업사진 03",
        path: "images/BOOTH_IMG_UZ2G5Szm_plwu7xeU9",
        id: "3UXwOqqbSmQ32jQCFu1q",
      },
      {
        title: "[부산SW마이스터고] 수업사진 17",
        path: "images/BOOTH_IMG_UZ2G5Szm_8nyQHndYr",
        order: 19,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_8nyQHndYr?alt=media&token=145cd1de-b931-44cd-8c33-1e78ff6198c3",
        id: "8Jm3glmvZoWcDgIxtFsR",
      },
      {
        order: 32,
        title: "[부산자동차고] 학생주도형 프로젝트 수업(2)",
        path: "images/BOOTH_IMG_UZ2G5Szm_l1EYtFlXT",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_l1EYtFlXT?alt=media&token=c6aecc0c-9d3d-4597-9e91-6f3b860c22eb",
        id: "8o5bUtCnpJ8pBDMAEN4o",
      },
      {
        order: 11,
        title: "[부산SW마이스터고] 수업사진 09",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_jukaNi7xq?alt=media&token=0b6a9536-2d80-4d1f-85f2-6c2aff005350",
        path: "images/BOOTH_IMG_UZ2G5Szm_jukaNi7xq",
        id: "BV9F371Si10yK80a97Cf",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_a5TNEikZB?alt=media&token=4d2c5e31-d923-4919-a016-a74e2276e212",
        order: 29,
        title: "[부산자동차고] 쥬니어레벨 기술자선배의 멘토링",
        path: "images/BOOTH_IMG_UZ2G5Szm_a5TNEikZB",
        id: "BiX166hClZ61vBxm6dkc",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_EzthDJOOH?alt=media&token=695d1945-7c46-4652-a414-2696a621bf78",
        path: "images/BOOTH_IMG_UZ2G5Szm_EzthDJOOH",
        order: 17,
        title: "[부산SW마이스터고] 수업사진 15",
        id: "DKcJeITJVhgf99g6Q32K",
      },
      {
        path: "images/BOOTH_IMG_UZ2G5Szm_S_9sCtdvO",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_S_9sCtdvO?alt=media&token=9451f262-324f-4c63-aa25-9e09c27effd6",
        order: 7,
        title: "[부산SW마이스터고] 수업사진 05",
        id: "DSzG8zPJjCxWTPjholA3",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_I5-ifE8R6?alt=media&token=65ed092e-2225-41f9-8b6b-d72c5ab95b18",
        title: "[부산자동차고] 산업기사 자격증 과정운영",
        order: 26,
        path: "images/BOOTH_IMG_UZ2G5Szm_I5-ifE8R6",
        id: "GdeftiANKbuCEq7AIRgC",
      },
      {
        order: 13,
        title: "[부산SW마이스터고] 수업사진 11",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_GaKXiieYU?alt=media&token=126f1493-c08c-44ee-86a6-fff9ec165548",
        path: "images/BOOTH_IMG_UZ2G5Szm_GaKXiieYU",
        id: "HjG3iFjVw8znLAYAXb4H",
      },
      {
        order: 28,
        path: "images/BOOTH_IMG_UZ2G5Szm_dV80ZRbu2",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_dV80ZRbu2?alt=media&token=814eb6fa-2a46-47dc-970a-06c4407d5d2f",
        title: "[부산자동차고] 전공선택형 교육과정",
        id: "IDt8JEFZL0QO7lTMPcu7",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_oXx-ost5X?alt=media&token=a76ceb46-8ed1-45d0-a4fc-54a0daea6dde",
        order: 24,
        title: "[부산자동차고] NCS 직업기초능력강화",
        path: "images/BOOTH_IMG_UZ2G5Szm_oXx-ost5X",
        id: "IHqlUH08e3dUYmdCtjjp",
      },
      {
        path: "images/BOOTH_IMG_UZ2G5Szm_eLEbYm8xe",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_eLEbYm8xe?alt=media&token=89f177a1-6ac4-4f8f-9ebe-09c36d700c82",
        title: "[부산SW마이스터고] 수업사진 06",
        order: 8,
        id: "IdkhRFUKyN3mT5t3mIlY",
      },
      {
        order: 18,
        path: "images/BOOTH_IMG_UZ2G5Szm_BT8QESHZC",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_BT8QESHZC?alt=media&token=487a300c-7899-4ed1-bd30-2418f24bc7f7",
        title: "[부산SW마이스터고] 수업사진 16",
        id: "JGQqHLfXQ3Rlc145Iwrk",
      },
      {
        title: "[부산SW마이스터고] 수업사진 08",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_dO9J3srfv?alt=media&token=f4aed4a9-1881-4341-9de6-0a968df06d01",
        order: 10,
        path: "images/BOOTH_IMG_UZ2G5Szm_dO9J3srfv",
        id: "L7HiIihd2l544pdnZbRi",
      },
      {
        path: "images/BOOTH_IMG_UZ2G5Szm_UYQOs9zig",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_UYQOs9zig?alt=media&token=ce5fabaa-2305-4eb1-b4d2-424117d0d616",
        title: "[부산SW마이스터고] 수업사진 12",
        order: 14,
        id: "LZ11WuTZcYCvqo14hHdf",
      },
      {
        title: "[부산SW마이스터고] 수업사진 04",
        order: 6,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_cr18qz5ks?alt=media&token=9fa18375-d689-411a-90d5-1252dc7fac0d",
        path: "images/BOOTH_IMG_UZ2G5Szm_cr18qz5ks",
        id: "Mk3N42ZUiyAFBr5D3UhB",
      },
      {
        title: "[부산SW마이스터고] 수업사진 01",
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_FIDVfmThD?alt=media&token=891d3552-a1cf-4b9c-9928-1f3d50f79330",
        path: "images/BOOTH_IMG_UZ2G5Szm_FIDVfmThD",
        id: "QYCJ3baGj1QjtcGftgAF",
      },
      {
        path: "images/BOOTH_IMG_UZ2G5Szm_QHW6ojYhN",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_QHW6ojYhN?alt=media&token=ceccbf57-7863-4e1c-ab35-de83b52556fe",
        order: 2,
        title: "[부산SW마이스터고] 수업사진 02",
        id: "RFHOuvpFupjhLMtRvuHs",
      },
      {
        order: 31,
        title: "[부산자동차고] 학생주도형 프로젝트수업(1)",
        path: "images/BOOTH_IMG_UZ2G5Szm_LBo1xyGl7",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_LBo1xyGl7?alt=media&token=21bcb65e-c951-46b9-a832-d48bfc0fcb53",
        id: "RZCqXRrzM46sEBpPKWmG",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_PLUi78oWm?alt=media&token=f369fb0f-a5fc-4a01-9e3f-15ca4fcabab3",
        title: "[부산SW마이스터고] 수업사진 13",
        order: 15,
        path: "images/BOOTH_IMG_UZ2G5Szm_PLUi78oWm",
        id: "TlGfGKETWwmhg0JMvGvs",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_v5hq0qVdS?alt=media&token=96a2ff2e-364c-49d7-acc0-32b71ca74625",
        path: "images/BOOTH_IMG_UZ2G5Szm_v5hq0qVdS",
        order: 22,
        title: "[부산해사고] 항해 시뮬레이터 수업",
        id: "VQQj7zsNEZBT5lWO8npS",
      },
      {
        order: 12,
        title: "[부산SW마이스터고] 수업사진 10",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_IY2rDgbEx?alt=media&token=08f389c2-0b74-4e57-a7fa-194a36ee2516",
        path: "images/BOOTH_IMG_UZ2G5Szm_IY2rDgbEx",
        id: "dkRTWyqvCzgmex4Vo1mu",
      },
      {
        title: "[부산자동차고] 산학협력 프로그램 참여",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_u7psgWKli?alt=media&token=80d1a20f-bbe9-4d67-8efd-7ac34d80bed9",
        order: 27,
        path: "images/BOOTH_IMG_UZ2G5Szm_u7psgWKli",
        id: "e5lwqqsiyy8zn04HFaMS",
      },
      {
        path: "images/BOOTH_IMG_UZ2G5Szm_kSoBvoUIz",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_kSoBvoUIz?alt=media&token=069cdd4c-30b9-4f04-a0f5-18257613987b",
        order: 25,
        title: "[부산자동차고] 미래자동차학과 개편",
        id: "exdL8dlvPcI8qX8NYCMY",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_RuSFcQv22?alt=media&token=e92af460-1f02-4a23-a437-7410a8e8f60f",
        order: 18,
        path: "images/BOOTH_IMG_UZ2G5Szm_RuSFcQv22",
        title: "[부산해사고] 기관실무기초(용접) 수업",
        id: "gfnWxcFZi6TFUU1uCHI8",
      },
      {
        order: 19,
        title: "[부산해사고] 선박보조기계 수업",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_40-gyU4xE?alt=media&token=524a2531-3bc5-4c7c-8cb4-53c9bd5d767b",
        path: "images/BOOTH_IMG_UZ2G5Szm_40-gyU4xE",
        id: "mQvNkbcY6ftKf0SyIUAY",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_TSXnAKGOX?alt=media&token=fbf82ca3-2fa9-4941-bf7d-a4e796297094",
        order: 16,
        title: "[부산SW마이스터고] 수업사진 14",
        path: "images/BOOTH_IMG_UZ2G5Szm_TSXnAKGOX",
        id: "rg50a6Oj7T6QngpmK7ve",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_P_E196FPD?alt=media&token=3a968f79-d71f-4e54-a3e2-b81944c5da63",
        path: "images/BOOTH_IMG_UZ2G5Szm_P_E196FPD",
        title: "[부산자동차고] NCS 전공심화능력 강화",
        order: 23,
        id: "tkb924djn4gM6UYf5Nhg",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_ik0LuaJjr?alt=media&token=3d72d1ec-2aaf-451f-b87a-d5ba10f6fa90",
        title: "[부산해사고] 선박운용 3D 출력물 수업",
        path: "images/BOOTH_IMG_UZ2G5Szm_ik0LuaJjr",
        order: 20,
        id: "v9uOyHre9pWTW2DYUOC6",
      },
      {
        path: "images/BOOTH_IMG_UZ2G5Szm_7EK1Iqzbi",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_7EK1Iqzbi?alt=media&token=339a9f70-97f0-4e3a-8f58-bbe6baee5b21",
        order: 30,
        title: "[부산자동차고] 학생주도형 동아리활동",
        id: "w7qynnWQexcNgbW6HG3z",
      },
      {
        title: "[부산해사고] 선박전기전자 수업",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_UZ2G5Szm_aNgmv16I8?alt=media&token=8f705a13-334b-4e38-a77e-924636aa47f3",
        path: "images/BOOTH_IMG_UZ2G5Szm_aNgmv16I8",
        order: 21,
        id: "xHxcXySImtHybPMvLhfm",
      },
    ],
    videos: [
      {
        order: 3,
        title: "[부산해사고] 기관과VLOG🛠|현장교육일상👷|선박해양플랜트연구소🇰🇷🏭|볼팅교육🔩",
        url: "https://youtu.be/TKHbV_miqZE",
        id: "4goXIk3VjEV7dRI4MixY",
      },
      {
        order: 1.5,
        url: "https://youtu.be/omGXLwwe4gg",
        title: "부산소프트웨어마이스터고 산학협력프로젝트 수업영상",
        id: "X2uDLV3Dee7s39r9ueVT",
      },
      {
        title: "부산 소프트웨어마이스터고 산학협력프로젝트 수업영상(2)",
        order: 0.75,
        url: "https://www.youtube.com/watch?v=IwpoH9EId0c",
        id: "abzqjQksliBMkhMDYvOK",
      },
      {
        title: "[부산자동차고] 연구학교 운영",
        order: 4,
        url: "https://youtu.be/rz68T4EQwfA",
        id: "xekjM9khVDzy5RhPBLOS",
      },
    ],
  },
  VIafRODb: {
    id: "VIafRODb",
    documents: [
      {
        title: "과 소개",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Ftn_xMfcEK_2023%20%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%80%E1%85%A9%20%E1%84%87%E1%85%B3%E1%84%85%E1%85%A9%E1%84%89%E1%85%B2%E1%84%8B%E1%85%A5(%E1%84%8E%E1%85%AC%E1%84%8C%E1%85%A9%E1%86%BC)-part-2.pdf?alt=media&token=35827063-565f-4b36-9b9a-d6a7293848a6",
        path: "documents/tn_xMfcEK_2023 부산영상예술고 브로슈어(최종)-part-2.pdf",
        order: 5.1875,
        id: "56iMm3wKlhkpnW4vN8tT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FCGXLLkH7y_2022%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EC%98%81%EC%83%81%EC%98%88%EC%88%A0%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.hwp?alt=media&token=7cade958-355e-4819-ad99-5f770a2bbbd4",
        order: 4.828125,
        path: "documents/CGXLLkH7y_2022학년도 부산영상예술고등학교 입학전형 요강.hwp",
        title: "2022학년도 부산영상예술고등학교 입학전형 요강",
        id: "LCRxYMWznzK6hOUBTEWb",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F0zEMdqjwH_2023%20%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%80%E1%85%A9%20%E1%84%87%E1%85%B3%E1%84%85%E1%85%A9%E1%84%89%E1%85%B2%E1%84%8B%E1%85%A5(%E1%84%8E%E1%85%AC%E1%84%8C%E1%85%A9%E1%86%BC)-part-4-%E1%84%8B%E1%85%A1%E1%86%B8%E1%84%8E%E1%85%AE%E1%86%A8%E1%84%83%E1%85%AC%E1%86%B7.pdf?alt=media&token=bc6e8d18-34ba-4914-ad3b-10872fa0e619",
        path: "documents/0zEMdqjwH_2023 부산영상예술고 브로슈어(최종)-part-4-압축됨.pdf",
        order: 6.4375,
        title: "재학생 인터뷰 및 모집 안내",
        id: "YAiP6d69EsIksTpRKs9H",
      },
      {
        path: "documents/iIfI3FH3d_0914 전단지 a4.pdf",
        title: "부산영상예술고등학교 전단지",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FiIfI3FH3d_0914%20%EC%A0%84%EB%8B%A8%EC%A7%80%20a4.pdf?alt=media&token=144370b5-6b95-4c9f-b22c-7ee48588aead",
        order: 4.9609375,
        id: "ccnFJ1uaXmMd6RP3NYT5",
      },
      {
        path: "documents/4ZC81Asku_2023 부산영상예술고 브로슈어(최종)-part-3 사본_1.pdf",
        order: 5.734375,
        title: "동아리 소개",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F4ZC81Asku_2023%20%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%80%E1%85%A9%20%E1%84%87%E1%85%B3%E1%84%85%E1%85%A9%E1%84%89%E1%85%B2%E1%84%8B%E1%85%A5(%E1%84%8E%E1%85%AC%E1%84%8C%E1%85%A9%E1%86%BC)-part-3%20%E1%84%89%E1%85%A1%E1%84%87%E1%85%A9%E1%86%AB_1.pdf?alt=media&token=63afadba-ce55-4bfc-a505-768048465304",
        id: "qsNjcO3czHQb6YorEVwo",
      },
      {
        order: 5.09375,
        path: "documents/Hv7_WK1la_2023 부산영상예술고 브로슈어(최종)-part-1-압축됨.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FHv7_WK1la_2023%20%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%80%E1%85%A9%20%E1%84%87%E1%85%B3%E1%84%85%E1%85%A9%E1%84%89%E1%85%B2%E1%84%8B%E1%85%A5(%E1%84%8E%E1%85%AC%E1%84%8C%E1%85%A9%E1%86%BC)-part-1-%E1%84%8B%E1%85%A1%E1%86%B8%E1%84%8E%E1%85%AE%E1%86%A8%E1%84%83%E1%85%AC%E1%86%B7.pdf?alt=media&token=fbb21547-4354-49eb-b5fd-6192be2af195",
        title: "부산영상예술고등학교 연혁",
        id: "wKyCB6yReFSNzE08ojSz",
      },
      {
        order: 6.1875,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Faiewr6tMf_2023%20%E1%84%87%E1%85%AE%E1%84%89%E1%85%A1%E1%86%AB%E1%84%8B%E1%85%A7%E1%86%BC%E1%84%89%E1%85%A1%E1%86%BC%E1%84%8B%E1%85%A8%E1%84%89%E1%85%AE%E1%86%AF%E1%84%80%E1%85%A9%20%E1%84%87%E1%85%B3%E1%84%85%E1%85%A9%E1%84%89%E1%85%B2%E1%84%8B%E1%85%A5(%E1%84%8E%E1%85%AC%E1%84%8C%E1%85%A9%E1%86%BC)-part-3%20%E1%84%89%E1%85%A1%E1%84%87%E1%85%A9%E1%86%AB.pdf?alt=media&token=e6ae0df2-4dc0-4c15-92b3-6106796ecd05",
        path: "documents/aiewr6tMf_2023 부산영상예술고 브로슈어(최종)-part-3 사본.pdf",
        title: "수상 경력 및 진학, 취업 현황",
        id: "xiUtlFRFboGxrzTEwH8k",
      },
    ],
    images: [
      {
        title: "사격부 회장기 전국사격대회 단체전 우승",
        order: 13,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_8hPUqWYrp?alt=media&token=0333d2d2-f552-48d6-b151-d7a90aaf60a5",
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_8hPUqWYrp",
        id: "9VagpxuVEgDHnNxStcvu",
      },
      {
        order: 11,
        title: "부산청소년예술제 영상부문 대상",
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_h7jEfHUxr",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_h7jEfHUxr?alt=media&token=7f58a487-83e1-4faf-8494-fbf70b0b3b07",
        id: "E1ByuQK6lnDFJ9YwcOw3",
      },
      {
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_AIlLF42j2",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_AIlLF42j2?alt=media&token=5ed71991-e406-48ef-a1b6-054102f2adb7",
        title: "사진동아리 활동",
        order: 7,
        id: "OxTrgil7d4LxlJPGrlzQ",
      },
      {
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_wESQ6buO8",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_wESQ6buO8?alt=media&token=5ffc60a0-96ef-4963-b7eb-1e62409cf85b",
        title: "연기과 뮤지컬 공연",
        order: 6,
        id: "SvyuRFiVtfzqtMv0kT10",
      },
      {
        title: "학교기업 활동",
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_lHaH7oGLY",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_lHaH7oGLY?alt=media&token=803b0336-59bf-4b56-9506-18b08463cd57",
        order: 10,
        id: "VIwkKgOzFTrMvWnVxt7o",
      },
      {
        order: 3,
        title: "영상연출과",
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_0rMX-bChj",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_0rMX-bChj?alt=media&token=ad4c5cd6-b24d-472d-8496-cc2a6f613ae4",
        id: "WymolFSnxaYqlT26I4NJ",
      },
      {
        title: "연기동아리 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_-DMzY8cUW?alt=media&token=f80ec69b-262a-45c1-8bd2-1b79e79e7aed",
        order: 8.5,
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_-DMzY8cUW",
        id: "ZFpM7TPPWEBXRQBkChP6",
      },
      {
        order: 2,
        title: "영상제작과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_TsS7FuQAG?alt=media&token=ff785eef-e01b-4f28-ba9d-0fed06e5febb",
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_TsS7FuQAG",
        id: "byZ5AF78ECYpZNfPGFFv",
      },
      {
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_zSUkp7-k-",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_zSUkp7-k-?alt=media&token=3868eb28-719e-4f04-b6ed-04a2cb65fa0d",
        title: "학교전경",
        order: 1,
        id: "c2EWrqMdZEnjs93xhdPj",
      },
      {
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_1FmQMEJYU?alt=media&token=b5e94954-993d-4256-8637-acabc0164a67",
        title: "영상제작심화 동아리 활동",
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_1FmQMEJYU",
        id: "lAoU43b7mfftbrjEJ1Ee",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_DdbMAFqrn?alt=media&token=b0c8305f-0f41-49e0-bdc4-d45141df4dcb",
        title: "연기과",
        order: 4,
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_DdbMAFqrn",
        id: "pvbnYV6xFMAOYty5qRSF",
      },
      {
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_KHHKXkf5a",
        order: 12,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_KHHKXkf5a?alt=media&token=6d0b138d-0d31-4c0f-a1af-ed216f600c97",
        title: "영도다리축제 핵인싸부문 댄스 대상",
        id: "uG35ByGTFTk0d0YOar2d",
      },
      {
        order: 5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_zRNAfC4si?alt=media&token=591e700f-0c98-4cf8-9813-1e004a52776c",
        path: "images/BOOTH_IMG_VIafRODbdgdTDxCxLl3sXt6Is9K2_zRNAfC4si",
        title: "영상디자인과",
        id: "wRW5Yu4OrbM5dOM8qfYw",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/ORqdHrYJ2YU",
        title: "부산영상예술고등학교 홍보영상",
        order: 2,
        id: "HLUrThUyoDwvuB7H7Zpm",
      },
      {
        order: 1.5,
        title: "메인영상",
        url: "https://youtu.be/WV6E5Tj9krY",
        id: "IHI7SjhNOJ8PNTnfBk6G",
      },
      {
        url: "https://youtu.be/9phOAMT44QQ",
        title: "부산영상예술고등학교 댄스동아리 공연",
        order: 7,
        id: "VuDM2l2OU535ykzagWiX",
      },
      {
        order: 2.1875,
        url: "https://youtu.be/_tciuizt1eo",
        title: "부산영상예술고등학교 연기과 장면발표",
        id: "WmW8R5f7UoK6au2JQtGU",
      },
      {
        order: 2.09375,
        title: "부산영상예술고등학교 전경 드론촬영",
        url: "https://youtu.be/jWG4Pd9kwgM",
        id: "XDFMQAtaPzNprxysc1gQ",
      },
      {
        title: "부산영상예술고등학교 자체제작 웹드라마",
        order: 2.75,
        url: "https://youtu.be/c0-m95VH1OM",
        id: "dkB96YBHX1O01bzkMoDm",
      },
      {
        order: 10.5,
        url: "https://www.youtube.com/watch?v=MxERr4oScGw&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=10",
        title: "부산영상예술고등학교 공연",
        id: "fY9Dcew2W5UvFE4KTjNz",
      },
      {
        order: 3.125,
        url: "https://youtu.be/KKQiNg8vDLY",
        title: "부산영상예술고등학교 쇼뮤직영상",
        id: "j9LDzGHfWQc5dPDmDwto",
      },
      {
        order: 2.375,
        url: "https://youtu.be/R1LNXi56x2c",
        title: "부산영상예술고등학교 영상디자인과 작품",
        id: "pVVcSDJIO4DmtFsZRYav",
      },
      {
        url: "https://youtu.be/DRtSvw1A_ls",
        order: 3.75,
        title: "부산영상예술고등학교 연기동아리 공연",
        id: "qDXVHT5iLjB6ck9OJ7yi",
      },
    ],
  },
  VKQfw3yG: {
    id: "VKQfw3yG",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FIhqn5l0W9_%EB%B3%B4%EA%B1%B4%EA%B3%A0%20%EB%A6%AC%ED%94%8C%EB%A0%9B%20(3)_4.png?alt=media&token=28060210-0ac5-40a8-a31c-bae388aeb575",
        order: 5,
        title: "23년도 리플렛 (4/4)",
        path: "documents/Ihqn5l0W9_보건고 리플렛 (3)_4.png",
        id: "5jICFrFwEfbanNSnXrcY",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FPlA43KOe9_%EB%B3%B4%EA%B1%B4%EA%B3%A0%20%EB%A6%AC%ED%94%8C%EB%A0%9B%20(3)_3.png?alt=media&token=ec4f36c8-e0a0-4dc5-b3ac-ba68cf8aec72",
        order: 4,
        title: "23년도 리플렛 (3/4)",
        path: "documents/PlA43KOe9_보건고 리플렛 (3)_3.png",
        id: "Gw4NqAokPpfnfVUBFzkC",
      },
      {
        title: "2023학년 입시전형요강",
        path: "documents/EYuAes4B9_2023학년도 부산보건고등학교 신입생 입학전형 요강.pdf",
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FEYuAes4B9_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EB%B3%B4%EA%B1%B4%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.pdf?alt=media&token=ac4c0ef2-826c-4f84-b36a-55c75bbcd2c1",
        id: "Yb7zW2kg5PIJwPQtcHw1",
      },
      {
        title: "23년도 리플렛 (1/4)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Fl6e-jIdvJ_%EB%B3%B4%EA%B1%B4%EA%B3%A0%20%EB%A6%AC%ED%94%8C%EB%A0%9B%20(3)_1.png?alt=media&token=0e7b18f2-044a-45ac-a6b7-980757f492a4",
        path: "documents/l6e-jIdvJ_보건고 리플렛 (3)_1.png",
        order: 2,
        id: "Z0RyEYzCLsN7H2kWEa4g",
      },
      {
        path: "documents/BUTB9CS86_보건고 리플렛 (3)_2.png",
        title: "23년도 리플렛 (2/4)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FBUTB9CS86_%EB%B3%B4%EA%B1%B4%EA%B3%A0%20%EB%A6%AC%ED%94%8C%EB%A0%9B%20(3)_2.png?alt=media&token=14f3203e-9929-4bf5-bd56-c7d4fdbceb29",
        order: 3,
        id: "pSR7O2lrzmdWelx0OHIj",
      },
    ],
    images: [
      {
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_cXVhCx_bJ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_cXVhCx_bJ?alt=media&token=91001062-fce7-4418-8c31-ba3d1ce356d8",
        title: "보건간호과",
        order: 1,
        id: "1fA0B2XsJx4QDJLG3eTX",
      },
      {
        order: 6.5,
        title: "학교 강당",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_ihFlTrVoG?alt=media&token=c944cd0c-6420-4270-b511-7fd7c3f0d09c",
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_ihFlTrVoG",
        id: "5ravknBUgLBXQQa2qr3a",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_Ic3HtCL5b?alt=media&token=98591f07-a0be-41fe-a1a8-164d12fea8d5",
        order: 1.5,
        title: "보건간호과",
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_Ic3HtCL5b",
        id: "997ap6UHzvzk2AJG4GLh",
      },
      {
        title: "학교 전경",
        order: 2,
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_2WNn2lEXj",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_2WNn2lEXj?alt=media&token=82e12287-3ae7-4f4e-84c6-2beafabd2e91",
        id: "CjPQDcVyLmUwO5a0MNzT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_nef6xMlPX?alt=media&token=386f4270-9ccc-47da-9c2b-1b5aa1d21d5e",
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_nef6xMlPX",
        title: "학교 앞 흰여울길",
        order: 5,
        id: "HBjqkQlFh4RxADt6n56C",
      },
      {
        order: 13,
        path: "images/BOOTH_IMG_VKQfw3yG_4UpJCyIOA",
        title: "당구장",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yG_4UpJCyIOA?alt=media&token=23c39a48-6175-4636-bc5b-47fe73093958",
        id: "JcNEhTZ99hOEa4kHfHKi",
      },
      {
        order: 1.875,
        path: "images/BOOTH_IMG_VKQfw3yG_vEOEawkpW",
        title: "푸드디저트과 신설",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yG_vEOEawkpW?alt=media&token=847b85e6-07c9-4f52-9a16-b3e4edf2293a",
        id: "L4c9rgjgVJBGo9hT8gtz",
      },
      {
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_nbCGdRnzt?alt=media&token=75dbea4e-df61-4585-99ba-a29747119d30",
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_nbCGdRnzt",
        title: "교내 정원",
        id: "Mur885hR948xVa5QS3M1",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_Fw0QcV3ET?alt=media&token=ae88fce9-6076-4ac3-9433-9d2ab14fe5f3",
        title: "금융경영과",
        order: 1.75,
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_Fw0QcV3ET",
        id: "P5oElw969M9r10WIz0zQ",
      },
      {
        order: 4,
        title: "부산보건고등학교 버스 정류장",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_tcMD0BQXU?alt=media&token=648a5f23-989d-4c3e-a9b0-bb6f5b20f06c",
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_tcMD0BQXU",
        id: "PnEkhnqQy27LVaek1zSQ",
      },
      {
        path: "images/BOOTH_IMG_VKQfw3yG_qwQ3aWJ94",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yG_qwQ3aWJ94?alt=media&token=339d6d75-2c92-4cee-a035-0bfcfd566ebd",
        order: 12,
        title: "헬스장",
        id: "TR4r8mxhwdsF2VhTuUKb",
      },
      {
        path: "images/BOOTH_IMG_VKQfw3yG_wLqYuwhuM",
        order: 14,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yG_wLqYuwhuM?alt=media&token=0e011d6e-d7a7-49c0-b24f-68335c192f25",
        title: "탁구장",
        id: "VpXGXTUzni0L83vAdhJi",
      },
      {
        path: "images/BOOTH_IMG_VKQfw3yG_AsO9qGerF",
        title: "코인노래방",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yG_AsO9qGerF?alt=media&token=e809873e-a409-4fb6-8aa4-c6e3a706a772",
        order: 15,
        id: "bwI227esgTBdN4z3L4CY",
      },
      {
        title: "교내 정원",
        order: 7,
        path: "images/BOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_XiWf9BJYL",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yGr9fj8J97GtBRgC7pp7D3_XiWf9BJYL?alt=media&token=24081d00-66b1-47e0-8efa-289050ce1e53",
        id: "qa3LRZCG6mdjAtHEeWbC",
      },
      {
        title: "트램펄린실",
        order: 16,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yG_LqPVYK1Fy?alt=media&token=a261a56d-b03e-4a22-ab23-3497b8c4518b",
        path: "images/BOOTH_IMG_VKQfw3yG_LqPVYK1Fy",
        id: "wPoSL1fzIGEz21t2CFlm",
      },
      {
        title: "푸드디저트과 신설",
        path: "images/BOOTH_IMG_VKQfw3yG_z0mzDc3hc",
        order: 1.9375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_VKQfw3yG_z0mzDc3hc?alt=media&token=6656906c-dac0-47d8-90b0-4db91fa518ef",
        id: "wREoLVt7griZQr8Red6P",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/nWi6SbE3Vmw",
        order: 10,
        title: "보건고의 만우절",
        id: "1sCtPJ4DC7soOnuPtmJN",
      },
      {
        title: "부산보건고등학교 드론전경",
        order: 7,
        url: "https://youtu.be/8KzN0KYIDqw",
        id: "EpvrP5eeanSXY6pzisK0",
      },
      {
        order: 6,
        title: "부산보건고등학교 가사실습실",
        url: "https://youtu.be/BJrtCeaLvqU",
        id: "KIqyNxxGdygE0rikg8t1",
      },
      {
        order: 5,
        url: "https://youtu.be/lmQr7QLuj9k",
        title: "부산보건고등학교 학나래 축제",
        id: "N8qKqmsBrXYlJ7pVKPwj",
      },
      {
        url: "https://youtu.be/sGZoJuv4_xA",
        title: "메인영상",
        order: 1,
        id: "QssC1MuOsydpqR5ZCMdn",
      },
      {
        url: "https://youtu.be/-57TMTJg4WM",
        order: 4,
        title: "고신대 간호학과 입학 졸업생 인터뷰",
        id: "TIKIvhhwONydVl8zlXzP",
      },
      {
        title: "부산보건고 '오마이쌤!'",
        url: "https://youtu.be/5r2vr7v_Y9k",
        order: 2.5,
        id: "a8vZbIj6LpNH0GfwBrpH",
      },
      {
        title: "부산보건고등학교의 맛나는 급식",
        url: "https://youtu.be/0hFqe0aHDew",
        order: 8,
        id: "o9ipfGr9Ae6Qptxpsx20",
      },
      {
        order: 3,
        url: "https://youtu.be/Wna5ectN--o",
        title: "국민은행 취업 졸업생 인터뷰",
        id: "vmv5Pe1zSv122Z8KHmm5",
      },
      {
        url: "https://youtu.be/OWdbjrIMRhQ",
        order: 2,
        title: "홍보영상",
        id: "wRJXgTzQWeW8rTnroyAD",
      },
    ],
  },
  XAMlGKMz: {
    id: "XAMlGKMz",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FV28cXhPf4_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%8F%99%EC%95%84%EA%B3%B5%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.hwp?alt=media&token=d73f6308-a4f9-46ea-af45-f70d20539974",
        path: "documents/V28cXhPf4_2023학년도 동아공업고등학교 입학전형 요강.hwp",
        title: "2023학년도 동아공고 신입생 입학전형 요강",
        order: 1.5,
        id: "ad6faOAkkh4zRI8LMZl8",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMz_qF9ixQQIR?alt=media&token=94af8201-a063-404e-b24a-f4faeca83d1a",
        order: 26,
        title: "화학공업과 실습 모습",
        path: "images/BOOTH_IMG_XAMlGKMz_qF9ixQQIR",
        id: "2q0zRewEkVXv5odbOomF",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_1BbWHIrQx",
        title: "중국 어학연수",
        order: 18,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_1BbWHIrQx?alt=media&token=558991a7-8c5d-4e85-bf01-4f500cbb7699",
        id: "AjYql6wWSPHbQ6eOvbzN",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_xAXwIr7BM",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_xAXwIr7BM?alt=media&token=bf0439aa-e3ca-4e89-bb76-f4b0722d80b3",
        order: 26,
        title: "k핸드메이드페어 부산- 화학공업과",
        id: "B6ZvVpD0lpj7li0ceTmX",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_2UQoSUR98",
        order: 23,
        title: " 동아리-지게차 자격 취득반",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_2UQoSUR98?alt=media&token=4948483f-a656-4c5a-8b6d-405c3f25f455",
        id: "F2I0xhInz4oHk5CQGDtk",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMz_TgQaqJOLR?alt=media&token=2881a44b-0cd4-420e-8d80-fc6bef740acc",
        order: 24,
        title: "자동차과",
        path: "images/BOOTH_IMG_XAMlGKMz_TgQaqJOLR",
        id: "GpUj3ATEvm5Qheo1su5C",
      },
      {
        order: 17,
        title: "중국 어학연수",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_E8x9vPb6U?alt=media&token=17034089-1a16-42c6-8b0f-45c81abab84b",
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_E8x9vPb6U",
        id: "HYuP0TPM3fBGzpuEr9G6",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_5xtEZ34yZ?alt=media&token=16c54dcc-0402-44cc-b696-244f424f3b0e",
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_5xtEZ34yZ",
        title: "동아리- 화공프로젝트반",
        order: 24.5,
        id: "K288bNZcG2UDnIXbeSzo",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_hFaMMcNXH",
        order: 6,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_hFaMMcNXH?alt=media&token=9c4f882f-b8f1-475c-83bc-b31d31b3b85f",
        title: "기계과 실습사진",
        id: "LrKnBZxMT0VJkQaRkyCZ",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_t2jE0lxsu",
        order: 19,
        title: "동아리-wee클래스 또래상담반",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_t2jE0lxsu?alt=media&token=671e035e-4dd8-4a97-8a26-1d6cca56665b",
        id: "NUvpchTwBZ7ePu5nBree",
      },
      {
        order: 20,
        title: "동아리-학교홍보반",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_jAsoHO5VM?alt=media&token=daf9de93-0da1-4abf-b24f-e1660e1d2a94",
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_jAsoHO5VM",
        id: "REq5SVbTpkWHeROgWKVc",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMz_CVQCstTDU",
        title: "동아공업고등학교 포스터",
        order: 22.9375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMz_CVQCstTDU?alt=media&token=df5b2599-3b08-4620-9997-a46a8c22767c",
        id: "Tf7ip82ehQpeABZlNGNe",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_q106PMWJf",
        order: 28,
        title: "동아리-기계 용접 취업 반",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_q106PMWJf?alt=media&token=a12b21a6-5662-4973-a793-4ffb4231f281",
        id: "WN3YtOSjJQHjKj0M27lR",
      },
      {
        order: 25,
        title: "자동차과 실습사진",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMz_kLP3jJgN-?alt=media&token=87f88d54-0dd4-4c97-b0ff-8d0593af7ace",
        path: "images/BOOTH_IMG_XAMlGKMz_kLP3jJgN-",
        id: "WOwJjPgyifiCaoVMpwTH",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMz_3cxdKiy2h?alt=media&token=b972e7ea-1b11-447f-98dc-af7352ad7ccc",
        order: 25,
        title: "제102회 전국체육대회 금메달",
        path: "images/BOOTH_IMG_XAMlGKMz_3cxdKiy2h",
        id: "jLw9V7u9T2SFGQuvF9Ow",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_Xsb5jPulT?alt=media&token=529ee7e3-3a00-4e88-8dcf-d34eed71833e",
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_Xsb5jPulT",
        order: 21,
        title: "동아리-배드민턴반",
        id: "m1xQ6RJxbdulEaFyE4FT",
      },
      {
        title: "학교 전경",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_lSZifYlCR?alt=media&token=d101156f-2a2f-4377-ae21-f177f3fd44ee",
        order: 11,
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_lSZifYlCR",
        id: "mif2cWWzdlGpEhyRgfnt",
      },
      {
        title: "중국 어학연수",
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_OftYNMNzu",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_OftYNMNzu?alt=media&token=ec8fe7b0-4221-43c0-8408-21458f3326e5",
        order: 16,
        id: "p9Kd2nKTRwkvUL677GGT",
      },
      {
        order: 29,
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_yhpnGliWg",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_yhpnGliWg?alt=media&token=7d11927f-1b9a-41b4-aee7-e1fd1997bce0",
        title: "포스터대회- 화학공업과",
        id: "pce1Fh9Pmw76a3Z1F27n",
      },
      {
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_OM7RUtr4A",
        order: 13.5,
        title: "체육대회-축구",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_OM7RUtr4A?alt=media&token=3cb0e4f3-e353-434e-b8d3-f1f08c09d0ac",
        id: "sW0akmRNA3VXPuMxwKfV",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_Qzx_WzWh3?alt=media&token=686f37f8-4052-4702-81a3-6ee800ff0d08",
        title: "3학년 대상 진학설명회",
        order: 15,
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_Qzx_WzWh3",
        id: "tE6NETFXW1n3OLxIYfs6",
      },
      {
        title: "화학공업과",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMz_J4Q81NCPi?alt=media&token=fde37137-7b67-44df-8514-5afdc44fc85f",
        order: 24,
        path: "images/BOOTH_IMG_XAMlGKMz_J4Q81NCPi",
        id: "xNBMCdbJiUPNK2IHvrwq",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_iQrZ0JYdW?alt=media&token=5396fb82-144f-4b65-86f3-d39753751a08",
        title: "주기율표만들기대회-화학공업과",
        order: 27,
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_iQrZ0JYdW",
        id: "xoJYbpv8UG4yQZ8w8D5Z",
      },
      {
        title: "체육대회",
        path: "images/BOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_mjjf0ygHR",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMzdueLmmPNi6GXNu4H2ux1_mjjf0ygHR?alt=media&token=28e758b4-f5e4-4cfd-b5f2-3941ebcb3200",
        order: 12,
        id: "zEGUSnS4qbYlDYYzjcYi",
      },
      {
        title: "기계과",
        order: 23,
        path: "images/BOOTH_IMG_XAMlGKMz_fl3mSR8Ob",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XAMlGKMz_fl3mSR8Ob?alt=media&token=6123c93a-3b09-4749-8cfa-ae8f835cbda3",
        id: "zhTZzYDzhj9SNlYw4zov",
      },
    ],
    videos: [
      {
        title: "화학공업과 배쓰솔트 배쓰밤 만들기",
        order: 2,
        url: "https://youtu.be/MPVsthrNscY",
        id: "CE8NmQHkscW7wqonb6dA",
      },
      {
        order: 1.75,
        title: "메인영상",
        url: "https://youtu.be/fCes1C7M1Ig",
        id: "XpmDnyRONCFJPNWofKJY",
      },
    ],
  },
  XLNAf0DA: {
    id: "XLNAf0DA",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F4ew2y0g35_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%8F%99%EB%9E%98%EC%9B%90%EC%98%88%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%EB%AA%A8%EC%A7%91%20%EB%A6%AC%ED%94%8C%EB%A6%BF.png?alt=media&token=56344ea4-f0f6-44b6-bb99-7ec9894bddcd",
        title: "2023학년도 동래원예고등학교 신입생 모집 리플릿1",
        order: 2,
        path: "documents/4ew2y0g35_2023학년도 동래원예고등학교 신입생 모집 리플릿.png",
        id: "LQ52Al6XwDhiie1GXTMS",
      },
      {
        path: "documents/StNgwqVLm_2023학년도 동래원예고등학교 신입생 모집 리플릿1.png",
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FStNgwqVLm_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%8F%99%EB%9E%98%EC%9B%90%EC%98%88%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%EB%AA%A8%EC%A7%91%20%EB%A6%AC%ED%94%8C%EB%A6%BF1.png?alt=media&token=8f4548d5-743e-4ac1-a834-4c0ec6d16627",
        title: "2023학년도 동래원예고등학교 신입생 모집 리플릿2",
        id: "UByd7X0NVPAisRvJyH7v",
      },
      {
        order: 1,
        path: "documents/ClZwtWyrY_2023학년도 신입생 입학전형 요강(동래원예고).hwp",
        title: "2023학년도 동래원예고등학교 신입생 입학전형 요강",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FClZwtWyrY_2023%ED%95%99%EB%85%84%EB%8F%84%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95(%EB%8F%99%EB%9E%98%EC%9B%90%EC%98%88%EA%B3%A0).hwp?alt=media&token=bd8a2b5c-d733-4a33-a787-87b874dd73eb",
        id: "em1IYvNBxSCNH8kJC1Y9",
      },
    ],
    images: [
      {
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_0b8B5mabj",
        order: 22,
        title: "식품가공과(식품가공실습-사과젤리제조)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_0b8B5mabj?alt=media&token=0d8b5d9f-fbf9-47d4-9b9e-34ab37939fc0",
        id: "4fa5Wst70RuGkOiiIeB7",
      },
      {
        order: 9.0859375,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_ORLd3FV4k?alt=media&token=65608e29-4814-4efb-ac41-420094c4d8ab",
        title: "명장공방(난공방) 운영",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_ORLd3FV4k",
        id: "5OlAizc1wJRmf1DsznQn",
      },
      {
        title: "제51회 전국영농학생축제 입상",
        order: 6.12890625,
        path: "images/BOOTH_IMG_XLNAf0DA_PL228YUHi",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_PL228YUHi?alt=media&token=48bb35fe-43e2-4821-be44-a8720269fc9f",
        id: "80JYT6gypdPXKYiV6kro",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_YfzE4ppMj?alt=media&token=3b528509-16df-46e9-aecb-3cc029f85d20",
        path: "images/BOOTH_IMG_XLNAf0DA_YfzE4ppMj",
        order: 8.67578125,
        title: "청소년 꽃다발 만들기 대회 출전",
        id: "A7wdeZSnBtuO9G39HY3K",
      },
      {
        order: 12,
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_MwZ4prIYw",
        title: "한국숲사랑청소년단 활동(산림청)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_MwZ4prIYw?alt=media&token=e6a47791-c7d8-481c-8c95-f4add48f6120",
        id: "ACDQpyrhNMQYrCIyuD83",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_zpJxYyJQD",
        title: "스마트팜 도시농업교육 프로그램 진행",
        order: 8.74169921875,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_zpJxYyJQD?alt=media&token=e8b81651-0f94-4e39-b454-7e276c866503",
        id: "ATvnOk25KGwynQu3mg91",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_UWFVwwFC2",
        order: 28,
        title: "동래원예고등학교 홈페이지 QR코드",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_UWFVwwFC2?alt=media&token=96797c06-d46d-4466-8d8f-6c18b3b2de9b",
        id: "BADRdtn84E0IuYFFaayA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_MqfYZPeNl?alt=media&token=239c688d-1150-427d-bbd3-11625a2383b1",
        order: 8.7373046875,
        title: "2022 스마트팜 코리아 박람회 견학",
        path: "images/BOOTH_IMG_XLNAf0DA_MqfYZPeNl",
        id: "BS1xiHbsVhLQfQhNepjz",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_EFtPJTDjb?alt=media&token=dc2f0881-9660-46a0-8a67-691f5cad02ce",
        order: 18,
        title: "화훼장식과(꽃꽂이실습)",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_EFtPJTDjb",
        id: "CpL6YcuS2HXSH5QskzLB",
      },
      {
        title: "현장실습교육(WPL)참가-까매요",
        path: "images/BOOTH_IMG_XLNAf0DA_PBTBYLLfQ",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_PBTBYLLfQ?alt=media&token=db46b676-301c-4e38-9db2-a4c3cb20e553",
        order: 8.7559814453125,
        id: "DhYLQgmjdGdtqA0fKQtN",
      },
      {
        order: 8.7197265625,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_kzyBU9uzY?alt=media&token=55863526-ccf9-4f76-b3ef-2908a06dda31",
        path: "images/BOOTH_IMG_XLNAf0DA_kzyBU9uzY",
        title: "2022 부산 지방기능경기 대회 입상",
        id: "F0vXSG59reV5K9xwKDYd",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_UXxjJzqXl",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_UXxjJzqXl?alt=media&token=fc880d64-caa4-4416-a030-b46f6c5321e8",
        title: "2022학년도 글로벌 현장학습 호주인턴십 최종합격",
        order: 8.744171142578125,
        id: "FM5rjmekL33YkFHNd5tU",
      },
      {
        title: "현장실습교육(WPL)참가-까매요",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_ogSBiQOOB?alt=media&token=cba1e3c2-d701-4c06-b333-87de41e9e3b3",
        order: 8.757080078125,
        path: "images/BOOTH_IMG_XLNAf0DA_ogSBiQOOB",
        id: "GD4dXkMSJvJv8AkELUYA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_VN2vosTZp?alt=media&token=6c00d065-6da7-436b-9b23-c5c32ef4faea",
        title: "스마트팜 도시농업교육 프로그램 진행",
        path: "images/BOOTH_IMG_XLNAf0DA_VN2vosTZp",
        order: 8.743896484375,
        id: "I5ihFhBPBXDKcKg21g1b",
      },
      {
        order: 29,
        title: "동래원예고등학교 유튜브 채널 QR코드",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_i6tFi195r",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_i6tFi195r?alt=media&token=616bc879-0049-4b90-9eea-63a4d460e3fb",
        id: "IgTAZnnJ2gvr1vA896r3",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_j9pzzDnA7?alt=media&token=ac7cb97c-7050-4f5a-88ca-590619507892",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_j9pzzDnA7",
        title: "동래원예고 귀여운 마스코트 오동통통 오리",
        order: 25.8125,
        id: "LWvpqRq2CHvyToNgmocp",
      },
      {
        order: 2.6875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_xlBL6E7l9?alt=media&token=61b718a4-6487-47ca-adb2-2874b12e7533",
        title: "동래원예고 학과소개 이미지",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_xlBL6E7l9",
        id: "Ls4lSXOQBzHC6z83k6hM",
      },
      {
        order: 9.07421875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_qtB4as5J6?alt=media&token=140fbc43-79c8-482a-b9fe-3a70ef5ef905",
        title: "명장공방(목공공방) 운영",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_qtB4as5J6",
        id: "MwZ67ONdnjM7eSelNoI5",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_iadPxV45A",
        order: 18.75,
        title: "농산업유통과(농산물 거래 실습)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_iadPxV45A?alt=media&token=244be7c8-7b6b-4f5f-a4cf-17d63238dee8",
        id: "N8dlTLb3NmCWZaspgp9U",
      },
      {
        title: "도시조경과(주택조경 모형 만들기 실습)",
        order: 19.5,
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_-GPXkiTsn",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_-GPXkiTsn?alt=media&token=e7af7462-c838-415f-b2de-fef2a8e2a3be",
        id: "NeRdBLEVRlMu8qWf77kY",
      },
      {
        title: "아름다운 교육상 대상 수상",
        order: 11.2890625,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_avThpuYqr?alt=media&token=dded1b25-5733-4553-9eb8-4986c03bb224",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_avThpuYqr",
        id: "NsDIW1jXw03c1BjQJMcw",
      },
      {
        order: 23,
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_RsT7IRv5S",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_RsT7IRv5S?alt=media&token=22f08c14-bc10-4b51-8454-d7b53a623798",
        title: "식품가공과(제빵실습)",
        id: "OseRiN6dGwjiVUzRxYXc",
      },
      {
        title: "도시원예과(채소재배실습)",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_y-2OY6TOF",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_y-2OY6TOF?alt=media&token=724ebaa6-d94c-4e1c-a142-e728de686c64",
        order: 17,
        id: "R66udumU9BeUjSgfXZ69",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_wTZPqZviQ",
        order: 8.693359375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_wTZPqZviQ?alt=media&token=8c98035d-5d1c-466e-b492-4dd525968685",
        title: "화훼창작 경진 대회",
        id: "RWNzgtanbtSU0NTSGDmC",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_FWq-vym0h?alt=media&token=d770cf91-b8f7-43f1-b856-2fde603ac2fe",
        order: 20.5,
        title: "산림자원과(체인톱 실습)",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_FWq-vym0h",
        id: "SBqheizuqwXZZX0ufESE",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_BwQgKMHEl?alt=media&token=e16884cd-92ef-48f7-afe6-2afe236c745b",
        path: "images/BOOTH_IMG_XLNAf0DA_BwQgKMHEl",
        title: "고교학점제 학과탐색 및 체험 활동",
        order: 9.126953125,
        id: "T8B4Dti4VykJsfmEZ9jE",
      },
      {
        order: 18.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_MrfnoSpXj?alt=media&token=4f5ba4b4-8249-4cfa-b2e8-4d9d9cc303f4",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_MrfnoSpXj",
        title: "화훼장식과(코사지 제작 실습)",
        id: "TmQIhxV7yQiQGPkYf3pV",
      },
      {
        order: 8.7449951171875,
        title: "직업계고 호주 글로벌 현장학습 출정식",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_y4Eh1xHMp?alt=media&token=422aac08-80d1-4844-ad8c-304a77529c04",
        path: "images/BOOTH_IMG_XLNAf0DA_y4Eh1xHMp",
        id: "VeoBlwWyQojRsu1I8usl",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_P7lv2Dmw8?alt=media&token=51f07377-b5bc-4f3c-b7f9-ab068a9f4fb0",
        title: "우장춘 홀",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_P7lv2Dmw8",
        order: 8.763671875,
        id: "Wh0KSxsiDsBKJseEitNB",
      },
      {
        order: 9.15625,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_h1myXVrth?alt=media&token=e335f26a-7098-4294-ad00-ffe1906297bd",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_h1myXVrth",
        title: "학생회(학교폭력 예방 및 금연캠페인)",
        id: "Xp4dAjVwYIoWyGROocag",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_8p-5LZkFK",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_8p-5LZkFK?alt=media&token=2f8662bd-0c0a-489f-84e1-2db135c9c716",
        title: "도시조경과(조경설계실습)",
        order: 19,
        id: "XycaWTc99uwO4Rjzw8rH",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA__RgKwP13i",
        order: 15,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA__RgKwP13i?alt=media&token=c2452c19-6737-47ea-8e2b-d0f4f29b4b96",
        title: "1학년 도예수업",
        id: "YnGSoZwx4zpqOFZhLGKA",
      },
      {
        title: "동래원예고 학과소개",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_E6iQo_kPv?alt=media&token=ab6f819d-6777-40f9-ac14-b58a3379deb5",
        order: 1.84375,
        path: "images/BOOTH_IMG_XLNAf0DA_E6iQo_kPv",
        id: "ZMI6a0UsxRggdt3b6rNw",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_2sLB4dytX",
        order: 4.19140625,
        title: "제51회 전국영농학생축제(FFK)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_2sLB4dytX?alt=media&token=b60172e8-68b5-4660-947c-65951a9cdd7a",
        id: "aKpO7BTWuY52YWQvQ7CB",
      },
      {
        order: 18.625,
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_3POoJllxT",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_3POoJllxT?alt=media&token=ac55d333-b6a9-4df5-a197-818906306658",
        title: "농산업유통과(유통전산실습)",
        id: "b8de6TwqPFNBrWs9nqwo",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_IL9-vp9xp",
        title: "임업기술훈련",
        order: 12.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_IL9-vp9xp?alt=media&token=69480975-f63e-4635-9347-4f8b9183054a",
        id: "bP0XoEGxVEYooofP69oD",
      },
      {
        title: "청소년 꽃다발 만들기 대회 출전",
        order: 8.640625,
        path: "images/BOOTH_IMG_XLNAf0DA_jJ14vKnTi",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_jJ14vKnTi?alt=media&token=6a3bdc54-b68f-4743-b59a-e369132e448d",
        id: "ccIIWNYKJM0EH9z9fArT",
      },
      {
        title: "현장실습교육(WPL)참가-까매요",
        path: "images/BOOTH_IMG_XLNAf0DA_0SD9zFL2N",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_0SD9zFL2N?alt=media&token=7ffd28b1-3a69-40c4-9d42-a0ef40139910",
        order: 8.7603759765625,
        id: "cjIMPrIWGLWLExHLZUXy",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_uDl9FNtJp?alt=media&token=b2c81e19-2026-4813-bee0-ed7068f2f5bd",
        order: 25.25,
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_uDl9FNtJp",
        title: "농업기계(지게차 실습)",
        id: "dJIM4ei2IPvmK7CrvvYU",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_g2nlP-rer",
        order: 24.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_g2nlP-rer?alt=media&token=1a0544c1-9db4-4a36-b0b4-8bb19e28cc8d",
        title: "식품가공과(제과실습)",
        id: "dYN0wlGyaOoIu9yCx1bg",
      },
      {
        title: "동래원예고 학과소개 이미지",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_BM2LXY3Bb?alt=media&token=40a3ce01-9343-40bd-8981-a7609deda8d8",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_BM2LXY3Bb",
        order: 3.53125,
        id: "eG6S47sAtp9cIpuwCzbg",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_KDuwwalQe?alt=media&token=33d15544-0b73-44d5-921c-264e3cf84ce8",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_KDuwwalQe",
        title: "학교 전경",
        order: 1,
        id: "edBdGE4Bkl1qX2eueZbb",
      },
      {
        order: 10.578125,
        path: "images/BOOTH_IMG_XLNAf0DA_58xGphUtl",
        title: "학업중단예방 프로그램 ",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_58xGphUtl?alt=media&token=f7ba2ca0-9cbf-45b6-9664-909a0b4af9a9",
        id: "ee7V4bAeW6WxBd5NyUVC",
      },
      {
        title: "농업기계(굴착기 실습)",
        order: 25.625,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_ZyND327yX?alt=media&token=c7aba216-9aab-4acb-8aae-9c5285ca6184",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_ZyND327yX",
        id: "f7LgHMD46P0mDfGmmkuc",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_Y3Eu1I26o?alt=media&token=a19f28ce-3ecc-4fa4-be4d-98f921e5b842",
        path: "images/BOOTH_IMG_XLNAf0DA_Y3Eu1I26o",
        order: 8.763465881347656,
        title: "43st 창업동아리 재능기부 활동",
        id: "jqctq08uqV7iVaDsvvgu",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_RSx3Yq2Pl",
        order: 8.73291015625,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_RSx3Yq2Pl?alt=media&token=9dfbb860-5b6a-4c5b-b230-2d8f41c7d624",
        title: "4-H 프로젝트 활동(토피어리 만들기)",
        id: "l7p8Tf4WWsxXqByT5Vz1",
      },
      {
        order: 8.762847900390625,
        path: "images/BOOTH_IMG_XLNAf0DA_7j1OLjeRn",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_7j1OLjeRn?alt=media&token=faa502c3-5997-452b-97a8-b77e6b4b70b4",
        title: "현장실습교육(WPL)참가-한림알로에",
        id: "mCXU99yYFJH80MugLu44",
      },
      {
        title: "43st 창업동아리 재능기부 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_ZebMkq0Rx?alt=media&token=70a932fe-9007-43ae-972a-22615b84e689",
        path: "images/BOOTH_IMG_XLNAf0DA_ZebMkq0Rx",
        order: 8.763259887695312,
        id: "mFCUYTt2GGzSJujaAstT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_6toNK9n6W?alt=media&token=5c75f9b8-19b2-4ba5-a3b6-cbad2398f3dd",
        title: "청소년 꽃다발 만들기 대회",
        order: 7.40625,
        path: "images/BOOTH_IMG_XLNAf0DA_6toNK9n6W",
        id: "ngZFX2ZSM1hy5tJscnew",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_mo_N_G7ty?alt=media&token=9d0003ed-d424-4409-84cf-ba9e8c40c59b",
        title: "전국학생 4-H연합회 리더십캠프 참가",
        order: 8.728515625,
        path: "images/BOOTH_IMG_XLNAf0DA_mo_N_G7ty",
        id: "oFDTpdWdyAbdYh62gdNr",
      },
      {
        title: "현장실습교육(WPL)참가-한림알로에",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_kfFjFMaA_?alt=media&token=03111b61-5dad-428c-8e72-7f7f38663a32",
        order: 8.76202392578125,
        path: "images/BOOTH_IMG_XLNAf0DA_kfFjFMaA_",
        id: "pZbAHCvwNbPB475sbJGc",
      },
      {
        title: "도시원예과(스마트팜 화훼재배 실습)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_thNEFsZrc?alt=media&token=4747e7f4-2080-40f5-a4d6-63f203e40bc6",
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_thNEFsZrc",
        order: 17.5,
        id: "pcp67uWhiCCyqD21k09d",
      },
      {
        order: 8.7109375,
        title: "2022학년도 부산광역시 기능경기대회",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_uy5n-Y6En?alt=media&token=eb87f28b-4c3e-468d-b114-eeabd1a56bcb",
        path: "images/BOOTH_IMG_XLNAf0DA_uy5n-Y6En",
        id: "umpXYcJTaMLevUkV30a4",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_yvInyPiDm",
        order: 20,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_yvInyPiDm?alt=media&token=02fbc232-4d73-44e0-803b-a471eb8a215e",
        title: "산림자원과(버섯재배실습)",
        id: "v86q1XhPCReH5G47hYcw",
      },
      {
        title: "고교학점제 학과탐색 및 체험 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_eVs6-Crnp?alt=media&token=3f9eab36-76c3-4d8f-8eab-7a5d90c35eea",
        order: 9.1416015625,
        path: "images/BOOTH_IMG_XLNAf0DAqWZldP8tckSgIj35DUC2_eVs6-Crnp",
        id: "vu7UMsNmZMYdYvg6QFBz",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_mCX-ra0Vh",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_mCX-ra0Vh?alt=media&token=17de6cbc-047e-42a9-82da-32af9031111f",
        order: 8.763568878173828,
        title: "화상국제교류반",
        id: "xcJwmMjdUYqqU5F7ACVW",
      },
      {
        title: "도시원예과(스마트팜 화훼재배 실습)",
        path: "images/BOOTH_IMG_XLNAf0DA_6fjLI2S9r",
        order: 17.25,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_6fjLI2S9r?alt=media&token=2d467100-faec-43fa-92d6-0be0efda8856",
        id: "ynUxMcSsJzD5GgJqEhUA",
      },
      {
        path: "images/BOOTH_IMG_XLNAf0DA_uktw3Jiyo",
        order: 4.8515625,
        title: "제51회 전국영농학생축제(FFK) 부산지부 지방대회",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_XLNAf0DA_uktw3Jiyo?alt=media&token=72b2d9d7-a3c3-4766-8c5a-efe9a40012a1",
        id: "zwRBcIxnuTN3RDhz4gvl",
      },
    ],
    videos: [
      {
        order: 8,
        url: "https://youtu.be/rSyUIHYyVuA",
        title: "'너와 나 짝짜꿍' 행사",
        id: "3W1wbPAcMwBzkCrWcXBY",
      },
      {
        order: 1.125,
        title: "기예능영상",
        url: "https://youtu.be/HZmSMex9ka0",
        id: "E7byXponU7yS99J1eX4X",
      },
      {
        url: "https://youtu.be/hVQwxVwC2Xo",
        title: "제48회 동래원예고등학교 국화전시회",
        order: 9,
        id: "URqYaxIDvAPbjl3owJgS",
      },
      {
        title: "주먹(하이파이브)데이!",
        order: 2.625,
        url: "https://youtu.be/0mevAjeVNBE",
        id: "XeSmMdMYi13bDxMeSgpz",
      },
      {
        title: "하이파이브데이~~",
        order: 3,
        url: "https://youtu.be/J5BaSpgHGiQ",
        id: "YVvYctKhxbcEv7pBwdnn",
      },
      {
        url: "https://youtu.be/OiR_F1IOc88",
        order: 5,
        title: "EBS 장학퀴즈",
        id: "ZwkHQqYPx2z4M9AlatyS",
      },
      {
        title: "온라인 전국영농학생축제-동래원예고편(안동MBC)",
        order: 6,
        url: "https://youtu.be/dobeKck_gcE",
        id: "fQdzXR4teaRfpQmZJng5",
      },
      {
        url: "https://youtu.be/62J8Itk8Qno",
        order: 1.875,
        title: "동래원예고 '우리모두 존중행'",
        id: "giYKZPDjhTvAEePvIau9",
      },
      {
        url: "https://youtu.be/AJVURL1YocU",
        title: "현장실습교육 협약식",
        order: 4,
        id: "lfFZuWCpKHoz6p3p6AWX",
      },
    ],
  },
  bjXIKSDJ: {
    id: "bjXIKSDJ",
    documents: [],
    images: [
      {
        title: "2022 전국상업경진대회 시상식",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_8Rr9n5ySS?alt=media&token=63c0f246-7777-41fc-b252-beccf86a4e7a",
        path: "images/BOOTH_IMG_bjXIKSDJ_8Rr9n5ySS",
        order: 27.972396850585938,
        id: "0ShHHrarHzC23paMBmeB",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_WzeKzGhcA?alt=media&token=15c17c1f-5ec0-41b1-a99c-89f7374dd72f",
        path: "images/BOOTH_IMG_bjXIKSDJ_WzeKzGhcA",
        order: 13.968740940093994,
        title: "관광컨벤션과 바텐더 실습",
        id: "1aq0PiJgaJYvGCzx7Xym",
      },
      {
        order: 24.771484375,
        title: "카페베이커리과 제과제빵 실습",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_zBm-ZJPJb?alt=media&token=c94323d7-6f9a-4992-9f6d-e03bcc0a549b",
        path: "images/BOOTH_IMG_bjXIKSDJ_zBm-ZJPJb",
        id: "1vKuJE4nNWg4wrhnCaiN",
      },
      {
        path: "images/BOOTH_IMG_bjXIKSDJ_kk2yat1Qk",
        title: "2023 신입생 모집요강 (2)",
        order: 2.375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_kk2yat1Qk?alt=media&token=22f0445e-d00e-4770-a445-cb630ed9e828",
        id: "4LIBvQaKMa0ofMDWS4ad",
      },
      {
        title: "교복",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_IHPOLF3Ku?alt=media&token=95a4eb61-67ed-400a-b25f-5e65cfdb85a4",
        order: 4.7491302490234375,
        path: "images/BOOTH_IMG_bjXIKSDJ_IHPOLF3Ku",
        id: "5zgFZ2OT3xRAzhvivcED",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_Us6Gz2DM3?alt=media&token=b4a5fe75-a2a5-4a53-9008-fc0786c9363d",
        path: "images/BOOTH_IMG_bjXIKSDJ_Us6Gz2DM3",
        order: 4.748260498046875,
        title: "2023 부산관광고 팜플렛 (12)",
        id: "60moEiSLuPxmeTCJt9d3",
      },
      {
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_f2sKbdo-e",
        title: "관광컨벤션과&한식조리과",
        order: 6.437481880187988,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_f2sKbdo-e?alt=media&token=ca680ed5-8c01-45b1-9436-883ef4c6dc99",
        id: "6NqbMQwVfCKgwqE0nlJi",
      },
      {
        order: 20.55859261751175,
        title: "관광컨벤션과 테이블세팅 실습",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_Y_GD-iXro?alt=media&token=ecb55523-6ff6-466c-a27f-0c371d2a008e",
        path: "images/BOOTH_IMG_bjXIKSDJ_Y_GD-iXro",
        id: "7DOu7lTyYm9ceny0Ujp1",
      },
      {
        title: "2023 부산관광고 팜플렛 (11)",
        order: 4.7476806640625,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_uGeR2YZGJ?alt=media&token=084eaa3c-742f-468c-a5d2-ee883ad2bbd2",
        path: "images/BOOTH_IMG_bjXIKSDJ_uGeR2YZGJ",
        id: "7kCjfd0U9yrS4wJW5pLG",
      },
      {
        title: "2023 부산관광고 팜플렛 (1)",
        order: 3.5625,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_vTUk04ckz?alt=media&token=f7d471d4-72df-42e1-af7c-be98dd1e4a46",
        path: "images/BOOTH_IMG_bjXIKSDJ_vTUk04ckz",
        id: "80A7MN7UMhVMubnPGfIt",
      },
      {
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_wAtSoUXyf",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_wAtSoUXyf?alt=media&token=911e4683-7f31-4547-a24b-0035c5238b32",
        order: 24.7802734375,
        title: "부산관광고등학교 제과제빵",
        id: "9QbqfMWrSxp2s8CE0Dpj",
      },
      {
        path: "images/BOOTH_IMG_bjXIKSDJ_U_vsvNUa_",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_U_vsvNUa_?alt=media&token=d585dbe0-66a3-4fd0-bd3f-fd2b6ecedaf5",
        order: 4.74072265625,
        title: "2023 부산관광고 팜플렛 (8)",
        id: "AibGrijS0llbpp6l5TwJ",
      },
      {
        order: 26.675048828125,
        path: "images/BOOTH_IMG_bjXIKSDJ_hhLPA4KOh",
        title: "원어민교사 수업",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_hhLPA4KOh?alt=media&token=3020a925-96a6-4697-9bbf-d40d620b2290",
        id: "Axo0O3nxChdUwZQxJweG",
      },
      {
        order: 33.0625,
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_-0XtDitB9",
        title: "입학식",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_-0XtDitB9?alt=media&token=f8c89b1b-b6b3-471f-9afe-2e3ca7a0035e",
        id: "BhbN8wP8U2q4HF9QrWoG",
      },
      {
        title: "2022 대한민국 국제요리&제과 경연대회",
        order: 27.7791748046875,
        path: "images/BOOTH_IMG_bjXIKSDJ_xEkZMaUPs",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_xEkZMaUPs?alt=media&token=0d9c3472-0e8b-458d-a4ab-f15909982563",
        id: "CqmrTSnbVdFInTvMUUZN",
      },
      {
        order: 28,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_FYzrh-4oQ?alt=media&token=ed896bf6-2feb-462b-a543-ebcd0562c2e0",
        path: "images/BOOTH_IMG_bjXIKSDJ_FYzrh-4oQ",
        title: "2022 AirBusan 드림스쿨 참여",
        id: "GBhNdRMK2F83G4QmRKxn",
      },
      {
        title: "2022 K-Move스쿨 싱가포르 호텔외식분야 취업연수과정",
        path: "images/BOOTH_IMG_bjXIKSDJ_O8JCbQ9H-",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_O8JCbQ9H-?alt=media&token=c912185b-c2c9-43a3-8f59-54dc204adfb4",
        order: 30.625,
        id: "GjYDBACz6SyQI44d8OqE",
      },
      {
        order: 25.51123046875,
        title: "한식조리과 궁중요리 실습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_MYXbL7m0o?alt=media&token=5ba8813e-7279-4738-9030-e897373bc99d",
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_MYXbL7m0o",
        id: "ICDshDWgfSuMkiS6KR1A",
      },
      {
        order: 25.872314453125,
        title: "글로벌 특강",
        path: "images/BOOTH_IMG_bjXIKSDJ_1bWkU4Ad5",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_1bWkU4Ad5?alt=media&token=301a66e1-79d5-4fbf-80c0-d52d33c13e33",
        id: "JkwMZHkPLoZBdpo2LGsJ",
      },
      {
        title: "관광컨벤션과 커피 로스팅 실습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_1VdCXykR1?alt=media&token=262825b7-4bc0-49d8-a71f-96860318b64a",
        order: 19.6171852350235,
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_1VdCXykR1",
        id: "LUSSsQlcNvCe6hXUqmLA",
      },
      {
        order: 27.944793701171875,
        path: "images/BOOTH_IMG_bjXIKSDJ_jhpo16uyZ",
        title: "2022 부산상업경진대회 출정식",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_jhpo16uyZ?alt=media&token=a284f316-9d77-46d5-b590-e77caf14d844",
        id: "MTZNpnNPO1VdpWu8TZvK",
      },
      {
        title: "2023 부산관광고 팜플렛 (7)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_IPWtUjooq?alt=media&token=30d65563-130f-4cf8-9799-281dac71b138",
        path: "images/BOOTH_IMG_bjXIKSDJ_IPWtUjooq",
        order: 4.7314453125,
        id: "OU5kbkBrE1CRFpiMuwnX",
      },
      {
        order: 25.150146484375,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_ndeny1nCq?alt=media&token=4db6aa10-9c29-4693-8e8b-5560e4cec6a6",
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_ndeny1nCq",
        title: "명장공방 기술전수",
        id: "QxIUXJntFg1Ljjdeqb42",
      },
      {
        order: 30,
        path: "images/BOOTH_IMG_bjXIKSDJ_y1INwambH",
        title: "2022 BST 홍보대사 캠프",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_y1INwambH?alt=media&token=e0c6d1f5-0275-4e59-b082-7727a5ae75d7",
        id: "RaPLx7ECbyTyG3iGS5fD",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_Vd-NbOouz?alt=media&token=b8d67b29-b848-49b0-9ffe-1f8042d0b6e0",
        order: 17.734370470046997,
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_Vd-NbOouz",
        title: "관광컨벤션과 TOPAS 실습",
        id: "RlppJ337oAblssyKvobm",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_z5BdBevxN?alt=media&token=08e79a54-eeab-45ce-b48f-a7e505b384ac",
        path: "images/BOOTH_IMG_bjXIKSDJ_z5BdBevxN",
        title: "카페베이커리과",
        order: 4.749275207519531,
        id: "SfYtGHxTFUlZJXx2c5s9",
      },
      {
        order: 27.88958740234375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_fgaju17aB?alt=media&token=35928307-60bb-4a01-a12e-60f593179ecf",
        path: "images/BOOTH_IMG_bjXIKSDJ_fgaju17aB",
        title: "2022 제10회 부산국제 AF아티산 페스티벌",
        id: "SkOcBHZnVMohs4UANuVa",
      },
      {
        order: 33.25,
        title: "졸업식",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_-ebGQXb4F?alt=media&token=adf2a46b-bdb5-4640-bc5d-f9a314fe10a2",
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_-ebGQXb4F",
        id: "TCTrRDFdWBloFEeniqUr",
      },
      {
        title: "각종 대회 참가",
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_ad6_dBmmY",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_ad6_dBmmY?alt=media&token=ffe3f53c-5d2f-4096-a4ba-15f7923ea7d6",
        order: 27.11669921875,
        id: "VgYFhbR9ma5qngGBHsoT",
      },
      {
        order: 4.745361328125,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_P9rtv5VUR?alt=media&token=e178cd41-a341-4f75-af06-d5b4c00d5677",
        path: "images/BOOTH_IMG_bjXIKSDJ_P9rtv5VUR",
        title: "2023 부산관광고 팜플렛 (9)",
        id: "VyLWwtLQnfpfOI383DP7",
      },
      {
        path: "images/BOOTH_IMG_bjXIKSDJ_0p9WkkgDs",
        title: "2023 부산관광고 팜플렛 (13)",
        order: 4.74884033203125,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_0p9WkkgDs?alt=media&token=3355dcdb-5343-45c5-8444-c86a2ed8e670",
        id: "WoxBHJa50pWWOIdsxG1K",
      },
      {
        title: "2023 부산관광고 팜플렛 (3)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_pfpzXB4Sa?alt=media&token=451e0b4b-b422-44fd-8a6a-ceb5130da520",
        order: 4.453125,
        path: "images/BOOTH_IMG_bjXIKSDJ_pfpzXB4Sa",
        id: "YKVaCGQjpnB76ov0FTXi",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_hGCU_PdRh?alt=media&token=3437eaba-7fd8-47b9-a136-530bf99bd26f",
        title: "산학일체형 도제학교 실습",
        path: "images/BOOTH_IMG_bjXIKSDJ_hGCU_PdRh",
        order: 25.6917724609375,
        id: "ZXQpOluyZctRnFIFiHFU",
      },
      {
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_02eBCnKkS",
        order: 32.875,
        title: "스승의날 행사",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_02eBCnKkS?alt=media&token=43bfed49-c58c-4a99-b034-600009c97500",
        id: "byMspBFsMT4nmLrfAp1g",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_Wqr8bFPLp?alt=media&token=23e12b7a-a6ac-45ea-bfa5-585fb19b131b",
        title: "2023 신입생 모집요강 (1)",
        path: "images/BOOTH_IMG_bjXIKSDJ_Wqr8bFPLp",
        order: 1.1875,
        id: "c4BlrQtYKFBtAAqD4H2B",
      },
      {
        order: 31.09375,
        title: "2022 BST 한마음 체육대회",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_VCKF1062N?alt=media&token=701764b4-3296-41fc-80d8-500f86908bd4",
        path: "images/BOOTH_IMG_bjXIKSDJ_VCKF1062N",
        id: "eTX9SRQCbPAam8SB9QFB",
      },
      {
        title: "2023 부산관광고 팜플렛 (2)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_RkaixAhx5?alt=media&token=77ab5776-05ed-4f55-a3dc-533273479bf4",
        path: "images/BOOTH_IMG_bjXIKSDJ_RkaixAhx5",
        order: 4.15625,
        id: "gICYkWQ4AxdrumZ6lOju",
      },
      {
        title: "관광컨벤션과 바리스타 실습",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_gsFz9oGSR?alt=media&token=dc1618f4-ab1d-47af-be10-6067dc533db9",
        order: 15.851555705070496,
        path: "images/BOOTH_IMG_bjXIKSDJ_gsFz9oGSR",
        id: "grXdP7obHfsAvrVo9zdH",
      },
      {
        order: 24.75390625,
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_XyGFpXoAA",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_XyGFpXoAA?alt=media&token=45a4d587-fe7e-44c7-92c9-eac172857ff8",
        title: "카페디저트실습",
        id: "hkGCCmKxQV1oALgp9yXQ",
      },
      {
        order: 30.9375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_OytUiwtaj?alt=media&token=f4d09c05-29cb-4b6d-b308-a222f788d4c2",
        title: "2022 일본 교환학생 환영식",
        path: "images/BOOTH_IMG_bjXIKSDJ_OytUiwtaj",
        id: "ipNvwKkso04KuLXNO6KQ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_i9FyrkkgY?alt=media&token=5b9d9f32-0285-4b33-9170-7c439357849b",
        order: 27.585952758789062,
        path: "images/BOOTH_IMG_bjXIKSDJ_i9FyrkkgY",
        title: "2022 교내 NCS 경연대회",
        id: "pSnsdjoqibBcz322J7N0",
      },
      {
        title: "2023 부산관광고 팜플렛 (10)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_FK_Jy732z?alt=media&token=16ff30a8-ee83-40f5-9320-10d08c6aa4a6",
        path: "images/BOOTH_IMG_bjXIKSDJ_FK_Jy732z",
        order: 4.74652099609375,
        id: "q71pgoZjAjZJ85AkMSXq",
      },
      {
        order: 4.712890625,
        path: "images/BOOTH_IMG_bjXIKSDJ_9KXHD5e6q",
        title: "2023 부산관광고 팜플렛 (6)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_9KXHD5e6q?alt=media&token=84c9a01c-2350-4f97-ae61-4e3f5134e85e",
        id: "rnfgGH7R0grP5EjjmVka",
      },
      {
        order: 4.749347686767578,
        path: "images/BOOTH_IMG_bjXIKSDJ_ZYuvZ6uHM",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_ZYuvZ6uHM?alt=media&token=4001a0f7-eb85-4d20-b736-d3548243cf5f",
        title: "한식조리과",
        id: "s5I25yT1aZo0tzEGfz6r",
      },
      {
        order: 4.749202728271484,
        title: "관광컨벤션과",
        path: "images/BOOTH_IMG_bjXIKSDJ_4qj5IvXRb",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_4qj5IvXRb?alt=media&token=ce5e3d87-ac7e-4531-bf65-78dc0ea082d8",
        id: "t9BowcH5BYPL7yChOst4",
      },
      {
        path: "images/BOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_jCtWao3Oe",
        title: "교내 꿈 발표 대회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJPGWhswgbUK4Om6JMuH72_jCtWao3Oe?alt=media&token=db05ee78-f8f6-475c-86ce-f99813e65940",
        order: 31.25,
        id: "vjsO4F1YAnottC8JsyNh",
      },
      {
        title: "2023 부산관광고 팜플렛 (4)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_AX_5isRCU?alt=media&token=5c0984b4-19c9-4feb-8248-a35faec98098",
        order: 4.6015625,
        path: "images/BOOTH_IMG_bjXIKSDJ_AX_5isRCU",
        id: "yrxANRPHvWm449xRJOXp",
      },
      {
        order: 4.67578125,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bjXIKSDJ_Rl8vG1TMC?alt=media&token=6efdf890-3f7d-4fb4-b94e-252fcc9620c8",
        path: "images/BOOTH_IMG_bjXIKSDJ_Rl8vG1TMC",
        title: "2023 부산관광고 팜플렛 (5)",
        id: "z5MvGuDb2M3caMBW9uxB",
      },
    ],
    videos: [
      {
        title: "부산관광고 도제학교_에스키스",
        order: 2,
        url: "https://youtu.be/mX5Lga19AG8",
        id: "2hFXuMdI46yuTjV1CTdw",
      },
      {
        url: "https://youtu.be/yKH42vZWgys",
        title: "2021 BST NCS 교내 기능경진대회",
        order: 10,
        id: "7wHAxfPvV7nOOQGti4Gz",
      },
      {
        url: "https://youtu.be/8C2gGq06kHY",
        order: 0.5,
        title: "부산관광고등학교 공식 홍보영상",
        id: "CnNHEtFQlNS3tLGeVTZu",
      },
      {
        title: "부산직업교육박람회_ 부산관광고편",
        order: 1.25,
        url: "https://youtu.be/a97xUOm0GZQ",
        id: "NbvJjpQSuJ3zTJf6A40q",
      },
      {
        order: 5,
        url: "https://youtu.be/-aZQtByXmzg",
        title: "2022 BST 중학생 진로연계체험",
        id: "NsXaxfqB9g5dB9bCnYOK",
      },
      {
        url: "https://youtu.be/8fI1j5Lhe-c",
        title: "부산관광고 도제학교_인터불고 호텔",
        order: 2.75,
        id: "PiPTjG09dhIkp1eTItIr",
      },
      {
        url: "https://youtu.be/6J0asFXkwb8",
        title: "2022 BST 수학여행",
        order: 8,
        id: "XOfthJ8cyDvloDeVlo6U",
      },
      {
        title: "2022 BST 체육대회",
        order: 6,
        url: "https://youtu.be/HnNZY57aUCg",
        id: "lQQSKRFmqBXh9PwPDSXN",
      },
      {
        title: "2022 BST 대한민국 국제요리&제과 경연대회 출전",
        order: 4,
        url: "https://youtu.be/7wOmZ-VFts4",
        id: "wesRPWyjZAo4A9MVa611",
      },
      {
        url: "https://youtu.be/RwQvRaPKAE8",
        order: 2.5,
        title: "부산관광고 도제학교_호텔금오산",
        id: "whwadVVHd6ZvpW7YIBcH",
      },
      {
        title: "2022 BST 중학생 기능경진대회 (스타캠프)",
        url: "https://youtu.be/tZtrQr3nUrA",
        order: 3,
        id: "x8o2LKPx1IlBeNEHPkYr",
      },
    ],
  },
  bm2RGZSA: {
    id: "bm2RGZSA",
    documents: [
      {
        order: 0.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FS7yCvoNCI_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%8F%99%EB%AA%85%EA%B3%B5%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.hwp?alt=media&token=138252f9-b0ba-4947-bcad-14d13fc54124",
        path: "documents/S7yCvoNCI_2023학년도 동명공업고등학교 입학전형 요강.hwp",
        title: "2023학년도 동명공고 입학전형 요강",
        id: "01vBXn8piEEzlgCvmlK9",
      },
      {
        order: 2,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FXyv5nQJhZ_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%8F%99%EB%AA%85%EA%B3%B5%EA%B3%A0_%EB%B8%8C%EB%A1%9C%EC%8A%88%EC%96%B4(%EC%B5%9C%EC%A2%85)_%EC%B5%9C%EC%A0%81%ED%99%94.pdf?alt=media&token=24af262f-ba2b-4a21-8b43-f0ee07eb66d3",
        path: "documents/Xyv5nQJhZ_2023학년도 동명공고_브로슈어(최종)_최적화.pdf",
        title: "2023학년도 동명공고 브로슈어",
        id: "0QKIKInG4qgleA3e0dt5",
      },
      {
        path: "documents/xNUejIM7d_2023학년도 동명공고_리플렛(최종).pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FxNUejIM7d_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%8F%99%EB%AA%85%EA%B3%B5%EA%B3%A0_%EB%A6%AC%ED%94%8C%EB%A0%9B(%EC%B5%9C%EC%A2%85).pdf?alt=media&token=397beb0f-0261-4261-a6f9-482ea46b0fab",
        order: 1,
        title: "2023학년도 리플렛",
        id: "cCrREZZ8m5DiZjL4g0DY",
      },
      {
        title: "[동명공고 소식지] 월간 동명 3호 (5월호)",
        order: 6.5,
        path: "documents/Rlrg59SpM_월간동명5월호(진짜마지막최종본!!).pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FRlrg59SpM_%EC%9B%94%EA%B0%84%EB%8F%99%EB%AA%855%EC%9B%94%ED%98%B8(%EC%A7%84%EC%A7%9C%EB%A7%88%EC%A7%80%EB%A7%89%EC%B5%9C%EC%A2%85%EB%B3%B8!!).pdf?alt=media&token=f34b780d-bdb7-4956-9b24-6cd52f4ba221",
        id: "i6LEbM2HV9kJigEhO77D",
      },
      {
        path: "documents/Gt8VESB-7_월간동명4월호(최종본).pdf",
        title: "[동명공고 소식지] 월간동명 2호 (4월호)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FGt8VESB-7_%EC%9B%94%EA%B0%84%EB%8F%99%EB%AA%854%EC%9B%94%ED%98%B8(%EC%B5%9C%EC%A2%85%EB%B3%B8).pdf?alt=media&token=19cb9405-4c72-4275-9f5d-43fb417854e9",
        order: 5,
        id: "kDFVPOH6NCT0Ruv7j9TH",
      },
      {
        path: "documents/gAWFp6ISo_월간동명6,7월호(최최종).pdf",
        order: 7,
        title: "[동명공고 소식지] 월간 동명 4호 (6,7월호)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FgAWFp6ISo_%EC%9B%94%EA%B0%84%EB%8F%99%EB%AA%856%2C7%EC%9B%94%ED%98%B8(%EC%B5%9C%EC%B5%9C%EC%A2%85).pdf?alt=media&token=550e620d-1a5a-40d3-95e7-af62341cc5d0",
        id: "lnfEuXteUVwvmqxY8qwD",
      },
      {
        order: 8,
        title: "[동명공고 소식지] 월간 동명 5호 (8,9월호)",
        path: "documents/WklZZH0im_월간동명 1015.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FWklZZH0im_%EC%9B%94%EA%B0%84%EB%8F%99%EB%AA%85%201015.pdf?alt=media&token=30ae92cd-4a2d-4ae7-b3d6-b9fcdb641189",
        id: "wLSIJ1QB9ExFN4XH6Tuc",
      },
      {
        title: "[동명공고 소식지] 월간 동명 1호 (3월호)",
        order: 4,
        path: "documents/RBwxgHbnf_월간동명 1호.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FRBwxgHbnf_%EC%9B%94%EA%B0%84%EB%8F%99%EB%AA%85%201%ED%98%B8.pdf?alt=media&token=0208610a-cd57-43bc-a849-cb4ac1008be7",
        id: "yrRPtjbGrbLIPrtFiPFH",
      },
    ],
    images: [
      {
        title: "동명공고 탁구장",
        order: 28.5,
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_PahrKLvWr",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_PahrKLvWr?alt=media&token=498a9f92-e52e-417b-9baf-6ee3a5e9ce46",
        id: "17oatHO893Zx13G50s2V",
      },
      {
        title: "만우절 교복입기 행사",
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_sLKggLSWs",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_sLKggLSWs?alt=media&token=d6bdc43b-1ccb-491b-a0c1-95046e4c97c1",
        order: 0.453125,
        id: "3Vnzj6FpLvLwtL1RcXGx",
      },
      {
        order: 26,
        path: "images/BOOTH_IMG_bm2RGZSA_tPiu1FpGi",
        title: "동명공고 당구장",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_tPiu1FpGi?alt=media&token=17b88a13-ba12-4ae3-8aa3-43ed24ef78be",
        id: "52ygDJXNHStk1lM6x8aY",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_6A7tgsWO-?alt=media&token=93472fd6-cb68-4c26-a9d6-7da4931e22f8",
        order: 29,
        path: "images/BOOTH_IMG_bm2RGZSA_6A7tgsWO-",
        title: "동명공고 학생쉼터 '동그라미'",
        id: "5ND6xhQJ3LcZkRcHzS0G",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_jiUdYR2N_?alt=media&token=fc970aee-159d-463a-a22f-85d6cf6f8070",
        order: 0.1702880859375,
        title: "사랑의 쌀기부 (자동차정비파트-용호종합사회복지관)",
        path: "images/BOOTH_IMG_bm2RGZSA_jiUdYR2N_",
        id: "9DI14VUa5cySTYGj6LIr",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_rHzR2BkgX?alt=media&token=ce8d104e-6e37-489d-90c8-443fd32ce8ab",
        path: "images/BOOTH_IMG_bm2RGZSA_rHzR2BkgX",
        order: 0.164794921875,
        title: "2022년 동명공고 전경",
        id: "Ac7pvMmdtnWb2NgorCUk",
      },
      {
        title: "일학습병행 UCC영상 공모전 대상 수상",
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_0XsJtzjos",
        order: 0.75,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_0XsJtzjos?alt=media&token=440c8499-450c-4b73-b896-867cd19d25de",
        id: "FeC7Ka79bm0snUWU7EAj",
      },
      {
        order: 23,
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_OOgP_1Z9e",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_OOgP_1Z9e?alt=media&token=dce85543-a114-40a1-8bab-ce35ea40fa60",
        title: "전기전자과 실습",
        id: "Iji7TzJI3QZPA07WFFKJ",
      },
      {
        title: "전기전자과 로봇 축구 대회",
        order: 23,
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_KV2U0pVRQ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_KV2U0pVRQ?alt=media&token=3ac07901-0be3-4e66-8499-0060a31d2b4c",
        id: "ItLznq1gwnamxX9KvB5Q",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_BwXO9eUq8?alt=media&token=7e45cc1b-0096-4825-9836-b088d0434642",
        order: 14.375,
        title: "스마트기계과 스마트제조 기술이 반영된 CNC선반",
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_BwXO9eUq8",
        id: "Muh6pQeixLtzvcTyAJ0Q",
      },
      {
        order: 0.1593017578125,
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_F9hjJqWMs",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_F9hjJqWMs?alt=media&token=41f7dd1f-b95c-4480-b3d0-682e158c65cf",
        title: "동명공고 전경",
        id: "QHqxluf4MFGoVJXopIoA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_BM8-7wWgt?alt=media&token=cd325c93-1c2c-4a57-b31a-7ff7ded82233",
        order: 7.5,
        title: "스마트기계과 피복아크용접실",
        path: "images/BOOTH_IMG_bm2RGZSA_BM8-7wWgt",
        id: "QRSGX6yyGfBDvcqP1y6l",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_8lW3cZSF1?alt=media&token=337e2980-5a7f-4385-88ce-55e110749777",
        order: 10.875,
        path: "images/BOOTH_IMG_bm2RGZSA_8lW3cZSF1",
        title: "스마트기계과 피복아크용접실 실습칸 내부",
        id: "Qw2ZUN6eB8izoEKAehFx",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_YkdX9gNKu?alt=media&token=d168ade5-6b20-4447-8934-5a83ea2e73d1",
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_YkdX9gNKu",
        title: "스마트기계과 머시닝센터(MCT)",
        order: 14.25,
        id: "UBjkWY2wPzY9IfoN2xog",
      },
      {
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_AHFq7q9qb",
        order: 16,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_AHFq7q9qb?alt=media&token=4c06ebd9-bd90-4638-a26f-76be4fad1ce6",
        title: "전기전자과 실습",
        id: "V4Bmtt62NxbvjS5U7mTz",
      },
      {
        path: "images/BOOTH_IMG_bm2RGZSA_lBTk3P6LH",
        title: "2022년 부산광역시 기능경기대회 금메달, 은메달 수상",
        order: 0.1318359375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_lBTk3P6LH?alt=media&token=37da96c2-4012-4e5c-946f-c1e4597b637a",
        id: "ZGqhPNmbRggSyhf2rk71",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_YKTQe5AQm?alt=media&token=b17b7d3d-00d1-49c7-b8e6-317750be269b",
        title: "동명공고 스크린골프장",
        path: "images/BOOTH_IMG_bm2RGZSA_YKTQe5AQm",
        order: 26,
        id: "bLcaFNXsDsm8g17hQwK9",
      },
      {
        title: "동명공고 헬스장",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_jYas62cNQ?alt=media&token=00c667c6-983b-4558-bb2f-3047b2a461fe",
        order: 28.2890625,
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_jYas62cNQ",
        id: "bRqGABPGKCLcYHBbDPVC",
      },
      {
        order: 23.5,
        title: "전기전자과 로봇 축구 대회",
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_TuSwGfHOA",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_TuSwGfHOA?alt=media&token=996fdfa8-b4ec-4157-86cf-100dfebe767c",
        id: "cGoCoizWkjlvj1QBbBX2",
      },
      {
        order: 0.37109375,
        title: "만우절 교복입기 행사",
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_x2G7Z13cH",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_x2G7Z13cH?alt=media&token=a35bae36-701f-41a6-8861-1c9e315c50ca",
        id: "ez4DtfUaOaZKCGWkPdcG",
      },
      {
        order: 24.5,
        title: "동명공고 셔틀버스",
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_NpJLLLjEp",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_NpJLLLjEp?alt=media&token=1395c5da-ae35-4d43-8eb8-f6dd0eed4cbd",
        id: "iZ9mS6UihhNTSlucgYK5",
      },
      {
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_G-H-icFor",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_G-H-icFor?alt=media&token=5ffcabfe-7b30-479a-afeb-5eb9cd650776",
        order: 14.5,
        title: "전기전자과 실습",
        id: "k7oiHFl1X6Id4oBAaq8L",
      },
      {
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_WMBgX2qr2",
        order: 0.3984375,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_WMBgX2qr2?alt=media&token=8f00d0fd-ca46-4d2b-918e-8ce7110b933b",
        title: "벚꽃 나들이",
        id: "nJ1bbomtcOmpkFtgSSPY",
      },
      {
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_RqMc3RVxC",
        title: "전기전자과 로봇 축구 대회",
        order: 24,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_RqMc3RVxC?alt=media&token=f54f54e3-3a3c-4847-b43b-feee4078419b",
        id: "oISQkGTo1DYlBtnJfGdM",
      },
      {
        path: "images/BOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_cEBtTaUnb",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSAo6cNBjWugWotWMmXc002_cEBtTaUnb?alt=media&token=addcde5c-d584-485c-b446-846e028ad355",
        title: "일학습병행 UCC영상 공모전 대상 수상",
        order: 0.42578125,
        id: "vKji67eRmtpkP6bXIflY",
      },
      {
        path: "images/BOOTH_IMG_bm2RGZSA_I5oXOdEu_",
        title: "2022학년도 신입생 모집 포스터",
        order: 0.10986328125,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_bm2RGZSA_I5oXOdEu_?alt=media&token=409992b6-e1ae-48f2-b1c5-6d4ae3bef598",
        id: "vw6wgPt5DmFmaG93k6Im",
      },
    ],
    videos: [
      {
        order: 0.0703125,
        title: "[동명공고] 동명공고에는 000이 있다?!(편의시설소개)",
        url: "https://youtu.be/6YfYWPnk0oQ",
        id: "2ryCyKiNRbJ7U72Sd4PR",
      },
      {
        order: 0.4375,
        url: "https://studio.youtube.com/video/B5xgzjSzJk0/edit",
        title: "[동명공고] 체육시간 당구장에서 당구한판!",
        id: "3c3HM18cuL7QDZGasA0y",
      },
      {
        order: 11,
        url: "https://www.youtube.com/watch?v=rmN0ZXqixtA",
        title: "🏫동명공고쌤🏫의 등굣길 🍯꿀🍯팁🍯",
        id: "4xf4pzojdybV4x9Rj5uu",
      },
      {
        title: "[동명공고] 만우절 즐거운 등굣길🤪❤ (오징어게임 가위바위보!)",
        order: 0.234375,
        url: "https://www.youtube.com/watch?v=vD3q1bl5G_I",
        id: "5Fpm1nO7MmL2PpUgjO5r",
      },
      {
        url: "https://www.youtube.com/watch?v=Qlb-9HD34T4",
        title: "☆★동명공업고등학교 e-스포츠 대회 (모바일 카트라이더) ((예선전))★☆",
        order: 13,
        id: "AdCTniyYxisXaSB0J9fE",
      },
      {
        url: "https://www.youtube.com/watch?v=ktbiHUqlpg8",
        order: 1.25,
        title: "[동명공고] 🥇전교1등&ALL1등급의 졸업생이 말해주는 동명공고에서 대학진학하기🥇",
        id: "Duj9OUN6GN1ZQFNwpLO9",
      },
      {
        title: "[동명공고]🧡 ~무엇이든 물어보세요~ 🧡슬기로운 공고생활 👧👩",
        url: "https://www.youtube.com/watch?v=UO_0L219y-k",
        order: 9,
        id: "F63tB3K3HvyFSv2oSSZ0",
      },
      {
        order: 6.5,
        url: "https://www.youtube.com/watch?v=yf2Xkl2Pvno",
        title: "[동명공고][전기전자과]👁‍🗨 미스터리음악쇼 동명가왕👁‍🗨 니가 왜 거기서 나와 ♬",
        id: "HhyMuhGyNIK1oPV0MjX6",
      },
      {
        title: "[동명공고] 💞스승의 은혜는 하늘 같아서💞우러러 볼수록 높아만지네💓스승의날💞",
        url: "https://www.youtube.com/watch?v=PpydKkpGh7Q",
        order: 5,
        id: "MUQXDkASAxzZkAty1rnu",
      },
      {
        title: "2023학년도 동명공고 소개영상",
        order: 0.046875,
        url: "https://youtu.be/lyOYsX26cY8",
        id: "P72vP1MJwzj6g2RYqiNZ",
      },
      {
        order: 0.22265625,
        url: "https://www.youtube.com/watch?v=OygHXQvK6B4",
        title: "[동명공고] 사랑의 쌀기부❤ 2탄🙋🏻‍♂️ (인터뷰🎤 + 자동차파트 소개🚗)",
        id: "YV8ZvlMKmMlS6CPm0sQq",
      },
      {
        order: 0.125,
        title: "[동명공고] 자동차파트🚗 사랑의 쌀기부❤ 1탄 (feat. 용호종합사회복지관)",
        url: "https://www.youtube.com/watch?v=v5Ygqtuu_C4",
        id: "gbXFmnzyljXr3Xr67SSx",
      },
      {
        title: "[동명공고] ⛹즐거웠던 2022년도 체육대회🏃‍ ",
        order: 0.228515625,
        url: "https://www.youtube.com/watch?v=-Bg3ljOotGA",
        id: "hXPbV6TMwojM7GmkMm0w",
      },
      {
        title: "[동명공고] (✨소리질러✨)부산지방기능경기대회 전기제어부문 🎉금메달 은메달 수상🎉",
        order: 6,
        url: "https://www.youtube.com/watch?v=P1AvyVbjpSs",
        id: "iwVpYgJRBgygTRZlFiru",
      },
      {
        url: "https://www.youtube.com/watch?v=NoKV8DUCVTk",
        order: 0.375,
        title: "[동명공고] (2년만에 열린) 교직원 체육대회~⛹❤ (feat. 참기름을 잡아라~!)",
        id: "lk9fL9ffgV5iMoazhjNK",
      },
      {
        url: "https://www.youtube.com/watch?v=ILF4Gro91iM",
        title: "[동명공고] 🙄학교안에 🏋️‍♀️💪🎱⛳이 있다고?",
        order: 7,
        id: "mghBITzIHyldA0lVK743",
      },
      {
        url: "https://www.youtube.com/watch?v=Nw_Sh5RNpwg",
        order: 0.96875,
        title: "💗동명공업고등학교에서 신입생 여러분을 기다립니다(●'◡'●)💗",
        id: "n3rGb3E8QDFoty9U3Mhk",
      },
      {
        title: "[동명공고] 기능사 필기면제자 검정시험 방과후 수업으로 완전정복!",
        url: "https://www.youtube.com/watch?v=KsHjINUtUMw",
        order: 0.09375,
        id: "ppVYafZ1UODdhWhvnIuk",
      },
      {
        title: "🎉[2021 일학습병행UCC 공모전_대상🥇] 도제 3일 🎊",
        order: 1.125,
        url: "https://www.youtube.com/watch?v=RSdJ0VFgYu8",
        id: "uFLmETdbmdrKwtQYXhHw",
      },
      {
        order: 12,
        url: "https://www.youtube.com/watch?v=Riw-G1zgxy8",
        title: "[브이로그/VLOG] 동명공고 흔한 교사의 출근길/등교하기 좋은 날",
        id: "v3y4sHeAXJXOsyBREdlU",
      },
      {
        order: 10,
        url: "https://www.youtube.com/watch?v=_IZgiTiz4Mk",
        title: "[VLOG/브이로그] 🚊🚌🚕동명공업고등학교 선생님의 출근길 VLOG🚊🚌🚕",
        id: "wOu3PLjbkdc3cqqWrNRL",
      },
    ],
  },
  cesE4dRx: {
    id: "cesE4dRx",
    documents: [],
    images: [
      {
        title: "2022 동의 스포츠데이",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_hkltoVOVP?alt=media&token=c7beaca8-f29e-4d5d-9cdf-a21d7d916009",
        order: 4.375,
        path: "images/BOOTH_IMG_cesE4dRx_hkltoVOVP",
        id: "1YAYvJ0Trck86i6TuXJV",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_BG5N_fWqr",
        title: "진로 캠프-골든벨",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_BG5N_fWqr?alt=media&token=785b31e4-ff3d-445a-8e55-4882f8150e23",
        order: 12,
        id: "3sMv2Cz6ql74jdmXIyM8",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_6ZDTrMLuK",
        title: "직업체험 - 반려동물 전문가",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_6ZDTrMLuK?alt=media&token=0045f1e8-ec8b-46d5-9313-7814ae53d35f",
        order: 38,
        id: "4PgXCEVgiTV0Bpde5d8r",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_JyUnJ0OAk",
        title: "2022 동의 진로 체험전",
        order: 4.875,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_JyUnJ0OAk?alt=media&token=ecb41f6c-47fa-491c-8a17-cbdb147ada30",
        id: "5NNTezFfJsLoZsQZMlLt",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_joBBMZ1CL",
        order: 6.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_joBBMZ1CL?alt=media&token=208f76e2-a1ea-4665-bf0a-3a287337194e",
        title: "산학일체형 도제학교 실습",
        id: "5ea4u3j5uDIrE6f1s6DB",
      },
      {
        title: "직업체험 - 물리치료사",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_2w5o5x9pf?alt=media&token=6c4f9918-e4b4-4075-9d54-81c87b9df741",
        order: 37,
        path: "images/BOOTH_IMG_cesE4dRx_2w5o5x9pf",
        id: "61ODavUvfrH5Wtflqs8w",
      },
      {
        title: "산업체 직무연수",
        path: "images/BOOTH_IMG_cesE4dRx__rTugHs2E",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx__rTugHs2E?alt=media&token=bd733ef9-e20d-46f6-b842-cf0d1a9c6b15",
        order: 25,
        id: "9LfEqAZez4HKgStEVXuL",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_9oVf4CR9Q",
        order: 5.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_9oVf4CR9Q?alt=media&token=8af15144-909b-4c20-b8cd-218293673e4e",
        title: "산학일체형 도제기업 실습",
        id: "Ase3QENVkaveIcscOHZ4",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_j6aixE1Aq?alt=media&token=0495f0d2-5cc1-49d1-ab1b-62720034eef5",
        title: "범용선반실",
        path: "images/BOOTH_IMG_cesE4dRx_j6aixE1Aq",
        order: 27,
        id: "BUOLL5bQrj2B9RzC2WrT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_XJGZ4FMUv?alt=media&token=58f0d70d-955c-461a-a67a-7b0708e0dd41",
        order: 11,
        title: "진로 캠프",
        path: "images/BOOTH_IMG_cesE4dRx_XJGZ4FMUv",
        id: "Ch44DPN71cUyvZJCmn9k",
      },
      {
        order: 26,
        title: "피복아크용접실",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_CRWK8AgBW?alt=media&token=8e9bf5a8-e834-42ed-ba66-2d8f4592979a",
        path: "images/BOOTH_IMG_cesE4dRx_CRWK8AgBW",
        id: "DIiFk9rqrcy90TNYoJds",
      },
      {
        title: "직업체험 - 특수분장",
        order: 42,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_Qoai6z872?alt=media&token=b3ec7188-b7ae-4b62-9d61-49f9e36ed371",
        path: "images/BOOTH_IMG_cesE4dRx_Qoai6z872",
        id: "E8vng9h7H6ClTcQY2mSg",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_8CvTb0K_0",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_8CvTb0K_0?alt=media&token=7361932d-b935-48b7-8204-db197e202d92",
        order: 43,
        title: "직업체험 - 특수분장",
        id: "EyBAjnA1yXoMtLtukFJ5",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_hbBYZff2t?alt=media&token=088a2ab9-b080-41f7-8b7c-7e04b31c153a",
        title: "2022 동의 진로 체험전",
        path: "images/BOOTH_IMG_cesE4dRx_hbBYZff2t",
        order: 4.984375,
        id: "F2v9rBAIwah9eKl0xEH1",
      },
      {
        order: 32,
        title: "지적실",
        path: "images/BOOTH_IMG_cesE4dRx_kHOuj-Hyv",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_kHOuj-Hyv?alt=media&token=5118b591-470c-40e7-8106-3c9ec81c06c1",
        id: "FOSm3wor4FhaDkhXIJJu",
      },
      {
        title: "직업체험  - 직업군인",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_6XHyDxebP?alt=media&token=98d6da30-7a5b-4e66-a7df-0521708afce5",
        path: "images/BOOTH_IMG_cesE4dRx_6XHyDxebP",
        order: 41,
        id: "H8PJN0aFNEmU35qRn8ic",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_YyeveYA-N?alt=media&token=c482e454-e267-4f57-98c7-6c73653c0b98",
        order: 20,
        title: "2022 기술사관육성사업 중소기업이해연수",
        path: "images/BOOTH_IMG_cesE4dRx_YyeveYA-N",
        id: "HXRiddI6KR5h2kgvFyJE",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_BV_3aO206?alt=media&token=e609823e-b1a7-40bc-8ce9-ea82b40cd6f2",
        title: "진로 캠프-자기 소개증 만들기",
        path: "images/BOOTH_IMG_cesE4dRx_BV_3aO206",
        order: 10,
        id: "JoRxz0OmNAKuKrhQbcR8",
      },
      {
        order: 21,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_oATSe3jTF?alt=media&token=70b2ea7b-ef5a-4461-8350-c1dc0f3009d9",
        path: "images/BOOTH_IMG_cesE4dRx_oATSe3jTF",
        title: "2022 기술사관육성사업 중소기업이해연수",
        id: "KoMB9DIEiY4sjLnzt0WB",
      },
      {
        order: 4.9375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_YLZWay1nw?alt=media&token=6e4c9c00-2fb2-4ad3-86fe-5811055d6a69",
        path: "images/BOOTH_IMG_cesE4dRx_YLZWay1nw",
        title: "2022 동의 진로 체험전",
        id: "LXQy4Q6SK6UwLzVsnhGV",
      },
      {
        title: "2022 동의 진로 체험전",
        path: "images/BOOTH_IMG_cesE4dRx_n0MMo-ajP",
        order: 4.8125,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_n0MMo-ajP?alt=media&token=6f8d6f63-d2db-40c0-a1a1-2a36100c38ad",
        id: "QCIcn8cSAtxzrwE33B3c",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_j0haQWDb8?alt=media&token=1cfa3b35-0337-41cd-81d1-8b0e5a3fef31",
        order: 29,
        title: "컴퓨터활용생산실",
        path: "images/BOOTH_IMG_cesE4dRx_j0haQWDb8",
        id: "Rpq6w3S6HN06Q0PNQOs8",
      },
      {
        title: "MCT가공",
        order: 23,
        path: "images/BOOTH_IMG_cesE4dRx_jO6l6jUJG",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_jO6l6jUJG?alt=media&token=a5e4bde2-832b-494d-b588-9c829bc7dd51",
        id: "SNqQbUit8OAW10s2YCpK",
      },
      {
        order: 13,
        title: "진로수업-자기 명함 만들기",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_pn0bqm1q5?alt=media&token=fbe49729-22af-47ad-9136-4fe862e16549",
        path: "images/BOOTH_IMG_cesE4dRx_pn0bqm1q5",
        id: "SSGpgkEB7D9c4AhHJGHw",
      },
      {
        order: 5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_ltqVP1TGt?alt=media&token=bf84f8da-00d8-4edb-acd8-fc2232892beb",
        path: "images/BOOTH_IMG_cesE4dRx_ltqVP1TGt",
        title: "산학일체형 도제기업 실습",
        id: "T0tjwnPUXjpBXs0dCZOt",
      },
      {
        title: "NCS 기반 우수 직업교육 훈련 경진대회 장려상 수상",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_qYAK7-Sm-?alt=media&token=a4155aad-f95e-4eba-8d0b-463fb387839b",
        order: 33,
        path: "images/BOOTH_IMG_cesE4dRx_qYAK7-Sm-",
        id: "W1Xt97z8My6gfG3DXDFe",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_QFGn3G3NI",
        order: 28,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_QFGn3G3NI?alt=media&token=3e5ba307-6577-4bbe-92df-e37dc731a99b",
        title: "기계요소설계실",
        id: "WujzKDrNO6jcSS2GV3xF",
      },
      {
        order: 35,
        title: "직업체험 -쿠키만들기",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_MPVlB7939?alt=media&token=1c8d61f0-fdd5-47d7-bf3f-873b90e0bcc1",
        path: "images/BOOTH_IMG_cesE4dRx_MPVlB7939",
        id: "Z9KkFKBtmVu0BJKobcON",
      },
      {
        order: 7,
        path: "images/BOOTH_IMG_cesE4dRx_E0R_TkR0q",
        title: "졸업생 초청 진로 멘토 교육",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_E0R_TkR0q?alt=media&token=be19ede0-7ce4-46d1-b55e-f938b9beaeb6",
        id: "bhFMy4c8hka6QvmQH0HT",
      },
      {
        title: "직업체험 - 제과제빵사",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_QA3nS5bzT?alt=media&token=b4c65632-9488-4c27-a1eb-1ffdfe336883",
        path: "images/BOOTH_IMG_cesE4dRx_QA3nS5bzT",
        order: 40,
        id: "i2b9v1WsXKc6mSt3ZKFp",
      },
      {
        order: 3.5,
        title: "2022 동의 스포츠데이",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_YFzHw0ekj?alt=media&token=ea0e9ba1-1b8b-4a53-ab8f-af1ab70ff78d",
        path: "images/BOOTH_IMG_cesE4dRx_YFzHw0ekj",
        id: "iJuClDJJxvv0nCHSAoop",
      },
      {
        order: 30,
        path: "images/BOOTH_IMG_cesE4dRx_PjWyndBzu",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_PjWyndBzu?alt=media&token=a74b76a2-b17e-472c-ab60-66b286c18d3d",
        title: "동남권 CAM 기술경진대회 시상",
        id: "lCw5HxZ5lxg8gytMH3gK",
      },
      {
        title: "진로 캠프",
        order: 9,
        path: "images/BOOTH_IMG_cesE4dRx_SDrRZkpPX",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_SDrRZkpPX?alt=media&token=d40b33c8-22c6-4fd8-8acc-98c2e5f5296a",
        id: "lsgTRnwaqkJIOAAaMGNM",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_F3ayj_GWU",
        title: "CNC가공",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_F3ayj_GWU?alt=media&token=1dc13dfd-67de-4531-9d0b-79308d4dbc4b",
        order: 24,
        id: "o4CcaxyMbTYW2Wzr38Ta",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_DR2UbLMJK?alt=media&token=27d2fba8-3e3c-4402-af83-c33337435065",
        path: "images/BOOTH_IMG_cesE4dRx_DR2UbLMJK",
        order: 4.96875,
        title: "2022 동의 진로 체험전",
        id: "oCtAyBqELEIphwjX1cwj",
      },
      {
        order: 4.75,
        path: "images/BOOTH_IMG_cesE4dRx_X8oXEFjBy",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_X8oXEFjBy?alt=media&token=18f8c600-b689-4765-9c3f-91306a2729d6",
        title: "스포츠데이(동의윈드오케스트라)",
        id: "oSF2BM2GvJAhv1UHfviX",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_1cEgvQW5J?alt=media&token=8240e5db-a951-4c3a-8f91-2824d1d06099",
        path: "images/BOOTH_IMG_cesE4dRx_1cEgvQW5J",
        order: 39,
        title: "직업체험 - 마술사",
        id: "orXm6FAthjO8txnE6qJ9",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_mk0CDEd4y",
        order: 36,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_mk0CDEd4y?alt=media&token=65a159a4-3bf9-4d51-a8fc-16eaa6a615bb",
        title: "직업체험 - 바리스타",
        id: "qDqKPUZyVUvyLzuT91m6",
      },
      {
        order: 8,
        path: "images/BOOTH_IMG_cesE4dRx_IqdmgHOTJ",
        title: "졸업생 초청 진로 멘토 교육",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_IqdmgHOTJ?alt=media&token=0917abcc-e244-43ce-b15c-b26be4eeb276",
        id: "qRe5UYZmDEeSrEkq3SNe",
      },
      {
        order: 31,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_EKn3LHvgs?alt=media&token=fb3fb916-1c22-4dc0-bece-4b6d39a73f16",
        path: "images/BOOTH_IMG_cesE4dRx_EKn3LHvgs",
        title: "실내디자인 2실",
        id: "qSsmFBDpC8j1tLWQ0AVf",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_MDDMT3w5p?alt=media&token=273c2870-7a76-464f-b27b-9aea7dc08d92",
        title: "직업체험 - 경호원",
        order: 42,
        path: "images/BOOTH_IMG_cesE4dRx_MDDMT3w5p",
        id: "rB97kfnWucb3L6npFpY8",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_oSRoOD-15?alt=media&token=3a35aeac-7dfe-4738-8a01-e0fa6e1522f1",
        order: 22,
        title: "교내 작품",
        path: "images/BOOTH_IMG_cesE4dRx_oSRoOD-15",
        id: "snJ3x6eFgZG0rJcoQbu6",
      },
      {
        path: "images/BOOTH_IMG_cesE4dRx_pzE1mDCiZ",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cesE4dRx_pzE1mDCiZ?alt=media&token=b9b68e6e-857b-4735-a1d4-011355202aa5",
        order: 19,
        title: "교내 일본어동아리",
        id: "yodZ4nZ11bxcLhCKqyhL",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/l9qljKpZLq8",
        order: 2.5,
        title: "청소년 비즈쿨 다잡고 봉사활동",
        id: "0Afhjn3t9O0KC6ggnCH7",
      },
      {
        order: 13,
        title: "자동제어기기제작실 2",
        url: "https://youtu.be/7R9kImxp1HQ",
        id: "0GwFw4J5hd44b31YT6Zj",
      },
      {
        title: "내선공사실 1-1",
        order: 5,
        url: "https://youtu.be/E4dUvtLTzX4",
        id: "1eEsxI5YVT3AXQa8orat",
      },
      {
        order: 6,
        url: "https://youtu.be/0vMx9j0S5-Q",
        title: "내선공사실 1-2",
        id: "2alOhsRsJY2BK40tqMJY",
      },
      {
        title: "내선공사실 2-1",
        url: "https://youtu.be/K3k53pB_1dM",
        order: 8,
        id: "8dcJ3mJbRzBcUmxzVrBU",
      },
      {
        title: "산학일체형 도제학교 기업 체험영상(2)",
        order: 4,
        url: "https://youtu.be/WtAI8oayhI8",
        id: "B86Whv77F9jRgrwag2Uf",
      },
      {
        title: "전기제작실 1",
        url: "https://www.youtube.com/watch?v=cpblEKKdvKg",
        order: 14,
        id: "QhAp4PiPTWTeVQ7gx3vX",
      },
      {
        order: 1,
        title: "메인영상",
        url: "https://youtu.be/csYOinxpu1g",
        id: "WYaVXyoq0hcXQwdfwqBz",
      },
      {
        title: "자동제어기기제작실 1",
        order: 12,
        url: "https://youtu.be/P0CuckWuU9k",
        id: "dRQkxOQ0mkaoohkVMELa",
      },
      {
        title: "동의공업고등학교 소개 영상",
        url: "https://www.youtube.com/watch?v=jfdZKLA1YiY",
        order: 2,
        id: "mP8LmXPx1Ju3hhJqxhJT",
      },
      {
        url: "https://youtu.be/Lns0PSujh_M",
        order: 3,
        title: "산학일체형 도제학교 기업 체험영상(1)",
        id: "qZ0IV5Oza3Cv8vWC4X1n",
      },
      {
        url: "https://www.youtube.com/watch?v=rRBF3skW2EI",
        order: 15,
        title: "전기제작실 2",
        id: "tTRLshw9Lw7aDMlSKeD5",
      },
      {
        order: 10,
        url: "https://youtu.be/d7lGjYq1TRU",
        title: "내선공사실 3-1",
        id: "xOU7xDAo1bdhCd0F3KcM",
      },
    ],
  },
  cx0gxz8p: {
    id: "cx0gxz8p",
    documents: [
      {
        path: "documents/X9PoOXjDT_입학전형요강.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FX9PoOXjDT_%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%EC%9A%94%EA%B0%95.pdf?alt=media&token=0947ca67-6f7a-4c11-9762-82c271196b16",
        title: "입학전형요강",
        order: 4,
        id: "AU3S4iJlKYfjL7kpyl0B",
      },
      {
        title: "학과소개",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F2Fo4ArNOY_%ED%95%99%EA%B3%BC%EC%86%8C%EA%B0%9C.pdf?alt=media&token=faad7827-a908-4bff-9f4f-d704e28a1797",
        order: 4,
        path: "documents/2Fo4ArNOY_학과소개.pdf",
        id: "eI7LGW0UlLfLEW4Ynybz",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F1teQUaR24_%ED%95%99%EA%B5%90%ED%8A%B9%EC%83%89%EC%82%AC%EC%97%85%20%EB%B0%8F%20%ED%95%99%EA%B5%90%EC%9A%B4%EC%98%81.pdf?alt=media&token=d0c2a13a-bf87-436a-97ae-2cd0189520d3",
        order: 4,
        title: "학교특색사업 및 학교운영",
        path: "documents/1teQUaR24_학교특색사업 및 학교운영.pdf",
        id: "vJO4iAuM1nYg4wO7zrTY",
      },
      {
        path: "documents/qQlRnsQP5_학교 소개.pdf",
        order: 1.5,
        title: "학교 소개",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FqQlRnsQP5_%ED%95%99%EA%B5%90%20%EC%86%8C%EA%B0%9C.pdf?alt=media&token=6f623106-3fc9-4dac-bcd7-c2b03b59074e",
        id: "vYpBwgpQRleN1uM96GWL",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_rvZoMvNdi?alt=media&token=716a18ae-9d81-4afb-8f4e-b8a793449f9c",
        order: 41,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_rvZoMvNdi",
        title: "체험학습",
        id: "0AOIw3Bb70UDcth3XWB7",
      },
      {
        order: 49,
        title: "스마트 공장 견학",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Q34GLNhMX",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Q34GLNhMX?alt=media&token=78985761-8b4e-4228-a5b6-dc3034d65e60",
        id: "2kcgFLddUBYeQCyHi7qs",
      },
      {
        order: 43,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_uEzcJfYF2?alt=media&token=7fb511fd-c2d7-4452-b243-9e4782485339",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_uEzcJfYF2",
        title: "체험학습",
        id: "344K0c0YmETxxAERx9kv",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_DElM02Wq7",
        order: 15,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_DElM02Wq7?alt=media&token=344f18d5-896b-46a6-bf7f-2f1a67686453",
        title: "화학공업과",
        id: "4c9JAflBtYCuRZXXWqEQ",
      },
      {
        title: "방송부",
        order: 4,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_7dPnef2wN",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_7dPnef2wN?alt=media&token=8010e472-abaa-4be8-bf7c-cae4519e6614",
        id: "4whogKu43XmMYlKLKnpQ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_iTygW-3hX?alt=media&token=fb80ec9e-4951-4c0b-a47a-ba89fc5945ca",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_iTygW-3hX",
        order: 63.49993896484375,
        title: "학교장과 학생들의 대화",
        id: "54LzpO8ech9JkFoKDkla",
      },
      {
        order: 20,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_lEsL5uC0H?alt=media&token=4a39ad1e-ca3e-4329-9c6b-d8910eb50005",
        title: "기계과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_lEsL5uC0H",
        id: "5VN7NNbhY2OesVf0Rie9",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_-_0hvdT_k?alt=media&token=d647c6d5-885a-480a-944e-2367846a245f",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_-_0hvdT_k",
        order: 66.5,
        title: "입학식",
        id: "8ErUIeR8z4eJEhLOceUE",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_xv4u_Bd_c",
        title: "신소재디자인과 - 컴퓨터그래픽",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_xv4u_Bd_c?alt=media&token=0d9a133c-e148-43a5-957c-06e6e397ac67",
        order: 12,
        id: "99BcHPxChM83UQMWHWpu",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Rni8zq92-?alt=media&token=9770f637-16af-4a16-ad43-a99d1af39e7d",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Rni8zq92-",
        title: "신소재디자인과",
        order: 39,
        id: "AAY03JQs7eLXlnZgIRD2",
      },
      {
        order: 5.5,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_9YP-ns0Lq",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_9YP-ns0Lq?alt=media&token=f3bbe3a7-fb0d-4f9b-9b5d-77150124f8ba",
        title: "기계과 MCT실습",
        id: "BYC9EHubrI9fqiQzSk1Z",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_wDiKV5fYT?alt=media&token=a30efc98-abbe-4000-87f3-3e2dd6b2b6af",
        order: 24,
        title: "기계과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_wDiKV5fYT",
        id: "CrBPV6EhPBzuTn2CQW10",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_gldNqQ64-?alt=media&token=633b327d-3710-474f-91a3-11975e6276f8",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_gldNqQ64-",
        title: "신소재디자인과 - 제화",
        order: 13,
        id: "D6khjvMzVzlHvuLZCFxB",
      },
      {
        title: "전기전자과 도제교육",
        order: 11.75,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_WAmlkPy4X?alt=media&token=cf6e9e18-6b83-471d-86ea-7595f966d314",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_WAmlkPy4X",
        id: "D77NHbq9CxbarDP2j1Ji",
      },
      {
        title: "체험학습",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_iVjwYS0h5",
        order: 45,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_iVjwYS0h5?alt=media&token=846df7d8-4fc6-4ff0-b2fc-c3a935924bc6",
        id: "DEWm0tZxG5aIOfTQKuG8",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_W7eU2VjEE?alt=media&token=ce9b69ac-e24f-4ad3-bfed-6b8b6b7610bd",
        order: 62,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_W7eU2VjEE",
        title: "학교장과 학생들의 대화",
        id: "DYZRAfgKLC5dGsbRbEhB",
      },
      {
        title: "현장체험학습",
        order: 53,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_74qjn21yJ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_74qjn21yJ?alt=media&token=be934f29-8f28-423b-96c6-86bb76afe79e",
        id: "EnCTFBARuC9WWGKafYJf",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_X8ewRsyIn",
        order: 8,
        title: "화학공업과 화학물질분석실습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_X8ewRsyIn?alt=media&token=6de8e9ee-dab1-45a3-b2a4-c1f3a7c76437",
        id: "EpswTDM1rFwcw4bmj898",
      },
      {
        title: "화학공업과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_yVYL6AIzR",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_yVYL6AIzR?alt=media&token=054e7338-7239-4ec6-b03a-6bd33cb71a79",
        order: 16,
        id: "HGuLhjVIzPHdC9sZ3UIj",
      },
      {
        order: 25,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_oRn2m2MlD?alt=media&token=fe047b2d-6819-4376-b041-8cb8a2e57978",
        title: "전기전자과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_oRn2m2MlD",
        id: "HSVui8bkRdJK1R9qiBzD",
      },
      {
        order: 42,
        title: "체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_He2kupdpx?alt=media&token=9d385455-1ba1-4257-a711-6e7b9f867aee",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_He2kupdpx",
        id: "HpUko4bLJapg7yIlMtAB",
      },
      {
        title: "학교전경",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_u-LrBEZco",
        order: 3.875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_u-LrBEZco?alt=media&token=97b77afc-0863-4d64-bd3a-7df8c4b357f7",
        id: "I267hqwb4FMqszo4t6wI",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_mLFqByrXS?alt=media&token=c656f4ac-528e-4746-9d7f-bf2fea39c4dc",
        order: 7,
        title: "화학공업과 단위조작실험실습",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_mLFqByrXS",
        id: "JwyNqcUhQMu4bSd0ZhB8",
      },
      {
        title: "전기전자과 내선공사실습",
        order: 11,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_bxZ-TnAbe?alt=media&token=f1f5466e-1df0-48f1-9624-63dfb6a91e83",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_bxZ-TnAbe",
        id: "KLVFxWQQrPxJC0Oi0QaA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Sh5BG5DOj?alt=media&token=cfc14878-a782-4183-975a-5d5a19472680",
        order: 52,
        title: "현장체험학습",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Sh5BG5DOj",
        id: "KTZw01PSAheHcKXx9EhS",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_qBZrSsJP2",
        title: "체험학습",
        order: 47,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_qBZrSsJP2?alt=media&token=8e8d800c-1f92-4c19-b6ee-4c3af28642c8",
        id: "LEDHNpaNaRsWAF7Wp0pD",
      },
      {
        title: "스마트 공장 견학",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_2Mnz8TeAj?alt=media&token=365398fb-e20e-4694-bd7b-6c0607b12f98",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_2Mnz8TeAj",
        order: 50,
        id: "LiWyLjubqyrYartBxkOg",
      },
      {
        order: 34,
        title: "건축토목과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_FSscoTCyq?alt=media&token=bb124139-7773-4c5a-b350-e86fc5274c65",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_FSscoTCyq",
        id: "MTwmTCCi1MYebro0RMDl",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_3v97QcExs",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_3v97QcExs?alt=media&token=a7995648-d18f-4b4c-8a7c-268a42952a53",
        title: "체험학습",
        order: 44,
        id: "NGMTXDwloz0c67s5z4CV",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_2Tft__9Hs",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_2Tft__9Hs?alt=media&token=aab7b318-47cd-42e7-aa48-ea6b5d211594",
        title: "건축토목과 실내디자인실습",
        order: 9,
        id: "PimgbenojFpzkQNdAikP",
      },
      {
        title: "관악부",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_lPNSvheH9",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_lPNSvheH9?alt=media&token=8a48e28a-ccd1-4a40-8282-db07077b928a",
        order: 5.25,
        id: "Puggz1i8qbFrslgSkywg",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_IJuyPfyXt?alt=media&token=91fe849e-ed8f-41bf-96e2-91c158770f83",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_IJuyPfyXt",
        order: 17,
        title: "화학공업과",
        id: "RXw5aXP03wTHCnBe1bbL",
      },
      {
        title: "건축토목과 측량실습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_n_s-5Z7fS?alt=media&token=00859192-9604-44f1-9410-bb5e958b301a",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_n_s-5Z7fS",
        order: 10,
        id: "S0LBe98nNwOS1aLB5Dun",
      },
      {
        order: 56,
        title: "현장체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_GU6S2HO8x?alt=media&token=7cbfc9dc-1b0f-4181-a989-04739da89653",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_GU6S2HO8x",
        id: "SG9l3ovo56EsMEGfbMy5",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_j0YfP16ez?alt=media&token=627d38ac-4fa2-4b2d-bd12-36c17a0be242",
        order: 48,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_j0YfP16ez",
        title: "스마트 공장 견학",
        id: "SXO0OvwDwTrHTv26ThKS",
      },
      {
        title: "건축토목과",
        order: 33,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_mCfoH-hY6",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_mCfoH-hY6?alt=media&token=ebd4f997-6b09-4960-8d5e-74c60eafdf42",
        id: "TB7qOhv0YvLDYiBehjoz",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_rKvYhacsT",
        order: 23,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_rKvYhacsT?alt=media&token=15a17cf3-cee8-4dee-91f2-b8aaedcb3003",
        title: "기계과",
        id: "TeWXlop8htMNmiFDOeD4",
      },
      {
        order: 36,
        title: "신소재디자인과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_rdJKjN_8F?alt=media&token=7c513f2e-c769-4e31-b04e-293c889d0a3d",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_rdJKjN_8F",
        id: "VDp9mOHAjYXo6ozBYovc",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_dPI4xGVoY?alt=media&token=a182356c-4fa9-4b31-a14a-68f748ea1c0b",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_dPI4xGVoY",
        order: 64,
        title: "입학식",
        id: "Vc6oZhDoWLCrbgM9OaiF",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_V2hgQNbaV",
        title: "체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_V2hgQNbaV?alt=media&token=a1f29b7d-eb28-46f1-8b96-61fbfa1f6386",
        order: 46,
        id: "WyH6l1m2LJQUuZk38ZFP",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_yZ8BCiDne?alt=media&token=d325dd18-30d9-45b7-8a76-ccdcf695f438",
        title: "기계과 용접실습",
        order: 6,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_yZ8BCiDne",
        id: "aapECQGIPrB7sFHJgw1M",
      },
      {
        order: 26,
        title: "전기전자과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_CFCMUM7Ca?alt=media&token=ea23fdf3-58e2-4a11-a54a-d7f0f390e150",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_CFCMUM7Ca",
        id: "b7b37Ks7eQG07v8olUnX",
      },
      {
        order: 31,
        title: "건축토목과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_NilSQJGgd?alt=media&token=b537ab04-fb80-4b3c-80d5-9a36c9367207",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_NilSQJGgd",
        id: "bBYUA0p6q37D0amgRdJo",
      },
      {
        order: 32,
        title: "건축토목과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Bemr0wXJd?alt=media&token=53290126-7e3a-4d70-8de3-ffe3f602facd",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_Bemr0wXJd",
        id: "df4WmTt73sn0CMF7zL8x",
      },
      {
        title: "학생대의원회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_dJp40mwSh?alt=media&token=9104dd6e-b2ed-49bb-888e-40a745608202",
        order: 60,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_dJp40mwSh",
        id: "du5bzMt182vPhgv2F9jT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_ZadyVyUya?alt=media&token=feaafa42-291d-4429-b50d-2873f5c3d955",
        order: 27,
        title: "전기전자과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_ZadyVyUya",
        id: "fhKbHolbqvJPi9C2LeOj",
      },
      {
        title: "신소재디자인과 - 재봉",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_wKHTxd0e0?alt=media&token=651414f4-5030-4efe-a273-bed0f781631a",
        order: 14.5,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_wKHTxd0e0",
        id: "h9MGEUw3NKxhP1Y0tkmF",
      },
      {
        order: 57,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_GHiz98hJT?alt=media&token=fe70aa0a-12c0-431f-90ae-e06a867e7e4a",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_GHiz98hJT",
        title: "현장체험학습",
        id: "ie1xTc8bcSga2dF59PlB",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_S0owqvrCo",
        order: 29,
        title: "전기전자과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_S0owqvrCo?alt=media&token=5cbc3b9a-d223-4338-b3c3-860404ecef4e",
        id: "ihem4OUG2DAsXDJmzQZd",
      },
      {
        order: 54,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_RGK44-DHp",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_RGK44-DHp?alt=media&token=6c7ca073-caaf-4f69-85cd-3cbaf56c9bae",
        title: "현장체험학습",
        id: "jDTqYkgopMLBNtVufEqh",
      },
      {
        order: 21,
        title: "기계과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_7A18JlCIk",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_7A18JlCIk?alt=media&token=2ff5a3d6-8639-4f28-993b-6ff8ba72e5ef",
        id: "kEzRR0V9zeqlbX2WaNgP",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_B9YHbD7_7",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_B9YHbD7_7?alt=media&token=d52390b6-340a-449b-a3cf-83930a76c181",
        title: "신소재디자인과",
        order: 37,
        id: "mWvu2j8nIbeleek1lut7",
      },
      {
        order: 58,
        title: "현장체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_FxhqPEqQd?alt=media&token=0c44859e-e9ba-4828-a61d-0cb49f32e14e",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_FxhqPEqQd",
        id: "mvGNv7zneD1KGZ0cVXUO",
      },
      {
        order: 5.37109375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8p_0CXmEsDpr?alt=media&token=e06eb5fe-caf2-42ed-b825-eef4b9545593",
        path: "images/BOOTH_IMG_cx0gxz8p_0CXmEsDpr",
        title: "2023학년도 신입생 입학전형 요강",
        id: "nosaQOsTPHchymswi5hf",
      },
      {
        order: 55,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_1cOEog0gW",
        title: "현장체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_1cOEog0gW?alt=media&token=94b6934f-0969-4434-a85a-ecfa5d26e79e",
        id: "npQtRRHeDQ9kmOupbaly",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_-Kq4WCHPh?alt=media&token=074b7c7e-05b4-4a89-9bc4-6dd0020c811f",
        title: "신소재디자인과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_-Kq4WCHPh",
        order: 40,
        id: "ov7CA1QS3nBwDmGNwsZh",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_xUYWtivNH?alt=media&token=6725d410-ff95-456b-b981-e018093111d4",
        order: 19,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_xUYWtivNH",
        title: "화학공업과",
        id: "p4IO70k0wMqOsajpn5HL",
      },
      {
        order: 18,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_0Zwpouac-?alt=media&token=ceea1292-5a15-4735-8deb-78852483dc23",
        title: "화학공업과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_0Zwpouac-",
        id: "p70HvKnd9RTb6Kch9d10",
      },
      {
        order: 51,
        title: "현장체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_q_1dkJ_d3?alt=media&token=6328e7c4-c500-49d4-b55c-6d69f6302710",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_q_1dkJ_d3",
        id: "qQRDmLcmmV4HsJFc0V5i",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_lf3m6zkTW?alt=media&token=8ef5de89-400c-4c23-98c3-3d169cd71ccd",
        title: "전기전자과",
        order: 28,
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_lf3m6zkTW",
        id: "qX0AYGt6X53NSDs6adss",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_uqxFYrUaF",
        order: 38,
        title: "신소재디자인과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_uqxFYrUaF?alt=media&token=f6a18b8a-5a7e-47c3-9061-3b9a367625fa",
        id: "qep78tVy08CQjJ2oxi0L",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_4Uagxpx3o?alt=media&token=22d02bc1-c8b3-4434-aa8d-f3860cf49f7a",
        title: "기계과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_4Uagxpx3o",
        order: 22,
        id: "quR4eGMMSHMNz0OrswXJ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8p_aivc-1hSj?alt=media&token=0417cd18-72a7-4332-bed4-f2c2379ea2cd",
        path: "images/BOOTH_IMG_cx0gxz8p_aivc-1hSj",
        title: "경남공고에 입학하면 이런 점이 좋아요!",
        order: 5.435546875,
        id: "rd8hIs9NjBASiaTMzYsd",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_P-iNbdJlN?alt=media&token=cbc80803-89ea-420a-b285-6287bf7a5271",
        order: 35,
        title: "건축토목과",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_P-iNbdJlN",
        id: "rrZeAkVJidg70gbTU7QO",
      },
      {
        order: 61,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_r--uS_vb8?alt=media&token=1cd5c2a2-623c-4cba-8191-b0cd6b7d6e1c",
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_r--uS_vb8",
        title: "현장체험학습",
        id: "tXthJRlqzBQmEEmiClVh",
      },
      {
        path: "images/BOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_24RsRhjwP",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_cx0gxz8pOHM6EkNGTBS6GAhfMaj1_24RsRhjwP?alt=media&token=6798973b-a301-4af8-bab6-a88dcc70a73b",
        order: 30,
        title: "전기전자과",
        id: "tmIBQNrbXyPXEOcrhXxH",
      },
    ],
    videos: [
      {
        title: "화학공업과 - 단위조작실",
        order: 3,
        url: "https://youtu.be/mIgjtx6rbmQ",
        id: "G4H7hKIhr1jqYJTvzYz8",
      },
      {
        title: "전기전자과 - 전자부품개발실",
        order: 5,
        url: "https://youtu.be/lYIrfxsYRBY",
        id: "Lz2E0izjk6BLGUMvgKC4",
      },
      {
        url: "https://youtu.be/Jg79jR9ua94",
        order: 6,
        title: "건축토목과 - 실내디자인실",
        id: "N9VCBwYeH2p7X0Wh1ZRR",
      },
      {
        url: "https://www.youtube.com/watch?v=edinuJYYZVE",
        title: "경남공고 시설 소개",
        order: 9,
        id: "Nh4NqtkfZjET9zFoPBot",
      },
      {
        title: "메인영상",
        order: 1,
        url: "https://youtu.be/F_RbRxQJTTI",
        id: "TH95CD1TAhRCoFHT2LDq",
      },
      {
        url: "https://youtu.be/pepP2lrXOWM",
        title: "신소재디자인과 - 패턴메이킹실",
        order: 7,
        id: "UcR5VYVR6N25DVqLzN6s",
      },
      {
        url: "https://www.youtube.com/watch?v=8XhiZdjSCpg",
        order: 2.5625,
        title: "경남공고 소개",
        id: "a1U40gAWmNtN2qXAticv",
      },
      {
        title: "경남공업고등학교 공연",
        order: 1.25,
        url: "https://www.youtube.com/watch?v=XTP7SYST-NY",
        id: "et60Biy4US0oJ2GlFjCI",
      },
      {
        title: "경남공업고등학교 드론 촬영 영상",
        order: 2.125,
        url: "https://youtu.be/dGN36JFSaUk",
        id: "iQOJ94bkaXzd4fMkYfO5",
      },
      {
        order: 4,
        url: "https://youtu.be/X8GbS2ONFMM",
        title: "전기전자과 - 자동제어시스템운용실",
        id: "kLyxYYRj1GOE96rHEcCV",
      },
      {
        url: "https://www.youtube.com/watch?v=sS9ORmMKbbc",
        title: "경남공고브이로그",
        order: 8,
        id: "wv2ojdi0TNweqU12e5lY",
      },
    ],
  },
  h91N2lKn: {
    id: "h91N2lKn",
    documents: [
      {
        path: "documents/fWh9Nr2eR_2023학년도 고등학교 입학전형 요강(안)(부일전자디자인고등학교)_확정본.hwp",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FfWh9Nr2eR_2023%ED%95%99%EB%85%84%EB%8F%84%20%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95(%EC%95%88)(%EB%B6%80%EC%9D%BC%EC%A0%84%EC%9E%90%EB%94%94%EC%9E%90%EC%9D%B8%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90)_%ED%99%95%EC%A0%95%EB%B3%B8.hwp?alt=media&token=04b362f7-0bda-494f-8357-bd8457cffe2a",
        title: "2023학년도 입학요강",
        order: 1,
        id: "ixVA8Hfj9JDgcptBYcdA",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_EX5INgIt9?alt=media&token=10df626a-0dfa-493e-989a-c6b90b882c3e",
        order: 3,
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_EX5INgIt9",
        title: "디자인과 소개",
        id: "02mdY4HE26GuATnTUuxe",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_SwAfg0N4L",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_SwAfg0N4L?alt=media&token=b029255f-f358-48a7-a0a5-2a114d32b63a",
        title: "신우쉼터 버스킹 공연",
        order: 33.5,
        id: "5FqplSotTCCUwak4yYGF",
      },
      {
        title: "Wee Class - 스쿠버 다이빙",
        path: "images/BOOTH_IMG_h91N2lKn_vW1s8YNFe",
        order: 10.9375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_vW1s8YNFe?alt=media&token=1309f7c4-a780-4326-b1e3-0516d34e71da",
        id: "BeXdX5eBEvfwgpFKvTLY",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_9LRdRaLdc",
        title: "Wee Class - 푸드 테라피",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_9LRdRaLdc?alt=media&token=2c4bae7c-6c12-45e5-8da2-25f9da465563",
        order: 10.96875,
        id: "C8Y9SoNgGOVA2uocyA2V",
      },
      {
        title: "(컴퓨터과) 인공지능 체험",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_Av-eoT13Y?alt=media&token=076d1c18-37ae-4548-84cd-b82b8b439aa6",
        path: "images/BOOTH_IMG_h91N2lKn_Av-eoT13Y",
        order: 6.03125,
        id: "DE182NRiD7bUxC03kF8G",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_XowMexDVS?alt=media&token=acbc1e17-5f30-4c5a-b780-de8afa0b46af",
        title: "(디자인과) 벽화그리기 ",
        order: 6.75,
        path: "images/BOOTH_IMG_h91N2lKn_XowMexDVS",
        id: "GlQE9SuDLzrXd0Cz3fz1",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_RhWjvgbrU?alt=media&token=78abe3ef-6161-4821-815b-e4ab3324d7e2",
        path: "images/BOOTH_IMG_h91N2lKn_RhWjvgbrU",
        title: "(디자인과) 컴퓨터그래픽 수업장면",
        order: 6.875,
        id: "JIleXbLeZP80PgxCRmmn",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_-Nju2i53M",
        title: "2019년 기능경기대회 시상식",
        order: 10,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_-Nju2i53M?alt=media&token=38e4e3e9-8da8-4418-8f68-70ae21859cec",
        id: "KdgaDsUQfZOcfsXkRc6S",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_6z0yEsdaF?alt=media&token=c1247f69-096b-42e0-9a14-45ac0c832d89",
        order: 33,
        path: "images/BOOTH_IMG_h91N2lKn_6z0yEsdaF",
        title: "웰컴스쿨 - 푸드트럭",
        id: "NZMhLumZrr9WCKj6wc0h",
      },
      {
        order: 8.5,
        title: "2021년 기능경기대회 시상식",
        path: "images/BOOTH_IMG_h91N2lKn_rRBty6747",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_rRBty6747?alt=media&token=5aa01daa-f563-4b3b-8e99-4c7e5abf9a2b",
        id: "OkjF5mP5SC45aASkr4dU",
      },
      {
        title: "(전기전자과) 로봇제어 실험",
        path: "images/BOOTH_IMG_h91N2lKn_eQd_tFnOH",
        order: 5.25,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_eQd_tFnOH?alt=media&token=ff84b8d6-baf8-4fbe-bdb5-cbe36b7aa488",
        id: "PkWGphrL6oemIJ49v7oH",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_YDsR_HzUn",
        order: 5.5,
        title: "(전기전자과) 감천문화마을 전기안전을 위한 재능기부활동",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_YDsR_HzUn?alt=media&token=01e42846-c6e9-4cc8-8851-d82422457929",
        id: "SEqI9G0ucwNNadtpoQlA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_aX2rwCWyu?alt=media&token=15d853f4-4205-442a-be51-95901f880af4",
        title: "Wee Class - 원예 치료",
        path: "images/BOOTH_IMG_h91N2lKn_aX2rwCWyu",
        order: 10.75,
        id: "TOfX3YdhBDwY0yn3IbyT",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_LDu8GHPDY",
        order: 27,
        title: "(동아리) AI",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_LDu8GHPDY?alt=media&token=9f783de8-6675-4306-b2d5-a730ac1962cd",
        id: "W0LCK1XgdCQh8UOUvYkV",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_hRvjC3uAD",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_hRvjC3uAD?alt=media&token=3c950a39-7ffc-4793-be23-69c310afe06a",
        order: 6.1875,
        title: "(컴퓨터과) 응용프로그래밍 수업장면",
        id: "XMmhGiuvdXjD5UVjA294",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_2_Qzjl01u",
        order: 1,
        title: "전기전자과 소개",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_2_Qzjl01u?alt=media&token=f20db940-ceb6-459e-bb33-d53addbb56d4",
        id: "YpOjSvbyOC9dj1hgQ42O",
      },
      {
        title: "2022 부일 중학생 미술실기대회",
        path: "images/BOOTH_IMG_h91N2lKn_9smeJckVc",
        order: 30.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_9smeJckVc?alt=media&token=96856ceb-544d-4a36-abed-6fd7f04bddc2",
        id: "aQaJ8k5kYmNeDpSJlsXB",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_pPx7zJC9R?alt=media&token=dd3f8fec-6a87-456e-953f-1c880fd61aaa",
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_pPx7zJC9R",
        order: 6.90625,
        title: "(디자인과) 조형 실습",
        id: "bfQPvoBuZ8YAgY6hFLhh",
      },
      {
        title: "2022 체육대회",
        order: 32.6875,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_ghLnBbAH5?alt=media&token=0d1dfa09-a89e-430f-a2ed-2d70e9aac382",
        path: "images/BOOTH_IMG_h91N2lKn_ghLnBbAH5",
        id: "c3jkAbkfsQUf75UZjhQ9",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_s61MqkJd2?alt=media&token=6552f851-c319-42a0-b204-48dd29c9dfd7",
        order: 33.25,
        path: "images/BOOTH_IMG_h91N2lKn_s61MqkJd2",
        title: "웰컴스쿨 - 허그데이",
        id: "c6vAtQv1jm0xxQ1ZbHlA",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_2E6u5hfY3?alt=media&token=f9e00f02-41c0-45d0-aa5d-e768bef66ba2",
        order: 9,
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_2E6u5hfY3",
        title: "2020년 기능경기대회 교내 시상식",
        id: "cBvzSGLovlkicYCuTcuL",
      },
      {
        title: "(동아리) 댄스부",
        path: "images/BOOTH_IMG_h91N2lKn_Q_Gid-r-P",
        order: 11.25,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_Q_Gid-r-P?alt=media&token=32a13845-e2da-44aa-946f-e3f39b4c20ac",
        id: "dYoGGkG8544TxsGsDw1n",
      },
      {
        title: "Wee Class - 도예 치료",
        order: 10.5,
        path: "images/BOOTH_IMG_h91N2lKn_FYZPbRSg3",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_FYZPbRSg3?alt=media&token=ac69663c-3177-46b5-93e0-64a199c21a0f",
        id: "gpaoiZwK4nBlhaOMqb1Y",
      },
      {
        title: "(동아리) IT인재양성반",
        path: "images/BOOTH_IMG_h91N2lKn_LbOcmizB5",
        order: 28,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_LbOcmizB5?alt=media&token=9f2e33e8-80e4-4176-b82f-7486ba56466b",
        id: "h2eLQJHnAk4e4cVsyHGU",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_HJTqzCkK4",
        title: "(동아리) Product 제품 디자인 ",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_HJTqzCkK4?alt=media&token=e192fe67-64cf-4cfb-8ea1-9d0ee4e63317",
        order: 11.96875,
        id: "irSIzVNna4PalCH9x5kK",
      },
      {
        title: "(동아리) 축구부",
        order: 11.109375,
        path: "images/BOOTH_IMG_h91N2lKn_Oqa9zfFrL",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_Oqa9zfFrL?alt=media&token=1c6c5b5c-001c-47e9-a4bb-0ee3f4315aa1",
        id: "kSCRjngiBdP3FKCihy0O",
      },
      {
        title: "신우 장학금",
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_oRZJPTuGu",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_oRZJPTuGu?alt=media&token=5a039634-d5a6-4600-a7f8-fbcb8fbef37f",
        order: 3.5,
        id: "lO9fr2X0Us8bAWbEFXqE",
      },
      {
        order: 11.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_asHZ-2frc?alt=media&token=337b8959-a9b7-4bed-bf74-ba6314c2b1a1",
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_asHZ-2frc",
        title: "(동아리) 대중가요부",
        id: "lR9tRsAZwZvMSLzeYUEG",
      },
      {
        order: 2,
        title: "컴퓨터과 소개",
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_MbGIjhxiC",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_MbGIjhxiC?alt=media&token=d146dde4-5ce4-4596-af13-7b5c51a72a2a",
        id: "nQsyIW7NDeYCM2PLy1so",
      },
      {
        order: 5.875,
        title: "(컴퓨터과) VR 소프트웨어 개발",
        path: "images/BOOTH_IMG_h91N2lKn_D-UK9bvuj",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_D-UK9bvuj?alt=media&token=894f66c4-ff4f-41fa-93a7-33d3cfe79d99",
        id: "oV26EhP5pGSiWx74wo45",
      },
      {
        order: 12.4375,
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_qHCIiX2E-",
        title: "(동아리) 국궁부",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_qHCIiX2E-?alt=media&token=dc04bb8f-6b4d-4328-8377-c7bbd8a38c12",
        id: "p0tg3QvqdKaJ56CxCBgM",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_8W61IvUj3",
        order: 5.75,
        title: "(컴퓨터과) 앱 개발 프로젝트",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_8W61IvUj3?alt=media&token=a818d920-62c9-4dff-837e-1888f83c03b7",
        id: "uy5uzDRkqaegv2pJ1rQj",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKn_aMT7WxCow",
        title: "(동아리) 방송부",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_aMT7WxCow?alt=media&token=558136cc-9420-4e93-9765-ea792e9ca32f",
        order: 26,
        id: "vNBmb9Xuo5YwGT8RRVUV",
      },
      {
        title: "(디자인과) 캐릭터 디자인",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_r2OO2EL6F?alt=media&token=9fe85ef3-cd49-4e63-85a2-4bdd13bb28c2",
        path: "images/BOOTH_IMG_h91N2lKn_r2OO2EL6F",
        order: 6.9375,
        id: "vV94tcSnVESPM2tXxV5g",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_Ij9mOzM6l?alt=media&token=cc74c242-1327-4a60-abbd-2eeeadbaadd4",
        order: 34.75,
        path: "images/BOOTH_IMG_h91N2lKn_Ij9mOzM6l",
        title: "부일 벚꽃 축제",
        id: "vlkrlyg44taCkRpurTeD",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_IfdNmVi_l?alt=media&token=18465afc-85ba-4cf9-9fd8-bb0e3a53b8e9",
        title: "2022 간부수련회",
        path: "images/BOOTH_IMG_h91N2lKn_IfdNmVi_l",
        order: 31.75,
        id: "wzxIjFzwTPpAsxKeEU5f",
      },
      {
        title: "(전기전자과) 도제식 현장교육",
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_W9Oic0HLc",
        order: 4,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_W9Oic0HLc?alt=media&token=00bf1017-3b2a-43c7-a39e-f9f1fd7b9c1b",
        id: "xG8KNeIVPYdYUEbOwj3U",
      },
      {
        path: "images/BOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_fX3qtSyt-",
        title: "(전기전자과) 전자측정 실습",
        order: 5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_h91N2lKnbQTDpGsHO0I4ec5mAah2_fX3qtSyt-?alt=media&token=7ee551b2-30d2-4a91-ad51-aa10d57011e4",
        id: "xOSDUmILBmx5VsKyEim6",
      },
      {
        title: "2022 체육대회",
        order: 32.375,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_M9ysCqZUQ?alt=media&token=d911c308-d365-4ac5-bbe3-36cc91be143e",
        path: "images/BOOTH_IMG_h91N2lKn_M9ysCqZUQ",
        id: "ySeFOLRAU32T83kbnJj4",
      },
      {
        title: "2022년 기능경기대회 시상식",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_h91N2lKn_O13fwq0tt?alt=media&token=e3ecf0cc-5d9b-449f-a595-451b5236b35d",
        path: "images/BOOTH_IMG_h91N2lKn_O13fwq0tt",
        order: 8.25,
        id: "zVTBtPk3l3gomPsmZKJS",
      },
    ],
    videos: [
      {
        url: "https://youtu.be/HMNJQWKM8xc",
        order: 4,
        title: "디자인과 기능전 작품",
        id: "29rTuAQdJb1k3J3nwgdw",
      },
      {
        order: 5,
        url: "https://youtu.be/pJHQ51Duvz0",
        title: "(전기전자과) 도제학교 소개",
        id: "7btYmkOC4Yv1jAyyRJ0O",
      },
      {
        order: 6.5,
        title: "(컴퓨터과) Buil Web Contest",
        url: "https://youtu.be/yvtYYioAVRg",
        id: "84KwqXG1ZpUEcRvhNFFu",
      },
      {
        order: 1,
        url: "https://youtu.be/x5dKPfG2iG4",
        title: "메인영상",
        id: "PBSwlYGn9hqJK4hUSd3z",
      },
      {
        order: 2,
        title: "전기전자과 기능전 작품",
        url: "https://youtu.be/wWOX5eDUsew",
        id: "S2gf6IefwJpXEwTDLf3c",
      },
      {
        order: 7,
        url: "https://youtu.be/MwwPAkWyCJ0",
        title: "(디자인과) 메타버스 전시관",
        id: "xRHSURFBPUxwP66xsYEA",
      },
      {
        order: 3,
        title: "컴퓨터과 기능전 작품",
        url: "https://youtu.be/nc9NqJkwOZ8",
        id: "yDkQemayDoX7SLXZ2buX",
      },
    ],
  },
  i03mPbAY: {
    id: "i03mPbAY",
    documents: [
      {
        title: "2023 서부산공고 포스터",
        path: "documents/ULttFlCB6_2023 서부산공고 포스터.jpg",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FULttFlCB6_2023%20%EC%84%9C%EB%B6%80%EC%82%B0%EA%B3%B5%EA%B3%A0%20%ED%8F%AC%EC%8A%A4%ED%84%B0.jpg?alt=media&token=c997f9bb-9e42-4e8a-81b9-13e4d2917ae4",
        order: 1,
        id: "j4zGU40ttGbGUgcsuSIh",
      },
      {
        title: "2023 서부산공고 입시요강",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FbOVgwFtaO_2023%20%EC%84%9C%EB%B6%80%EC%82%B0%EA%B3%B5%EA%B3%A0%20%EC%9E%85%EC%8B%9C%EC%9A%94%EA%B0%95.pdf?alt=media&token=5455d029-138d-4d80-8c7f-7b6421d3dc26",
        path: "documents/bOVgwFtaO_2023 서부산공고 입시요강.pdf",
        order: 2,
        id: "jTNbr9OrKga1AikcO1Kk",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_8vUDiijUP?alt=media&token=f8fab5e6-74cf-4647-a72d-280010757f0c",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_8vUDiijUP",
        order: 7,
        title: "벽화그리기활동2",
        id: "5SDOwBeYxONptcOBGWHG",
      },
      {
        order: 8,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_qmf1EQwtS?alt=media&token=1cbbdd36-b555-49fc-a5b0-f17a01d7b095",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_qmf1EQwtS",
        title: "점심시간",
        id: "B3DrO7OvMfi7ImXJ5aHh",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_eHRQHc_Oo",
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_eHRQHc_Oo?alt=media&token=f952f1c8-0d65-467a-a98f-13c04922a731",
        title: "학생,학부모,교사 한마음 달리기",
        id: "GdFjxZubaFWc6tD1Pl7E",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_H-_p_uFPe",
        title: "의무검정 준비",
        order: 24,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_H-_p_uFPe?alt=media&token=427ea5f4-ae4a-49b4-a74f-59faddde3c21",
        id: "HY2BrrpGSm7H2T8iK9bF",
      },
      {
        title: "벽화그리기활동1",
        order: 6,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_ZodVQBaze?alt=media&token=149449ae-42f1-42e6-8a87-23db7ddea020",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_ZodVQBaze",
        id: "PYaTO4kHHeaxmN6azNcM",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_p6xyW_WEq",
        title: "진로비전캠프",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_p6xyW_WEq?alt=media&token=7a337582-c36e-48f7-ad0c-4cf1ef145f50",
        order: 16,
        id: "PecLvK7dTRBfwJtpyi9k",
      },
      {
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_W5IkDiqQP?alt=media&token=3d17fa4e-0718-48b9-a50c-e347c430ff39",
        title: "학교전경",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_W5IkDiqQP",
        id: "TEmok1S4w1zmHKxcjaCA",
      },
      {
        title: "기계과 실습수업1",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_T3enThzav?alt=media&token=900eab28-96c6-4d60-9ec1-e3e59e21f6e4",
        order: 12,
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_T3enThzav",
        id: "TfvUoytxOL4KEa6aII0h",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_7FAOh_EE0",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_7FAOh_EE0?alt=media&token=9a131479-a1ea-49e6-837f-63868b37197b",
        title: "진로특강",
        order: 23,
        id: "UypHnbt8mj9yWioByV45",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_IzNcYhTyf?alt=media&token=c51086ab-1f27-4b3a-b687-a5cad14f15a1",
        order: 14,
        title: "스마트팩토리과 실습수업1",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_IzNcYhTyf",
        id: "bwj13xSuItedx0U9p3f8",
      },
      {
        title: "버스킹데이",
        order: 20,
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_ZaCo-GMGI",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_ZaCo-GMGI?alt=media&token=828f27fb-40de-4b27-8f22-d9e2fe780682",
        id: "hyIwvSAN2bGBBgyP4KpL",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_FKmMAZtBV?alt=media&token=ab92bf94-c72d-4dce-ae4e-d62336fbf583",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_FKmMAZtBV",
        order: 19,
        title: "에너지 체험학습",
        id: "itFPmTaP9EyhGNVOFIEU",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_BZnqfYF9O?alt=media&token=5b4838aa-a97e-4524-b0c2-dce54790a549",
        title: "봉사활동",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_BZnqfYF9O",
        order: 2,
        id: "jCzlkVPN5x9UfMGNDwRU",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_i4Qxu89CY",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_i4Qxu89CY?alt=media&token=51704d97-a707-493d-a322-bbf7ed5aaec1",
        order: 5,
        title: "진로비전캠프",
        id: "lhVRWLSzAHcp7HCv20rF",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_-pTp1txS8?alt=media&token=7d225a78-ec39-4852-9610-1dcd221e310b",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_-pTp1txS8",
        order: 9,
        title: "전기에너지과 실습수업1",
        id: "lxt9epxIJ6FmIv7iy6zP",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_rIuylYl5e",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_rIuylYl5e?alt=media&token=337b2d87-1058-4639-ac60-51f9e3a6fe7d",
        title: "체육대회2",
        order: 22,
        id: "nBpslAYzgoBnGbsL0zDN",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_TjpEouPSE",
        order: 10,
        title: "전기에너지과 실습수업2",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_TjpEouPSE?alt=media&token=b3740b39-70fd-4db4-892f-367d449b19e8",
        id: "ngYCt3k5VmLElnXu04NU",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_2l6LLws_1?alt=media&token=ff5ebf6f-6955-4989-b0e5-9967ac0044ce",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_2l6LLws_1",
        order: 4,
        title: "학교등교버스",
        id: "pFmQVJporTA3syJeN3hz",
      },
      {
        title: "무한상상실 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_cjk4oqUev?alt=media&token=cd9b3a5c-b9fb-4ba3-9363-03270854edda",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_cjk4oqUev",
        order: 17,
        id: "q7BTfikP5Mbu38g4chg7",
      },
      {
        order: 21,
        title: "체육대회1",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_pqIkXeSDR",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_pqIkXeSDR?alt=media&token=4c82a949-1bc2-42bd-a77d-42242fd6ef35",
        id: "q9joC86zLjRyBQjka2uZ",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_Qwjl0pRBP",
        order: 13,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_Qwjl0pRBP?alt=media&token=94540bf7-b90d-4809-b2b1-cb27e5da306c",
        title: "기계과 실습수업2",
        id: "r9NU4y8B39nXlHGAZ9cZ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_w1p0aDElg?alt=media&token=6e640db2-370c-4be3-8987-ff158ef38b22",
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_w1p0aDElg",
        title: "전기에너지과 실습수업3",
        order: 11,
        id: "sD7FlhbXElbzexsDhRNA",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_GLtpid0oz",
        title: "스마트팩토리과 실습수업2",
        order: 15,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_GLtpid0oz?alt=media&token=984de86e-0f54-4511-8c25-705ef314caa0",
        id: "tTBj4L76B7QamlApNE9P",
      },
      {
        path: "images/BOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_ijufpX2zs",
        title: "무한상상실 활동2",
        order: 18,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_i03mPbAYXqUcLzOcy82cDYTOP2z2_ijufpX2zs?alt=media&token=628b8d40-4947-491e-83c6-4a95ed5ea1c1",
        id: "wyVCqiM5WgWTROEe0Cj3",
      },
    ],
    videos: [
      {
        order: 2,
        url: "https://youtu.be/yMb_GX8i-yQ",
        title: "서부산공업고등학교 홍보영상",
        id: "0VqVbmxbsMMW22gbcXjX",
      },
      {
        title: "메인영상",
        url: "https://youtu.be/yBtrBRkSK7Y",
        order: 1,
        id: "BxeJGpcNwyZvzeZ74aNZ",
      },
    ],
  },
  iTnoR3l0: {
    id: "iTnoR3l0",
    documents: [],
    images: [
      {
        order: 4,
        path: "images/BOOTH_IMG_iTnoR3l0_oirkmWzlL",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_oirkmWzlL?alt=media&token=576bbb9c-611c-4a9f-9691-e9a4b66c81f7",
        title: "병과학교추수지도(군특14기)",
        id: "BWcQmRVcy7cyQ3Gd1ua6",
      },
      {
        order: 6,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_pBV8_qc8s?alt=media&token=499391e0-9c1a-4808-96e8-da472c07cf14",
        title: "진로취업특강(1학년)",
        path: "images/BOOTH_IMG_iTnoR3l0_pBV8_qc8s",
        id: "BnUeN0XVhpNGkWARDH2x",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_6F-Rlkv6c?alt=media&token=2537938d-d0ee-48d0-b2b6-4da8c575c36f",
        path: "images/BOOTH_IMG_iTnoR3l0_6F-Rlkv6c",
        order: 3,
        title: "군특15기선서식",
        id: "EypEGNIA6EpTK9AA4Hzw",
      },
      {
        order: 5,
        title: "현장체험활동(2학년)",
        path: "images/BOOTH_IMG_iTnoR3l0_AW1I-fHEZ",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_AW1I-fHEZ?alt=media&token=8e7ceafe-d794-4ebe-833b-365404dca2c2",
        id: "LAcuIuR3ZrAzQtcsFkbs",
      },
      {
        title: "취업역량강화를 위한 교직원 워크샵",
        path: "images/BOOTH_IMG_iTnoR3l0_x803V1LJk",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_x803V1LJk?alt=media&token=7a48d843-371b-4b36-b36b-b99abcd91ac7",
        order: 8,
        id: "NxwrW6T8x7vBPRNKyRGD",
      },
      {
        path: "images/BOOTH_IMG_iTnoR3l0_bnRxfvuBx",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_bnRxfvuBx?alt=media&token=40f7df96-5618-4c34-aa1f-6410deb61ee7",
        order: 10,
        title: "교육감배 고교동아리 축구대회",
        id: "SJL7zJe5CosCreI5nIVJ",
      },
      {
        order: 1,
        title: "도제반운영위원회",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_oxbWwSnTI?alt=media&token=6be4ac6f-e1b1-42a5-88e6-3cb5383c9e3b",
        path: "images/BOOTH_IMG_iTnoR3l0_oxbWwSnTI",
        id: "YnadZUSImFRVRQeTP71H",
      },
      {
        title: "전국자동차정비기능경기대회(은메달 수상)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_1MyQg0Int?alt=media&token=5ed6813d-06c9-4da8-b891-94c7e5ebd8f6",
        path: "images/BOOTH_IMG_iTnoR3l0_1MyQg0Int",
        order: 11,
        id: "hYBLFuSJsDdI52L0OJXu",
      },
      {
        path: "images/BOOTH_IMG_iTnoR3l0_R5Zvc2qbg",
        order: 2,
        title: "도제반제주캠프(2학년)",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_R5Zvc2qbg?alt=media&token=fcade4d0-cb8e-4f8d-a483-01ba09aee9a7",
        id: "pwzvMEsZpkbn5tOfdEaC",
      },
      {
        title: "생명존중교육",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_A89pOjBln?alt=media&token=e250baad-66cd-4389-8de7-4ef5426d3f09",
        path: "images/BOOTH_IMG_iTnoR3l0_A89pOjBln",
        order: 7,
        id: "rcOvYeag5XJuNCUu78nT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_a6Ga1xk5l?alt=media&token=6c8a1518-99c2-4e2f-8ab3-81853bd43ee7",
        title: "전국기능경기대회 수상(동메달, 장려상 수상)",
        order: 12,
        path: "images/BOOTH_IMG_iTnoR3l0_a6Ga1xk5l",
        id: "wsAOmadV1LMIN6LuLGMo",
      },
      {
        path: "images/BOOTH_IMG_iTnoR3l0_P5_QVvGyU",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_iTnoR3l0_P5_QVvGyU?alt=media&token=f5744592-ddc3-4831-8613-ab11d308638f",
        title: "학교로 찾아가는 노동인권 교육",
        order: 9,
        id: "ySv5fNclbLtVISwRR5HO",
      },
    ],
    videos: [
      {
        order: 1,
        title: "메인영상",
        url: "https://youtu.be/91gC9UiW5yM",
        id: "Xw2bNZUGYIdZoRcL7BGW",
      },
    ],
  },
  kDHHuyBb: {
    id: "kDHHuyBb",
    documents: [],
    images: [
      {
        order: 4,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_kDHHuyBb_txR2iYHk5?alt=media&token=fbf207a3-7800-45c1-804b-c525217b7f37",
        title: "특성화마이스터고현황_상업계열",
        path: "images/BOOTH_IMG_kDHHuyBb_txR2iYHk5",
        id: "2Vuztp4mt3p3DBtn2lZv",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_kDHHuyBb_-pWB-ipG5?alt=media&token=5065df5b-a919-4763-832f-0382e67da73b",
        order: 3,
        path: "images/BOOTH_IMG_kDHHuyBb_-pWB-ipG5",
        title: " 특성화마이스터고현황_공업 상업계열",
        id: "PfXsZ9L0m81cztbkKJ8M",
      },
      {
        order: 5,
        path: "images/BOOTH_IMG_kDHHuyBb_H0MNd4wj7",
        title: "특성화마이스터고현황",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_kDHHuyBb_H0MNd4wj7?alt=media&token=af8fac18-0f8d-4a03-9518-17d7c5da8fac",
        id: "TXPWCO1RUmQWXn6libVK",
      },
      {
        title: "특성화마이스터고현황_공업계열",
        order: 2,
        path: "images/BOOTH_IMG_kDHHuyBb_Ry6bis6_R",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_kDHHuyBb_Ry6bis6_R?alt=media&token=9138a93d-dc77-4fc6-8d86-45f44ce23191",
        id: "i2iZcsRr0CGiEv6b0h64",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_kDHHuyBb_AW3RYJm2B?alt=media&token=91a8a92a-ab4a-4eca-bb84-c6dc6192da33",
        order: 1,
        path: "images/BOOTH_IMG_kDHHuyBb_AW3RYJm2B",
        title: "지도로 보는 특성화마이스터고",
        id: "xC3GCySltZkBKkX0Jh51",
      },
      {
        order: 7,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_kDHHuyBb_fCvCYmlmO?alt=media&token=626a129f-00f1-4244-9d77-0c5c5b0caed7",
        title: "특성화마이스터고 입시상담 및 문의",
        path: "images/BOOTH_IMG_kDHHuyBb_fCvCYmlmO",
        id: "xo2sIPXtVrHIf8HjtxxH",
      },
      {
        order: 6,
        title: "특성화마이스터고현황",
        path: "images/BOOTH_IMG_kDHHuyBb_tK9KvWBgr",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_kDHHuyBb_tK9KvWBgr?alt=media&token=0266d8c4-2eae-430f-aa9f-5f6672000dbe",
        id: "zYiCBRjTwgxnkld14yk7",
      },
    ],
    videos: [
      {
        title: " '가능성을 실력으로 만드는 곳'",
        url: "https://youtu.be/nMnYj1nDuoc",
        order: 8,
        id: "57XyyvNQF2j3dVP9gqBR",
      },
      {
        order: 1,
        url: "https://youtu.be/G7g5tT_XKfM",
        title: "특성화마이스터고란?",
        id: "ByfVjtmw1cumy44SLwHz",
      },
      {
        title: " 우리 회사가 직업계고 인재를 8년간 육성해 온 진짜 이유",
        order: 9,
        url: "https://youtu.be/bqbEan0rFqM",
        id: "FpdILKYmOKTaS5K7OHwD",
      },
      {
        url: "https://youtu.be/9naEd215qnI",
        order: 13,
        title: "고교 취업에 대한 모든 것, 취업 선배가 답한다!",
        id: "GxzYwWdl6rwD9CC2I1q3",
      },
      {
        url: "https://youtu.be/LFO3Dtov0bI",
        title: "대학진학이냐 취업이냐 그것이 문제로다! 교육부X무엇이든물어보살",
        order: 3,
        id: "ID1ekkOwZHGKTLlRwd6n",
      },
      {
        title: "특성화고, 어때요?",
        url: "https://youtu.be/VTf8OooUhWs",
        order: 10,
        id: "QWOFfmgPHgZmOGNftq5Y",
      },
      {
        title: "당신은 왜 특성화고에 오셨나요?",
        url: "https://youtu.be/6ao1VeCeE2w",
        order: 7,
        id: "S7zcA8RNMSHzLGh7GV1a",
      },
      {
        url: "https://youtu.be/p4NZto0tk-I",
        title: "직업계고 다니면 이런 놀라운 이점이 있다고?!",
        order: 12,
        id: "WG1wGALQhJbFqKL5xNOO",
      },
      {
        title: "공부 말고 다른 거 해볼 생각 있어",
        url: "https://youtu.be/hEU_xBBheSA",
        order: 6,
        id: "YkAH9qmeY8RcUH6PiDef",
      },
      {
        title: "스무살에 바로 취업한 나, 제법 젠틀해요",
        order: 5,
        url: "https://youtu.be/-yABNPdl1tU",
        id: "dy9pVykCRd5YV7D6nvC8",
      },
      {
        title: "넌 꿈이 뭐니? 특성화고, 마이스터고를 만나봐",
        order: 4,
        url: "https://youtu.be/KGvogp0xjqc",
        id: "eQrXUJXTD8IZSTD1RkSO",
      },
      {
        url: "https://youtu.be/R0OAESnSZkg",
        title: '현실에 없는"학교2021" 남주 여주의 현실적인 학교 조언',
        order: 2,
        id: "oneyrbKgiZJ97Vnsqjwo",
      },
      {
        url: "https://youtu.be/EY2wlKJg7dU",
        order: 11,
        title: "하이파이브! 특성화고, 마이스터고의 모든 것",
        id: "qDGZbFgZmqbU2oH3xZjJ",
      },
    ],
  },
  qztaNBYC: {
    id: "qztaNBYC",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FotMAp_Nxn_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EC%A7%84%EC%97%AC%EC%9E%90%EC%83%81%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95(%EC%B5%9C%EC%A2%85).pdf?alt=media&token=a52e3598-6233-453b-ab78-c875643141be",
        title: "2023학년도 신입생 모집 요강",
        order: 1,
        path: "documents/otMAp_Nxn_2023학년도 부산진여자상업고등학교 신입생 입학전형 요강(최종).pdf",
        id: "fYxJjTT4D7uqghsdjpAM",
      },
    ],
    images: [],
    videos: [
      {
        url: "https://youtu.be/_ec4MpzW-Co",
        title: "미리보는 고교생활 브이로그 _ 부산진여상 학생회편 ",
        order: 2.25,
        id: "A6OXDK8mVJL5OPVuopal",
      },
      {
        url: "https://youtu.be/-SVlITHixBk",
        title: "특성화고 안내서_부산진여상",
        order: 2.875,
        id: "B3eoiAHI7zx8hZgGI4TD",
      },
      {
        url: "https://youtu.be/WOooSeaKtiw",
        title: "메인영상",
        order: 1,
        id: "OWFHZCLv0swxNqRHKJGp",
      },
      {
        order: 4.5,
        url: "https://youtu.be/kptsyv2NxJE",
        title: "부산진여상 MBC 금융고 장학퀴즈",
        id: "f6V7uL0wufc0EY6CzIXi",
      },
      {
        url: "https://www.youtube.com/watch?v=81uzkuq4eB8",
        order: 7,
        title: "2022 미리보는 고교생활",
        id: "jas2Ix9jDUQTWjhYr8Ix",
      },
      {
        url: "https://www.youtube.com/watch?v=3fQhhTaF29E",
        order: 3.5,
        title: "부산진여상 댄스 동아리 베아트리스 공연",
        id: "mAxbUMCDllMA2hxFdDw4",
      },
      {
        order: 6,
        url: "https://www.youtube.com/watch?v=8rEVwaLVGMg&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=13",
        title: "부산진여자상업고등학교 공연",
        id: "qYUzwrJIYfFDB5xNiasB",
      },
    ],
  },
  sEoItUIg: {
    id: "sEoItUIg",
    documents: [
      {
        order: 3,
        title: "부산공업고등학교 홍보 브로셔",
        path: "documents/ueGOvvkb7_브로슈어 2차교정본.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FueGOvvkb7_%EB%B8%8C%EB%A1%9C%EC%8A%88%EC%96%B4%202%EC%B0%A8%EA%B5%90%EC%A0%95%EB%B3%B8.pdf?alt=media&token=ef3186a0-8635-4c53-821e-db85219bdc5f",
        id: "Ad1c9JImlCnuP4EPmJl0",
      },
      {
        path: "documents/-gL2W81Ej_전형요강 2차 교정본.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2F-gL2W81Ej_%EC%A0%84%ED%98%95%EC%9A%94%EA%B0%95%202%EC%B0%A8%20%EA%B5%90%EC%A0%95%EB%B3%B8.pdf?alt=media&token=b2fd613d-f811-4076-a418-8309aa6d1b5b",
        title: "부산공업고등학교 입학전형요강_공지용",
        order: 3,
        id: "E1UdrUAgnD20gvxNbbiF",
      },
      {
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FPtOSu8skA_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EA%B3%B5%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.pdf?alt=media&token=2b0f215b-b584-41c4-a07b-f035474ec907",
        path: "documents/PtOSu8skA_2023학년도 부산공업고등학교 입학전형 요강.pdf",
        title: "부산공업고등학교 입학전형요강",
        id: "yup1L3nCESMYMKzrDWxM",
      },
    ],
    images: [
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_yMC4VH39-",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_yMC4VH39-?alt=media&token=808a5712-9d08-41a4-93d5-da11e6eef364",
        order: 24,
        title: "장학금 수여식",
        id: "1sQZsgLs3evaiKpiliXs",
      },
      {
        order: 29,
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_Bc4sQMmcN",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_Bc4sQMmcN?alt=media&token=cba7f8f5-0a02-4251-a45a-ef7920a63336",
        title: "정원 음악회",
        id: "2UiCHUlWXHO6UNeUAJrN",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_3lIJHlobQ?alt=media&token=e658a01a-10d4-4a03-9685-29aae3648db2",
        order: 18,
        title: "학교 축제",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_3lIJHlobQ",
        id: "3Yt6ZL3ObvgUiZvbg5jB",
      },
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_CT6AV-lok",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_CT6AV-lok?alt=media&token=9fb3a265-5f86-4dc4-9e02-ade12bbcf8b3",
        title: "기계과 실습",
        order: 10,
        id: "8gFGLvbxAfQr8o1Iju4D",
      },
      {
        title: "기숙사 내부",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_FVd_qfIX9",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_FVd_qfIX9?alt=media&token=7e7f4a58-0994-4156-91c8-b75c0d328867",
        order: 6,
        id: "9rrztwt8PnlImfDPbywY",
      },
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_JXddyhW3u",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_JXddyhW3u?alt=media&token=af8e2198-5552-41e2-a0ee-7b814f59eeda",
        order: 1,
        title: "학교전경",
        id: "EfyZPbFJqxa0s7CXt4A3",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_yrFVxUh4j?alt=media&token=b0c7d5ce-0e01-460e-8057-a9947822c4df",
        title: "인성자존감_텃밭 가꾸기",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_yrFVxUh4j",
        order: 20,
        id: "Or7QM7RTNPNEZYB8CVrT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_Y59ZO-iIc?alt=media&token=406455d9-2f70-4bde-a408-236cb9421bbb",
        order: 9,
        title: "관악부",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_Y59ZO-iIc",
        id: "PFNWFRY55DnfSTM9vZPM",
      },
      {
        order: 14,
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_-OhkBe9gL",
        title: "1인 1악기 합주 대회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_-OhkBe9gL?alt=media&token=b9573a9d-848d-4bbc-adce-d0cc3b124dfb",
        id: "PYjwnxWkGzVp3CB3KkC5",
      },
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_DRcQiq2Xp",
        order: 12,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_DRcQiq2Xp?alt=media&token=d35f74ee-a288-4fb5-962c-301bb2b87fee",
        title: "건축과 실습",
        id: "PbFgHAdyW0nkY5MApw1I",
      },
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_eKd0lQnkS",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_eKd0lQnkS?alt=media&token=c2c9c2f4-6e25-497c-9b8b-043f9c83e9d4",
        order: 11,
        title: "전기과 실습",
        id: "RHEEh1VmfKRlLmx1085D",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_3iL3VGKtF?alt=media&token=0c67b712-d765-44e7-8324-9595edfc6d5c",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_3iL3VGKtF",
        title: "체육대회",
        order: 17,
        id: "TCzi7q2bOFvOiFnO9maV",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_DmBKawtm1?alt=media&token=01e2641e-d111-4bb4-8a02-c27cd8c057fd",
        title: "도서관",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_DmBKawtm1",
        order: 26,
        id: "UIuwH9qi0fi11lBvL5sA",
      },
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_opI37lEGM",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_opI37lEGM?alt=media&token=a1fe71b4-682f-4353-b31b-0f17eba3ffd4",
        title: "잔디 정원",
        order: 2,
        id: "VpUqHTDLiAVkdV0ZFSYi",
      },
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_w2sZPPtrC",
        title: "기숙사 헬스장",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_w2sZPPtrC?alt=media&token=44ae7cde-5fd5-46da-9a85-e6d2837629db",
        order: 23,
        id: "X9VN0UlMKnKc0mXQwJ0i",
      },
      {
        title: "야구부",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_uai972PcB?alt=media&token=17c34609-b94e-4ce8-8afd-6f4059290974",
        order: 8,
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_uai972PcB",
        id: "dhB0LLoc2h3ObS5ku4SX",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_r_kn8vOkJ?alt=media&token=65f38d48-a73f-470b-90e7-2afa88c3af0c",
        order: 4,
        title: "기숙사",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_r_kn8vOkJ",
        id: "dxOzpmvW0rviiEkxwoZT",
      },
      {
        title: "마라톤_2",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_yjEhKtOWM?alt=media&token=7544addd-3711-4631-bc7c-6e699b8078a1",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_yjEhKtOWM",
        order: 16,
        id: "gTR21QEERxh78JaM6VCZ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_mSrwftH6_?alt=media&token=4e0c146f-e9f7-4852-8ca7-93ab49324634",
        order: 25,
        title: "학교 기념관",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_mSrwftH6_",
        id: "mqEy9T2x58hpOfNVSqLs",
      },
      {
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_Km_oV4I3C",
        order: 3,
        title: "학교 역사관",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_Km_oV4I3C?alt=media&token=1c3a4f13-ce3f-4dbb-95f3-c3813fffe680",
        id: "qd2p1UaG5WPDQofotwEC",
      },
      {
        order: 5,
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_dQo9uaerr",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_dQo9uaerr?alt=media&token=da9b44a4-fbe4-4578-96a8-c982d5e59b61",
        title: "정독실",
        id: "s6NvVL7tmaw2IK9wvIqx",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_WQQaHhZ6z?alt=media&token=9d0104c5-a06d-4bfe-8252-b89122858281",
        order: 28.96875,
        title: "기능경기대회 입상",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_WQQaHhZ6z",
        id: "u97BqQFyNQ7neUHW2ESX",
      },
      {
        title: "인성자존감_글로벌",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_BNjEgBxRR?alt=media&token=38c6cb74-c429-4dfb-a574-16570c0b8483",
        order: 19,
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_BNjEgBxRR",
        id: "uvEgCcVKu5PCE0KZxPp7",
      },
      {
        title: "용접실습",
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_TIORSp6lr",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_TIORSp6lr?alt=media&token=97eff927-53c9-4966-a394-e03c9f2d6713",
        order: 13,
        id: "vXCwFRlIdIQ3qL2Va8FT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_wZYW--No3?alt=media&token=59a16e53-4d2a-4551-aaf9-7414703153cc",
        title: "마라톤_1",
        order: 15,
        path: "images/BOOTH_IMG_sEoItUIgZ9UCAAak821IcO8Osh72_wZYW--No3",
        id: "vpyWdiH1Q0CQwqs8q1cy",
      },
    ],
    videos: [
      {
        title: "[부산공업고등학교 홍보영상] 건축 토목과로 올레?",
        order: 5,
        url: "https://youtu.be/h1rhJyvtrSs",
        id: "9tvqNxXbOd9Q4ndJM5Au",
      },
      {
        url: "https://youtu.be/9rWCo9kTYPg",
        order: 6,
        title: "[VLOG] 부산공업고등학교 건축토목과를 소개할게!",
        id: "CcSaS0jast1ROpZ3usky",
      },
      {
        title: "부산공업고등학교 헬리캠 영상",
        url: "https://youtu.be/pS85igsGm00",
        order: 2,
        id: "H3dAtmg1MV3oKjmrh4Zo",
      },
      {
        order: 3,
        title: "부산공업고등학교 학과 소개",
        url: "https://youtu.be/ZNllT-V5j0Q",
        id: "LEL9i118H2gC7s54sGLB",
      },
      {
        url: "https://youtu.be/vNDnMwFA0DU",
        title: "메인 영상",
        order: 1,
        id: "p1apbqc01Dgc7aszBpMM",
      },
      {
        url: "https://www.youtube.com/watch?v=QZywN2YtZh8&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=5://youtu.be/ESPOuIr-pGo",
        order: 4,
        title: "부산공업고등학교 공연",
        id: "tRuwVd0Ig6sUmSD2rGhk",
      },
    ],
  },
  skpjRyhp: {
    id: "skpjRyhp",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FIxXF1hJfe_2022%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EA%B8%B0%EA%B3%84%EA%B3%B5%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.pdf?alt=media&token=fbfb64ed-3dc5-4cc7-a043-5e3ccb866d90",
        title: "부산기계공업고등학교 입학전형 요강(2022학년도)",
        order: 3,
        path: "documents/IxXF1hJfe_2022학년도 부산기계공업고등학교 입학전형 요강.pdf",
        id: "1OT4w8a7h5BVzPvhBLxf",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FLIFezAj9P_2022%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EA%B8%B0%EA%B3%84%EA%B3%B5%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%ED%99%8D%EB%B3%B4%20%EB%A6%AC%ED%94%8C%EB%A0%9B.pdf?alt=media&token=c98742cc-9b35-4765-9d5f-7d69676e252f",
        order: 1,
        path: "documents/LIFezAj9P_2022학년도 부산기계공업고등학교 신입생 홍보 리플렛.pdf",
        title: "부산기계공업고등학교 신입생 홍보 리플렛(2022학년도)",
        id: "8PZAHX4yrJiQfq6KEZpp",
      },
      {
        order: 2,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FOxVbYP1SA_2022%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EA%B8%B0%EA%B3%84%EA%B3%B5%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%ED%99%8D%EB%B3%B4%20%ED%8C%9C%ED%94%8C%EB%A0%9B.pdf?alt=media&token=ac9f4b8f-7172-4a66-a903-63a54347af6e",
        title: "부산기계공업고등학교 신입생 홍보 팜플렛(2022학년도)",
        path: "documents/OxVbYP1SA_2022학년도 부산기계공업고등학교 신입생 홍보 팜플렛.pdf",
        id: "TcsE4kEPfUPNrvZE99ZB",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_5heT-WIh3?alt=media&token=288c8760-7fa9-4c59-8d74-f1bbb6e2bbd6",
        order: 5,
        title: "독일 글로벌 현장학습단",
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_5heT-WIh3",
        id: "22fXQg9Z2TYZDHC1tvMn",
      },
      {
        order: 3,
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_vqC0j67VM",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_vqC0j67VM?alt=media&token=b660939a-6ddf-478a-bc0b-95aabb16a7bb",
        title: "말레이시아 GMI 영어 프로젝트 기반학습 연수",
        id: "5Aaf2VGTBV4VTPTjHmog",
      },
      {
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_NimQwuxR5",
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_NimQwuxR5?alt=media&token=373f8565-0ba3-486d-b033-838063fd6af4",
        title: "청소년 비즈쿨 운영학교",
        id: "8qFuoNMxKOodg8FLKyrv",
      },
      {
        order: 7,
        title: "국제기능올림픽 금메달",
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_CX2Agplrz",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_CX2Agplrz?alt=media&token=1df59aa7-7d6f-452a-a5c6-f7100c475db3",
        id: "GJcEgCW0YbOxRyonIKFf",
      },
      {
        order: 4,
        title: "중국 산업체 견학 및 문화체험 연수",
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_4wxmbRBMQ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_4wxmbRBMQ?alt=media&token=76fd1ec5-d812-41d1-acf8-09ff2080cc7b",
        id: "GvQMwB8nB535bfy3HYxw",
      },
      {
        title: "민속부 수영야류",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_weyrsNpxF?alt=media&token=06c66beb-35f0-4d83-ad62-a83fea13cdb2",
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_weyrsNpxF",
        order: 10,
        id: "a0NhYXxUcgmfoIXf7UPi",
      },
      {
        title: "제54회 전국기능경기대회 금탑",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_Mg8Y_oir5?alt=media&token=d8226b07-2bd3-4252-a8e0-d60051caa8b0",
        order: 6,
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_Mg8Y_oir5",
        id: "fuM8iSI6J3dO2KWhPVju",
      },
      {
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_5SWzat7Wd",
        title: "한국수력원자력 견학",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_5SWzat7Wd?alt=media&token=1d03bdbb-2cb2-4148-85ed-8499e8487490",
        order: 1,
        id: "obvjrKGt1zkhWH3HvJQ0",
      },
      {
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_n82asULLS",
        order: 12,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_n82asULLS?alt=media&token=1dee427b-6b4a-4853-baff-097a197c4b61",
        title: "제56회 전국기능경기대회 동탑",
        id: "rZZhB4V72xUVLBMdZYfJ",
      },
      {
        title: "3D 프린터 동아리 활동",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_ZwFxVv00j?alt=media&token=c9e23c2f-9b0f-432b-b52f-05fd4739d22c",
        order: 11,
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_ZwFxVv00j",
        id: "sKhDNi34ajCx0gCUpLwz",
      },
      {
        title: "일본 히로시마공업고등학교 자매교류 연수",
        order: 2,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_ym7R3d22s?alt=media&token=e853dd56-48f0-4250-a044-a8ba5c18c701",
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_ym7R3d22s",
        id: "sjstvV15NaR7MOFF98Ss",
      },
      {
        title: " 제55회 전국기능경기대회 동탑",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_NUBqGkcEs?alt=media&token=9829c191-c907-4c84-96f6-0fdfedc6fb01",
        order: 8,
        path: "images/BOOTH_IMG_skpjRyhpv1MofbC6nNlizu13cDz1_NUBqGkcEs",
        id: "zHwnSxrJbPvK8uk1CQ21",
      },
    ],
    videos: [
      {
        order: 3,
        title: "악대부 공연",
        url: "https://youtu.be/DA719oaHfTA",
        id: "A7zkZYzZ6ctQh8W5h02h",
      },
      {
        url: "https://youtu.be/YQ8v6VDte8w",
        title: "장학퀴즈",
        order: 2,
        id: "aVmPu6ylORQfEsa3Kg0c",
      },
      {
        order: 1.5,
        url: "https://youtu.be/P9ASZe0Zd1I",
        title: "메인 영상",
        id: "zi1U66SG2H6MokeawsWx",
      },
    ],
  },
  tlt0bwaq: {
    id: "tlt0bwaq",
    documents: [
      {
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FKsQ3eX4OC_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%ED%95%B4%EC%82%AC%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.pdf?alt=media&token=d2c6c712-eac3-430a-beeb-2693784eb0c6",
        title: "2023학년도 부산해사고등학교 입학전형 요강",
        path: "documents/KsQ3eX4OC_2023학년도 부산해사고등학교 입학전형 요강.pdf",
        id: "ELwIlrewEanWVtwUXqMh",
      },
      {
        title: "2023학년도 부산해사고 리플릿",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FO5Lnk-2oS_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%ED%95%B4%EC%82%AC%EA%B3%A0%204%EB%8B%A8%EB%A6%AC%ED%94%8C%EB%A6%BF.pdf?alt=media&token=bf40c051-029a-405f-91e2-37b1084da4b6",
        order: 2,
        path: "documents/O5Lnk-2oS_2023학년도 부산해사고 4단리플릿.pdf",
        id: "erL154e1ImLeIJfQggmB",
      },
    ],
    images: [
      {
        order: 4,
        path: "images/BOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_U1j9rKSpT",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_U1j9rKSpT?alt=media&token=dce23380-df17-4710-9265-71b7dbfdfae8",
        title: "실습수업_연구학교",
        id: "6h2HshFbq4pat0BSvK0l",
      },
      {
        order: 1,
        path: "images/BOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_giwba2N9L",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_giwba2N9L?alt=media&token=52d6f1eb-074e-4dd3-a5e0-83cf7d24aa17",
        title: "실습수업_연구학교",
        id: "D4OHIDunBk25MHVh3rQv",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_MlOWi7fHb?alt=media&token=38bb1207-ca5e-488c-ad0b-8ed176931f87",
        path: "images/BOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_MlOWi7fHb",
        title: "실습수업_연구학교",
        order: 2,
        id: "NW55y6dgFmgikbmMaHzV",
      },
      {
        path: "images/BOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_bUQA_C2MI",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_bUQA_C2MI?alt=media&token=a2bdbdf7-8796-401c-bafb-c8b503015bbe",
        order: 3,
        title: "실습수업_연구학교",
        id: "Qmqr2G1vegBntXDOx6Xr",
      },
      {
        title: "해군 P.O.T.C 해군뱃지 전달식",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_bbe5CF8DA?alt=media&token=a1f45741-61ef-46a8-a08a-bafcbe961785",
        path: "images/BOOTH_IMG_tlt0bwaqluVjjxLeMtoz55JSbw82_bbe5CF8DA",
        order: 0.5,
        id: "dVucM4CONv0xTpqYBMG8",
      },
    ],
    videos: [
      {
        url: "https://www.youtube.com/watch?v=1JV7tiDClsc",
        order: 1,
        title: "부산해사고등학교 신입생 입학식",
        id: "PL8bN7f4lO4LdJ0Vlsav",
      },
      {
        order: 3,
        title: "기관과 현장 교육 영상",
        url: "https://www.youtube.com/watch?v=TKHbV_miqZE",
        id: "T4ibAlDK19Mzc6QcC1oG",
      },
      {
        order: 4,
        url: "https://www.youtube.com/watch?v=gDRBUMPZvR8",
        title: "해사고 학생들의 일상",
        id: "TBgZsx6uIEzEOJsfh4Tw",
      },
      {
        url: "https://www.youtube.com/watch?v=yU7XHguFR6s",
        title: "항해과 예선 교육 영상",
        order: 2,
        id: "zZqWJ1sq1LYTQvxx24AQ",
      },
    ],
  },
  vJI5HSIg: {
    id: "vJI5HSIg",
    documents: [
      {
        path: "documents/OPiVTAn6f_2023학년도 팜플렛(웹용).pdf",
        order: 2,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FOPiVTAn6f_2023%ED%95%99%EB%85%84%EB%8F%84%20%ED%8C%9C%ED%94%8C%EB%A0%9B(%EC%9B%B9%EC%9A%A9).pdf?alt=media&token=1740c093-f00a-491b-a4dd-9f8a73a7b121",
        title: "2023학년도 팜플렛",
        id: "KxZt4Owix48Hy9CIH8aV",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FLKQ7NXQKs_2023%ED%95%99%EB%85%84%EB%8F%84%20%ED%8C%9C%ED%94%8C%EB%A0%9B(%EC%9B%B9%EC%9A%A9).pdf?alt=media&token=36de064d-5817-4c6c-adf5-33394b409e52",
        path: "documents/LKQ7NXQKs_2023학년도 팜플렛(웹용).pdf",
        order: 1,
        title: "2023학년도 팜플렛",
        id: "YPBlPYh7Q10uAopCfAsJ",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIgD5QFM305SJIkeHvs6cY2_yUnqEkKW8?alt=media&token=1fc517d1-0112-43bc-ae92-5f55a5d74e2a",
        title: "한국선급 용접사 시험합격 뉴스 보도",
        order: 0.5,
        path: "images/BOOTH_IMG_vJI5HSIgD5QFM305SJIkeHvs6cY2_yUnqEkKW8",
        id: "I2YTpSK3TFEZHkcvPWbG",
      },
      {
        order: 6,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIg_opkrHXTYK?alt=media&token=1b3e72f0-8ed0-4d46-b191-0c2c944df32b",
        title: "전기전자과1",
        path: "images/BOOTH_IMG_vJI5HSIg_opkrHXTYK",
        id: "LENvrfJ6F9TtLmG3twPJ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIg_COlwUETUA?alt=media&token=ab144ca1-7741-4a94-bfc7-917385b5d137",
        order: 7,
        title: "전기전자과2",
        path: "images/BOOTH_IMG_vJI5HSIg_COlwUETUA",
        id: "LXRVNBD8bqJb7GQDEkPS",
      },
      {
        path: "images/BOOTH_IMG_vJI5HSIg_r7pWMMgh1",
        order: 3,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIg_r7pWMMgh1?alt=media&token=23af2091-4545-4ce9-84c4-7e9ed59d886c",
        title: "우리학교자랑2",
        id: "QiyDte0ThI6cCVRX57gO",
      },
      {
        path: "images/BOOTH_IMG_vJI5HSIg_7DjsNLAzD",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIg_7DjsNLAzD?alt=media&token=43f8206d-ff79-43dc-807d-317d745abc39",
        order: 5,
        title: "기계과2",
        id: "Tj6sqxOhU61qTEsIgEPh",
      },
      {
        path: "images/BOOTH_IMG_vJI5HSIg_K9R9nnqJ3",
        title: "기계과1",
        order: 4,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIg_K9R9nnqJ3?alt=media&token=1339323a-3bb9-4052-bff9-7b1202323556",
        id: "dhouSYOlv4v9wlHdFHVV",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIg_7dqCj0_5Z?alt=media&token=1df3fa75-1aaa-4f5c-b745-eeab5bae46d3",
        order: 8,
        path: "images/BOOTH_IMG_vJI5HSIg_7dqCj0_5Z",
        title: "해공을 선택해야 하는 이유",
        id: "hsly8PdYkr93LOJec9b1",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vJI5HSIg_911FBygBe?alt=media&token=df01e8c1-da71-4cb9-b3f5-5c63ecc2feb5",
        path: "images/BOOTH_IMG_vJI5HSIg_911FBygBe",
        order: 2,
        title: "우리학교자랑1",
        id: "jLctGB5e6alT1hWnEQu7",
      },
    ],
    videos: [
      {
        title: "해외취업 인터뷰",
        url: "https://youtu.be/dP_PUR2BEVE",
        order: 2,
        id: "GIRBEWBX0jip4BVcXLlj",
      },
      {
        order: 2.5,
        url: "https://youtu.be/LD_TFhl3YMg",
        title: "메인영상",
        id: "pG1N6GcyxMKZhnYMD88B",
      },
    ],
  },
  vMjlWuyV: {
    id: "vMjlWuyV",
    documents: [
      {
        order: 1.6875,
        path: "documents/KR9vLYwPu_리플렛 최종_opt.pdf",
        title: "2023학년도 신입생 모집 리플렛",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FKR9vLYwPu_%EB%A6%AC%ED%94%8C%EB%A0%9B%20%EC%B5%9C%EC%A2%85_opt.pdf?alt=media&token=872abce3-8fbe-4ffc-8d15-cfd8b855ebb7",
        id: "2wHyC2r0hGrb6UDwWfxS",
      },
      {
        order: 4.5,
        path: "documents/rHAgHMkUq_부산문화여자고등학교 학과 및 실습실 소개.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FrHAgHMkUq_%EB%B6%80%EC%82%B0%EB%AC%B8%ED%99%94%EC%97%AC%EC%9E%90%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%ED%95%99%EA%B3%BC%20%EB%B0%8F%20%EC%8B%A4%EC%8A%B5%EC%8B%A4%20%EC%86%8C%EA%B0%9C.pdf?alt=media&token=a974b6af-6498-406b-bb47-dea1b6336c20",
        title: "부산문화여자고등학교 학과 및 실습실 소개",
        id: "9byUgTtoD9k4O9vuwUVL",
      },
      {
        order: 1.125,
        title: "2023학년도 신입생 모집 포스터",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FOOZvvRuoy_%ED%8F%AC%EC%8A%A4%ED%84%B0%20%EC%B5%9C%EC%A2%85.pdf?alt=media&token=b59c812b-4275-4dc3-a65e-b2fda0982bdf",
        path: "documents/OOZvvRuoy_포스터 최종.pdf",
        id: "MhBWm9KN2wZv0PZmWG1Y",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2Fno2jKOGZk_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EB%AC%B8%ED%99%94%EC%97%AC%EA%B3%A0%20%EC%8B%A0%EC%9E%85%EC%83%9D%20%EC%B2%B4%ED%97%98%ED%94%84%EB%A1%9C%EA%B7%B8%EB%9E%A8%20%EC%95%88%EB%82%B4%20%EB%B0%8F%20%EC%8B%A0%EC%B2%AD%EC%84%9C.hwp?alt=media&token=9557d8d4-889f-4d77-99c0-3657ffe39639",
        title: "2023학년도 신입생 체험프로그램 안내 및 신청서",
        path: "documents/no2jKOGZk_2023학년도 부산문화여고 신입생 체험프로그램 안내 및 신청서.hwp",
        order: 2.25,
        id: "QZYgN42DBuzzcR1eeoYD",
      },
    ],
    images: [
      {
        order: 17,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_qlAvGJS2c?alt=media&token=eca67841-0439-4c08-8eae-fdb92a829ddd",
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_qlAvGJS2c",
        title: "으라차차 극복데이",
        id: "0VMmAT01RDmTQYsbH3kH",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_pYoefxJRN?alt=media&token=09ece920-6339-4c31-a221-f6758b86ca0a",
        title: "제23회 부산국제관광전",
        path: "images/BOOTH_IMG_vMjlWuyV_pYoefxJRN",
        order: 36.5,
        id: "7Owvhs04HwcVIAcPb4CR",
      },
      {
        path: "images/BOOTH_IMG_vMjlWuyV_wELmfKQcg",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_wELmfKQcg?alt=media&token=3660eb6f-ba94-4897-863d-f177c1b8ed39",
        order: 39,
        title: "해운대로타리클럽 장학금 전달식",
        id: "7fmKpoUI54fvmM9GRTNx",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_OMwxY5aAR?alt=media&token=3fd42ba7-580a-40fa-9a5a-dfa1aa9add3e",
        path: "images/BOOTH_IMG_vMjlWuyV_OMwxY5aAR",
        title: "독도바로알기주간 캠페인 실시",
        order: 39,
        id: "8KVf8LG80LIJwLWuwzFQ",
      },
      {
        title: "한국조리실",
        order: 39.5,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_wjk6GFM8I?alt=media&token=3436e342-2e22-437b-a882-158a1a81db44",
        path: "images/BOOTH_IMG_vMjlWuyV_wjk6GFM8I",
        id: "FPPkwW8a869W7JdxOJGg",
      },
      {
        order: 9,
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_4IwbFKuIT",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_4IwbFKuIT?alt=media&token=4ecef9a2-a77b-43b4-adf6-7fcc82d07cce",
        title: "패션디자인과 전통한복공모대전 참가",
        id: "Hpn9XrhRg95ZoLdOSyx8",
      },
      {
        order: 13,
        title: "부산광역시 일자리 박람회",
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_5P1_nEfnt",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_5P1_nEfnt?alt=media&token=76062a89-bee4-4651-8e98-46c8a81eea79",
        id: "KZxhA4JDPvnegxRF6b37",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_JGeth5rXd?alt=media&token=217ee463-1b2a-4cf3-a12a-ce866678c3ca",
        order: 39,
        title: "간호실습실",
        path: "images/BOOTH_IMG_vMjlWuyV_JGeth5rXd",
        id: "LlavSMFUelEwrL5rJwH5",
      },
      {
        title: "영유아놀이지도실",
        order: 40.25,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_iV8fzzu57?alt=media&token=9aada928-d567-4cde-855a-3157436fcc93",
        path: "images/BOOTH_IMG_vMjlWuyV_iV8fzzu57",
        id: "TpFaiePaQwG0RWjJizA3",
      },
      {
        path: "images/BOOTH_IMG_vMjlWuyV_BFgECRKVF",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_BFgECRKVF?alt=media&token=7716cb7d-05ea-4bb8-8253-b85e98916f26",
        order: 40.0625,
        title: "융합교과실",
        id: "WWO2Zel543kxOgdPnE9M",
      },
      {
        order: 40,
        path: "images/BOOTH_IMG_vMjlWuyV_fVgMjUHVq",
        title: "호텔식음료 서비스실무실",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_fVgMjUHVq?alt=media&token=6fb200cc-ef00-455c-8dec-efb73e085963",
        id: "XpBcyV5vdy8lpnP1Xsyt",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_U8JZuN7gP?alt=media&token=5e4b8f33-ceb2-4992-9a01-a300a16ee161",
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_U8JZuN7gP",
        order: 20,
        title: "패션디자인과 해운대백사장 한복데이 패션쇼 진행",
        id: "bhhmkSP4p3l3KJuuLzvo",
      },
      {
        title: "독서교육활동 독서기행",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_oJyErMBxz?alt=media&token=c009d930-3fed-4763-b304-e21aafa3cfd7",
        order: 5,
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_oJyErMBxz",
        id: "cyds6p3hwzaop7yvPACN",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_Qr09Lw4Rx?alt=media&token=febe5ecf-da3e-4144-b057-338be34ecd65",
        order: 38,
        path: "images/BOOTH_IMG_vMjlWuyV_Qr09Lw4Rx",
        title: "2022학년도 벚꽃사진 콘테스트",
        id: "dnm39bVKfxqLlhokLsXB",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_C4hh9T1jf?alt=media&token=803a6395-145c-4b4e-bd2b-c0211f82c7c9",
        path: "images/BOOTH_IMG_vMjlWuyV_C4hh9T1jf",
        order: 36,
        title: "2022년 부산상업경진대회 수상",
        id: "e57BbN5TF91JopZ6CbUe",
      },
      {
        path: "images/BOOTH_IMG_vMjlWuyV_9OCST1yZY",
        order: 39.75,
        title: "바텐더실",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_9OCST1yZY?alt=media&token=c7ce668c-e05b-40d0-afb0-f060f5541cfe",
        id: "fXMhkEN2QXzmHlDz1fTp",
      },
      {
        title: "부산문화학생가요제",
        order: 10,
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_hUP7gWaFB",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_hUP7gWaFB?alt=media&token=ef3bdd28-d85d-4981-8bf5-833920736215",
        id: "hBfuMrFFtNdVencRxNXr",
      },
      {
        order: 38.5,
        path: "images/BOOTH_IMG_vMjlWuyV_njfTPTyvx",
        title: "인성키움 다도교육",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_njfTPTyvx?alt=media&token=560518df-5b0a-4da8-898f-367f331de533",
        id: "iU8zbxstOpURumIOj95N",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_BNiUXJyMp?alt=media&token=8f59b670-c15c-4995-be49-b3bded9cde5d",
        order: 23,
        title: "제13회 전국 카누드래곤보트대회 우승",
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_BNiUXJyMp",
        id: "jkHwhW1C7LCCQ4BaaSLT",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_6kZK987Zy?alt=media&token=51930497-e6b2-4650-aef4-cd06c27384a6",
        order: 11,
        title: "전국상업경진대회 동아리콘테스트 대상 수상",
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_6kZK987Zy",
        id: "mCq0zKHZxVssSjyMjZmR",
      },
      {
        order: 36,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_P2njWPjl-?alt=media&token=3cf7d031-2b51-4b26-aceb-bc368fd8f254",
        path: "images/BOOTH_IMG_vMjlWuyV_P2njWPjl-",
        title: "한마음 학생 체육 축제",
        id: "rpnXrvHcWDtK1VoxAD8m",
      },
      {
        title: "영유아교수방법실",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_zPxXZs1Ml?alt=media&token=f350516d-4700-4e2b-9650-25bb53d5e893",
        order: 40.5,
        path: "images/BOOTH_IMG_vMjlWuyV_zPxXZs1Ml",
        id: "s6Bc8BoHDE70eDgJWWeQ",
      },
      {
        title: "2022년 문인협회 시낭송대회",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_dFS86uZ9d?alt=media&token=08d6b24f-4a21-4c4f-8444-39a710824c1c",
        path: "images/BOOTH_IMG_vMjlWuyV_dFS86uZ9d",
        order: 36,
        id: "sgbwXvQLAVcaxB3gUwH5",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_zboUQsoZh?alt=media&token=0961d78e-8509-4f04-a70e-4900420a00ee",
        order: 37,
        title: "2022학년도 시낭송대회",
        path: "images/BOOTH_IMG_vMjlWuyV_zboUQsoZh",
        id: "shsmLfTFs80NAcPnXY11",
      },
      {
        order: 19,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_SaMxtgVux?alt=media&token=5ea7dcaa-cc8d-4798-9f64-d0c9108da90c",
        path: "images/BOOTH_IMG_vMjlWuyVZZgJZ3gPWJOkel2K4DJ3_SaMxtgVux",
        title: "평화통일퀴즈축제",
        id: "t5oHPuB3yzCLcZCEFVKr",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_oqBPFzHJa?alt=media&token=59a2a41c-cdd8-45a2-bca0-df74be6bb054",
        path: "images/BOOTH_IMG_vMjlWuyV_oqBPFzHJa",
        title: "제과제빵실",
        order: 39,
        id: "tl6l62xNx9ew82mJwIO5",
      },
      {
        order: 40.125,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_vXZgZzjsN?alt=media&token=cfd2a02b-d394-4682-8c1a-5752ca4ebeb7",
        title: "영유아놀이지도실",
        path: "images/BOOTH_IMG_vMjlWuyV_vXZgZzjsN",
        id: "tsbg1g8PehyuIAgFn66w",
      },
      {
        path: "images/BOOTH_IMG_vMjlWuyV_8rowwKmgr",
        order: 0.3125,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_8rowwKmgr?alt=media&token=3a808dde-1c0c-4751-9457-19ad44c5f263",
        title: "중학생 진로체험 ",
        id: "uNOoah5UsCi1zkoYu5JP",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_uuZY-XV0A?alt=media&token=e52367ba-e3c7-467e-8216-dd4b0cf78d95",
        order: 35,
        title: "2022년 청소년 비즈쿨 페스티벌 참가",
        path: "images/BOOTH_IMG_vMjlWuyV_uuZY-XV0A",
        id: "yDAYrB1PRhHDZHTavkS0",
      },
      {
        order: 36.25,
        title: "제11회 백의의 천사 선서식",
        path: "images/BOOTH_IMG_vMjlWuyV_u6-o_W0jA",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_u6-o_W0jA?alt=media&token=05733be2-f1e6-4a6f-ae67-d4fdda618df6",
        id: "yNLR3oLP0SaV5pFoSMX9",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_vMjlWuyV_RxzFlkjrD?alt=media&token=12dfeaa8-302c-4caf-a202-0b134835b228",
        order: 34,
        title: "2022년 청소년 비즈쿨 창업동아리 비즈마켓 운영",
        path: "images/BOOTH_IMG_vMjlWuyV_RxzFlkjrD",
        id: "ymHqctAKa3UJEr3PybII",
      },
    ],
    videos: [
      {
        title: "부산문화여자고등학교 국제관광과를 소개합니다!",
        url: "https://www.youtube.com/watch?v=N8Ec8pp_nSI",
        order: 1.1875,
        id: "0m1TnxDQ7XMz0Ivj4DPz",
      },
      {
        title: "유아교육과 졸업 수성유치원 취업",
        order: 1.4375,
        url: "https://youtu.be/kp4MmGGj_jQ",
        id: "5pDO72cbnHPplzGemAcM",
      },
      {
        title: "부산문화여자고등학교 보건간호과를 소개합니다!",
        url: "https://www.youtube.com/watch?v=U6ExHfoZVGU",
        order: 1.21875,
        id: "9o3IL5dbp7omwyKy8nQZ",
      },
      {
        url: "https://youtu.be/PxKxsXpzKjs",
        title: "보건간호과 졸업 부산 보건직 공무원 취업",
        order: 1.859375,
        id: "D2BF9V457axMhrVIsFgN",
      },
      {
        url: "https://youtu.be/WZ7kb9qbe-4",
        title: "유아교육과 졸업 신라대학교 유아교육과 진학",
        order: 4,
        id: "F1enMCc2J5xIjZSEEulQ",
      },
      {
        url: "https://youtu.be/YkKo7N7EZGY",
        title: "메인영상",
        order: 1,
        id: "Ha99nT3QCNOj7Pshb824",
      },
      {
        order: 1.203125,
        url: "https://www.youtube.com/watch?v=bh6FHyzHBEo",
        title: "부산문화여자고등학교 패션디자인과를 소개합니다!",
        id: "Jfd6oZoPgXB8bsckjxX4",
      },
      {
        order: 8,
        url: "https://youtu.be/06TznvRpuMM",
        title: "패션디자인과 졸업 리틀마켓 취업",
        id: "RiR7cqFPGkkOOASR6jLX",
      },
      {
        order: 1.0625,
        url: "https://www.youtube.com/watch?v=4KQbCi2aC4Q&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=8",
        title: "부산문화여자고등학교 공연",
        id: "T5kwHfVpOD6P5vYHFm1R",
      },
      {
        title: "국제관광과 졸업 제주호텔 취업",
        order: 2,
        url: "https://youtu.be/OsMOkWjxZGQ",
        id: "W8kC0hmoldI9PfZTHmah",
      },
      {
        title: "국제관광과 졸업 동아대 입학",
        url: "https://youtu.be/khDbmioqaOo",
        order: 7,
        id: "eUggf18IiTuJSW4JccpL",
      },
      {
        title: "학교 홍보 동영상",
        order: 1.25,
        url: "https://youtu.be/3uc8anrHHjM",
        id: "esoXZNlzf0QAYRjpBq8q",
      },
      {
        url: "https://youtu.be/PFikamaIEJ0",
        order: 1.40625,
        title: "패션디자인과 졸업 경희대 의상학과 진학",
        id: "vNLVWtPUk9UElboTsELd",
      },
      {
        url: "https://www.youtube.com/watch?v=isBdh3rnijY",
        title: "미리보는 고교생활",
        order: 1.125,
        id: "zZwOQ9Qb2vdzx7sYkqMd",
      },
    ],
  },
  w4TXDfxl: {
    id: "w4TXDfxl",
    documents: [
      {
        path: "documents/KLW-dFpvQ_2023학년도 부산마케팅고등학교 입학전형 요강.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FKLW-dFpvQ_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EB%A7%88%EC%BC%80%ED%8C%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.pdf?alt=media&token=03a54038-1e2f-4aae-be2a-48f1bbec2677",
        title: "2023학년도 부산마케팅고 입학전형 요강",
        order: 1,
        id: "42X2ePw3fkHZ5SfijMlw",
      },
    ],
    images: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_jwo02G9Yb?alt=media&token=839b3bd1-03ad-47c7-80a5-5a3264dec785",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_jwo02G9Yb",
        order: 11,
        title: "바리스타 로스팅 체험",
        id: "1vnP8jkdHA6xbNh9arHE",
      },
      {
        title: "2022 보건간호과 나이팅게일 선서식",
        order: 2.390625,
        path: "images/BOOTH_IMG_w4TXDfxl_zXvecObZi",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_zXvecObZi?alt=media&token=04aae6da-3b5e-41e6-b2ba-48307e37df19",
        id: "2zsDQSgXYUqDh6e4wZzu",
      },
      {
        title: "스포츠과 심폐소생술",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_UL1M1yDz6",
        order: 3.6484375,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_UL1M1yDz6?alt=media&token=7c357620-2e47-4706-8acb-875b88e6e7b8",
        id: "3W6zNN3HWMXYfn5IgruD",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_THXqw8P1_?alt=media&token=c1ab3fce-906f-42d8-bcac-d2dc122229c2",
        title: "진로체험행사_3",
        order: 19,
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_THXqw8P1_",
        id: "3gNRXMW445W1m7A3UB4a",
      },
      {
        title: "2022 스포츠건강관리과 롤러스케이트장 체험",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_DIljSJTJW?alt=media&token=02f4a0d2-13b6-48a1-a2f3-f41c6100d76f",
        path: "images/BOOTH_IMG_w4TXDfxl_DIljSJTJW",
        order: 2.3759765625,
        id: "5FFpjuJGMGc7Tj7qpKwZ",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_HD-UIBXyO",
        title: "나이팅게일 선서식",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_HD-UIBXyO?alt=media&token=fb08d384-6f28-4ea9-aefb-2296ff4c18a6",
        order: 3.11328125,
        id: "6BSIEced4RbcvPtzu4Aa",
      },
      {
        order: 25,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_i-FKl4doo?alt=media&token=c190163c-ce3a-4933-a478-555616a1571b",
        title: "나이팅게일 선서식",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_i-FKl4doo",
        id: "6pTu3RvZ0yjRlniMCjZh",
      },
      {
        title: "KRX금융특강_4",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_PRjGDe1zq?alt=media&token=95e32176-cb78-401f-b5cb-5dca9b6cf95c",
        order: 18,
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_PRjGDe1zq",
        id: "7a0D7vVFCShBhSzBkEM1",
      },
      {
        order: 6.5,
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_Av-e0DUV4",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_Av-e0DUV4?alt=media&token=48b4e795-7544-4f68-875c-30e9835318aa",
        title: "스포츠과 특강",
        id: "A2UeSaTNrSNzNxGi8Y2q",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_8xJ0bTFAT",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_8xJ0bTFAT?alt=media&token=6661e60d-1eb5-4e6e-93ee-7dbc9816183c",
        order: 13,
        title: "2021 부산상업경진대회",
        id: "BpmNVkOvf4FO45issMX3",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_NGzaFbipH?alt=media&token=4860b661-2f4f-41c4-a41e-73017a452102",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_NGzaFbipH",
        title: "진로체험행사_5",
        order: 2.47265625,
        id: "CPfiAXSvh7vOwpNs6NX4",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_sDVIiRe0i",
        order: 17,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_sDVIiRe0i?alt=media&token=eef42e85-2031-4d2f-9bd8-dbe23a13e277",
        title: "KRX금융특강_2",
        id: "FIOFbNqNl6x18uSOQrHZ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_NpONjPELI?alt=media&token=96540a39-ab19-4bbd-aed7-e2c4bff09b4c",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_NpONjPELI",
        order: 2.455078125,
        title: "스포츠 데이",
        id: "FT4inzDsLxXeiv4RZ7LX",
      },
      {
        title: "스포츠데이(배구)",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_7MZC4i6e_",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_7MZC4i6e_?alt=media&token=a8511b3a-be0b-4776-bd40-23b13106997b",
        order: 5.5859375,
        id: "JO8C0T1MPX4JVOqcwmql",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_xWlr-gToK",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_xWlr-gToK?alt=media&token=77ea4f6c-ba93-4f3b-961b-c072d09ade06",
        order: 7.75,
        title: "2019 은목 축제",
        id: "KWOogz7gNTeeRN9L6fSW",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_iTBghXgnZ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_iTBghXgnZ?alt=media&token=a5842936-07a4-4356-9f55-de67cac329f6",
        title: "파티셰 체험2",
        order: 16,
        id: "L6Zr1YMmUrJDpDzPQwSL",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_seu1Mx3Pq",
        title: "진로체험행사_7",
        order: 22,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_seu1Mx3Pq?alt=media&token=c2509cc0-4e76-4a8d-b10d-55b7d41fd912",
        id: "LXRgI3SK1A9ZMVuGmnsk",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl_vizeO1xfk",
        title: "2022 금융정보과 금융보드게임",
        order: 2.414794921875,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_vizeO1xfk?alt=media&token=feacca4f-739e-4b45-a99c-a62a15ee3246",
        id: "NSFa55QrbKzPRFI9GAUI",
      },
      {
        title: "은목 축제 2019",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_RL0poBKqy",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_RL0poBKqy?alt=media&token=6eed9856-c8c5-47bf-a4a9-3d3049363a53",
        order: 8.5,
        id: "P1xwHOyUqpnxML2JcwMA",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl_P7At4J1R5",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_P7At4J1R5?alt=media&token=65f3be6f-6941-491b-ba3b-9b5aa4243feb",
        title: "2022  보건간호과 나이팅게일선서식2",
        order: 2.384765625,
        id: "RijEJB0HTwaCiOz79Y1X",
      },
      {
        title: "2022 은행창구체험",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_EKVbtzAw5?alt=media&token=97215fcd-0acc-4e48-8f54-dee603467b6c",
        path: "images/BOOTH_IMG_w4TXDfxl_EKVbtzAw5",
        order: 2.40673828125,
        id: "UhptUemwpfvwEMznUmuI",
      },
      {
        order: 12,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_GW2doeVip?alt=media&token=1b11e419-a688-4494-98b3-3384d66b4922",
        title: "바텐더 체험",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_GW2doeVip",
        id: "VTyiP5rOu43WKvROZQaG",
      },
      {
        title: "부마고 환경",
        order: 2.34375,
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_akXiqauyX",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_akXiqauyX?alt=media&token=121388cb-ff8f-4395-ac1e-0fe067203bee",
        id: "WkebhcGY0BhhIaCNmZgw",
      },
      {
        title: "학교스포츠클럽(티볼)",
        order: 4.671875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_w2jnu0rZy?alt=media&token=a0e087c3-e070-43e9-909d-fc5325bf8494",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_w2jnu0rZy",
        id: "a4XVpoqy0PdSqAHiSrFD",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_bgiVEZJ5S?alt=media&token=315f4289-f28c-4688-b0c0-e33aa0618e55",
        title: "2022 금융정보과 테블릿이용수업",
        order: 2.4228515625,
        path: "images/BOOTH_IMG_w4TXDfxl_bgiVEZJ5S",
        id: "blOyN8jDK1n6GWqi6Sfv",
      },
      {
        order: 20,
        title: "진로체험행사_4",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_pmSg6_ssw?alt=media&token=6804085c-a264-4080-b6c0-04f30a4ce252",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_pmSg6_ssw",
        id: "dSMy98lRgMO1DygCpTlG",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_0NVZI07rf",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_0NVZI07rf?alt=media&token=c8da3fea-cd72-4ed4-b706-ea2c9c045f44",
        order: 2.578125,
        title: "지역사회기부금행사",
        id: "dsk76Tg55T31TPaqOqC5",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_3HH-TcxCH",
        order: 24,
        title: "보건간호과",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_3HH-TcxCH?alt=media&token=78546c66-73f7-4d9b-80f0-7ebef7b8242a",
        id: "gbpyh0CuwA0QeU4YMXje",
      },
      {
        title: "2022 은행창구체험",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_0YMiNtJ6r?alt=media&token=e0c0d290-56b5-4bd7-99ab-efc088207564",
        path: "images/BOOTH_IMG_w4TXDfxl_0YMiNtJ6r",
        order: 2.3946533203125,
        id: "ndtdsSMBlEGAiLJ9zmd1",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_7IAn-nK7n",
        order: 2.3671875,
        title: "부마고 자랑(졸업생)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_7IAn-nK7n?alt=media&token=6823c317-02c4-4824-a695-f0f0b91d4ef8",
        id: "njZX1Oz4BNvckeemdY7V",
      },
      {
        title: "부산마케팅고등학교 학과 안내",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_IdTqNx-j0?alt=media&token=f42bd160-8268-4954-8851-1d18a562c60c",
        order: 2.25,
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_IdTqNx-j0",
        id: "onUI0nFF7pddM1bELAw2",
      },
      {
        title: "지역사회기부금행사2",
        order: 15,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_eSIugpfPI?alt=media&token=87ca72b2-723b-43fe-9e3b-082b3d307190",
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2_eSIugpfPI",
        id: "qN4Lz6Ea4E3mc8QBJR8p",
      },
      {
        path: "images/BOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2__w2yF1mod",
        order: 23,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl2bgKZs9gF3QFXiojt3j2__w2yF1mod?alt=media&token=c106fe0f-77fc-4896-a447-3d0073f24045",
        title: "보건간호과",
        id: "vWPztBxnCNOiXYA6ZwuE",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_LPJnI1DLr?alt=media&token=1e49ac13-db4f-4514-a229-97617f42a96e",
        title: "2022 스포츠건강관리과 롤러스케이트장 체험",
        order: 2.37158203125,
        path: "images/BOOTH_IMG_w4TXDfxl_LPJnI1DLr",
        id: "wFKVxrIIFd8gdGuKCdKY",
      },
      {
        order: 2.398681640625,
        path: "images/BOOTH_IMG_w4TXDfxl_NywclAAmt",
        title: "2022 은행창구체험",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_w4TXDfxl_NywclAAmt?alt=media&token=f5e8df39-2aaf-4b06-b0ac-a489adeb97f8",
        id: "zeiomom4l0tYlG4bpC6x",
      },
    ],
    videos: [
      {
        url: "https://www.youtube.com/watch?v=XvC6WjbBnPA&t=29s",
        title: "2022 관광서비스과",
        order: 4.266889667822397,
        id: "7nECcimIFPG8BTFBio0d",
      },
      {
        order: 5.102783132635523,
        url: "https://www.youtube.com/watch?v=ac1w0LWxlic&t=1s",
        title: "마케팅카페 & 안전체험관",
        id: "BEGa88jnhJot7L9EXq2k",
      },
      {
        order: 13.374839782714844,
        title: "금융과영상",
        url: "https://www.youtube.com/watch?v=9pF2juuhcCs&t=11s",
        id: "FApKJqUFE6RlqJX6JPpJ",
      },
      {
        title: "2021 신협합격 학생 인터뷰",
        url: "https://www.youtube.com/watch?v=ghuOHPGInEg",
        order: 7.652213841676712,
        id: "GSqhTckgGnnnKQSkzhY7",
      },
      {
        title: "2022 금융정보과",
        url: "https://www.youtube.com/watch?v=jQJURRtvwY4&t=112s",
        order: 4.885813394706929,
        id: "Gi9rBCaqIoJ0uLc4K65T",
      },
      {
        title: "특성화고 학생의 VLOG in 부마고 (지우의 하루)",
        order: 6.988894958049059,
        url: "https://youtu.be/Ch9AQEHYjn0",
        id: "H5K9U6YS9JUIDpEoBPKT",
      },
      {
        order: 4.253280993987573,
        url: "https://youtu.be/sdI3l91Aka4",
        title: "부산마케팅고등학교 홍보 영상",
        id: "Ig4jvJmYFwcoc0NsbwJZ",
      },
      {
        order: 4.052387415198609,
        title: "메인영상",
        url: "https://youtu.be/jxYN4jAqI_s",
        id: "InQ3Vml6SZ5AVAy9Cp4E",
      },
      {
        url: "https://www.youtube.com/watch?v=6wMz2YzSIZc",
        order: 18.1875,
        title: "독서발표대회 최우수",
        id: "J62VoqMw9yy3N5PocBJl",
      },
      {
        url: "https://www.youtube.com/watch?v=vUdtabBYAEo",
        title: "모여봐요~ 금융의 숲",
        order: 6.367075709626079,
        id: "KBlLck5Iamg1DnMhU2Oa",
      },
      {
        title: "2021 NH 농협 입사 강OO",
        order: 9.718062400817871,
        url: "https://www.youtube.com/watch?v=zpwZ_x7JNqA",
        id: "PIAI4KyjIRCt34cghoVW",
      },
      {
        url: "https://www.youtube.com/watch?v=v6_1gC21njA&t=50s",
        title: "관광과에 눈이 돌아가",
        order: 18.5,
        id: "SC2UfqTFRMfnQbDObRSE",
      },
      {
        title: "2022 보건간호과",
        url: "https://www.youtube.com/watch?v=nkdFnoRSs10",
        order: 4.273694004739809,
        id: "bkR8JdXhVyOdVEAHux7D",
      },
      {
        url: "https://youtu.be/Nfffseh8eVk",
        title: "어서 오십시오~ 관광서비스과입니다~",
        order: 7.451387643814087,
        id: "cUOGGN9PBId0le6mUJqi",
      },
      {
        url: "https://www.youtube.com/watch?v=P1EEpCqJjY4&t=42s",
        order: 17.1875,
        title: "우리학교 홍보짱",
        id: "duH8imNeElZEuOppNkoF",
      },
      {
        order: 11.749679565429688,
        title: "2020 한국자산관리공사 고졸 공채 입사 3학년 김수정",
        url: "https://youtu.be/KOIVbZBdiCY",
        id: "eAHNaAhYKhF9CuZ4yLIY",
      },
      {
        order: 7.643978655338287,
        url: "https://youtu.be/qaxky1tc6jE",
        title: "부산마케팅고 최강 취업 프로젝트! 33.3 독서+자격증 졸업인증제",
        id: "h9wdwBlWFsPgbaYT7tTz",
      },
      {
        title: "2022 스포츠건강관리과",
        url: "https://www.youtube.com/watch?v=eOoUlx8r_q4&t=58s",
        order: 4.280498341657221,
        id: "ix7watfxVNmZbCKL0rrc",
      },
      {
        title: "스포츠과가 건강한 이유!",
        url: "https://youtu.be/vHBx3SAsaeQ",
        order: 5.868955676909536,
        id: "lkdCRZKmT1Xqayrfuymj",
      },
      {
        url: "https://www.youtube.com/watch?v=r6ZQQ1-DWY0",
        order: 5.47082898078952,
        title: "2021 나이팅게일 선서식 브이로그",
        id: "q563JqKxNLjcly0OHFCe",
      },
      {
        order: 10.937032699584961,
        url: "https://www.youtube.com/watch?v=FVVsNaZ-X1s",
        title: "스포츠과 영상",
        id: "sYyQ1e26iPVMF5RSCQ2c",
      },
      {
        url: "https://youtu.be/4bk-X0KgESo",
        order: 9.718062400817871,
        title: "2020 한국예탁결제원 고졸 공채 입사 3학년 최O진",
        id: "uJDFPRaOkajGAG9CTass",
      },
      {
        title: "팬카페(지역나눔 행사) & 현장체험 조주",
        order: 6.3272440689615905,
        url: "https://www.youtube.com/watch?v=y7SCHg2IlI4",
        id: "xoeLo2R2a4bxk5rgt2Ag",
      },
    ],
  },
  xOz83nC9: {
    id: "xOz83nC9",
    documents: [
      {
        path: "documents/sMmX2PZiL_B7DYE7_제목을 입력해주세요._1.jpg",
        title: "예비컴과인 Q&A",
        order: 1,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FsMmX2PZiL_B7DYE7_%EC%A0%9C%EB%AA%A9%EC%9D%84%20%EC%9E%85%EB%A0%A5%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94._1.jpg?alt=media&token=f4d5e594-5d05-4f19-8a4d-23a1499110f5",
        id: "0PCQJOYb5RQMe7vqnoiJ",
      },
      {
        title: "[부산컴퓨터과학고등학교], 인텔 AI 공모서 기염",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2Fyizm4--n0_%EB%B6%80%EC%82%B0%EC%9D%BC%EB%B3%B4.pdf?alt=media&token=afa7756d-6f82-40da-bb2c-0a3e719b6462",
        order: 10,
        path: "documents/yizm4--n0_부산일보.pdf",
        id: "1XwMX42Tb2UBmigCyTcl",
      },
      {
        order: 5,
        title: "예비컴과인 Q&A (4)",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2F4ztV_xmIs_HZE9IT_%EC%A0%9C%EB%AA%A9%EC%9D%84%20%EC%9E%85%EB%A0%A5%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94._5.jpg?alt=media&token=50d3fb3d-fa38-422e-b4bb-19202cddf25d",
        path: "documents/4ztV_xmIs_HZE9IT_제목을 입력해주세요._5.jpg",
        id: "5oiwMRpl6Qiv1F0PZYFq",
      },
      {
        order: 3,
        title: "예비컴과인 Q&A (2)",
        path: "documents/EChDsRi5C_KKWGLG_제목을 입력해주세요._3.jpg",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FEChDsRi5C_KKWGLG_%EC%A0%9C%EB%AA%A9%EC%9D%84%20%EC%9E%85%EB%A0%A5%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94._3.jpg?alt=media&token=4d9856f9-3779-42e6-8a59-c60bceb1f9ce",
        id: "9YiGJnC2eabZ1cOEdMD8",
      },
      {
        title: "입학전형요강",
        path: "documents/OUre5B3Ie_2023학년도 부산컴퓨터과학고등학교 입학전형 요강.pdf",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FOUre5B3Ie_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EC%BB%B4%ED%93%A8%ED%84%B0%EA%B3%BC%ED%95%99%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95.pdf?alt=media&token=f9a9ae3c-2a85-4b73-b5ea-625e606056da",
        order: 0.75,
        id: "gg9uxHSEWG3KSr4jQsfb",
      },
      {
        path: "documents/qW4hjdAhr_HL6PPI_제목을 입력해주세요._4.jpg",
        order: 4.5,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FqW4hjdAhr_HL6PPI_%EC%A0%9C%EB%AA%A9%EC%9D%84%20%EC%9E%85%EB%A0%A5%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94._4.jpg?alt=media&token=93f55874-d996-46c5-8837-cb7af6f96c39",
        title: "예비컴과인 Q&A (3)",
        id: "qXgHWbeOTa5aBTerV7Bu",
      },
      {
        order: 2,
        path: "documents/llEK_TDlj_46UWI3_제목을 입력해주세요._2.jpg",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FllEK_TDlj_46UWI3_%EC%A0%9C%EB%AA%A9%EC%9D%84%20%EC%9E%85%EB%A0%A5%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94._2.jpg?alt=media&token=12fe2840-f085-41eb-aabf-2aae05aab3b6",
        title: "예비컴과인 Q&A (1)",
        id: "v0XflVj2IOGBbkTnxJ2P",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/documents%2FVKabCY1F3_2RUTT7_%EC%A0%9C%EB%AA%A9%EC%9D%84%20%EC%9E%85%EB%A0%A5%ED%95%B4%EC%A3%BC%EC%84%B8%EC%9A%94._6.jpg?alt=media&token=11c210bf-085c-4713-866f-3c37949a32dd",
        title: "예비컴과인 Q&A (5)",
        path: "documents/VKabCY1F3_2RUTT7_제목을 입력해주세요._6.jpg",
        order: 6,
        id: "xf2SECUOvsZ8jqz3xRgB",
      },
    ],
    images: [
      {
        title: "부산컴퓨터과학고등학교",
        path: "images/BOOTH_IMG_xOz83nC9_C3P_SX0Db",
        order: 0.7494735717773438,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_C3P_SX0Db?alt=media&token=6d5525a6-41c7-4e6b-9bd1-3b644b11865e",
        id: "1wWwn0n5mJls4iQMxSVN",
      },
      {
        title: "즐거운 컴과고 생활",
        order: 1.780348970554769,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_5aUbsenkF?alt=media&token=8db6e013-7347-4111-bfbb-5aeaf8a2d71f",
        path: "images/BOOTH_IMG_xOz83nC9_5aUbsenkF",
        id: "3x8DUWXh8gQAQaE03vbl",
      },
      {
        path: "images/BOOTH_IMG_xOz83nC9_2hJ1ZicHP",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_2hJ1ZicHP?alt=media&token=a93b85f5-d6d1-410f-b702-4f8282fea5a7",
        order: 5.3123626708984375,
        title: "카카오톡 채널",
        id: "55RVgaP0IrKyQOivP9uY",
      },
      {
        order: 14.5,
        path: "images/BOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_aGnrCZpW8",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_aGnrCZpW8?alt=media&token=c94c4bdb-344f-4b84-bb54-8ac9f1b74bb9",
        title: "예비 후배님들 반가워요~^^",
        id: "5k7UIU3rqhcU5Kv66a7Q",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_CT5ozE5uH?alt=media&token=7a052514-3530-4057-a5e9-0e0ef5d115f8",
        title: "국제교류 프로그램(Global Youth Online Exchange)",
        order: 9,
        path: "images/BOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_CT5ozE5uH",
        id: "ALHYnvzXO7onKwrvxyrv",
      },
      {
        path: "images/BOOTH_IMG_xOz83nC9_dktoL0EIl",
        order: 1.4984212294220924,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_dktoL0EIl?alt=media&token=c7086c88-3917-4902-9d29-43721d8796d0",
        title: "취업 현황",
        id: "BzGuAO8Jfv8Xjlo56Swa",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_XihRNeHgz?alt=media&token=e39faba9-028a-4537-a1bc-e421456b5c86",
        path: "images/BOOTH_IMG_xOz83nC9_XihRNeHgz",
        title: "디자인계열 소개-2",
        order: 1.4872366189956665,
        id: "HkDQ5UNaAyKbAWn8CW09",
      },
      {
        order: 8,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_Iqxg-5ET3?alt=media&token=ac1d5e89-e4e9-42f4-b18f-7a2c42dc5098",
        path: "images/BOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_Iqxg-5ET3",
        title: "부산컴퓨터과학고등학교 홈페이지(QR코드)",
        id: "I2vGYVGjickvgoVMnAAa",
      },
      {
        path: "images/BOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_C4sCKbCp0",
        order: 10,
        title: "싱가포르 ITE College West 방문",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_C4sCKbCp0?alt=media&token=a3b10ff5-7be7-416c-a908-52eadf7778ab",
        id: "Kz4GdIiY2ofvIduY8wcM",
      },
      {
        path: "images/BOOTH_IMG_xOz83nC9_mY0jhIrgj",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_mY0jhIrgj?alt=media&token=ec28edca-6ea8-4aa3-9a6d-a0e69908515e",
        order: 3.9685440063476562,
        title: "컴과고 교통편",
        id: "Pb8OlTOSY07rIQ5UHvAG",
      },
      {
        title: "학교 특색 시설",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_plkw4em30?alt=media&token=40ddc6e8-95f5-46ce-b297-3c1d3fc6ccb1",
        order: 1.4974833279848099,
        path: "images/BOOTH_IMG_xOz83nC9_plkw4em30",
        id: "Po6i9jnfwtYmpqpKoKTM",
      },
      {
        path: "images/BOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_cikyR2Fif",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_cikyR2Fif?alt=media&token=e7a477da-e2d0-4c5d-9cbb-1709d5a03583",
        order: 13,
        title: "인텔(Intel) AI LAB",
        id: "QNEZ6j8PCMp837BS10IJ",
      },
      {
        path: "images/BOOTH_IMG_xOz83nC9__QG_2RoB0",
        title: "뉴스 컴과고",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9__QG_2RoB0?alt=media&token=301ea2c6-8dda-4159-8101-b13c8972752c",
        order: 1.1242103576660156,
        id: "YZGLQptYIWFxhFSoaYrE",
      },
      {
        title: "IT계열 소개",
        path: "images/BOOTH_IMG_xOz83nC9_fUYU0gl1Y",
        order: 1.4052629470825195,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_fUYU0gl1Y?alt=media&token=271f69cc-e618-4206-8645-a42e87174880",
        id: "ZQ4YfVt2JHXrJvcIAHXc",
      },
      {
        order: 1.3115787506103516,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_to0LiHYug?alt=media&token=ed1b6d74-4b8a-4f95-8cbf-388fd7cdd94d",
        title: "부산기능경기대회 수상",
        path: "images/BOOTH_IMG_xOz83nC9_to0LiHYug",
        id: "ZqvGBIvWLKzUBjm70OOT",
      },
      {
        order: 1.4521050453186035,
        path: "images/BOOTH_IMG_xOz83nC9_C8j_ZQPK8",
        title: "IT계열 소개-2",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_C8j_ZQPK8?alt=media&token=f3986891-b29d-4f7c-8bc1-271f70266a6b",
        id: "hgBit2mR3mCPuVp0ss5G",
      },
      {
        title: "비즈니스계열 소개-2",
        path: "images/BOOTH_IMG_xOz83nC9_IA6rAksMd",
        order: 1.4960195124149323,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_IA6rAksMd?alt=media&token=3f4b9e66-34f6-4880-a09f-14d72d6e48a5",
        id: "jGJd5qvHr0UL2nWXGJzh",
      },
      {
        path: "images/BOOTH_IMG_xOz83nC9_6ppPvXDqP",
        order: 1.493091881275177,
        title: "비즈니스계열 소개",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_6ppPvXDqP?alt=media&token=ace14c88-40cc-4fd8-82aa-93975eeb6f5c",
        id: "p2ev62thuERDceBIN1AD",
      },
      {
        order: 11,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_yG265FCYK?alt=media&token=33cc7909-2df5-4e19-8aab-0267e10b45ce",
        title: "동의과학대학교 AI · 사물인터넷 교육협력 협약(MOU)",
        path: "images/BOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_yG265FCYK",
        id: "rAozC0nxV8BNJ4JdiuYv",
      },
      {
        title: "진학 현황",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_zapoVkJ5U?alt=media&token=f09df936-92ea-448e-aad2-bff59872ccf4",
        path: "images/BOOTH_IMG_xOz83nC9_zapoVkJ5U",
        order: 1.4988901801407337,
        id: "tyFs649hoS5Mn8MEW2A4",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_wZ0D_F3vb?alt=media&token=af38aa05-bfee-4fb6-9a23-faa893e4210a",
        title: "문화 예술 동아리",
        order: 2.0618077609688044,
        path: "images/BOOTH_IMG_xOz83nC9_wZ0D_F3vb",
        id: "vwODRyEcOV2LayCZTwUK",
      },
      {
        title: "디자인계열 소개",
        order: 1.4755260944366455,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9_7HqTnXhL8?alt=media&token=dfbcded8-120c-4e4d-9bd6-1c62d3192617",
        path: "images/BOOTH_IMG_xOz83nC9_7HqTnXhL8",
        id: "wUlZJNq52KkbI4lFIsVM",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_xXdgH4GSv?alt=media&token=1a443311-efb1-404d-8a1c-1cf8facba89f",
        title: "2018 대한민국 인재상 수상",
        order: 12,
        path: "images/BOOTH_IMG_xOz83nC9tfPnQxpcbPOqBRPX3Gv1_xXdgH4GSv",
        id: "xgQaqlMrhWo2rsGbmJff",
      },
    ],
    videos: [
      {
        title: "[부산컴퓨터과학고등학교]연구학교-고교학점제 소개 영상",
        url: "https://youtu.be/KyPFz3zDMbk",
        order: 0.40673828125,
        id: "2SC8VDJc1BhZwIlKmqsD",
      },
      {
        order: 2.6953125,
        title: "[부산컴퓨터과학고등학교] AI Glass",
        url: "https://www.youtube.com/watch?v=r7Q7fElJSmA&t=9s",
        id: "3chwuDa5JpVzQYegIvHS",
      },
      {
        title: "[부산컴퓨터과학고등학교] 체육대회 드론샷",
        order: 8,
        url: "https://youtu.be/vy6vC3EvKP4",
        id: "9jOcaWkOWjFTdejCwY8k",
      },
      {
        url: "https://youtu.be/PhAmOHN9uQY",
        title: "[부산컴퓨터과학고등학교]참치송 패러디 영상",
        order: 2.796875,
        id: "PJJlBGIChwHZmAayh8pf",
      },
      {
        order: 0.40289306640625,
        url: "https://youtu.be/kuuEsqEkxHc",
        title: "[부산컴퓨터과학고등학교]22학년도 인공지능 영상",
        id: "Ut9mOlDJmk0vpAmQ2Bet",
      },
      {
        title: "[부산컴퓨터과학고등학교]22학년도 컴과고 비즈쿨 영상",
        order: 2.37890625,
        url: "https://youtu.be/dDDt2xuKNgI",
        id: "eVbwlturpdIKyFtjeV8p",
      },
      {
        title: "메인영상",
        url: "https://youtu.be/nOs9DHVnL6A",
        order: 0.400970458984375,
        id: "t6sESl8aE6jTPSwIwxtZ",
      },
      {
        url: "https://youtu.be/hyNJKR6aSzQ",
        title: "[부산컴퓨터과학고등학교]22년도 홍보 영상",
        order: 0.391357421875,
        id: "wxo0H5OAsMbiLDXMr5ls",
      },
    ],
  },
  xrjwWDOM: {
    id: "xrjwWDOM",
    documents: [
      {
        order: 1,
        title: "입학전형요강",
        path: "documents/PPmHXiBwA_(최종) 2023학년도 입학전형 요강(부산정보관광고).hwp",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FPPmHXiBwA_(%EC%B5%9C%EC%A2%85)%202023%ED%95%99%EB%85%84%EB%8F%84%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95(%EB%B6%80%EC%82%B0%EC%A0%95%EB%B3%B4%EA%B4%80%EA%B4%91%EA%B3%A0).hwp?alt=media&token=6286e717-4550-46f2-8182-9621354ab4aa",
        id: "3IbhjKkA58CVX3hRiQWO",
      },
    ],
    images: [
      {
        order: 66,
        title: "BNK와 함께하는 농구교실",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_u7M6XP6JG?alt=media&token=8983818a-c679-4147-9d95-e96962d506f4",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_u7M6XP6JG",
        id: "0CHu3O96onSbFeCxtreb",
      },
      {
        order: 43,
        title: "외부체험학습",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_YE3FVWgmb",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_YE3FVWgmb?alt=media&token=29fbe4a4-fe4b-4055-aa21-7878740a7e1e",
        id: "27ceLxCAaUn0EZU0FVtG",
      },
      {
        title: "학교 전경",
        order: 6,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_8DSt4XDca",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_8DSt4XDca?alt=media&token=32798cce-88e9-4ec5-8235-9a6b0f070596",
        id: "4TFNI99c7QcrkJjZDeVi",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_d29brCD4X?alt=media&token=effbc83e-f749-468a-97e8-c9691120974b",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_d29brCD4X",
        title: "BNK와 함께하는 농구교실",
        order: 68,
        id: "4mnm0hez5z66ZRlVhqAp",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_JYBrwFD3t?alt=media&token=e66e5a6b-6325-417e-9594-481823cc0d00",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_JYBrwFD3t",
        title: "전공체험캠프",
        order: 17,
        id: "5XeQUWGg7vq8magHFP6Q",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0P9B4G7q8",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0P9B4G7q8?alt=media&token=84b3f0f0-87e5-43df-95c6-f0b80d438c46",
        order: 24,
        title: "전공체험캠프",
        id: "8I7H2riUAD2hAsBHkh8l",
      },
      {
        order: 65,
        title: "BNK와 함께하는 농구교실",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_WrDRHkpMN",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_WrDRHkpMN?alt=media&token=285d7a17-0cac-45c7-9124-725ee6696788",
        id: "8RCb998500pMoxDz54qW",
      },
      {
        title: "외부체험학습",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ulEb3agYO",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ulEb3agYO?alt=media&token=4a7bfda8-623e-401f-84c5-24ce56d320eb",
        order: 42,
        id: "8RMejfAQze44m9K366NJ",
      },
      {
        order: 28,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ueuBocwCh",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ueuBocwCh?alt=media&token=65735557-fddb-468b-a9ce-2d64a77e461c",
        title: "졸업실무경진대회",
        id: "9i4NDi7f06mXtJTHKGBy",
      },
      {
        title: "전공체험캠프",
        order: 10,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_kosDD4vlB?alt=media&token=22efad8f-e50a-4f4d-99ee-dd051139e2a3",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_kosDD4vlB",
        id: "9mu8HhUASzrzuMS3Mnzd",
      },
      {
        order: 33,
        title: "졸업실무경진대회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ReGkbU_Y4?alt=media&token=03768f24-fe2f-4f68-ab57-0094d5886523",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ReGkbU_Y4",
        id: "Av3krjWsn1upzrGufAbC",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_DTUMEOdEn?alt=media&token=ac0fce8c-fabc-4ac4-9abe-b75754362f0b",
        order: 63,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_DTUMEOdEn",
        title: "외부체험학습",
        id: "B299c05uEESboP6mTpk0",
      },
      {
        order: 55,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_uxsjuc-9s",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_uxsjuc-9s?alt=media&token=9a41fbd6-881b-42cd-9dde-a35f6342289f",
        title: "외부체험학습",
        id: "BOkgk5dJfXpfsw9KFn1L",
      },
      {
        order: 8.9375,
        title: "전국 여고생 관광서비스경진대회 수상",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_3F9xIzgs1?alt=media&token=7fd75826-1ec4-4699-99e1-592939b9353c",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_3F9xIzgs1",
        id: "Co08sAiRr9Ggi9bxGKsO",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_IyIvNp3sX",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_IyIvNp3sX?alt=media&token=578a2fd6-a3b3-4331-877e-8bf044f97b80",
        order: 37,
        title: "졸업실무경진대회",
        id: "Damydrwesz260myaJJHH",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_9AVFwJbug",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_9AVFwJbug?alt=media&token=5e05ac26-9b1d-47e2-b738-8edb8e2a9d7f",
        title: "전공체험캠프",
        order: 14,
        id: "G11hNkCmhGhU8php9tUQ",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_it4hbLoyI",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_it4hbLoyI?alt=media&token=f0c5aabf-6ad8-498f-afdd-ebb6e14c77da",
        order: 19,
        title: "전공체험캠프",
        id: "G8yhouJjPL32jpDZ2jsq",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0W3I-W45A",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0W3I-W45A?alt=media&token=e732f261-7d7f-4831-be46-29a409d42fe3",
        order: 74,
        title: "비즈쿨 BIZCOOL",
        id: "GJKI4kJeoErMIEOC4rwj",
      },
      {
        order: 5.1875,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_M4xeRmuw6",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_M4xeRmuw6?alt=media&token=97e4a8d2-25d3-4bbd-a0de-88328bff68e8",
        title: "학교캐릭터",
        id: "GeGOEbzVZIooP36Oq27p",
      },
      {
        title: "전공체험캠프",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_R0HM49NO2",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_R0HM49NO2?alt=media&token=16fcb7f9-f56c-4ed9-a46f-054f0e936b43",
        order: 11,
        id: "HFarJytD79DhfJWLLaGp",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOM_NiAGsaQKY",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOM_NiAGsaQKY?alt=media&token=00f0ac33-4b78-46c0-ac7b-683c6e363df6",
        title: "신입생 홍보 리플렛-1",
        order: 2.265625,
        id: "I69PR30SyIjPngzMAphZ",
      },
      {
        title: "비즈쿨 BIZCOOL",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_7487Bf9dR",
        order: 73,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_7487Bf9dR?alt=media&token=2744a289-ef76-4a5b-9245-84bbd53ce0ee",
        id: "IqJK9whQ89bHTpIzzH16",
      },
      {
        title: "졸업실무경진대회",
        order: 38,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_psQGzaYpu?alt=media&token=333a731a-63d9-4111-85b2-b61955efcb3e",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_psQGzaYpu",
        id: "J2zOROORtoafUkiMgk1Z",
      },
      {
        order: 32,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_s7cWrEz5O?alt=media&token=484ec071-3956-4bfc-9972-9decabca1925",
        title: "졸업실무경진대회",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_s7cWrEz5O",
        id: "K1ljttHFbqLURcYdpF5i",
      },
      {
        title: "졸업실무경진대회",
        order: 30,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_L70STXWVX?alt=media&token=7417ff32-c24a-48f8-a11b-9b3e3a4b91ab",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_L70STXWVX",
        id: "K7T8xRRJavP8G8HaVpg4",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0U28Hz6Ex",
        title: "호텔경영과",
        order: 7.875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0U28Hz6Ex?alt=media&token=a6ea01ac-1448-42ce-8f74-5218edd930bb",
        id: "KQgbvA3LkTV0XykvCgWO",
      },
      {
        title: "BNK와 함께하는 농구교실",
        order: 69,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_vVHIqFPd-",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_vVHIqFPd-?alt=media&token=d88c91c2-5ca0-4fc3-a4d3-65f49e872f12",
        id: "KbVdS0Fna8iA2dt97G7X",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_acrPe3A-v?alt=media&token=72e8daa2-93b1-45cb-98a4-4cb1cc8a1751",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_acrPe3A-v",
        title: "졸업실무경진대회",
        order: 27,
        id: "LNSm6AVQT6Fe0NgOAFw5",
      },
      {
        order: 39,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_5keXut-s2?alt=media&token=64b51ce0-7e34-4cca-9fe4-0c6e3b7ad39a",
        title: "졸업실무경진대회",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_5keXut-s2",
        id: "LPtKq4TjEaKsGDAhTEIP",
      },
      {
        title: "외부체험학습",
        order: 64,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_5_bGIGnGY",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_5_bGIGnGY?alt=media&token=efc9b827-7508-4440-9d48-65a4733afdc2",
        id: "MTUxzEKpRvgT6znrJ6N4",
      },
      {
        title: "전공체험캠프",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Q8OeR4STy",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Q8OeR4STy?alt=media&token=a7f01bde-54a5-4f13-9b30-801adccc29da",
        order: 15,
        id: "Mdu10JriOCGFXYyI8x3G",
      },
      {
        title: "졸업실무경진대회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_vVVu3tv7c?alt=media&token=c3a5d189-215c-4a24-951e-b56573faee2b",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_vVVu3tv7c",
        order: 29,
        id: "MrgIDbTqXli4vfiRfPt4",
      },
      {
        order: 5.59375,
        title: "졸업실무경진대회 영상",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_boGqFmKez",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_boGqFmKez?alt=media&token=eac2e199-6c64-42b6-9e48-1de2b222ba75",
        id: "NtFVw8sllZht7lvLUHrQ",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_KWpQqPrU6?alt=media&token=8371572f-39fd-4017-b3b7-bd9cc8837a09",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_KWpQqPrU6",
        order: 72,
        title: "비즈쿨 BIZCOOL",
        id: "OACL0ZqRWnrmZhxj0URT",
      },
      {
        order: 22,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_bnfofvCvV",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_bnfofvCvV?alt=media&token=c8b6acf3-9a5c-4de8-afa1-10e1120feffe",
        title: "전공체험캠프",
        id: "PC9looZvQYjYAUXGRPdx",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_O__e-eUc2",
        order: 31,
        title: "졸업실무경진대회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_O__e-eUc2?alt=media&token=577e0906-1470-484b-b0e3-18d6561da34e",
        id: "QPdnmHVn5LcnVk2Q5v7d",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_HHAY6i9Od?alt=media&token=ce682ac6-48a1-472a-955f-f3b2df950ba9",
        title: "외부체험학습",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_HHAY6i9Od",
        order: 51,
        id: "QqZd4kLXJRqQZ1oBXlIZ",
      },
      {
        order: 52,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_OHSlnIbSq",
        title: "외부체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_OHSlnIbSq?alt=media&token=f9f3dbf3-9641-4c43-a84f-99fcdbf7f21f",
        id: "R8BIW9EoUJbzZiKofu5w",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_hSIG7-v9V",
        title: "외부체험학습",
        order: 62,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_hSIG7-v9V?alt=media&token=ab742d2f-4580-40dd-9659-a1b4e1950289",
        id: "U2c0FNyc0fcHKn56k4Fn",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_rcgqTJYdm",
        title: "외부체험학습",
        order: 48,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_rcgqTJYdm?alt=media&token=de3b9cec-dd97-49f0-8a01-93ac132061a9",
        id: "VPVVvPvljnCQEEJs12hf",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_zsgRg40NB",
        order: 16,
        title: "전공체험캠프",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_zsgRg40NB?alt=media&token=7a734997-8c17-48bb-91b4-2d60e1597b43",
        id: "VSczIj8qN9p8s8lywLmP",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_t6UBioRtR",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_t6UBioRtR?alt=media&token=7da4e9e6-f881-43e5-af11-ae048323486a",
        title: "전공체험캠프",
        order: 21,
        id: "VWgT4iNiLQlOieKE2yvb",
      },
      {
        order: 18,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_TwAw1eslp",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_TwAw1eslp?alt=media&token=e598397c-afed-4458-a299-71886d00ca59",
        title: "전공체험캠프",
        id: "Weg3HmbeABoVosDaEe8k",
      },
      {
        order: 61,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Sdg-OAllI?alt=media&token=2eab0ce3-3800-4924-aa33-ead5a1aaae06",
        title: "외부체험학습",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Sdg-OAllI",
        id: "WpnkBmbqxXzxpPBNKKY2",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_-6Gc3u7Mj?alt=media&token=15b3e695-98e0-403d-b071-6ff95d6eb910",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_-6Gc3u7Mj",
        order: 50,
        title: "외부체험학습",
        id: "X8Zy9jPBDcLK3gnmhZ7V",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0NkM0HgVq?alt=media&token=fcd86d7b-ed18-463f-bfcd-833016c61a38",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_0NkM0HgVq",
        order: 12,
        title: "전공체험캠프",
        id: "YUvRmLqLiA0ArOpHyIxD",
      },
      {
        order: 54,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2__gAFEgw0i?alt=media&token=11d70d25-f7ae-48ab-a975-6797eff174bb",
        title: "외부체험학습",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2__gAFEgw0i",
        id: "YWiSsOb9zh88Lj5xTHt9",
      },
      {
        title: "비즈쿨 BIZCOOL",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_R2BBbp5DR?alt=media&token=72ea1a5b-25d0-4f89-8a5b-491e073364a1",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_R2BBbp5DR",
        order: 76,
        id: "ZpeyugzJrgdP0DCDzJ3x",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_1uL0jvOJA",
        title: "졸업실무경진대회",
        order: 35,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_1uL0jvOJA?alt=media&token=836940a4-0865-434d-861c-714263469ecc",
        id: "a8SlB6R5n0qFltsnvg5f",
      },
      {
        title: "입시홍포 리플렛-2",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOM_RmHL5UC4r?alt=media&token=f9c16ead-8086-4537-999d-997c6e32188b",
        order: 2.71875,
        path: "images/BOOTH_IMG_xrjwWDOM_RmHL5UC4r",
        id: "a9RnR0PjFWiuFRrSW5LE",
      },
      {
        title: "졸업실무경진대회",
        order: 40,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Mtft2oT6O",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Mtft2oT6O?alt=media&token=b18d7235-839d-49b5-bfaf-e94157732555",
        id: "aOhgdJ5UFMsIGOgYdCC7",
      },
      {
        order: 1.8125,
        path: "images/BOOTH_IMG_xrjwWDOM_KsBPUBl4Z",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOM_KsBPUBl4Z?alt=media&token=6aed9cc9-fb1d-4673-8926-3cd1d28329c2",
        title: "입시홍보 포스터",
        id: "avqr8QX5gmGCwoTlDgXF",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ICs3pDyl0",
        title: "졸업실무경진대회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ICs3pDyl0?alt=media&token=227c4439-77ac-4c4b-9fe9-fa0605269cf5",
        order: 36,
        id: "bBWl2Il1k0M7fQkKo2gR",
      },
      {
        order: 57,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_8SRI4EB2o?alt=media&token=3a26d6c9-a10b-4eb8-8013-3a181ccabe0f",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_8SRI4EB2o",
        title: "외부체험학습",
        id: "cgwBZR2F8KE8NXgDA8Cd",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_fyYey1efo?alt=media&token=036b8864-e652-4ff5-b26a-b9d657b62ba5",
        order: 49,
        title: "외부체험학습",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_fyYey1efo",
        id: "d6VDA8F1SKGvEI057TFW",
      },
      {
        title: "전공체험캠프",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_RVDFDuYup",
        order: 10,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_RVDFDuYup?alt=media&token=18e855e2-261b-438b-b515-b3cb51f9c979",
        id: "eHWMrn8aK8w2IXKL1bZa",
      },
      {
        order: 41,
        title: "졸업실무경진대회",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_9_NqLlVD9?alt=media&token=88f49fc8-2ee9-4bc9-a02f-f9ce6f591ef9",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_9_NqLlVD9",
        id: "h2WmD1glwYd97zPCMLDG",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_yZTL58Ozx?alt=media&token=67934c64-349d-4f7e-86b9-341c9d0e0dc8",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_yZTL58Ozx",
        order: 46,
        title: "외부체험학습",
        id: "jxLgKEqjEOmuMESjJA8w",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_yZbRa0b9I",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_yZbRa0b9I?alt=media&token=20462ef4-1b48-4168-b7d0-b5e104470fcd",
        title: "비즈쿨 BIZCOOL",
        order: 71,
        id: "lOGKgP9z9YGmFySDDkFN",
      },
      {
        title: "외부체험학습",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_uIfh6ac9q",
        order: 45,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_uIfh6ac9q?alt=media&token=e38390e2-247d-4207-bfdc-6e87f268fe2f",
        id: "lzQYwp6zsSUu9yZmV4RE",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_6Phevk7fy",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_6Phevk7fy?alt=media&token=22b85f51-9d94-48b8-a155-4bd47259f7d9",
        order: 70,
        title: "비즈쿨 BIZCOOL",
        id: "mJASiBzuzeXSArjdLOws",
      },
      {
        order: 58,
        title: "외부체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_bteGkhf2O?alt=media&token=d7835b3a-a1b1-4d5a-b6ae-3ca5183a962f",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_bteGkhf2O",
        id: "ooG4TWlZlIq61AVAwYOt",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_87KUH_cCd",
        order: 34,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_87KUH_cCd?alt=media&token=825bb5a4-ca43-442b-a310-88661ae6f682",
        title: "졸업실무경진대회",
        id: "ozgVB88D3dQEXwJMw35W",
      },
      {
        order: 13,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ax5JBH5My",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ax5JBH5My?alt=media&token=8fd7ba08-8aa1-4fe6-95ca-ae34e3e0d2fa",
        title: "전공체험캠프",
        id: "pGvbpZbfad3ULkC5Zpue",
      },
      {
        order: 26,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_dUS5YB0jx?alt=media&token=46a46714-537c-4948-8267-b11d6654441a",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_dUS5YB0jx",
        title: "졸업실무경진대회",
        id: "pzNSzPLpc7qUXtpI0eVK",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_hdhJO6yHg?alt=media&token=81cdae5e-0130-4039-bc62-e990a9f67179",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_hdhJO6yHg",
        order: 25,
        title: "졸업실무경진대회",
        id: "qOw4PY8m5pf2eRfDIuUC",
      },
      {
        title: "외부체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ZQOA40IRA?alt=media&token=a966b9f8-2744-4986-82d0-dc0297fb168d",
        order: 56,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_ZQOA40IRA",
        id: "qZxV4ikSZXvjwcUkQjQl",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_9QcjOCRFL?alt=media&token=e10ab6be-2a94-4396-ac62-a6adf5d26cfb",
        title: "외부체험학습",
        order: 53,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_9QcjOCRFL",
        id: "rIKZdlBhj9EFZ0XYncOs",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_GcJUpHsMo",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_GcJUpHsMo?alt=media&token=2ccb43f6-e316-4aac-8b34-bdf54fdc05ec",
        title: "비즈쿨 BIZCOOL",
        order: 75.5,
        id: "rL1Kn860cQxF4Np5wJLi",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_st2gK4b29?alt=media&token=2bf83b86-6d92-4663-a7fd-0ff0eeebaf46",
        order: 60,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_st2gK4b29",
        title: "외부체험학습",
        id: "rMNeK0LjTDdCeyfyfCLs",
      },
      {
        order: 44,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_BJY4jVKyw",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_BJY4jVKyw?alt=media&token=e3bd4c23-4427-46ea-9f56-9736ca81dd20",
        title: "외부체험학습",
        id: "riUqOz6LlUm1NVBlviGF",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_WkJRmElxe",
        order: 47,
        title: "외부체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_WkJRmElxe?alt=media&token=5d21996f-8cf7-4d97-a9b4-78ca81418587",
        id: "s9L84fm9rEW9EeIBczSV",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_XeUSe9_x1",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_XeUSe9_x1?alt=media&token=7c2e9673-14f4-4666-8ac6-c6c72ecfd8df",
        order: 67,
        title: "BNK와 함께하는 농구교실",
        id: "sgs2Smp75jQ3OI8Valds",
      },
      {
        order: 20,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Ir63ay0-6",
        title: "전공체험캠프",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_Ir63ay0-6?alt=media&token=e18778b2-0083-481e-b6e3-9702ec2c8177",
        id: "x4idYCIrQBZPnodHTlgj",
      },
      {
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_cIMghmKW1",
        order: 59,
        title: "외부체험학습",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_cIMghmKW1?alt=media&token=650874a4-fedb-4811-81ed-6b134687d294",
        id: "xNJ81KC5K7J6JawPpWSK",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_1FNmo1fZY?alt=media&token=447fe0d2-5696-44eb-938e-f4b9de672d56",
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_1FNmo1fZY",
        title: "전공체험캠프",
        order: 23,
        id: "y3UepzVHNz53WyKIZlil",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_OzYRWOBDo?alt=media&token=35a88149-1164-4403-9355-d853da786e14",
        order: 7,
        path: "images/BOOTH_IMG_xrjwWDOMy4dKlfeYQpCgdupaFFy2_OzYRWOBDo",
        title: "호텔조리과",
        id: "zqyxfwUzhtsRzgmhuHYY",
      },
    ],
    videos: [
      {
        order: 8,
        title: "6시내고향 영상",
        url: "https://youtu.be/bWVVFsS2p9I",
        id: "9v61a9B4W5DRKKnQyjqb",
      },
      {
        url: "https://youtu.be/jyCM4oqpJYU",
        title: "메인영상",
        order: 1,
        id: "EH5sER2igdXCY90ZTauO",
      },
      {
        title: "댄스부(D.T.S.)영상",
        order: 4.75,
        url: "https://youtu.be/0-x0iiRf4oM",
        id: "FVJ370SI6nIDOiLRxbRW",
      },
      {
        title: "부산 교육 뉴스 영상",
        url: "https://youtu.be/lmddDL56Hg8",
        order: 4.375,
        id: "Fu759O2FXBTWFnsB4w2L",
      },
      {
        title: "부산정보관광고등학교 공연",
        url: "https://www.youtube.com/watch?v=hx585PaCxiI&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=12",
        order: 11,
        id: "THmvtY6e75EOnwnnu9uG",
      },
      {
        order: 7,
        url: "https://youtu.be/3OrpqDAuafc",
        title: "어울림 경진대회 영상",
        id: "YozjCMQwXFcb1u1gN8D3",
      },
      {
        title: "학교생활 및 수업 V-Log",
        order: 3.5,
        url: "https://www.youtube.com/watch?v=BwqNjCJ7ioY",
        id: "bAl6tUDqxENWnIJ4rSzM",
      },
      {
        url: "https://youtu.be/womYcp3Rz7g",
        title: "MBC 부라보 영상",
        order: 6,
        id: "bDVUQJ3BWDJjfg3GtGl9",
      },
      {
        url: "https://youtu.be/FBZo1RBN-WY",
        title: "진로 직업 체험 영상",
        order: 3,
        id: "hOQu6ly54N6jzt2IiyNK",
      },
      {
        title: "인터뷰 영상",
        order: 4,
        url: "https://youtu.be/nWIc4F-JuZM",
        id: "m3hc4J58cngjQBXqiW6v",
      },
      {
        title: "코로나극복 격려송 프로젝트",
        url: "https://www.youtube.com/watch?v=eIv1eYLvagc",
        order: 3.75,
        id: "pDv1w20CSts91SQOwXuv",
      },
      {
        title: "홍보 영상",
        url: "https://youtu.be/2pI9LWyRb4E",
        order: 2,
        id: "tQY4iUcOuPp4NL9tOpyM",
      },
    ],
  },
  y38CucaG: {
    id: "y38CucaG",
    documents: [
      {
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/documents%2FMj6uGWWKp_2023%ED%95%99%EB%85%84%EB%8F%84%20%EB%B6%80%EC%82%B0%EC%97%AC%EC%9E%90%EC%83%81%EC%97%85%EA%B3%A0%EB%93%B1%ED%95%99%EA%B5%90%20%EC%9E%85%ED%95%99%EC%A0%84%ED%98%95%20%EC%9A%94%EA%B0%95%20.hwp?alt=media&token=595b278e-fdee-4624-9659-8b80499b5cf8",
        title: "2023학년도 부산여자상업고등학교 입학전형요강",
        order: 1,
        path: "documents/Mj6uGWWKp_2023학년도 부산여자상업고등학교 입학전형 요강 .hwp",
        id: "lezOiQOz0l19qB7KXq5s",
      },
    ],
    images: [
      {
        order: 5.5,
        path: "images/BOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_ahyJ-jUqu",
        title: "마케팅사무행정학과 소개",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_ahyJ-jUqu?alt=media&token=94894480-5817-4326-8c56-ec246b27d0af",
        id: "8gSqoSXNHIErAY869v0R",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_ZM8Xq6JGe?alt=media&token=d23277b4-88ed-4616-9e3e-625a5e499c74",
        title: "부산여상 취업 활동",
        order: 7.75,
        path: "images/BOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_ZM8Xq6JGe",
        id: "FHZhnndIrctnL8NmuhSg",
      },
      {
        path: "images/BOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_Q-ja0eXGZ",
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_Q-ja0eXGZ?alt=media&token=a73b00d1-f8bd-4809-ab90-c1d20dd84b7b",
        title: "부산여상 유튜브",
        order: 9,
        id: "JUlH0XpxxaVEUclFrgt5",
      },
      {
        title: "부산여자상업고등학교 진학 현황",
        path: "images/BOOTH_IMG_y38CucaG_1UO2EI44C",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_y38CucaG_1UO2EI44C?alt=media&token=bd91bc86-cbff-4a0e-97a8-c40f61e79ef7",
        order: 8,
        id: "WgQD0AjM3z0AgFhVDpz0",
      },
      {
        title: "부산여자상업고등학교 홍보 포스터",
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_y38CucaG_Wl64PjVI_?alt=media&token=a503f9bd-5432-4b1f-a447-b3fc8ac8ea3e",
        order: 2.75,
        path: "images/BOOTH_IMG_y38CucaG_Wl64PjVI_",
        id: "giflK6ucSo9kwA0P2zbA",
      },
      {
        path: "images/BOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2__uZcbLIvR",
        order: 4.125,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2__uZcbLIvR?alt=media&token=79fc775f-78a3-479d-9a31-18764f4a22fb",
        title: "무역금융학과 소개",
        id: "kh7KJyjO5w40Z6PA7aVD",
      },
      {
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_Hemw7SJGl?alt=media&token=6dfd0698-6ce8-4d26-bdc0-f82d59132384",
        path: "images/BOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_Hemw7SJGl",
        order: 8,
        title: "부산여상 인스타",
        id: "m6K4lMgEztFYp53UVpAA",
      },
      {
        order: 5.875,
        url: "https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/images%2FBOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_Xt6SbsMhe?alt=media&token=5679290c-1793-4bb1-9800-e9326b06d4ea",
        path: "images/BOOTH_IMG_y38CucaGpzbM5HO8y1VlLv85VNI2_Xt6SbsMhe",
        title: "IT 사무행정학과 소개",
        id: "qXZCy1HfP6ISrf5brlxT",
      },
      {
        title: "부산여자상업고등학교 입학 전형 요강",
        path: "images/BOOTH_IMG_y38CucaG_Ydswe5c7j",
        order: 9,
        url: "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/images%2FBOOTH_IMG_y38CucaG_Ydswe5c7j?alt=media&token=c7158a80-e5ab-4658-9055-c9ab46997a20",
        id: "rYLhLsgzDfS3c6KVIi1w",
      },
    ],
    videos: [
      {
        order: 8.5,
        title: "드론으로 촬영한 부산여상~!!",
        url: "https://youtu.be/28lMUWK6wVA",
        id: "3LWnmIAchmFLoZvYMsTz",
      },
      {
        title: "부산여자상업고등학교의 매력!!",
        url: "https://youtu.be/U6avww56Eig",
        order: 5,
        id: "3Yx8s4zPCKf8XnMas9iG",
      },
      {
        order: 3.5,
        title: "다시여기부산여상!!",
        url: "https://youtu.be/1Lse765Jxzo",
        id: "ABYCM8QQ0KXEfBiCqkw5",
      },
      {
        url: "https://www.youtube.com/watch?v=8okcestASgA",
        order: 1.59375,
        title: "2022년 5월 보은의 달 행사 오징어게임 드론영상",
        id: "ITiJK9GDp4QPTNgtaeEc",
      },
      {
        title: "2022년 부산여상 가을 해연축제 커버댄스 출품작 ♥",
        order: 16,
        url: "https://www.youtube.com/watch?v=uNgfxOuAZLo",
        id: "MBWGCb9nNB4wZC6gHSlP",
      },
      {
        order: 6,
        url: "https://youtu.be/FQB9IWn2QFQ",
        title: "상업특공대2",
        id: "MLzbymf2ST1XFvzEuDI8",
      },
      {
        url: "https://www.youtube.com/watch?v=KPNIRkS68EI&list=PLKw0MX8TxWWJ2VjgjuI2QmAyANOzmHg_X&index=1",
        title: "부산여자상업고등학교 공연",
        order: 11.5,
        id: "NNWEwyhpKLcumkAb95Vv",
      },
      {
        title: "부산여상 고교학점제 진로특강 ",
        url: "https://www.youtube.com/watch?v=m9MKAHFuiuc",
        order: 1.75,
        id: "RO0L6r740y4dHxZ4CCRK",
      },
      {
        url: "https://www.youtube.com/watch?v=Ou9KdXqTu8A",
        title: "2022년 졸업생 장금상선 취업생 김민서",
        order: 0.5625,
        id: "RPlMiYFuEqYnxQnF7bkH",
      },
      {
        order: 1.15625,
        url: "https://www.youtube.com/watch?v=_3j9CJLYzy0",
        title: "2022년 제2회 중소기업 알리기 UCC 공모전 부산여상 3학년 최우수상",
        id: "Rers6QJkEt0cgV0mN8fU",
      },
      {
        title: "부산여상 대학진학 졸업생과의 인터뷰",
        url: "https://www.youtube.com/watch?v=xYaLQrJiJBM",
        order: 0.375,
        id: "S2XcVuxdVoEoZ1G3VEGY",
      },
      {
        title: "2022년 부산여상 가을 해연축제 커버댄스 출품작 ♥",
        order: 15.5,
        url: "https://www.youtube.com/watch?v=uNgfxOuAZLo",
        id: "SYjsSg6U9PpBkOshkE0b",
      },
      {
        url: "https://www.youtube.com/watch?v=7ivM0FEqu5U",
        order: 1.609375,
        title: "2022년 5월 오징어게임",
        id: "VbB0G3dgXUqnv05oRIN0",
      },
      {
        title: "상업특공대(부산여자상업고등학교의 탐방을 시작해볼까요?)",
        url: "https://youtu.be/Z1i0Y-0AvfQ",
        order: 2.375,
        id: "X94PODeGfGx4U7SyK1Ad",
      },
      {
        url: "https://www.youtube.com/watch?v=AfV9kai32Jk",
        title: "2022년 체육대회",
        order: 5.5,
        id: "aa7sx0kRUKN4d6hWcjd8",
      },
      {
        url: "https://www.youtube.com/watch?v=OvHqpY1dhtM",
        title: "2022년 가을 해연축제 댄스부 공연",
        order: 1.625,
        id: "brFl2ZJt2ss4DGqtNQF5",
      },
      {
        order: 4,
        url: "https://youtu.be/9zaH55R2RII",
        title: "부산여상의 탐구생활!!",
        id: "c2YK3wo7tw0Qg0zfWAWK",
      },
      {
        url: "https://www.youtube.com/watch?v=SncE2nIb-pc",
        order: 1.5625,
        title: "메인영상",
        id: "cpPHwfrqG5oZOI6MmCBe",
      },
      {
        title: "부산여상 학교홍보 커버댄스",
        order: 6.75,
        url: "https://www.youtube.com/watch?v=zYSqMo2AnWo",
        id: "k02GlDBGBoWqUOjxDPHV",
      },
      {
        order: 0.75,
        title: "중소 기업 알리기  UCC 공모전 2학년 최우수상",
        url: "https://www.youtube.com/watch?v=QoKk6BNEtek",
        id: "oVTVi4s4AFrYq4n4V4II",
      },
      {
        url: "https://www.youtube.com/watch?v=Za0e6oBt6nk",
        title: "2022년 1학년 알로이시오기지 1968 체험활동",
        order: 14,
        id: "ycZNtoyU14SloWA2D5d2",
      },
    ],
  },
};
const Index = () => {
  const dispatch = useDispatch();
  const { globalData, getGlobalDataStatus } = useSelector((state) => state.global);
  const { boothData, getAllLiveProgramsStatus } = useSelector((state) => state.booth);
  const [isEventResultModal1, setIsEventResultModal1] = useState(true);
  // const [isEventResultModal2, setIsEventResultModal2] = useState(true);
  // const [isEventResultModal3, setIsEventResultModal3] = useState(true);
  const ids = [1, 2, 3, 4, 5];
  useEffect(() => {
    dispatch(getUserRankingAction(5));
    dispatch(getClassRankingAction(5));
    return () => {
      dispatch(syncRankingDone());
    };
  }, []);
  useEffect(() => {
    dispatch(getAllLiveProgramsAction());
    dispatch(getBoothsAction());
    return () => {
      dispatch(syncLiveProgramDone());
    };
  }, []);
  // useEffect(() => {
  //   // data.forEach((item) => {
  //   //   firestore().collection("booth").doc(item.id).set(item, { merge: true });
  //   // });
  //   Object.entries(DUMMY_2).forEach(([key, value]) => {
  //     if (value.documents)
  //       value.documents.forEach((item) => {
  //         firestore().collection("booth").doc(key).collection("documents").doc(item.id).set(item, { merge: true });
  //       });
  //     if (value.images)
  //       value.images.forEach((item) => {
  //         firestore().collection("booth").doc(key).collection("images").doc(item.id).set(item, { merge: true });
  //       });
  //     if (value.videos)
  //       value.videos.forEach((item) => {
  //         firestore().collection("booth").doc(key).collection("videos").doc(item.id).set(item, { merge: true });
  //       });
  //   });
  // }, []);
  useEffect(() => {
    // data.forEach((item) => {
    //   firestore().collection("booth").doc(item.id).set(item, { merge: true });
    // });

    const eventResultBannerFlag1 = window.localStorage.getItem("eventResult" + ids[2]);
    // const eventResultBannerFlag2 = window.localStorage.getItem('eventResult' + ids[3]);
    // const eventResultBannerFlag3 = window.localStorage.getItem('eventResult' + ids[2]);
    if (eventResultBannerFlag1) {
      if (new Date().getDate() == eventResultBannerFlag1) {
        setIsEventResultModal1(false);
      } else {
        setIsEventResultModal1(true);
      }
    }
    // if (eventResultBannerFlag2) {
    //   if (new Date().getDate() == eventResultBannerFlag2) {
    //     setIsEventResultModal2(false);
    //   } else {
    //     setIsEventResultModal2(true);
    //   }
    // }
    // if (eventResultBannerFlag3) {
    //   if (new Date().getDate() == eventResultBannerFlag3) {
    //     setIsEventResultModal3(false);
    //   } else {
    //     setIsEventResultModal3(true);
    //   }
    // }
  }, []);

  return (
    <>
      <Helmet meta={ROUTES.home.meta}>
        <title>{ROUTES.home.headTitle}</title>
      </Helmet>
      <MainBanner mdSrc={BANNERS.mainBanner} xsSrc={MOBILE_BANNERS.mainBanner} alt="메인 배너" marginBottom="small" />
      <Banner
        mdSrc={BANNERS.metaverseBanner}
        xsSrc={MOBILE_BANNERS.metaverseBanner}
        alt="부산 직업계고 메타버스"
        href={"https://zep.us/play/y1bRXO"}
        marginBottom="small"
      />
      <Banner
        mdSrc={BANNERS.liveProgramBanner}
        xsSrc={MOBILE_BANNERS.liveProgramBanner}
        alt="실시간 라이브 배너"
        to="/liveProgramPlan"
        marginBottom="small"
      />
      <Container>
        {getGlobalDataStatus?.done && globalData?.announce && globalData.announce.text?.trim() !== "" && (
          <NoticeText announce={globalData.announce?.text} style={{ marginBottom: "66px" }} href={globalData.announce?.url} />
        )}
        <Grid container spacing={2}>
          {globalData.headerVideo?.isActive && (
            <Grid item xs={12}>
              <H1 button={{ text: "기예능영상 오후 영상 시청하기", href: globalData.headerVideo?.url }}>메인영상</H1>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            {globalData.liveVideoUrl !== "" ? (
              <YoutubeContainer videoId={getYoutubeId(globalData.liveVideoUrl)} className="ifr" />
            ) : (
              <VideoPlaceholder src={videoPlaceholderImage} />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            {globalData.liveVideoUrl2 !== "" ? (
              <YoutubeContainer videoId={getYoutubeId(globalData.liveVideoUrl2)} className="ifr" />
            ) : (
              <></>
            )}
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Comments />
          </Grid> */}
          {/* <Grid item xs={12}>
            <LiveVideos />
          </Grid> */}
          {globalData.isRankingOn && (
            <Grid item xs={12}>
              <H1 subHeading="반별 / 개인별" button={{ text: "더보기", to: "/ranking" }}>
                실시간 랭킹
              </H1>
              <Ranking />
              <p style={{ lineHeight: "18px", color: "#5e5e5e", fontSize: "16px" }}>
                랭킹을 통해서 <b>반 전체 간식</b> 부터 <b>아이패드</b>까지 받아가세요!
              </p>
            </Grid>
          )}
          {/* <Grid item xs={12}>
            <H1>프로그램</H1>
            <Schedule />
          </Grid> */}
          {/* <Grid item xs={12}>
            <H1 button={{ text: '더보기', to: '/liveProgramPlan' }}>실시간 상담</H1>
            <Grid container spacing={2}>
              {getAllLiveProgramsStatus?.done &&
                boothData
                  .filter(booth => booth.isActive && (booth.isLiveYoutube || booth.isLiveZoom))
                  .map(booth => <LiveProgramCard booth={booth} />)}
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <H1 button={{ text: "더보기", to: "/booths" }}>부스</H1>
            <Booths />
          </Grid>
          <Grid item xs={12}>
            <H1 button={{ text: "더보기", to: "/videos" }}>동영상</H1>
            <Videos />
          </Grid>
        </Grid>

        {isEventResultModal1 && (
          <EventResultModal
            setIsEventResultModal={setIsEventResultModal1}
            isModal={isEventResultModal1}
            src={[
              // // "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/modals%2F%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%87%E1%85%A1%E1%86%A8_SNS_%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9_01.png?alt=media&token=a4ead681-d4bd-4e9a-8d57-552fa638ea79",
              // "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/modals%2F%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%87%E1%85%A1%E1%86%A8_SNS_%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9_03.png?alt=media&token=602df934-a01d-4f38-ae63-f2ec986692a2",
              // "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/modals%2F%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%87%E1%85%A1%E1%86%A8_SNS_%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9_04.png?alt=media&token=795c1bce-507b-42a3-8e20-d5876addb8b0",
              // "https://firebasestorage.googleapis.com/v0/b/bsjobedu2022.appspot.com/o/modals%2F%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%87%E1%85%A1%E1%86%A8_SNS_%E1%84%80%E1%85%AA%E1%86%BC%E1%84%80%E1%85%A9_02.png?alt=media&token=f2cc4ffc-183d-4a20-bddd-678b480ad9a3",
              "https://firebasestorage.googleapis.com/v0/b/bsjobedu2023.appspot.com/o/popups%2F%E1%84%89%E1%85%B3%E1%84%90%E1%85%A2%E1%86%B7%E1%84%91%E1%85%B3%E1%84%8B%E1%85%B5%E1%84%87%E1%85%A6%E1%86%AB%E1%84%90%E1%85%B3%20%E1%84%83%E1%85%A1%E1%86%BC%E1%84%8E%E1%85%A5%E1%86%B7%E1%84%8C%E1%85%A1%E1%84%87%E1%85%A1%E1%86%AF%E1%84%91%E1%85%AD.png?alt=media&token=b4858dc5-8e93-466e-bbf2-bcfb9f0d3d0e",
            ]}
            id={ids[2]}
          />
        )}
        {/* {isEventResultModal3 && (
          <EventResultModal
            setIsEventResultModal={setIsEventResultModal3}
            isModal={isEventResultModal3}
            id={ids[2]}
            src="https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/assets%2FeventBanner%2F%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%87%E1%85%A1%E1%86%A8_%E1%84%89%E1%85%A1%E1%86%BC%E1%84%83%E1%85%A1%E1%86%B7%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A5%E1%86%B8%E1%84%91%E1%85%A1%E1%86%B8%E1%84%8B%E1%85%A5%E1%86%B8.png?alt=media&token=039fda3c-22df-49e1-b0ec-92a660faffcb"
            liveApply
          />
        )} */}
        {/* {isEventResultModal2 && (
          <EventResultModal
            setIsEventResultModal={setIsEventResultModal2}
            isModal={isEventResultModal2}
            id={ids[3]}
            src="https://firebasestorage.googleapis.com/v0/b/busanjobexpo.appspot.com/o/%E1%84%8C%E1%85%B5%E1%86%A8%E1%84%87%E1%85%A1%E1%86%A8_%E1%84%89%E1%85%A1%E1%86%BC%E1%84%83%E1%85%A1%E1%86%B7%E1%84%89%E1%85%B5%E1%86%AB%E1%84%8E%E1%85%A5%E1%86%BC%E1%84%87%E1%85%A5%E1%86%B8%E1%84%91%E1%85%A1%E1%86%B8%E1%84%8B%E1%85%A5%E1%86%B8.png?alt=media&token=fa3b96a6-71cb-4c62-9bc2-6e33c845bb76"
          />
        )} */}
      </Container>
    </>
  );
};

export default Index;
