import { all, fork, takeLatest, put, call, take, cancel, select, takeEvery } from "redux-saga/effects";
import { actions, syncCommentDone, enterChannelAction } from "../reducers/comment";
import { actions as adminActions } from "../reducers/admin";
import { rsfDB, firestore, db } from "../firebase";
import { getSecondsBetween } from "../utils/Functions";
import { TIME_LIMIT } from "../constants/commentsConstants";
import { updateUserDataAction } from "../reducers/user";

function* enterRoom({ channelId }) {
  const now = new Date();
  now.setMinutes(now.getMinutes() - 10);
  yield put({
    type: actions.ENTER_CHANNEL_SUCCESS,
    channelId,
    enteredAt: now,
  });
}
function* getComments(action) {
  yield put(enterChannelAction(action.channelId));
  yield take(actions.ENTER_CHANNEL_SUCCESS);
  const comment = yield select((state) => state.comment);
  const enteredAt = action.enteredAt ?? comment.enteredAt;
  const task = yield fork(
    rsfDB.syncCollection,
    firestore()
      .collection(`channel/${action.channelId}/comments`)
      .where("isDeleted", "==", false)
      .where("createdAt", ">=", enteredAt)
      .orderBy("createdAt", "desc"),
    {
      successActionCreator: (data) => {
        let comments = [];
        data.forEach((doc) => {
          comments = [{ id: doc.id, ...doc.data() }, ...comments];
        });
        return { type: actions.GET_COMMENTS_SUCCESS, data: comments };
      },
      failureActionCreator: (err) => ({
        type: actions.GET_COMMENTS_FAILURE,
        error: err.message,
      }),
    }
  );
  yield take(actions.SYNC_COMMENT_DONE);
  yield cancel(task);
}
function* getNumberOfRecentComments(action) {
  const { channelId, amount } = action;
  yield put(enterChannelAction(action.channelId));
  yield take(actions.ENTER_CHANNEL_SUCCESS);
  const task = yield fork(
    rsfDB.syncCollection,
    firestore().collection(`channel/${channelId}/comments`).where("isDeleted", "==", false).orderBy("createdAt", "desc").limit(amount),
    {
      successActionCreator: (data) => {
        let comments = [];
        data.forEach((doc) => {
          comments = [...comments, { id: doc.id, ...doc.data() }];
        });
        return {
          type: actions.GET_NUMBER_OF_RECENT_COMMENTS_SUCCESS,
          data: comments,
        };
      },
      failureActionCreator: (err) => ({
        type: actions.GET_NUMBER_OF_RECENT_COMMENTS_FAILURE,
        error: err.message,
      }),
    }
  );
  yield take(actions.SYNC_COMMENT_DONE);
  yield cancel(task);
}
function* postComment(action) {
  const { channelId, data, timeLimit } = action;
  const { userData } = yield select((state) => state.user);
  const { recentCommentPostedAt } = yield select((state) => state.comment);
  const now = new Date();
  let secondsBetween;
  let firstComment = true;
  if (recentCommentPostedAt) secondsBetween = getSecondsBetween(recentCommentPostedAt, now);
  else firstComment = false;
  if (!(userData.status === "admin" || userData.status === "superAdmin") && firstComment && secondsBetween < timeLimit) {
    alert(`아직 댓글을 달 수 없습니다. ${timeLimit - secondsBetween}초 남았습니다.`);
    return;
  }
  yield put(updateUserDataAction("commentCount", firestore.FieldValue.increment(1)));
  try {
    if (userData.status === "student") {
      yield call(rsfDB.addDocument, `channel/${channelId}/comments`, {
        commentCount: userData.commentCount + 1,
        email: userData.email,
        phone: userData.phone,
        schoolName: userData.schoolName,
        grade: userData.grade,
        class: userData.class,
        number: userData.number,
        isBlocked: userData.isBlocked,
        point: userData.point,
        dream: userData.description ?? "",
        isDeleted: false,
        name: userData.name,
        channelId,
        ...data,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
    } else {
      yield call(rsfDB.addDocument, `channel/${channelId}/comments`, {
        ...data,
        isBlocked: userData.isBlocked ?? false,
        isDeleted: false,
        name: userData.name,
        channelId,
        createdAt: firestore.FieldValue.serverTimestamp(),
      });
    }
    yield put({
      type: actions.POST_COMMENT_SUCCESS,
      now,
    });
  } catch (err) {
    yield put({
      type: actions.POST_COMMENT_FAILURE,
      error: err.message,
    });
  }
}
function* reportUser({ id, channelId, comment }) {
  try {
    let user = yield call(rsfDB.getDocument, `reportedUser/${id}`);
    if (user.data() === undefined) {
      user = yield call(rsfDB.getDocument, `user/${id}`);
      yield call(
        rsfDB.setDocument,
        `reportedUser/${id}`,
        {
          uid: id,
          userName: user.data().userName,
          phone: user.data().phone ?? null,
          point: user.data().point,
          isBlocked: user.data().isBlocked ?? false,
          reportedComments: firestore.FieldValue.arrayUnion({
            channelId,
            ...comment,
            reported: null,
          }),
          reported: firestore.FieldValue.increment(1),
        },
        { marge: true }
      );
    } else {
      user = yield call(rsfDB.getDocument, `user/${id}`);
      yield call(rsfDB.updateDocument, `reportedUser/${id}`, {
        point: user.data().point,
        reportedComments: firestore.FieldValue.arrayUnion({
          channelId,
          ...comment,
          reported: null,
        }),
        reported: firestore.FieldValue.increment(1),
      });
    }

    yield put({
      type: actions.REPORT_USER_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.REPORT_USER_FAILURE,
      error: err.message,
    });
  }
}
function* deleteComment(action) {
  const { channelId, commentId } = action;
  try {
    yield call(rsfDB.updateDocument, `channel/${channelId}/comments/${commentId}`, "isDeleted", true);
    yield put({
      type: adminActions.LOCAL_COMMENT_DELETE,
      channelId,
      commentId,
    });
    yield put({
      type: actions.DELETE_COMMENT_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.DELETE_COMMENT_FAILURE,
      error: err.message,
    });
  }
}
function* updateComment({ channelId, commentId, target, value }) {
  try {
    yield call(rsfDB.updateDocument, `channel/${channelId}/comments/${commentId}`, target, value);
    yield put({
      type: adminActions.LOCAL_COMMENT_UPDATE,
      commentId,
      target,
      value,
    });
    yield put({
      type: actions.UPDATE_COMMENT_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_COMMENT_FAILURE,
      error: err.message,
    });
  }
}
function* addChannel({ channelId, data }) {
  try {
    yield call(rsfDB.setDocument, `channel/${channelId}`, data, { merge: true });
    yield put({
      type: actions.ADD_CHANNEL_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.ADD_CHANNEL_FAILURE,
      error: err.message,
    });
  }
}

function* getAllComments() {
  const task = yield fork(rsfDB.syncCollection, firestore().collection(`channel`), {
    successActionCreator: (data) => {
      data.forEach((doc) => {
        if (doc.id) {
          db.collection(`channel`)
            .doc(doc.id)
            .collection("comments")
            .orderBy("createdAt", "desc")
            .limit(10)
            .get()
            .then((result) => {
              result.forEach((doc) => {
                comments = [...comments, { id: doc.id, ...doc.data() }];
              });
            });
        }
      });

      return {
        type: actions.GET_ALL_COMMENTS_SUCCESS,
        data: comments,
      };
    },
    failureActionCreator: (err) => ({
      type: actions.GET_ALL_COMMENTS_FAILURE,
      error: err.message,
    }),
  });
  yield take(actions.SYNC_COMMENT_DONE);
  yield cancel(task);
}
function* watchGetComments() {
  yield takeEvery(actions.GET_COMMENTS_REQUEST, getComments);
}
function* watchGetNumberOfRecentComments() {
  yield takeEvery(actions.GET_NUMBER_OF_RECENT_COMMENTS_REQUEST, getNumberOfRecentComments);
}
function* watchPostComment() {
  yield takeLatest(actions.POST_COMMENT_REQUEST, postComment);
}
function* watchDeleteComment() {
  yield takeEvery(actions.DELETE_COMMENT_REQUEST, deleteComment);
}
function* watchGetAllComments() {
  yield takeLatest(actions.GET_ALL_COMMENTS_REQUEST, getAllComments);
}
function* watchUpdateComment() {
  yield takeLatest(actions.UPDATE_COMMENT_REQUEST, updateComment);
}
function* watchReportUser() {
  yield takeLatest(actions.REPORT_USER_REQUEST, reportUser);
}
function* watchAddChannel() {
  yield takeLatest(actions.ADD_CHANNEL_REQUEST, addChannel);
}
function* watchEnterRoom() {
  yield takeLatest(actions.ENTER_CHANNEL_REQUEST, enterRoom);
}
export default function* commentSaga() {
  yield all([
    fork(watchGetComments),
    fork(watchGetNumberOfRecentComments),
    fork(watchPostComment),
    fork(watchDeleteComment),
    fork(watchGetAllComments),
    fork(watchUpdateComment),
    fork(watchReportUser),
    fork(watchAddChannel),
    fork(watchEnterRoom),
  ]);
}
