import React from "react";
import styled from "styled-components";
import { profileImageTotal } from "../constants/asseetConstants";

const Image = styled.img`
  display: block;
  width: 100%;
`;
const Container = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
`;
function Avatar({ id, childeren, ...props }) {
  return (
    <Container {...props}>
      <Image src={profileImageTotal[id]} />
      {childeren}
    </Container>
  );
}

export default Avatar;
