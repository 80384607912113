import { enableAllPlugins } from 'immer';
enableAllPlugins();
import { produce } from 'immer';

export const initialState = {
  videoData: [],
  currentVideoData: {},
  currentCategory: '전체보기',
  getVideosStatus: {
    loading: false,
    done: false,
    error: null,
  },
  getVideoDataStatus: {
    loading: false,
    done: false,
    error: null,
  },
  updateVideoDataStatus: {
    loading: false,
    done: false,
    error: null,
  },
  addVideoDataStatus: {
    loading: false,
    done: false,
    error: null,
  },
  deleteVideoDataStatus: {
    loading: false,
    done: false,
    error: null,
  },
};

export const actions = {
  GET_VIDEOS_REQUEST: 'GET_VIDEOS_REQUEST',
  GET_VIDEOS_SUCCESS: 'GET_VIDEOS_SUCCESS',
  GET_VIDEOS_FAILURE: 'GET_VIDEOS_FAILURE',
  GET_VIDEO_DATA_REQUEST: 'GET_VIDEO_DATA_REQUEST',
  GET_VIDEO_DATA_SUCCESS: 'GET_VIDEO_DATA_SUCCESS',
  GET_VIDEO_DATA_FAILURE: 'GET_VIDEO_DATA_FAILURE',
  UPDATE_VIDEO_DATA_REQUEST: 'UPDATE_VIDEO_DATA_REQUEST',
  UPDATE_VIDEO_DATA_SUCCESS: 'UPDATE_VIDEO_DATA_SUCCESS',
  UPDATE_VIDEO_DATA_FAILURE: 'UPDATE_VIDEO_DATA_FAILURE',
  ADD_VIDEO_DATA_REQUEST: 'ADD_VIDEO_DATA_REQUEST',
  ADD_VIDEO_DATA_SUCCESS: 'ADD_VIDEO_DATA_SUCCESS',
  ADD_VIDEO_DATA_FAILURE: 'ADD_VIDEO_DATA_FAILURE',
  DELETE_VIDEO_DATA_REQUEST: 'DELETE_VIDEO_DATA_REQUEST',
  DELETE_VIDEO_DATA_SUCCESS: 'DELETE_VIDEO_DATA_SUCCESS',
  DELETE_VIDEO_DATA_FAILURE: 'DELETE_VIDEO_DATA_FAILURE',
  SET_VIDEO_CATEGORY: 'SET_VIDEO_CATEGORY',
};

export const getVideosAction = () => ({
  type: actions.GET_VIDEOS_REQUEST,
});
export const getVideoDataAction = (id) => ({
  type: actions.GET_VIDEO_DATA_REQUEST,
  id,
});
export const updateVideoDataAction = (id, data) => ({
  type: actions.UPDATE_VIDEO_DATA_REQUEST,
  id,
  data,
});
export const addVideoDataAction = (data) => ({
  type: actions.ADD_VIDEO_DATA_REQUEST,
  data,
});
export const deleteVideoDataAction = (id) => ({
  type: actions.DELETE_VIDEO_DATA_REQUEST,
  id,
});
export const setCategoryAction = (value) => ({
  type: actions.SET_VIDEO_CATEGORY,
  value,
});

const reducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_VIDEOS_REQUEST:
        draft.getVideosStatus.done = false;
        draft.getVideosStatus.loading = true;
        draft.getVideosStatus.error = null;
        break;
      case actions.GET_VIDEOS_SUCCESS:
        draft.getVideosStatus.loading = false;
        draft.getVideosStatus.error = null;
        draft.getVideosStatus.done = true;
        draft.videoData = action.data;
        break;
      case actions.GET_VIDEOS_FAILURE:
        draft.getVideosStatus.loading = false;
        draft.getVideosStatus.done = false;
        draft.getVideosStatus.error = action.error;
        break;
      case actions.GET_VIDEO_DATA_REQUEST:
        draft.getVideoDataStatus.done = false;
        draft.getVideoDataStatus.loading = true;
        draft.getVideoDataStatus.error = null;
        break;
      case actions.GET_VIDEO_DATA_SUCCESS:
        draft.getVideoDataStatus.loading = false;
        draft.getVideoDataStatus.done = true;
        draft.getVideoDataStatus.error = null;
        draft.currentVideoData = { ...action.data, id: action.id };
        break;
      case actions.GET_VIDEO_DATA_FAILURE:
        draft.getVideoDataStatus.done = false;
        draft.getVideoDataStatus.loading = false;
        draft.getVideoDataStatus.error = action.error;
        break;
      case actions.UPDATE_VIDEO_DATA_REQUEST:
        draft.updateVideoDataStatus.done = false;
        draft.updateVideoDataStatus.loading = true;
        draft.updateVideoDataStatus.error = null;
        break;
      case actions.UPDATE_VIDEO_DATA_SUCCESS:
        draft.updateVideoDataStatus.loading = false;
        draft.updateVideoDataStatus.error = null;
        draft.updateVideoDataStatus.done = true;
        draft.currentVideoData = action.data;
        break;
      case actions.UPDATE_VIDEO_DATA_FAILURE:
        draft.updateVideoDataStatus.loading = false;
        draft.updateVideoDataStatus.done = false;
        draft.updateVideoDataStatus.error = action.error;
        break;
      case actions.ADD_VIDEO_DATA_REQUEST:
        draft.addVideoDataStatus.done = false;
        draft.addVideoDataStatus.loading = true;
        draft.addVideoDataStatus.error = null;
        break;
      case actions.ADD_VIDEO_DATA_SUCCESS:
        draft.addVideoDataStatus.loading = false;
        draft.addVideoDataStatus.error = null;
        draft.addVideoDataStatus.done = true;
        draft.videoData = action.data;
        break;
      case actions.ADD_VIDEO_DATA_FAILURE:
        draft.addVideoDataStatus.loading = false;
        draft.addVideoDataStatus.done = false;
        draft.addVideoDataStatus.error = action.error;
        break;
      case actions.DELETE_VIDEO_DATA_REQUEST:
        draft.deleteVideoDataStatus.done = false;
        draft.deleteVideoDataStatus.loading = true;
        draft.deleteVideoDataStatus.error = null;
        break;
      case actions.DELETE_VIDEO_DATA_SUCCESS:
        draft.deleteVideoDataStatus.loading = false;
        draft.deleteVideoDataStatus.error = null;
        draft.deleteVideoDataStatus.done = true;
        draft.videoData = action.data;
        break;
      case actions.DELETE_VIDEO_DATA_FAILURE:
        draft.deleteVideoDataStatus.loading = false;
        draft.deleteVideoDataStatus.done = false;
        draft.deleteVideoDataStatus.error = action.error;
        break;
      case actions.SET_VIDEO_CATEGORY:
        draft.currentCategory = action.value;
        break;
      default:
        break;
    }
  });

export default reducer;
