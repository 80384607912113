// 댓글이 삭제되었을 경우
export const REMOVE_COMMENT = -10;
// 유저가 블록당했을 경우
export const BLOCKED_USER = -100;
// 댓글, 채팅 작성했을 경우
export const WRITE_MAIN_COMMENT = 3;
export const POST_MAIN_IMAGE = 10;
// 댓글, 채팅 작성했을 경우
export const WRITE_BOOTH_LIVE_COMMENT = 3;
export const POST_BOOTH_LIVE_IMAGE = 10;
// 댓글, 채팅 작성했을 경우
export const WRITE_VIDEO_COMMENT = 3;
// 부스 방문
export const VISIT_BOOTH = 1;
// 비디오 시청
export const WATCH_VIDEO = 1;

//부스 선호도 포인트

export const boothEvaluate = {
  entered: 2,
  textComment: 1,
  imgComment: 3,
};
