import React from 'react';
import PropTypes from 'prop-types';
import {
  SwipeableDrawer,
  List,
  ListItem,
  SvgIcon,
  makeStyles,
} from '@material-ui/core';
import SideMenuLink from '../components/SideMenuLink';
import { LOGO } from '../constants/asseetConstants';

const useStyles = makeStyles({
  listItem: {
    padding: 30,
  },
  logoImg: {
    width: '100%',
    maxWidth: 200,
  },
});
const SideMenu = ({ toggleDrawer, menuContents }) => {
  const classes = useStyles();
  return (
    <SwipeableDrawer anchor='right' open={toggleDrawer} elevation={11}>
      <List>
        <ListItem className={classes.listItem}>
          <img src={LOGO} alt='로고' className={classes.logoImg}></img>
        </ListItem>
        {menuContents.map(({ label, to, detail, icon }) => {
          return (
            <ListItem key={label}>
              <SideMenuLink key={label} to={to} label={label} detail={detail}>
                <SvgIcon component={icon} key={label} fontSize='large' />
              </SideMenuLink>
            </ListItem>
          );
        })}
      </List>
    </SwipeableDrawer>
  );
};

SideMenu.propTypes = {
  toggleDrawer: PropTypes.bool.isRequired,
  menuContents: PropTypes.array.isRequired,
};

export default SideMenu;
