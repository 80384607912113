export const RandomKey = (length) => {
  const chars = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
  let randomString = '';
  for (let i = 0; i < length; i++) {
    const rnum = Math.floor(Math.random() * chars.length);
    randomString += chars.substring(rnum, rnum + 1);
  }

  return randomString;
};


// 날짜 출력 함수
export const printDate = (value) => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const date = value.getDate();
  const month = value.getMonth();
  const year = value.getFullYear();
  const day = value.getDay();
  const dateString = `${year}년 ${month + 1}월 ${date}일 (${week[day]})`;
  return dateString;
};

export const printFullDate = (value) => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  const date = value.getDate();
  const month = value.getMonth();

  const day = value.getDay();
  const dateString = `${month + 1}월 ${date}일 (${week[day]})`;

  const hour = ('0' + (value.getHours() === 12 ? 12 : value.getHours() % 12)).substr(-2);
  const flag = value.getHours() < 12 ? '오전' : '오후';
  const minutes = ('0' + value.getMinutes()).substr(-2);
  const time = `  ${flag} ${hour}시 ${minutes}분`;
  return dateString + time;
};


export const printTime = (value) => {
  const hour = ('0' + (value.getHours() === 12 ? 12 : value.getHours() % 12)).substr(-2);
  const flag = value.getHours() < 12 ? '오전' : '오후';
  const minutes = ('0' + value.getMinutes()).substr(-2);
  const time = `${flag} ${hour}시 ${minutes}분`;
  return time;
};

export const printChatTime = (value) => {
  const hour = ('0' + (value.getHours() === 12 ? 12 : value.getHours() % 12)).substr(-2);
  const flag = value.getHours() < 12 ? '오전' : '오후';
  const minutes = ('0' + value.getMinutes()).substr(-2);
  const time = `${flag} ${hour}:${minutes}`;
  return time;
};

export const preventEnterKeySubmit = (keyEvent) => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault();
  }
};
export const printDateNum = (value) => {
  const date = ('0' + value.getDate()).slice(-2);
  const month = ('0' + (value.getMonth() + 1)).slice(-2);
  const year = value.getFullYear();
  const dateString = `${year}${month}${date}`;
  return dateString;
};


export const printDateDash = (value) => {
  const date = ('0' + value.getDate()).slice(-2);
  const month = ('0' + (value.getMonth() + 1)).slice(-2);
  const year = value.getFullYear();
  const dateString = `${year}-${month}-${date}`;
  return dateString;
};


export const nameMasking = (strName) => {
  if (strName.length > 2) {
    const originName = strName.split('');
    originName.forEach(function(name, i) {
      if (i === 0 || i === originName.length - 1) return;
      originName[i] = '*';
    });
    const joinName = originName.join();
    return joinName.replace(/,/g, '');
  } else {
    const pattern = /.$/;
    return strName.replace(pattern, '*');
  }
};

export const phoneNumberMasking = (value) => {

  const targetChar = value.substring(3, 7);
  return value.replace(targetChar, '****');
};


export const getSecondsBetween = (from, to) =>{
  return parseInt(Math.abs(to.getTime() - from.getTime()) / (1000) % 60); 
}