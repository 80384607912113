import firebase from "firebase";
import { all, fork, take, takeLatest, put, call, select, cancel, takeEvery } from "redux-saga/effects";
import { actions, syncUserAction } from "../reducers/user";
import { auth, firestore, rsfDB } from "../firebase";
import shortid from "shortid";
import { addChannelAction } from "../reducers/comment";

// call은 동기, fork는 비동기 요청
function* logIn(action) {
  try {
    const { user } = yield call(auth.signInWithEmailAndPassword, action.email, action.password);
    const key = shortid.generate();
    yield call(rsfDB.updateDocument, `user/${user.uid}`, { sessionKey: key });
    yield put(syncUserAction(user.uid));
    window.sessionStorage.setItem("sessionKey", key);
    window.sessionStorage.setItem("userId", user.uid);
    yield put({
      type: actions.LOG_IN_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.LOG_IN_FAILURE,
      error: err.message,
    });
  }
}
function* sendPasswordReset({ email }) {
  try {
    yield call(auth.sendPasswordResetEmail, email);
    yield put({
      type: actions.SEND_PASSWORD_RESET_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.SEND_PASSWORD_RESET_SUCCESS,
      error: err.message,
    });
  }
}
function* syncUser({ id }) {
  const task = yield fork(rsfDB.syncDocument, `user/${id}`, {
    successActionCreator: (data) => ({
      type: actions.SYNC_USER_SUCCESS,
      data: data.data(),
    }),
    failureActionCreator: (err) => ({
      type: actions.SYNC_USER_FAILURE,
      err: err.message,
    }),
  });
  yield take(actions.SYNC_USER_DONE);
  yield cancel(task);
}

function* logOut() {
  try {
    yield call(auth.signOut);
    window.sessionStorage.clear();
    yield put({ type: actions.SYNC_USER_DONE });
    yield put({
      type: actions.LOG_OUT_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.LOG_OUT_FAILURE,
      error: err.message,
    });
  }
}

function* signUp(action) {
  try {
    // 자동 로그인을 위한 세션키 생성
    const key = shortid.generate();
    // 유저 데이터 생성 날짜
    const createdAt = new Date();
    // firebase auth 상의 user 생성
    const { user } = yield call(auth.createUserWithEmailAndPassword, action.email, action.password);
    // 세션 저장
    window.sessionStorage.setItem("sessionKey", key);
    window.sessionStorage.setItem("userId", user.uid);
    if (action.data.status === "student") {
      // 학생일 경우 각 반(class) collection의 key 값 생셩
      const classId = encodeURI(`${action.data.schoolName}${action.data.grade}${action.data.class}`);
      // 반 정보 확인
      const classData = yield call(rsfDB.getDocument, `class/${classId}`);
      if (!classData.exists) {
        // 정보가 없으면 정보 생성
        yield call(
          rsfDB.setDocument,
          `class/${classId}`,
          {
            point: 0,
            schoolName: action.data.schoolName,
            grade: action.data.grade,
            class: action.data.class,
            shortName: action.data.shortSchoolName,
            users: [user.uid],
          },
          { marge: true }
        );
      } else {
        yield call(rsfDB.updateDocument, `class/${classId}`, "users", firestore.FieldValue.arrayUnion(user.uid));
      }
      // firestore 상의 유저 데이터 생성
      yield call(rsfDB.setDocument, `user/${user.uid}`, {
        uid: user.uid,
        email: user.email,
        point: 0,
        visitedBooths: [],
        watchedVideos: [],
        stamps: [],
        isBlocked: false,
        createdAt,
        appliedPrograms: [],
        sessionKey: key,
        classId,
        commentCount: 0,
        ...action.data,
      });
    } else {
      // 일반인일 경우 간단하게 firestore에 유저 정보 생성
      yield call(rsfDB.setDocument, `user/${user.uid}`, {
        uid: user.uid,
        email: user.email,
        isBlocked: false,
        createdAt: new Date(),
        sessionKey: key,
        ...action.data,
      });
    }
    // firestore 과 data 동기화
    yield put(syncUserAction(user.uid));
    // SING_UP_SUCCESS 액션 실행.
    yield put({
      type: actions.SIGN_UP_SUCCESS,
    });
    // 실패할 경우 에러메세지 저장
  } catch (err) {
    yield put({
      type: actions.SIGN_UP_FAILURE,
      error: err.code,
    });
  }
}

function* checkUserSession() {
  try {
    const uid = window.sessionStorage.getItem("userId");
    const sessionKey = window.sessionStorage.getItem("sessionKey");
    const snapshot = yield call(rsfDB.getDocument, `user/${uid}`);
    const userData = snapshot.data();
    if (userData.sessionKey === sessionKey) {
      yield put(syncUserAction(uid));
      yield put({
        type: actions.CHECK_USER_SESSION_SUCCESS,
        session: true,
      });
    } else {
      window.sessionStorage.clear();
      yield put({
        type: actions.CHECK_USER_SESSION_SUCCESS,
        session: false,
      });
    }
  } catch (err) {
    yield put({
      type: actions.CHECK_USER_SESSION_FAILURE,
      error: err.message,
    });
  }
}

function* updatePoint({ point }) {
  const { userData } = yield select((state) => state.user);
  console.log("test110910");
  try {
    yield call(rsfDB.updateDocument, `user/${userData.uid}`, "point", firebase.firestore.FieldValue.increment(point));
    if (userData.isRanker) {
      yield call(rsfDB.updateDocument, `topRanker/${userData.uid}`, "point", firebase.firestore.FieldValue.increment(point));
    }
    //근거 기록을 위한 데이터
    if (point < 0) {
      yield call(rsfDB.updateDocument, `user/${userData.uid}`, "subtractedPoint", firebase.firestore.FieldValue.increment(point * -1));
    }
    if (userData.isClassRanker) {
      yield call(rsfDB.updateDocument, `topRankClass/${userData.classId}`, "point", firebase.firestore.FieldValue.increment(point));
    }
    yield call(rsfDB.updateDocument, `class/${userData.classId}`, "point", firebase.firestore.FieldValue.increment(point));
    yield put({
      type: actions.UPDATE_POINT_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_POINT_FAILURE,
      error: err.message,
    });
  }
}
function* updateUserData({ target, value, id }) {
  const {
    userData: { uid },
  } = yield select((state) => state.user);
  try {
    yield call(rsfDB.updateDocument, `user/${id ?? uid}`, target, value);
    yield put({
      type: actions.UPDATE_USER_DATA_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.UPDATE_USER_DATA_FAILURE,
      error: err.message,
    });
  }
}
function* adminLogIn({ userId, password }) {
  try {
    // Firebase auth에서 이메일로만 가입이 가능해서 가짜 이메일 주소를 생성함.
    const email = `${userId}@adminbsjj.io`;
    // 로그인
    const { user } = yield call(auth.signInWithEmailAndPassword, email, password);
    // 세션키 생성
    const key = shortid.generate();
    // 유저의 세션키 업데이트
    yield call(rsfDB.updateDocument, `user/${user.uid}`, { sessionKey: key });
    // 데이터 불러오기
    yield put(syncUserAction(user.uid));
    // 세션 정보 로컬에 저장
    window.sessionStorage.setItem("sessionKey", key);
    window.sessionStorage.setItem("userId", user.uid);
    // 로그인 성공 액션 실행
    yield put({
      type: actions.ADMIN_LOG_IN_SUCCESS,
    });
  } catch (err) {
    yield put({
      type: actions.ADMIN_LOG_IN_FAILURE,
      error: err.message,
    });
  }
}

function* adminSignUp(action) {
  try {
    const { userId, password, data } = action;
    // Firebase auth에서 이메일로만 가입이 가능해서 가짜 이메일 주소를 생성함.
    const email = `${userId}@adminbsjj.io`;
    // 자동 로그인을 위한 세션키 생성
    const key = shortid.generate();
    // 유저 데이터 생성 날짜
    const createdAt = new Date();
    // firebase auth 상의 user 생성
    const { user } = yield call(auth.createUserWithEmailAndPassword, email, password);
    // 세션 저장
    window.sessionStorage.setItem("sessionKey", key);
    window.sessionStorage.setItem("userId", user.uid);

    // firestore 상의 유저 데이터 생성
    yield call(rsfDB.setDocument, `user/${user.uid}`, {
      uid: user.uid,
      email,
      userName: data.userName,
      createdAt,
      sessionKey: key,
      status: "admin",
      ...data,
    });

    // yield call(rsfDB.updateDocument, `booth/${data.boothId}`, {
    //   title: data.title,
    //   name: data.name,
    //   description: data.description,
    //   isActive: false,
    //   address: '',
    //   logo: {
    //     path: '',
    //     url: '',
    //   },
    //   announce: {
    //     text: '',
    //     url: '',
    //   },
    //   links: [
    //     { title: '', url: '' },
    //     { title: '', url: '' },
    //     { title: '', url: '' },
    //     { title: '', url: '' },
    //   ],
    //   isLive: false,
    //   isLiveYoutube: false,
    //   isLiveZoom: false,
    //   category: '',
    //   tags: [],
    //   createdAt,
    //   sessionKey: key,
    // });
    yield put(
      addChannelAction(data.boothId, {
        category: "booth",
        id: data.boothId,
        createdAt,
      })
    );
    // 유저 데이터 불러오기
    yield put(syncUserAction(user.uid));

    // SING_UP_SUCCESS 액션 실행. 로컬상의 데이터 저장
    yield put({
      type: actions.ADMIN_SIGN_UP_SUCCESS,
    });
    // 실패할 경우 에러메세지 저장
  } catch (err) {
    yield put({
      type: actions.ADMIN_SIGN_UP_FAILURE,
      error: err.code,
    });
  }
}

function* watchLogIn() {
  yield takeLatest(actions.LOG_IN_REQUEST, logIn);
}
function* watchLogOut() {
  yield takeLatest(actions.LOG_OUT_REQUEST, logOut);
}
function* watchSignUp() {
  yield takeLatest(actions.SIGN_UP_REQUEST, signUp);
}
function* watchSendPasswordReset() {
  yield takeLatest(actions.SEND_PASSWORD_RESET_REQUEST, sendPasswordReset);
}
function* watchCheckUserSession() {
  yield takeLatest(actions.CHECK_USER_SESSION_REQUEST, checkUserSession);
}
function* watchUpdatePoint() {
  yield takeEvery(actions.UPDATE_POINT_REQUEST, updatePoint);
}
function* watchUpdateUserData() {
  yield takeLatest(actions.UPDATE_USER_DATA_REQUEST, updateUserData);
}
function* watchAdminLogIn() {
  yield takeLatest(actions.ADMIN_LOG_IN_REQUEST, adminLogIn);
}
function* watchAdminSignUp() {
  yield takeLatest(actions.ADMIN_SIGN_UP_REQUEST, adminSignUp);
}
function* watchSyncUser() {
  yield takeLatest(actions.SYNC_USER_REQUEST, syncUser);
}

export default function* userSaga() {
  yield all([
    fork(watchLogIn),
    fork(watchLogOut),
    fork(watchSignUp),
    fork(watchSendPasswordReset),
    fork(watchCheckUserSession),
    fork(watchUpdatePoint),
    fork(watchUpdateUserData),
    fork(watchAdminLogIn),
    fork(watchAdminSignUp),
    fork(watchSyncUser),
  ]);
}

// utils
