import React from 'react';
import styled from 'styled-components';
import playbtn from '../assets/button/playbtn.png';
import getYoutubeId from '../utils/getYoutubeId';
const Container = styled.div`
  position: relative;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: auto;
  &:hover {
    .hoverBackground {
      transition: 0.3s ease;
      background-color: ${(props) => props.theme.primaryColor};
    }
  }
`;
const HoverBackground = styled.div`
  position: absolute;
  z-index: 5;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-20px, -20.5px);
  width: 38px;
  height: 39px;
  border-radius: 25px;
`;
const Thumb = styled.img`
  border-radius: 10px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
const Button = styled.img`
  position: absolute;
  z-index: 10;
  cursor: pointer;
  top: 50%;
  left: 50%;
  transform: translate(-25px, -25px);
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;
const Title = styled.p`
  margin-bottom: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
function YoutubeThumb({ url, title, onClick }) {
  return (
    <>
      <Container onClick={onClick}>
        <Thumb
          src={`https://img.youtube.com/vi/${getYoutubeId(url)}/mqdefault.jpg`}
          alt={title}
        />
        <Button src={playbtn} alt='재생버튼' />
        <HoverBackground className='hoverBackground'></HoverBackground>
      </Container>
      <Title>{title}</Title>
    </>
  );
}

export default YoutubeThumb;
