import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Image1 from '../../assets/images/timeTable/timeTable1.png';
import Image2 from '../../assets/images/timeTable/timeTable2.png';
import Image3 from '../../assets/images/timeTable/timeTable3.png';
const Container = styled(Grid)`
  width: 100%;
`;
const TimeTable = styled.img`
  width: 98%;
  margin: 0 auto 10px auto;
  display: block;
`;
function Schedule() {
  return (
    <Container container>
      <Grid item xs={12} md={4}>
        <TimeTable src={Image1} alt='일정표'></TimeTable>
      </Grid>
      <Grid item xs={12} md={4}>
        <TimeTable src={Image2} alt='일정표'></TimeTable>
      </Grid>
      <Grid item xs={12} md={4}>
        <TimeTable src={Image3} alt='일정표'></TimeTable>
      </Grid>
    </Container>
  );
}

export default Schedule;
