import { combineReducers } from 'redux';

import user from './user';
import booth from './booth';
import video from './video';
import comment from './comment';
import global from './global';
import admin from './admin';
import ranking from './ranking';

// Action creators...

const rootReducer = combineReducers({
  index: (state = {}, action) => {
    switch (action.type) {
      // case HYDRATE:
      //   return { ...state, ...action.payload };
      default:
        return { ...state };
    }
  },
  user,
  booth,
  video,
  comment,
  global,
  ranking,
  admin,
});

export default rootReducer;
