// 각 스테이터스 별로 loading: 로딩중인지, done: 처리가 끝났는지,
// error: 에러가 있는지 (에러가 있으면 에러메세지로 설정)을 확인할 수 있다
export const createStatus = () => ({
  loading: false,
  done: false,
  error: null,
});
export const resetStatus = () => ({
  loading: false,
  done: false,
  error: null,
});

export const createRequestStatus = () => ({
  loading: true,
  error: null,
  done: false,
});
export const createSuccessStatus = () => ({
  loading: false,
  error: null,
  done: true,
});
export const createFailureStatus = (error) => ({
  loading: false,
  done: false,
  error,
});
