import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import theme from '../../styles/theme';
import { useHistory } from 'react-router';
const useStyles = makeStyles(({ breakpoints }) => ({
  box: {
    marginBottom: theme.bigBoxInMargin,
    borderRadius: 16,
    overflow: 'hidden',
    transition: 'all 0.7s ease',
    padding: 16,
    cursor: 'pointer',
    textShadow: '2px 2px white',
    // [breakpoints.up('sm')]: {
    //   height: 192,
    // },
    position: 'relative',
    '&:hover': {
      '& .liveText': {
        transform: 'translateX(0)',
      },
      boxShadow: '7px 10px 49px 0px rgba(0,0,0,0.38)',
      '-webkit-box-shadow': '7px 10px 49px 0px rgba(0,0,0,0.38)',
      moxBoxShadow: '7px 10px 49px 0px rgba(0,0,0,0.38)',
      background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
      backgroundSize: '400% 400%',
      animation: '$gradient 15s ease infinite',
      textShadow: '1px 1px white',
    },
  },
  background: {
    position: 'absolute',
    height: '100%',
    width: '97%',
    // width: 'calc(100%)',
    borderRadius: 16,
    margin: '0 1.5%',
    backgroundColor: theme.primaryColor,
    opacity: 0.1,
    top: 0,
    left: 0,
    '&:hover': {
      background: 'none',
    },
  },
  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    zIndex: 10,
  },
  img: {
    display: 'block',
    // maxWidth: 160,
    // maxHeight: 160,
    zIndex: 10,
    // [breakpoints.only('xs')]: {
    maxHeight: 130,
    maxWidth: 100,
    // },
  },
  textBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // [breakpoints.up('sm')]: {
    //   height: 160,
    //   padding: '15px 0',
    // },
    // [breakpoints.only('xs')]: {
    height: '100%',
    // },
    zIndex: 10,
  },
  liveText: {
    position: 'absolute',
    whiteSpace: 'nowrap',
    right: -20,
    fontWeight: 700,
    // bottom: -25,
    // fontSize: 100,
    color: 'white',
    transition: '0.7s ease',
    opacity: 0.4,
    transform: 'translateX(100%)',
    // [breakpoints.down('sm')]: {
    fontSize: 60,
    bottom: -20,
    // },
    zIndex: 5,
  },
  title: {
    marginBottom: 4,
    fontWeight: 500,
    // fontSize: '24px',
    zIndex: 10,
    // [breakpoints.only('xs')]: {
    marginTop: 0,
    fontSize: '16px',
    // },
  },
  subject: {
    zIndex: 10,
    fontWeight: '500',
    // fontSize: '36px',
    marginBottom: 7,
    zIndex: 10,
    // [breakpoints.only('xs')]: {
    fontSize: '24px',
    // },
  },
  caption: {
    fontSize: 30,
    zIndex: 10,
  },
  description: {
    zIndex: 10,
    fontWeight: '500',
    fontSize: '16px',
  },
}));
function LiveProgramCard({ booth }) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Grid
      item
      xs={12}
      md={6}
      className={classes.box}
      onClick={() =>
        history.push({
          pathname: `/booth/${booth.id}`,
          search: '?program=true',
        })
      }
    >
      <Grid container>
        <Grid item xs={5} sm={3}>
          <Box className={classes.logo}>
            <img className={classes.img} src={booth.logo.url} alt="logo" />
          </Box>
        </Grid>
        <Grid xs={7} sm={9}>
          <Box className={classes.textBox}>
            <div className={classes.background}></div>
            <Typography variant="h5" className={classes.title}>
              {booth.title}
            </Typography>
            <Typography variant="h4" className={classes.subject}>
              {booth.liveSubject}
            </Typography>
            <Typography variant="h4" className={classes.description}>
              {booth.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h1" className={[classes.liveText, 'liveText']}>
        LIVE 진행중
      </Typography>
    </Grid>
  );
}

export default LiveProgramCard;
