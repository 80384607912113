import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useSelector } from 'react-redux';

const OptionItem = ({ text, value, onClose }) => {
  const handleListItemClick = (value) => {
    onClose(value);
  };
  return (
    <>
      <ListItem button onClick={() => handleListItemClick(value)} key={value}>
        <ListItemText primary={text} />
      </ListItem>
    </>
  );
};
export default function CommentModal({ onClose, open, mine }) {
  const { userData } = useSelector((state) => state.user);
  const handleClose = () => {
    onClose();
  };

  let temp = 3;

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle id='simple-dialog-title'>댓글 관리</DialogTitle>
      <List>
        {mine && (
          <>
            <OptionItem text='삭제하기' value='delete' onClose={onClose} />
            <OptionItem text='창 닫기' value='close' onClose={onClose} />
          </>
        )}
        {!mine &&
          (userData?.status === 'student' || userData?.status === 'public') && (
            <>
              <OptionItem text='신고하기' value='report' onClose={onClose} />
              <OptionItem text='창 닫기' value='close' onClose={onClose} />
            </>
          )}
        {!mine &&
          (userData?.status === 'admin' ||
            userData?.status === 'superAdmin') && (
            <>
              <OptionItem text='신고하기' value='report' onClose={onClose} />
              <OptionItem text='삭제하기' value='delete' onClose={onClose} />
              <OptionItem text='차단하기' value='block' onClose={onClose} />
              <OptionItem text='창 닫기' value='close' onClose={onClose} />
            </>
          )}
      </List>
    </Dialog>
  );
}
