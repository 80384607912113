import img01 from "../assets/central/introductionImage/1.png";
import img02 from "../assets/central/introductionImage/2.png";
import img03 from "../assets/central/introductionImage/3.png";
import img04 from "../assets/central/introductionImage/4.png";
export const GLOBAL_DATA_KEY = "wVk1DJSAQGdy5qWAa6N0";
export const SITE_NAME = "부산직업교육온오프라인박람회";
export const introductionData = {
  title: "직업계고 학생들의 희망찬 미래를 응원합니다!",
  content: `‘2023학년도 부산직업교육박람회’가 개최되어 대단히 뜻깊게 생각합니다. 
  
  2000년, 전국 최초로 힘차게 출발한 부산직업교육박람회는 직업계고등학교 학생들에게 전문기술 능력 향상 및 전문기능인으로서의 자긍심을 높여주고, 중학생들에게는 다양하고 내실 있는 진로 탐색과 직업 체험의 기회를 제공하고 있습니다.
  
  이번 박람회는 부산지역 특성화고와 마이스터고, 부산국제영화고를 포함한 총 37개교 학생들이 참가하여 기·예능전과 직업체험전, 특별관과 상담관 등 다양한 체험프로그램을 마련하였으며, 20개교 29개 동아리 학생들이 준비한 난타, 댄스공연과 같은 다채로운 공연도 함께 펼쳐질 예정입니다.
  
  첨단 기술이 눈부신 발전을 거듭하는 4차 산업혁명 시대에서 숙련된 전문기술은 더 나은 미래를 만드는 원동력입니다. 직업계고 학생들의 노력과 열정이 맺은 결실과 전문기술 인재를 키우는 현장을 한눈에 체험할 수 있는 이번 박람회에 여러분의 많은 관심과 성원 부탁드립니다.
  
  우리 교육청은 지·산·학 연계를 바탕으로, 2021년부터 교육부, 부산시와 함께 직업교육혁신지구 사업을 추진하고 지역 전략산업과 연계한 선취업·후학습 양성체계를 보다 체계적으로 구축하여 직업계고 학생들이 미래 발전을 견인하는 글로벌 인재로 성장하도록 최선을 다하고 있습니다.
  
  직업계고 학생들의 꿈을 향한 도전과 열정이 펼쳐질 이번 박람회를 통해 유익한 시간을 보내시길 바라며, 아울러 우리 학생들의 희망찬 미래를 항상 응원하겠습니다.
  
  
  감사합니다.
  
  `,

  date: "2023. 7. 12.",
  // position: '부산광역시',
  name: "부산광역시교육감 하 윤 수",
};

export const GUIDE_DATA = [
  {
    image: img01,
    title: "박람회 즐기는 방법",
    description: `‣ 박람회 시청하기
    ‣ 실시간 상담 참여하기!
      ‣ 셋, 온라인 부스와 다양한 영상, VR 컨텐츠 살펴보기!
      ‣ 넷, VR을 참여하고 스탬픝투어를 통해 이벤트 참여하기!`,
  },
  {
    image: img02,
    title: "온라인 부스",
    description:
      "부산에 있는 다양한 특성화 · 마이스터고등학교의 관한 영상과 사진 그리고 VR 컨텐츠는 물론 실시간 상담을 통해 진로와 직업에 대해 생각해보고 탐색해보세요!",
    link: "/booths",
  },
  {
    image: img03,
    title: "주제관",
    description:
      "다양한 학교에 대한 소개 영상과 공연 영상들을 확인할 수 있습니다. 진로와 관련된 다양한 영상들을 ‘주제관’ 메뉴에서 한 번에 시청해보세요!",
    link: "/videos",
  },
  {
    image: img04,
    title: "실시간 부스/상담",
    description: `실시간으로 소통하며 다양한 진로프로그램에 참여해보세요.
      ※ '실시간 부스/상담’ 메뉴에서 자세한 일정과 내용을 확인하세요!`,
    link: "/liveProgramPlan",
  },
];

export const tableSetLable = {
  body: {
    emptyDataSourceMessage: "관련 데이터가 없습니다.",
  },
  toolbar: {
    searchTooltip: "검색",
    searchPlaceholder: "검색",
  },
  pagination: {
    labelDisplayedRows: "현재: {from} - {to} / 전체: {count}",
    labelRowsSelect: "줄 씩 보기",
    firstAriaLabel: "첫 페이지",
    firstTooltip: "첫 페이지",
    previousAriaLabel: "이전 페이지",
    previousTooltip: "이전 페이지",
    nextAriaLabel: "다음 페이지",
    nextTooltip: "다음 페이지",
    lastAriaLabel: "마지막 페이지",
    lastTooltip: "마지막 페이지",
  },
};
// export const MENU_CONTENTS = [
//   {
//     label: 'HOME',
//     to: '/home',
//     icon: HomeRounded,
//     detail: '홈페이지로 이동!',
//   },
//   {
//     label: '참가부스',
//     to: '/booth',
//     icon: DirectionsRunRounded,
//     detail: '00개의 참가 부스를 한 눈에!',
//   },
//   {
//     label: '영상관',
//     to: '/videoContents',
//     icon: OndemandVideoRounded,
//     detail: '주제별 영상모음',
//   },
//   {
//     label: '실시간상담',
//     to: '/liveConsult',
//     icon: SupervisorAccountRounded,
//     detail: '실시간 상담 신청!',
//   },
//   {
//     label: '박람회소개',
//     to: '/introduction',
//     icon: DescriptionRounded,
//     detail: '부산 진로 온라인 박람회 정보',
//   },
//   {
//     label: '박람회 안내',
//     to: '/guide',
//     icon: HelpRounded,
//     detail: '부산 진로 온라인 박람회 안내',
//   },
// ];
