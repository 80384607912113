import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import usePathname from '../hooks/usePathname';
const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.isCurrentPage ? props.theme.primaryColor : props.theme.navColor};
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  ${(props) => props.theme.transition};
  &:hover {
    background-color: ${(props) => props.theme.primaryColor};
    color: white;
    opacity: 0.7;
  }
  height: 60px;
  border-radius: 4px;
  div {
    height: 56px;
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      font-weight: 600;
    }
    p {
      margin: 0;
      line-height: 24px;
    }
  }
`;

const SideMenuLink = ({ children, to, label, detail }) => {
  const history = useHistory();
  const pathname = usePathname();
  const [isCurrentPage, setIsCurrentPage] = useState(false);
  const onClickHandler = useCallback(() => {
    history.push(to);
  }, []);
  useEffect(() => {
    if (to?.includes(pathname)) setIsCurrentPage(true);
    else setIsCurrentPage(false);
  }, [pathname]);
  return (
    <Container onClick={onClickHandler} isCurrentPage={isCurrentPage}>
      {children}
      <div>
        <p className='title'>{label}</p>
        <p className='detail'>{detail}</p>
      </div>
    </Container>
  );
};

SideMenuLink.propTypes = {
  text: PropTypes.string.isRequired,
};

export default SideMenuLink;
