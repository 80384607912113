// 수정 사항 //된 부분들 +                     <Link to={authUser ? `/manage/${authUser.schoolNum}` : '/adminLogin'}>

import React, { useMemo } from "react";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";

import Link from "../components/Link";
import { Grid } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import RoundButton from "../components/RoundButton";
import LogoSlider from "../components/LogoSlider";
import globalTheme from "../styles/theme";
const FooterContainer = styled.div`
  max-width: ${(props) => props.theme.maxWidth};
  padding: 36px 8px 24px 8px;
  margin: ${(props) => props.theme.bigBoxOutMargin} auto 0 auto;
  color: ${(props) => props.theme.greyColor};
`;
const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  container: {
    flexGrow: 1,
  },
  item: {
    padding: 14,
    paddingLeft: 40,
    textAlign: "left",
    [breakpoints.only("sm")]: {
      paddingLeft: 100,
    },
    [breakpoints.only("xs")]: {
      padding: 14,
      textAlign: "center",
    },
  },
  adminModeBtn: {
    padding: spacing(2),
  },
  headerItem: {
    padding: spacing(2),
    paddingLeft: 40,
    textAlign: "left",
    fontWeight: "Bold",
    [breakpoints.only("sm")]: {
      paddingLeft: 100,
    },
    [breakpoints.only("xs")]: {
      padding: 14,
      textAlign: "center",
    },
  },
  logo: {
    padding: spacing(1),
    textAlign: "center",
  },
  copyRight: {
    marginTop: 30,
    textAlign: "center",
    height: "60px",
    lineHeight: "60px",
    paddingTop: "10px",
    borderTop: `1px solid #41464D`,
    [breakpoints.only("xs")]: {
      fontSize: "0.9em",
    },
  },
}));
const Footer = () => {
  const classes = useStyles();
  //   const authUser = useContext(AuthenticationContext);
  return (
    <FooterContainer>
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} sm={12} md={5} className={classes.item}>
          <Grid item className={classes.logo}>
            <LogoSlider />
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={12} md={7}>
          <Grid item xs={6} sm={6} md={4}>
            <Grid item className={classes.headerItem}>
              Services
            </Grid>
            <Grid item className={classes.item}>
              <Link to="/home">메인 행사</Link>
            </Grid>
            <Grid item className={classes.item}>
              <Link to="/booths">온라인 부스</Link>
            </Grid>
            <Grid item className={classes.item}>
              <Link to="/videos">주제관</Link>
            </Grid>
            <Grid item className={classes.item}>
              <Link to="/liveProgramPlan">실시간 부스/상담</Link>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Grid item className={classes.headerItem}>
              Support
            </Grid>
            <Grid item className={classes.item}>
              <Link to="/introduction">박람회 소개</Link>
            </Grid>
            <Grid item className={classes.item}>
              <Link to="/guide">이용안내</Link>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Grid item className={classes.adminModeBtn}>
              <RoundButton to="/admin" color={globalTheme.greyColor}>
                <SettingsIcon style={{ marginRight: "5px" }} /> 관리자 모드
              </RoundButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.copyRight}>Copyright ⓒ 부산광역시교육청 All rights reserved.</div>
        </Grid>
      </Grid>
    </FooterContainer>
  );
};
export default Footer;
